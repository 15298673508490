import { makeStyles } from "@material-ui/styles"
import { lazy, Suspense, useEffect } from "react"
import { Switch, useRouteMatch } from "react-router-dom/cjs/react-router-dom"
import CommonRoute from "../../components/CommonRoute"
import Loader from "../../components/Loader/Loader"
import { wait } from "../../utils/wait"
import { useDispatch } from "react-redux"
import { resetPropertySearchParams } from "../../redux/actions/bookingRoomAction.js"
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

const PropertyDetails = lazy(() =>
  wait(1000).then(() => import("./propertyDetails.jsx"))
)
const CheckoutPage = lazy(() => wait(1000).then(() => import("./checkOut.jsx")))

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    position: "relative",
    fontFamily: "Poppins",
  },
  header: {
    background:
      "linear-gradient(to right,rgba(252, 184, 53, 1),rgba(244, 109, 37, 1))",
    height: "20rem",
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

function PropertyViewNew() {
  const classes = useStyles()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetPropertySearchParams())
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={classes.root}>
        <section className={classes.header}></section>
        <Switch>
          <Suspense fallback={<Loader />}>
            <CommonRoute
              exact
              path={`${url}/checkout`}
              component={CheckoutPage}
            />
            <CommonRoute
              exact
              path={`${url}/:city/:propertyId`}
              component={PropertyDetails}
            />
          </Suspense>
        </Switch>
      </div>
    </LocalizationProvider>
  )
}

export default PropertyViewNew
