import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "./ImageSlider.css"
import { baseurl } from "../../Service/httpCommon"
import Rating from "../OrderBooking/Rating"
import Api from "../../Service/Api"
import { Link } from "react-router-dom"
import { Card, Paper, Stack } from "@mui/material"
import { twnButtonStyles } from "../../utils/townoStyle"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch } from "react-redux"
import { handleChangeSearchParams } from "../../redux/actions/bookingRoomAction"

export default function ImageSlider() {
  const [popularHotels, setPopularHotels] = useState([])
  const [deviceInnerWidth, setDeviceInnerWidth] = useState(window.innerWidth)
  const [fetchingData, setFetchingData] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleWindowSizeChange = () => {
    setDeviceInnerWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    setFetchingData(true)
    Api.get("agentpopularactive").then((res) => {
      setPopularHotels(res.data)
      setFetchingData(false)
    })
  }, [])
  const settings = {
    dots: false,
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 2000,
    width: "346px",
    infinite: popularHotels.length > 3,
  }

  return (
    <div className="imm">
      <Slider {...settings}>
        {popularHotels.map((item, index) => (
          <div
            className="card-item"
            key={index}
            onClick={() => {
              dispatch(
                handleChangeSearchParams("location", {
                  label: item.city,
                  value: item.city,
                })
              )
              dispatch(
                handleChangeSearchParams("propertyId", {
                  label: item.propertyName,
                  value: item.propertyId,
                })
              )
              history.push(
                `/konnect/propertyNewView/${item.city}/${item.propertyId}`
              )
            }}
          >
            <div className="card-inner">
              <div className="card-top">
                {item.topLeftLabel ? <span>{item.topLeftLabel}</span> : null}
                <img
                  src={`${baseurl}getimage/${item.image}`}
                  alt="HotelImage"
                />
              </div>
              <div
                className="card-bottom"
                style={{ borderRadius: "0 0 5px 5px" }}
              >
                <div className="card-info">
                  <Rating rating={item.starRate} />
                  <h5>{item.propertyName}</h5>
                  <p>{item.city}</p>
                  <div style={{ marginTop: "2px" }}>
                    <span style={{ marginRight: "10px" }}>Starting from</span>
                    <button>Rs.{item.minmumprice}/-</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
