import { useCallback, useState } from "react"

export const useInViewObserver = () => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const containerRef = useCallback((node) => {
    if (node == null) return

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log("Intersecting")

        setIsIntersecting(true)
      }
    })

    const element = node
    observer.observe(element)
    return () => observer.disconnect()
  }, [])

  return { isIntersecting, containerRef }
}
