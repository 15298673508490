import { filter } from "lodash";
import { useState, useEffect, useRef } from "react";
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  InputAdornment,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import XLSX from "xlsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TravelAgentView from "./TravelAgentView";
// components
import jsPDF from "jspdf";
import {
  TableListHead,
  TableListToolbar,
  SearchNotFound,
} from "../../components/tabel";
import "jspdf-autotable";
import EditIcon from "@material-ui/icons/Edit";
import TravelAgentForm from "./TravelAgentForm";
import Api from "../../Service/Api";
import { baseurl } from "../../Service/httpCommon";
import { AddCircleRounded } from "@material-ui/icons";
import { SearchStyle } from "../../components/tabel/TableListToolbar";
import { twnButtonStyles } from "../../utils/townoStyle";
import SearchIcon from "@material-ui/icons/Search";
import { Grid, Stack } from "@mui/material";
import { ReactComponent as EditIconCustom } from "../../assets/Icons/editCustomIcon.svg";
import Loader from "../../components/Loader/Loader";

const TABLE_HEAD = [
  { id: "profile", label: "Avatar" },
  { id: "name", label: "Agent Name" },
  { id: "Email", label: "Email" },
  { id: "mobile", label: "Mobile" },
  { id: "altmobile", label: "Alt Mobile" },
  { id: "city", label: "City" },
  { id: "Agent Category", label: "Agent Category" },
  { id: "", label: "Action", align: "right" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_designation) =>
        _designation.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TravelAgent() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewselectedId, setViewselectedId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [travelAgentList, setTravelAgentList] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleScreenSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenSizeChange);

    return () => window.removeEventListener("resize", handleScreenSizeChange);
  }, [width]);

  const OpenDialog = async (row) => {
    setOpen(true);
    setSelectedId(row.id);
  };
  useEffect(() => {
    TravelAgent();
  }, []);

  const TravelAgent = async () => {
    await Api.get("agentlist").then((res) => {
      setTravelAgentList(res.data);
    });
  };
  const OpenDialogView = async (row) => {
    setOpenView(true);
    setViewselectedId(row.id);
  };
  const CloseDialogView = () => {
    setOpenView(false);
    setViewselectedId(0);
    setSelectedId(0);
  };
  const CloseDialog = () => {
    setOpen(false);
    setSelectedId(0);
    TravelAgent();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadExcel = () => {
    const newData = travelAgentList.map((row) => {
      delete row.id;
      delete row.uniqueId;
      delete row.createdBy;
      delete row.createdAt;
      delete row.updateBy;
      delete row.updateAt;
      delete row.status;
      delete row.profile;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Designation List");
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "DesignationList.xlsx");
  };

  const DownloadPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.text("Designation Details", 20, 10);
    doc.autoTable({
      body: travelAgentList,
      columns: [
        { header: "Designation", dataKey: "name" },
        { header: "Description", dataKey: "description" },
      ],
    });
    doc.save("Designation List.pdf");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - travelAgentList.length)
      : 0;
  const filteredDesignation = applySortFilter(
    travelAgentList,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = filteredDesignation.length === 0;

  const totalDataLength = filteredDesignation.length;
  const dataPerPage = 5;
  let noOfPages = Math.ceil(totalDataLength / dataPerPage);
  console.log("totalDataLength", totalDataLength, noOfPages);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  let tempDataIndex = currentPageNo * dataPerPage; //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength);
  let firstDataIndex = 0;
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex);

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value; //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo);
    }
  };

  const loaderRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const scrollPosition = window.pageYOffset;

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    );

    console.log("offsetHeight", document.documentElement.offsetHeight);

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false);

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight - 1
    ) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        changePageNo(1);
      }, 2000);
      console.log("ok");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [firstDataIndex, lastDataIndex, currentPageNo]);

  return (
    <>
      {width <= 768 ? (
        <section style={{ padding: "60px 20px" }}>
          <header
            style={{
              display: "flex",
              alignItems: "safe center",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ color: "#f46d24" }}>Travel Agent</h2>
          </header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="first baseline"
          >
            <SearchStyle
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search Agent"
              style={{ ...twnButtonStyles.smFonts, marginTop: "1em" }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "text.disabled" }} color="primary" />
                </InputAdornment>
              }
            />
            <Stack direction="row" textAlign="end">
              <IconButton
                onClick={() => {
                  DownloadPdf();
                }}
                style={{ padding: "5px" }}
              >
                <i
                  className="fas fa-file-pdf"
                  style={{
                    color: "#F46D25",
                    textAlign: "center",
                    fontSize: "28px",
                  }}
                ></i>
              </IconButton>

              <IconButton
                onClick={() => {
                  downloadExcel();
                }}
                style={{ padding: "5px" }}
              >
                <i
                  className="fas fa-file-csv"
                  style={{
                    color: "#F46D25",
                    textAlign: "center",
                    fontSize: "28px",
                  }}
                ></i>
              </IconButton>

              <IconButton onClick={OpenDialog} style={{ padding: "5px" }}>
                <AddCircleRounded
                  color="primary"
                  style={{ height: "30px", width: "30px" }}
                />
              </IconButton>
            </Stack>
          </Stack>
          <section style={{ marginTop: "15px" }}>
            {filteredDesignation
              .slice(firstDataIndex, lastDataIndex)
              .map((row) => {
                const {
                  id,
                  logoPath,
                  name,
                  mail,
                  mobile,
                  altmobile,
                  city,
                  travelAgentCategory,
                } = row;
                return (
                  <Card
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <CardHeader
                      style={{
                        background: "#f46d25",
                        height: "fit-content",

                        borderRadius: "10px",
                      }}
                      action={
                        <IconButton
                          style={{
                            background: "white",
                            padding: "5px",
                            // marginTop: "20px",
                          }}
                          onClick={() => {
                            OpenDialog(row);
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      }
                      avatar={
                        logoPath ? (
                          <img
                            src={`${baseurl}getimage/${logoPath}`}
                            alt="profile"
                            style={{
                              width: "30px",
                              borderRadius: "50%",
                              height: "30px",
                              background: "white",
                            }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              width: "30px",
                              borderRadius: "50%",
                              height: "30px",
                            }}
                          />
                        )
                      }
                      title={<div style={{ color: "white" }}> {name}</div>}
                      subheader={<div style={{ color: "white" }}>{mail}</div>}
                    />
                    <CardContent
                      style={{ paddingBottom: "6px", paddingTop: "6px" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <small style={{ fontWeight: "bold" }}>
                              Mobile No.
                            </small>
                          </Grid>
                          <Grid item xs={0.5} style={{ color: "#f46d25" }}>
                            :
                          </Grid>
                          <Grid item xs={5.5}>
                            <small>{mobile}</small>
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={5}>
                            <small style={{ fontWeight: "bold" }}>
                              Alt No.
                            </small>
                          </Grid>
                          <Grid item xs={0.5} style={{ color: "#f46d25" }}>
                            :
                          </Grid>
                          <Grid item xs={6}>
                            {altmobile ? altmobile : "---"}
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <small style={{ fontWeight: "bold" }}>City</small>
                          </Grid>
                          <Grid item xs={0.5} style={{ color: "#f46d25" }}>
                            :
                          </Grid>
                          <Grid item xs={5.5}>
                            <small>{city}</small>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={2.95}>
                            <small style={{ fontWeight: "bold" }}>
                              Agent Category
                            </small>
                          </Grid>
                          <Grid item xs={0.3} style={{ color: "#f46d25" }}>
                            :
                          </Grid>
                          <Grid item xs={5.5}>
                            <small>{travelAgentCategory?.toUpperCase()}</small>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            <div ref={loaderRef} />
            {loading && (
              <div
                style={{
                  top: 0,
                  width: "100%",
                  height: "5%",
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            )}
          </section>
        </section>
      ) : (
        <div style={{ padding: "4% 1% 1% 1%" }}>
          <div>
            <TableListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              pdfDownload={DownloadPdf}
              downloadExcel={downloadExcel}
              searchPlaceholderName={"Search Your TravelAgent"}
              tableName={"Travel Agent List"}
              open={OpenDialog}
            />
            <Card>
              <TableContainer>
                <Table>
                  <TableListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={travelAgentList.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredDesignation
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          logoPath,
                          name,
                          mail,
                          mobile,
                          altmobile,
                          city,
                          travelAgentCategory,
                        } = row;
                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell align="left">
                              {logoPath ? (
                                <img
                                  src={`${baseurl}getimage/${logoPath}`}
                                  alt="profile"
                                  style={{
                                    width: "40px",
                                    borderRadius: "50%",
                                    height: "40px",
                                  }}
                                />
                              ) : (
                                <Avatar></Avatar>
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell align="left">{mail}</TableCell>
                            <TableCell align="left">{mobile}</TableCell>
                            <TableCell align="left">{altmobile}</TableCell>
                            <TableCell align="left">{city}</TableCell>
                            <TableCell align="left">
                              {travelAgentCategory}
                            </TableCell>

                            <TableCell align="left">
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    OpenDialog(row);
                                  }}
                                  style={{ padding: "0px", color: "#F46D25" }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="view"
                                  style={{
                                    padding: "0px",
                                    marginLeft: "10px",
                                    color: "#F46D25",
                                  }}
                                  onClick={() => {
                                    OpenDialogView(row);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={9} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredDesignation.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </div>
      )}{" "}
      <TravelAgentForm
        open={open}
        onClose={CloseDialog}
        selectedId={selectedId}
      />
      <TravelAgentView
        open={openView}
        onClose={CloseDialogView}
        selectedIdView={viewselectedId}
      />
    </>
  );
}
