import Select from "react-select"

const TownoThemedSelect = (props) => {
  return (
    <Select
      {...props}
      styles={{
        menu: (baseStyles, state) => ({
          ...baseStyles,
          zIndex: 10,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background: state.isFocussed && "#f46d2540",
          cursor: "pointer",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: "#f46d25",
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: "#f46d25",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#f46d2540",
          primary: "#f46d25",
        },
      })}
    />
  )
}

export default TownoThemedSelect
