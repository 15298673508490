/* eslint-disable eqeqeq */
import { React, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { clearCookies } from "../../utils/clearCookie";
import "./MoreT.scss";

export function Admin() {
  let hasAdmin = localStorage.getItem("role");
  return (
    <>
      {hasAdmin === "Agent Admin" ? (
        <div className="moret">
          <NavLink to="/konnect/designation" activeClassName="active-link">
            Designation
          </NavLink>
          <NavLink to="/konnect/roleList" activeClassName="active-link">
            Role
          </NavLink>
          <NavLink to="/konnect/staff" activeClassName="active-link">
            Staff
          </NavLink>
          <NavLink to="/konnect/Commonfeature" activeClassName="active-link">
            Add Common
          </NavLink>
        </div>
      ) : (
        <div className="moret">
          <>
            {hasAdmin !== "Hotel Admin" && (
              <NavLink to="/konnect/branch" activeClassName="active-link">
                Branch
              </NavLink>
            )}
            <NavLink to="/konnect/designation" activeClassName="active-link">
              Designation
            </NavLink>
          </>

          <NavLink to="/konnect/role" activeClassName="active-link">
            Role
          </NavLink>
          <NavLink to="/konnect/staff" activeClassName="active-link">
            Staff
          </NavLink>
          <NavLink to="/konnect/dropdown" activeClassName="active-link">
            Add Feature
          </NavLink>
          <NavLink to="/konnect/Commonfeature" activeClassName="active-link">
            Add Common
          </NavLink>
          <NavLink to="/konnect/addPackageCard" activeClassName="active-link">
            B2C Add Package Card
          </NavLink>
          {/* <NavLink
            to="/konnect/mostPopularHotels"
            activeClassName="active-link"
          >
            Most Popular Hotels
          </NavLink>
          <NavLink
            to="/konnect/mostPopularPackages"
            activeClassName="active-link"
          >
            Most Popular Packages
          </NavLink>
          <NavLink to="/konnect/bestExperiences" activeClassName="active-link">
            Best Experiences
          </NavLink> */}
          {/* <NavLink
            to="/konnect/favouriteDestination"
            activeClassName="active-link"
          >
            Favourite Destination
          </NavLink> */}
        </div>
      )}
    </>
  );
}

export function Clients() {
  return (
    <div className="moret">
      <NavLink to="/konnect/lead" activeClassName="active-link">
        Lead Dashboard
      </NavLink>
      <NavLink to="/konnect/client" activeClassName="active-link">
        Client
      </NavLink>
      <NavLink to="/konnect/myEnquiries" activeClassName="active-link">
        My Enquiries
      </NavLink>
    </div>
  );
}

export function FinanceTeam() {
  return (
    <div className="moret">
      <NavLink to={"/konnect/bookingList"} activeClassName="active-link">
        Booking List
      </NavLink>
      <NavLink
        to="/konnect/packageOrQuotation/package"
        activeClassName="active-link"
      >
        Package
      </NavLink>
      <NavLink to="/konnect/transferList" activeClassName="active-link">
        Transfer
      </NavLink>
      <NavLink to="/konnect/invoiceList" activeClassName="active-link">
        Invoice List
      </NavLink>
    </div>
  );
}

export function Agent() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleScreenSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenSizeChange);
    return () => window.removeEventListener("resize", handleScreenSizeChange);
  }, [width]);

  return (
    <div className="moret">
      {width <= 768 ? (
        <NavLink to="/konnect/travelAgent" activeClassName="active-link">
          Add Travel Agent
        </NavLink>
      ) : (
        <>
          <NavLink to="/konnect/travelAgent" activeClassName="active-link">
            Add Travel Agent
          </NavLink>

          <NavLink to="/konnect/mostPopular" activeClassName="active-link">
            Most Popular
          </NavLink>
          <NavLink to="/konnect/lastMinuteDeals" activeClassName="active-link">
            Last Minute Deals
          </NavLink>
          <NavLink to="/konnect/DealoftheWeek" activeClassName="active-link">
            Deal of the Week
          </NavLink>
          <NavLink to="/konnect/bestSelling" activeClassName="active-link">
            Best Selling
          </NavLink>
        </>
      )}
    </div>
  );
}
export function Booking() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  let role = localStorage.getItem("role");
  let agent = localStorage.getItem("agent");
  console.log(role, agent);

  if (width <= 768) {
    return (
      <div className="moret">
        {role !== "ViewOnly" && (
          <>
            {" "}
            <NavLink to="/konnect/client" activeClassName="active-link">
              Client
            </NavLink>
            <NavLink
              to={
                role === "Hotel Admin" || agent === "Hotel Admin"
                  ? "/konnect/hotelNewBooking"
                  : "/konnect/newBooking"
              }
              activeClassName="active-link"
            >
              New Booking
            </NavLink>
            <NavLink
              to={
                role === "Hotel Admin" || agent === "Hotel Admin"
                  ? "/konnect/hotelBookingList"
                  : "/konnect/bookingList"
              }
              activeClassName="active-link"
            >
              Booking List
            </NavLink>
            <NavLink
              to="/konnect/packageOrQuotation/package"
              activeClassName="active-link"
            >
              Package
            </NavLink>
            <NavLink to="/konnect/transferList" activeClassName="active-link">
              Transfer
            </NavLink>
            <NavLink to="/konnect/invoiceList" activeClassName="active-link">
              Invoice List
            </NavLink>
          </>
        )}
        {role === "ViewOnly" && (
          <NavLink to="/konnect/bookinglist" activeClassName="active-link">
            Booking List
          </NavLink>
        )}
      </div>
    );
  } else {
    return (
      <div className="moret">
        <NavLink to="/konnect/client" activeClassName="active-link">
          Client
        </NavLink>
        <NavLink
          to={
            role === "Hotel Admin" || agent === "Hotel Admin"
              ? "/konnect/hotelNewBooking"
              : "/konnect/newBooking"
          }
          activeClassName="active-link"
        >
          New Booking
        </NavLink>
        <NavLink
          to={
            role === "Hotel Admin" || agent === "Hotel Admin"
              ? "/konnect/hotelBookingList"
              : "/konnect/bookingList"
          }
          activeClassName="active-link"
        >
          Booking List
        </NavLink>
        {role !== "Hotel Admin" && (
          <NavLink to="/konnect/lead" activeClassName="active-link">
            Lead Dashboard
          </NavLink>
        )}
        <>
          <NavLink
            to="/konnect/packageOrQuotation/package"
            activeClassName="active-link"
          >
            Package
          </NavLink>
          <NavLink to="/konnect/transferList" activeClassName="active-link">
            Travel Transfer
          </NavLink>
        </>

        <NavLink to={"/konnect/invoiceList"} activeClassName="active-link">
          GST Invoice List
        </NavLink>
        <NavLink to="/konnect/activityList" activeClassName="active-link">
          Activity List
        </NavLink>
        <NavLink to="/konnect/agencyOnboarding" activeClassName="active-link">
          Agency Onboarding
        </NavLink>
        <NavLink to="/konnect/agencyList" activeClassName="active-link">
          Travel Agent List
        </NavLink>
      </div>
    );
  }
}

export function AgentBookings() {
  let hasAdmin = localStorage.getItem("role");
  let checkAgent = localStorage.getItem("agent");

  return (
    <>
      {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
        <div className="moret">
          <NavLink
            to="/konnect/packageOrQuotation/package"
            activeClassName="active-link"
          >
            Package
          </NavLink>
          {window.innerWidth <= 768 && (
            <NavLink
              to="/konnect/newAgentBooking"
              activeClassName="active-link"
            >
              New Booking
            </NavLink>
          )}
          <NavLink to="/konnect/agentBookings" activeClassName="active-link">
            {window.innerWidth <= 768 ? "Booking List" : "Hotel Booking"}
          </NavLink>
          <NavLink to="/konnect/transferList" activeClassName="active-link">
            Travel Transfer
          </NavLink>
          <NavLink to="/konnect/invoiceList" activeClassName="active-link">
            GST Invoice List
          </NavLink>
        </div>
      ) : null}
    </>
  );
}

export function Vendors() {
  let hasAdmin = localStorage.getItem("role");
  return (
    <div className="moret">
      {hasAdmin !== "Common" && (
        <NavLink to="/konnect/properties" activeClassName="active-link">
          Property List
        </NavLink>
      )}

      <NavLink to="/konnect/roomListView" activeClassName="active-link">
        Calender View
      </NavLink>
    </div>
  );
}

export function Property() {
  let hasAdmin = localStorage.getItem("role");
  let unique_id = localStorage.getItem("unique_id");

  useEffect(() => {
    hasAdmin === "Hotel Admin" &&
      sessionStorage.setItem("propertyId", unique_id);
  }, [unique_id]);

  return (
    <>
      {hasAdmin === "Hotel Admin" ? (
        <div className="moret">
          <NavLink
            to={`/konnect/hotelView/${unique_id}`}
            activeClassName="active-link"
          >
            Property Details
          </NavLink>
          <NavLink
            to="/konnect/hotelCalenderView"
            activeClassName="active-link"
          >
            Calender View
          </NavLink>
        </div>
      ) : (
        <div className="moret">
          {hasAdmin === "Agent Admin" ? (
            <NavLink to="/konnect/properties" activeClassName="active-link">
              Properties
            </NavLink>
          ) : (
            <NavLink to="/konnect/propertyList" activeClassName="active-link">
              Properties
            </NavLink>
          )}
          <NavLink to="/konnect/roomListView" activeClassName="active-link">
            Calender View
          </NavLink>
        </div>
      )}
    </>
  );
}

export function Profile() {
  const history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    clearCookies();
    window.open("/konnect/login", "_self");
  };

  return (
    <div className="moret">
      <NavLink to="/konnect/profile" activeClassName="active-link">
        Profile
      </NavLink>
      <NavLink to="/konnect/changePassword" activeClassName="active-link">
        Change Password
      </NavLink>
      <Link onClick={logout}>Logout</Link>
    </div>
  );
}

export function TravelAgentCommonCalenderView() {
  return (
    <div className="moret">
      <NavLink to="/konnect/roomListView" activeClassName="active-link">
        Calender View
      </NavLink>
    </div>
  );
}
