import { Button, IconButton, TextField } from "@material-ui/core"
import {
  DeleteForever,
  LocationOnOutlined,
  Remove,
  StarBorder,
} from "@material-ui/icons"
import { Rating } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import { Grid } from "@mui/material"
import { differenceInDays, format } from "date-fns"
import { createContext, useContext, useEffect, useState } from "react"
import { FaArrowRightLong } from "react-icons/fa6"
import { useRazorpay } from "react-razorpay"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import API from "../../Service/Api"
import { razorPayApiKey } from "../../Service/httpCommon"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import {
  resetPropertyRoomsCheckout,
  resetPropertySearchParams,
} from "../../redux/actions/bookingRoomAction"
import { resetHotelDetails } from "../../redux/actions/hotelViewAction"
import { propertyAddress } from "./propertyDetails"
import { MobileDatePicker } from "@mui/x-date-pickers-pro"
import dayjs from "dayjs"
import TownoThemedSelect from "../../components/townoThemedSelect/townoThemedSelect.component"
import { map } from "lodash"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

let rEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

let guestSchema = Yup.object({
  firstName: Yup.string().required("firstname is required"),
  lastName: Yup.string().required("lastname is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
  email: Yup.string().matches(rEmail, "Invalid Mail ID"),
  // birthDate: Yup.date()
  //   .required("Date of birth is required")
  //   .typeError("Invalid date format")
  //   .test("age", "You must be at least 18 years old", (value) => {
  //     if (!value) return false // If no date is selected, the test fails
  //     // Check if the date is before 18 years ago
  //     return new Date(value) <= dayjs().subtract(18, "year").toDate()
  //   }),
  city: Yup.string().required("city is required"),
  country: Yup.string().required("country is required"),
  address: Yup.string().required("address is required"),
  state: Yup.string().required("state is required"),
  zip: Yup.string().required("zip is required"),
})

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    inset: 0,
    width: "100%",
    padding: "5rem 1.5rem 5rem 1.75rem",
  },
  header: {
    fontSize: "2rem",
    fontWeight: 600,
    color: "#fff",
    textShadow: "0rem 0rem 0.4rem #000",
  },
  propertyCard: {
    boxShadow: "0rem 0rem 0.5rem rgba(231, 231, 231, 1)",
    background: "#fff",
    borderRadius: "0.2rem",
    padding: "0.5rem",
  },
  container: {
    boxShadow: "0rem 0rem 0.5rem rgba(231, 231, 231, 1)",
    background: "#fff",
    borderRadius: "0.2rem",
    padding: "0.5rem",
  },
  type1: {
    background:
      " radial-gradient(circle at bottom left,transparent 30px,#F7F7FE 0) bottom left,radial-gradient(circle at bottom right,transparent 30px,#F7F7FE 0) bottom right",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
  },
}))

const namePrefixOptions = [
  { label: "Mr", value: "Mr" },
  { label: "Ms", value: "Ms" },
  { label: "Mrs", value: "Mrs" },
]

const namePrefixValues = {
  Mr: { label: "Mr", value: "Mr" },
  Ms: { label: "Ms", value: "Ms" },
  Mrs: { label: "Mrs", value: "Mrs" },
}

const GuestContext = createContext({
  additionalGuestDetails: null,
  updateAdditionalGuests: (guests) => null,
  addAdditionalGuests: (key, room) => null,
  removeAdditionalGuests: (key, index) => null,
  handleChangeAdditionalGuests: (
    e,
    key,
    index,
    isSelect = false,
    selectName = ""
  ) => null,
})

const CheckoutPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  // const unique_id = localStorage.getItem("unique_id")
  const { loading: hotelLoading, hotelDetails } = useSelector(
    ({ hotelDetail }) => hotelDetail
  )

  const {
    location,
    propertyId: property,
    checkinFrom,
    checkinTo,
    rooms,
    children,
    adults,
    openModal,
  } = useSelector(({ searchProperties }) => searchProperties)

  const {
    checkoutRoomDetails,
    totalBookingAmount,
    totalRooms,
    checkIn,
    checkOut,
    guestName,
    guestMobileNo,
    guestEmail,
    additionalGuestInfo,
    fetching,
    propertyId,
  } = useSelector(({ checkoutRooms }) => checkoutRooms)

  const [errors, setErrors] = useState({})
  const { error, isLoading, Razorpay } = useRazorpay()
  const [initiatePayment, setInitiatePayment] = useState(false)

  const [leadGuest, setLeadGuestDetails] = useState({
    title: "Mr",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    birthDate: dayjs(),
    address: "",
    city: "",
    country: "",
    state: "",
    zip: "",
  })

  const [additionalGuestDetails, setAdditionalGuestDetails] = useState(
    new Map()
  )

  const handleChangeGuestDetails = async (e) => {
    const { name, value } = e.target
    setLeadGuestDetails((c) => ({ ...c, [name]: value }))

    try {
      // Validate a single field dynamically
      await guestSchema.validateAt(name, { [name]: value })
      setErrors((prev) => ({ ...prev, [name]: "" })) // Clear the error if validation passes
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }))
    }
  }

  const handleChangeAdditionalGuests = (
    e,
    key,
    index,
    isSelect = false,
    selectName = ""
  ) => {
    setAdditionalGuestDetails((curr) => {
      const newMap = new Map(curr)
      let updatedGuests = newMap.get(key)

      if (!isSelect) {
        const { name, value } = e.target
        updatedGuests = updatedGuests.map((g, i) => {
          if (i == index) {
            return { ...g, [name]: value }
          }
          return g
        })
      } else {
        updatedGuests = updatedGuests.map((g, i) => {
          if (i === index) {
            return { ...g, [selectName]: e.value }
          }
          return g
        })
      }
      newMap.set(key, updatedGuests)
      return newMap
    })
  }

  const updateAdditionalGuests = (guests) => {
    setAdditionalGuestDetails(guests)
  }

  const addAdditionalGuests = (key, room) => {
    const body = {
      roomType: room.displayName,
      roomNo: room.noOfRooms,
      type: "adult",
      firstName: "",
      lastName: "",
      title: "Mr",
    }

    setAdditionalGuestDetails((curr) => {
      const updatedMap = new Map(curr)
      const existingGuests = updatedMap.get(key) || []
      updatedMap.set(key, [...existingGuests, body])
      return updatedMap
    })
  }

  const removeAdditionalGuests = (key, index) => {
    setAdditionalGuestDetails((curr) => {
      const newMap = new Map(curr)
      let existingGuests = newMap.get(key)
      existingGuests = existingGuests.filter((g, i) => i !== index)
      newMap.set(key, existingGuests)
      return newMap
    })
  }

  const mergeSelectedRoomsGuestDetails = () => {
    let guestDetails = []
    console.log({ additionalGuestDetails })

    for (let [key, value] of additionalGuestDetails) {
      guestDetails = [...guestDetails, ...value]
    }
    console.log({ guestDetails })
    return guestDetails
  }

  const handlePayment = () => {
    guestSchema
      .validate(leadGuest, { strict: true })
      .then(async () => {
        const postBody = {
          uniqueId: localStorage.getItem("unique_id"),
          bookingId: null,
          propertyId: hotelDetails.propertyId,
          checkIn: format(new Date(checkinFrom), "yyyy-MM-dd"),
          checkOut: format(new Date(checkinTo), "yyyy-MM-dd"),
          noOfNights: differenceInDays(
            new Date(checkinTo),
            new Date(checkinFrom)
          ),
          totalRooms,
          totalBookingAmount,
          leadGuest: {
            birthDate: dayjs(leadGuest.birthDate).format("YYYY-MM-DD"),
            address: leadGuest.address,
            city: leadGuest.city,
            country: leadGuest.country,
            email: leadGuest.email,
            phone: leadGuest.phone,
            state: leadGuest.state,
            zip: leadGuest.zip,
            firstName: leadGuest.firstName,
            lastName: leadGuest.lastName,
            title: leadGuest.title,
          },
          createdBy: localStorage.getItem("auth"),
          checkoutRoomDetails: checkoutRoomDetails.filter(
            (r) => r.noOfRooms != 0
          ),
          additionalGuestInfo: mergeSelectedRoomsGuestDetails(),
        }

        setInitiatePayment(true)
        try {
          const key = razorPayApiKey
          const res = await API.get(
            `createRazorPayOrder?amount=${totalBookingAmount}`
          )
          console.log({ payment: res.data })

          const options = {
            key: key,
            amount: parseFloat(totalBookingAmount) * 100, // Amount in paise
            currency: res.data.currency,
            name: "Towno",
            image:
              "https://towno.in/static/media/towno.fontImage.e2f4a9c0a37977ce1fdb6845e0ec5325.svg",
            description: "Test Transaction",
            order_id: res.data.id,
            handler: async (response) => {
              const updatedPostBody = { ...postBody, paymentDetails: response }
              setInitiatePayment(true)

              await API.post("checkoutBooking", updatedPostBody)

              setInitiatePayment(false)
              history.replace("/konnect/checkoutBookingList")
              dispatch(resetPropertySearchParams())
              dispatch(resetPropertyRoomsCheckout())
              dispatch(resetHotelDetails())

              alert("Payment Successful!")
            },
            prefill: {
              name: `${leadGuest.firstName} ${leadGuest.lastName}`,
              email: leadGuest.email,contact: leadGuest.phone,
            },
            theme: {
              color: "#f46d25",
            },
          }

          const razorpayInstance = new Razorpay(options)
          razorpayInstance.open()
        } catch (error) {
          console.log(error)
          toast.error("Unable to process the request")
        } finally {
          setInitiatePayment(false)
        }
      })
      .catch((validationErrors) => {
        const formattedErrors = validationErrors?.inner?.reduce(
          (acc, error) => ({ ...acc, [error.path]: error.message }),
          {}
        )
        setErrors(formattedErrors)
      })
  }

  return (
    <div className={classes.root}>
      <Grid container md={12}>
        <Grid item md={12} className={classes.header}>
          Review your Booking
        </Grid>
        <Grid item container md={12} spacing={2} paddingTop={4}>
          <Grid container item md={4} alignSelf="flex-start">
            <Grid item container md={12} className={classes.propertyCard}>
              <Grid item md={12}>
                <span
                  style={{
                    fontSize: "0.8rem",
                    color: "rgba(154, 158, 166, 1)",
                    mixBlendMode: "difference",
                  }}
                >
                  {hotelDetails.starRating} Star Hotel in {hotelDetails.state}
                </span>
              </Grid>
              <Grid item container md={12}>
                <Grid item md={5}>
                  <div style={{ fontSize: "1.6rem", fontWeight: 600 }}>
                    {hotelDetails.displayName}
                  </div>
                  <Rating
                    readOnly
                    value={Number(hotelDetails.starRating)}
                    size="small"
                    emptyIcon={<StarBorder fontSize="inherit" />}
                    classes={{
                      iconFilled: {
                        style: {
                          background:
                            "linear-gradient(rgba(252, 214, 53, 1),rgba(247, 169, 40, 1))",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  fontSize: "0.85rem",
                  color: "rgba(154, 158, 166, 1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlined color="primary" />
                <>{propertyAddress(hotelDetails)}</>
              </div>
            </Grid>

            <Grid
              item
              container
              md={12}
              className={classes.container}
              marginTop={1.5}
            >
              <Grid item md={12} fontWeight={600}>
                Your Booking Details
              </Grid>

              <Grid
                item
                container
                md={12}
                padding={1}
                className={classes.type1}
              >
                <Grid item container md={4} textAlign={"center"}>
                  <Grid item md={12} fontSize={"0.75rem"} color={"#626263"}>
                    Check-in
                  </Grid>
                  <Grid item md={12} fontWeight={600}>
                    {format(new Date(checkIn ?? checkinFrom), "do MMM")}
                  </Grid>
                  <Grid item md={12} fontSize={"0.75rem"} color={"#626263"}>
                    {format(new Date(checkOut ?? checkinTo), "iii")}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                  display={"flex"}
                  justifyContent={"center"}
                  fontSize={"0.8rem"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      background: "#f46d25",
                      padding: "0.5rem",
                      width: "fit-content",
                      color: "#fff",
                      borderRadius: "0.3rem",
                      cursor: "none",
                      alignSelf: "flex-start",
                    }}
                  >
                    {differenceInDays(
                      new Date(checkIn ?? checkinTo),
                      new Date(checkOut ?? checkinFrom)
                    )}{" "}
                    night
                    {differenceInDays(
                      new Date(checkIn ?? checkinTo),
                      new Date(checkOut ?? checkinFrom)
                    ) == 1
                      ? null
                      : "s"}
                  </div>
                </Grid>
                <Grid item md={4} textAlign={"center"}>
                  <Grid item md={12} fontSize={"0.75rem"} color={"#626263"}>
                    Check-out
                  </Grid>
                  <Grid item md={12} fontWeight={600}>
                    {format(new Date(checkIn ?? checkinTo), "do MMM")}
                  </Grid>
                  <Grid item md={12} fontSize={"0.75rem"} color={"#626263"}>
                    {format(new Date(checkIn ?? checkinTo), "iii")}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container md={8}>
            <Grid container item md={12} className={classes.container}>
              <Grid item md={12} fontWeight={600} fontSize="1.5rem">
                Client Details
              </Grid>
              <Grid item container md={12} spacing={2}>
                <Grid item md={2}>
                  <TownoThemedSelect
                    options={namePrefixOptions}
                    value={namePrefixValues[leadGuest.title]}
                    onChange={(option) =>
                      setLeadGuestDetails((c) => ({ ...c, title: option }))
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="First Name"
                    name="firstName"
                    value={leadGuest.firstName}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.firstName)}
                    helperText={errors?.firstName}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Last Name"
                    name="lastName"
                    value={leadGuest.lastName}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.lastName)}
                    helperText={errors?.lastName}
                  />
                </Grid>

                <Grid item md={4}>
                  <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    openTo="year"
                    label="Date Of Birth"
                    value={leadGuest.birthDate}
                    onChange={(date) =>
                      setLeadGuestDetails((c) => ({ ...c, birthDate: date }))
                    }
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        error={Boolean(errors?.birthDate)}
                        helperText={errors?.birthDate}
                      />
                    )}
                    closeOnSelect
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Contact No."
                    name="phone"
                    value={guestMobileNo ?? leadGuest.phone}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.phone)}
                    helperText={errors?.phone}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Email Id"
                    name="email"
                    value={guestEmail ?? leadGuest.email}
                    onChange={handleChangeGuestDetails}
                    type="email"
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Address"
                    name="address"
                    value={leadGuest.address}
                    onChange={handleChangeGuestDetails}
                    type="address"
                    error={Boolean(errors?.address)}
                    helperText={errors?.address}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Country"
                    name="country"
                    value={leadGuest.country}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.country)}
                    helperText={errors?.country}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="State"
                    name="state"
                    value={leadGuest.state}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.state)}
                    helperText={errors?.state}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="City"
                    name="city"
                    value={leadGuest.city}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.city)}
                    helperText={errors?.city}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Zip"
                    name="zip"
                    value={leadGuest.zip}
                    onChange={handleChangeGuestDetails}
                    type="text"
                    error={Boolean(errors?.zip)}
                    helperText={errors?.zip}
                  />
                </Grid>
              </Grid>

              <Grid item md={12}>
                <hr
                  style={{
                    borderTop: "1.75px dashed #9A9EA6B2",
                    background: "#fff",
                  }}
                />
              </Grid>

              <GuestContext.Provider
                value={{
                  additionalGuestDetails,
                  updateAdditionalGuests,
                  addAdditionalGuests,
                  removeAdditionalGuests,
                  handleChangeAdditionalGuests,
                }}
              >
                <Grid item container md={12} spacing={2}>
                  <Grid item md={12} fontSize={"1.5rem"} fontWeight={600}>
                    Guest Details
                  </Grid>
                  {checkoutRoomDetails?.map((r, idx) => (
                    <>
                      {console.log("Hello")}
                      <RoomGuestDetails room={r} idx={idx} />
                      {idx < checkoutRoomDetails.length - 1 && (
                        <Grid item md={12}>
                          <hr
                            style={{
                              borderTop: "1.75px dashed #9A9EA6B2",
                              background: "#fff",
                            }}
                          />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </GuestContext.Provider>
            </Grid>

            <Grid
              item
              container
              md={12}
              className={classes.container}
              marginTop={1}
            >
              <Grid item container md={12} fontSize="1.5rem" fontWeight={600}>
                <Grid item md={12}>
                  Total Price
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  style={{ background: "#F7F7FE", borderRadius: "0.5rem" }}
                  padding={1}
                  fontSize={"0.85rem"}
                >
                  <Grid item container md={12} spacing={1}>
                    {checkoutRoomDetails
                      ?.filter((r) => r.noOfRooms != 0)
                      .map((room, idx) => (
                        <Grid item container md={12}>
                          <Grid
                            item
                            md={6}
                            color={"#505661"}
                            textAlign={"left"}
                            fontWeight={"normal"}
                          >
                            {room.noOfRooms} room{room.noOfRooms > 1 ? "s" : ""}{" "}
                            x{" "}
                            {differenceInDays(
                              new Date(checkIn ?? checkinTo),
                              new Date(checkOut ?? checkinFrom)
                            )}{" "}
                            night
                            {differenceInDays(
                              new Date(checkIn ?? checkinTo),
                              new Date(checkOut ?? checkinFrom)
                            ) == 1
                              ? null
                              : "s"}
                          </Grid>
                          <Grid
                            item
                            md={6}
                            textAlign={"right"}
                            fontWeight={600}
                            fontSize={"1rem"}
                          >
                            {currencyFormatter(
                              parseFloat(room.roomRent) * room.noOfRooms
                            )}
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                  <Grid item md={12}>
                    <hr
                      style={{
                        borderTop: "1.75px dashed #9A9EA6B2",
                        background: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item md={7}></Grid>
                  <Grid item container md={5}>
                    <Grid item container md={8}>
                      <Grid item md={12} fontSize={"1rem"} fontWeight={600}>
                        Total
                      </Grid>
                      <Grid
                        item
                        md={12}
                        fontSize={"0.7rem"}
                        color={"#505661"}
                        fontWeight={"normal"}
                      >
                        Inclusive of all taxes
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      fontWeight={600}
                      fontSize={"1.2rem"}
                      textAlign={"right"}
                    >
                      {currencyFormatter(totalBookingAmount)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} textAlign={"right"} marginTop={2}>
              <Button
                size="small"
                style={{ borderRadius: "2rem" }}
                endIcon={<FaArrowRightLong />}
                onClick={handlePayment}
              >
                Pay Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ScreenLoader open={initiatePayment} />
    </div>
  )
}

const typeValue = {
  adult: { label: "Adult", value: "adult" },
  child: { label: "Child", value: "child" },
}

function RoomGuestDetails({ room, idx }) {
  const {
    additionalGuestDetails,
    addAdditionalGuests,
    removeAdditionalGuests,
    handleChangeAdditionalGuests,
  } = useContext(GuestContext)

  const [typeOptions, setTypeOptions] = useState([
    { label: "Adult", value: "adult", limit: room.adults, disable: false },
    { label: "Child", value: "child", limit: room.children, disable: false },
  ])

  const getKey = (room) => {
    return `${room.displayName}-${room.mealplan}`
  }

  return (
    <Grid item container md={12} spacing={2}>
      <Grid item container md={12}>
        <Grid item md={0.5}>
          {idx + 1}.
        </Grid>
        <Grid item md={11.5} fontWeight={600}>
          {room.displayName} ({room.mealplan}) - {room.noOfRooms} x room{" "}
          <small
            style={{ color: "red", fontWeight: "normal", fontStyle: "italic" }}
          >
            (Max.{" "}
            {room.adults &&
              `${Number(room.adults) * Number(room.noOfRooms)} Adults`}{" "}
            {room.children &&
              `, ${
                Number(room?.children) * Number(room.noOfRooms)
              } children`}{" "}
            )
          </small>
        </Grid>
      </Grid>

      <Grid item container md={12} spacing={1} marginLeft={"2rem"}>
        {additionalGuestDetails?.get(getKey(room))?.map((g, index) => (
          <Grid item container md={12} alignItems={"center"} spacing={1}>
            <Grid item md={1.5}>
              <TownoThemedSelect
                options={namePrefixOptions}
                value={namePrefixValues[g.title]}
                onChange={(option) => {
                  // handleChange(option, index, "title", true)
                  handleChangeAdditionalGuests(
                    option,
                    getKey(room),
                    index,
                    true,
                    "title"
                  )
                }}
              />
            </Grid>
            <Grid item md={3}>
              <TownoThemedSelect
                options={typeOptions}
                value={typeValue[g.type]}
                onChange={(option) => {
                  // handleChange(option, index, "type", true)
                  // setTypeOptions((c) => {
                  //   if (option.limit > 0) {
                  //     c = c.map((o, i) =>
                  //       o.value == option.value
                  //         ? { ...o, [option.value]: o[option.value] - 1 }
                  //         : o
                  //     )
                  //   } else {
                  //     c = c.map((o, i) => ({ ...o, disable: true }))
                  //   }
                  //   return c
                  // })
                  handleChangeAdditionalGuests(
                    option,
                    getKey(room),
                    index,
                    true,
                    "type"
                  )
                }}
                formatOptionLabel={(option) => (
                  <div>
                    {option.label}{" "}
                    <small>
                      {option?.limit &&
                        `(${Number(option?.limit) * Number(room.noOfRooms)})`}
                    </small>
                  </div>
                )}
              />
            </Grid>
            <Grid item md={3.5}>
              <TextField
                value={g.firstName}
                label="First Name"
                size="small"
                name={`firstName`}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  // handleChange(e, index)
                  handleChangeAdditionalGuests(e, getKey(room), index)
                }}
              />
            </Grid>
            <Grid item md={3.5}>
              <TextField
                value={g.lastName}
                label="Last Name"
                size="small"
                name={`lastName`}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  // handleChange(e, index)
                  handleChangeAdditionalGuests(e, getKey(room), index)
                }}
              />
            </Grid>
            <Grid item md={0.5}>
              <IconButton
                size="small"
                onClick={() => {
                  // remove(index, room)
                  removeAdditionalGuests(getKey(room), index)
                }}
              >
                <DeleteForever color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item md={12}>
        <Button
          style={{ marginLeft: "2rem" }}
          size="small"
          onClick={() => {
            // add(room)
            const key = `${room.displayName}-${room.mealplan}`
            addAdditionalGuests(key, room)
          }}
          disabled={
            additionalGuestDetails?.get(getKey(room))?.length ==
            room.maxStayLimit * room.noOfRooms
          }
        >
          Add Guests
        </Button>
      </Grid>
    </Grid>
  )
}

export default CheckoutPage
