import { ActionTypes } from "../actions/actionTypes"

export const hotelDetailsListReducer = (
  state = {
    hotelDetails: {},
    loading: false,
  },
  action
) => {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.HOTELDETAIL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.HOTELDETAIL_LIST_SUCCESS:
      return { ...state, loading: false, hotelDetails: payload }
    case ActionTypes.HOTEL_DETAILS_RESET:
      return { ...state, hotelDetails: {} }
    default:
      return state
  }
}

export const hotelRoomListReducer = (
  state = { hotelRoomList: [], loading: false },
  action
) => {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.FETCHING_HOTEL_ROOM_DETAILS:
      return { ...state, loading: true }
    case ActionTypes.FETCHING_HOTEL_ROOM_DETAILS_SUCCESS:
      return { ...state, loading: false, hotelRoomList: payload }
    case ActionTypes.HOTEL_ROOM_DETAILS_RESET:
      return { ...state, hotelRoomList: [], loading: false }
    default:
      return state
  }
}
