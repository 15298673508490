import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import IncredibleIndiaLogo from "../../assets/homePageAssets/affliation_logo/increadble india.webp";
import Nidhi from "../../assets/homePageAssets/affliation_logo/nidhi.webp";
import QR from "../../assets/homePageAssets/affliation_logo/qr.webp";
import GoaTourism from "../../assets/homePageAssets/affliation_logo/goa-tourism.webp";
import UttarakhandTourism from "../../assets/homePageAssets/affliation_logo/UTTARAKHAND TOURSIM.webp";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    width: "100%",
    aspectRatio: "0.6/0.6",
    overflow: "hidden",
    borderRadius: "0.5rem",

    "& img": {
      objecFit: "cover",
      width: "100%",
      height: "100%",
    },
  },
  container: {
    margin: "2.5rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "5rem 0rem 0rem 0rem",
    },
  },
  assetsContainer: {
    padding: "1rem 10rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      justifyContent: "center", //added this line to come in center
    },
  },
  gridImgContainer: {
    padding: "2rem",
    flexBasis: "20%!important", //added this line for responsive
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      flexBasis: "50%!important", //added this line for responsive
    },
  },
  header: {
    fontSize: "3.125rem !important",
    fontWeight: "bold",
    fontFamily: "Poppins",
    margin: 0,
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      textAlign: "left !important",
      fontSize: "1.75rem !important",
      fontWeight: 600,
      padding: "0rem 1rem",
      // padding: "2rem 0rem 1rem 1rem",
    },
  },
}));

const AffliationSection = () => {
  const classes = useStyles();
  const [affliationAssets, setAffliationAssets] = useState([
    { imgSrc: Nidhi },
    { imgSrc: QR },
    { imgSrc: GoaTourism },
    { imgSrc: UttarakhandTourism },
    { imgSrc: IncredibleIndiaLogo },
  ]);
  return (
    <Grid container md={12} xs={12} className={classes.container}>
      <Grid item md={12} xs={12} textAlign={"center"}>
        <h2 className={classes.header}>Proud Affiliation with</h2>
      </Grid>

      <Grid item container md={12} xs={12} className={classes.assetsContainer}>
        {affliationAssets.map((a, i) => (
          <Grid item md={3} xs={6} className={classes.gridImgContainer}>
            <div className={classes.imgContainer}>
              <img src={a.imgSrc} loading="lazy" />
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AffliationSection;
