import React, { createContext, useState, useContext, useCallback } from "react"
import { Button, Grow, Modal, Paper } from "@material-ui/core"
import { Grid } from "@mui/material"

// Create Context
const NotificationContext = createContext({
  showNotification: (message, notificationType) => null,
})

export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [type, setType] = useState("success")

  const showNotification = useCallback((msg, notificationType) => {
    setMessage(msg)
    setType(notificationType)
    setOpen(true)
  }, [])

  const closeNotification = () => setOpen(false)

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Modal open={open} onClose={closeNotification}>
        <Grow in={open}>
          <Grid
            container
            md={12}
            sm={12}
            justifyContent="center"
            alignItems={"center"}
            height={"100vh"}
          >
            <Paper
              style={{
                padding: "2rem",
                backgroundColor: type === "error" ? "#ffdddd" : "#ddffdd",
              }}
            >
              <strong>{message}</strong>
              <br />
              <center>
                <Button onClick={closeNotification}>Close</Button>
              </center>
            </Paper>
          </Grid>
        </Grow>
      </Modal>
    </NotificationContext.Provider>
  )
}

// Custom Hook for Notifications
export function useNotificationProvider() {
  const values = useContext(NotificationContext)
  if (values == null) {
    throw new Error("Notification Provider Error")
  }
  return values
}
