import LocalLibraryIcon from "@material-ui/icons/LocalLibrary"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import StoreIcon from "@material-ui/icons/Store"
import React from "react"
import { Link, NavLink } from "react-router-dom"
import TKonnect1 from "../../assets/logo/TKonnect1.png"
import "./Navbar.css"
// import HomeIcon from "@material-ui/icons/HomeIcon";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined"
import { Tooltip } from "@mui/material"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light.css"
import BookingIcon from "../../assets/Icons/BookingIcon.svg"
import ClientIcon from "../../assets/Icons/ClientIcon.svg"
import DashboardIcon from "../../assets/Icons/DashboardIcon.svg"
import HomeIcon1 from "../../assets/Icons/HomeIcon1.svg"
import ProfileIcon from "../../assets/Icons/ProfileIcon.svg"
import ReportIcon from "../../assets/Icons/ReportIcon.svg"
import VendorIcon from "../../assets/Icons/VendorIcon.svg"
import {
  Admin,
  Agent,
  AgentBookings,
  Booking,
  Clients,
  FinanceTeam,
  Profile,
  Property,
  Vendors,
} from "./MoreT"

const Header = () => {
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")

  return (
    <div className="navbars" style={{ height: "60px" }}>
      <div className="logo">
        <img src={TKonnect1} alt="logo" style={{ width: "45%" }} />
      </div>
      <div className="subHeader">
        {hasAdmin === "Agent Admin" ||
        checkAgent === "Agent" ||
        checkAgent === "Common" ? (
          <div className="rightMenu">
            <NavLink
              to="/konnect/agent"
              className="more"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div className="item">
              <img src={HomeIcon1} width="20px" height="20px"/konnect/>
                <span className="caption">Home</span>
              </div> */}
              <Tooltip title="Home">
                <img src={HomeIcon1} width="20px" height="20px" />
              </Tooltip>
              {checkAgent === "Common" && (
                <span style={{ fontSize: "12.5px" }}>Home</span>
              )}
            </NavLink>
          </div>
        ) : (
          <div className="rightMenu">
            <Link
              to={
                hasAdmin === "Hotel Admin"
                  ? "/konnect/hotelDashboard"
                  : "/konnect/dashboard"
              }
              className="more"
            >
              <div className="item">
                {/* <HomeIcon /> */}
                <img src={HomeIcon1} width="20px" height="20px" />
                <span className="caption">Home</span>
              </div>
            </Link>
          </div>
        )}

        {(hasAdmin === "Agent Admin" && checkAgent !== "Common") ||
        checkAgent === "Agent" ? (
          <Link to="/konnect/agentDashboard" className="more">
            {/* <div className="item">
              <img src={DashboardIcon} width="20px" height="20px" />
              <span className="caption">Dashboard</span>
            </div> */}
            <Tooltip title="Dashboard">
              <img src={DashboardIcon} width="20px" height="20px" />
            </Tooltip>
          </Link>
        ) : null}

        {(hasAdmin === "Admin" ||
          hasAdmin === "Super Admin" ||
          hasAdmin === "Agent Admin" ||
          hasAdmin === "Hotel Admin") &&
        checkAgent !== "Common" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Admin />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  {/* <BusinessCenterIcon /> */}
                  <img src={HomeIcon1} width="20px" height="20px" />
                  <span className="caption">Admin</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : null}

        {(hasAdmin === "Agent Admin" && checkAgent !== "Common") ||
        checkAgent === "Agent" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Clients />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  <img src={ClientIcon} width="20px" height="20px" />
                  <span className="caption">Clients</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : null}

        {hasAdmin === "Admin" || hasAdmin === "Super Admin" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Agent />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  <LocalLibraryIcon />
                  <span className="caption">Agency</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : null}

        {(hasAdmin === "Agent Admin" && checkAgent !== "Common") ||
        checkAgent === "Agent" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<AgentBookings />}
              interactive={true}
              trigger="click"
            >
              {/* <Link to="#" className="more"> */}
              <div className="more">
                <div className="item">
                  <img src={BookingIcon} width="20px" height="20px" />
                  <span className="caption">Booking</span>
                </div>
              </div>
              {/* </Link> */}
            </Tippy>
          </div>
        ) : (
          checkAgent !== "Common" &&
          hasAdmin !== "Finance Team" && (
            <div className="rightMenu">
              <Tippy
                className="admin"
                theme="light"
                content={<Booking />}
                interactive={true}
                trigger="click"
              >
                <div className="more">
                  <div className="item">
                    <LocalOfferIcon />
                    <span className="caption">Booking</span>
                  </div>
                </div>
              </Tippy>
            </div>
          )
        )}

        {hasAdmin === "Finance Team" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<FinanceTeam />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  <LocalOfferIcon />
                  <span className="caption">Booking</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : null}

        {(hasAdmin === "Agent Admin" && checkAgent !== "Common") ||
        checkAgent === "Agent" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Vendors />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  <img src={VendorIcon} width="20px" height="20px" />
                  <span className="caption">Vendors</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : (
          checkAgent === "Common" && (
            <div>
              <NavLink
                to="/konnect/roomListView"
                activeClassName="active-link"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DateRangeIcon style={{ color: "white", height: "21px" }} />
                <span style={{ color: "white", fontSize: "12.5px" }}>
                  Calender View
                </span>
              </NavLink>
            </div>
          )
        )}

        {hasAdmin === "Admin" ||
        hasAdmin === "Super Admin" ||
        hasAdmin === "Hotel Admin" ||
        hasAdmin === "Sales" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Property />}
              interactive={true}
              trigger="click"
            >
              <div className="more">
                <div className="item">
                  <StoreIcon />
                  <span className="caption">Property</span>
                </div>
              </div>
            </Tippy>
          </div>
        ) : null}

        {/* {hasAdmin === "Admin" ||
          hasAdmin === "Super Admin" ||
          hasAdmin === "Finance Team" ? ( */}
        {checkAgent !== "Common" && (
          <div className="rightMenu">
            <Link to="/konnect/report" className="more">
              <div className="item">
                <img src={ReportIcon} width="20px" height="20px" />
                <span className="caption">Report</span>
              </div>
            </Link>
          </div>
        )}

        {/* ) :
          null
        } */}

        <div className="rightMenu">
          <Tippy
            className="admin"
            theme="light"
            content={<Profile />}
            interactive={true}
            trigger="click"
          >
            <div className="more">
              <div className="item">
                <img src={ProfileIcon} width="20px" height="20px" />
                <span className="caption">Profile</span>
              </div>
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  )
}

export default Header
