import styles from "./gradientButton.styles.module.css"

function GradientButton({ children, style, props }) {
  return (
    <button className={styles.gradientButton} style={style} {...props}>
      {children}
    </button>
  )
}
export default GradientButton
