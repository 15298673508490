import { Button, Grow, Modal, Paper } from "@material-ui/core"
import { Grid } from "@mui/material"
import { memo, useCallback, useState } from "react"

export function useNotification() {
  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState("")
  const [type, setType] = useState("success")

  const showNotification = useCallback((msg, notificationType) => {
    setMessage(msg)
    setType(notificationType)
    setOpenModal(true)
  }, [])

  const Success = useCallback((msg) => {
    showNotification(msg, "success")
  }, [])

  const ErrorN = useCallback((msg) => {
    showNotification(msg, "error")
  }, [])

  const NotificationComponent = memo(() => (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Grow in={openModal}>
        <Grid container justifyContent="center">
          <Paper
            style={{
              padding: "2rem",
              backgroundColor: type === "error" ? "#ffdddd" : "#ddffdd",
            }}
          >
            <strong>{message}</strong>
            <center>
              <Button onClick={() => setOpenModal(false)}>Close</Button>
            </center>
          </Paper>
        </Grid>
      </Grow>
    </Modal>
  ))

  return { Success, ErrorN, NotificationComponent }
}
