import DestinationLayout from "../../layout/DestinationLayout/Destination.layout"
import styles from "./Destination.styles.module.css"

import sample from "../../assets/illustrations/hotels_mp/Golden Tulip.png"
import sample2 from "../../assets/illustrations/hotels_mp/Giri Vihar.png"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import DestinationCardV2 from "../../components/DestinationNew/DestinationCard_V2/DestinationCardV2.component"

import NextTripBanner from "../../assets/illustrations/destination/destination_intermediate_banner/background_next_trip.png"
import { useContext } from "react"
import { ContactModalContext } from "../../context/contactModal.context"

import Badrinath from "../../assets/illustrations/destination/pilgrims_card/badrinath.png"
import Rishikesh from "../../assets/illustrations/destination/pilgrims_card/rishikesh.png"
import Haridwar from "../../assets/illustrations/destination/pilgrims_card/haridwar.png"
import Chardham from "../../assets/illustrations/destination/pilgrims_card/chardham.png"
import PlanningNextTripBanner from "../../components/DestinationNew/PlanningNextTripBanner/PlanningNextTripBanner.component"

import KeyfactsBackground from "../../assets/illustrations/destination/banner_images/destination_bg_1.png"
import { useRouteMatch, Switch } from "react-router-dom"
import { Route, Router } from "react-router-dom/cjs/react-router-dom.min"
import DestinationHome from "./DestinationSubPages/DestinationSpecificHomePage.page.jsx/DestinationHome.page"
import DestinationSubCity from "./DestinationSubPages/DestionationSubCity/DestinationSubCity.page"
import DestinationTourism from "./DestinationSubPages/DestinationTourism/DestinationTourism.page"
import PlacesToVisit from "./DestinationSubPages/PlacesToVIsit/PlacesToVisit.page"
import DestinationBestTime from "./DestinationSubPages/DestinationBestTime/DestinationBestTime.page"
import DestinationHowToReach from "./DestinationSubPages/DestinationHowToReach/DestinationHowToReach.page"
import DestinationThingsTodo from "./DestinationSubPages/DestinationThingsTodo/DestinationThingsTodo.page"
import DestinationKeyFacts from "./DestinationSubPages/DestinationKeyFacts/DestinationKeyFacts.page"
import DestinationShoppingAndEntertainment from "./DestinationSubPages/DestinationShoppingAndEntertainment/DestinationShoppingAndEntertainment.page"
import DestinationCuisine from "./DestinationSubPages/DestinationCuisine/DestinationCuisine.page"
import DestinationThingsCarry from "./DestinationSubPages/DestinationThingsToCarry/DestinationThingsToCarry.page"
import DestinationFestivalsAndEvents from "./DestinationSubPages/DestinationFestivalsAndEvents/DestinationFestivalsAndEvents.page"
import DestinationTourPackages from "./DestinationSubPages/DestinationTourPackages/DestinationTourPackages.page"

const Destination = () => {
  const { toggleModal } = useContext(ContactModalContext)

  const { path, url } = useRouteMatch()

  return (
    <DestinationLayout>
      <Switch>
        <Route path={path} exact component={DestinationHome} />
        <Route
          path={`${path}/destination`}
          exact
          component={DestinationSubCity}
        />
        <Route
          path={`${path}/uttarakhand-tourism`}
          exact
          component={DestinationTourism}
        />

        <Route
          path={`${path}/places-to-visit-in-uttarakhand`}
          exact
          component={PlacesToVisit}
        />

        <Route
          path={`${path}/best-time-to-visit-uttarakhand`}
          exact
          component={DestinationBestTime}
        />

        <Route
          path={`${path}/how-to-reach-uttarakhand`}
          exact
          component={DestinationHowToReach}
        />

        <Route
          path={`${path}/things-to-do-in-uttarakhand`}
          exact
          component={DestinationThingsTodo}
        />
        <Route
          path={`${path}/key-facts-about-uttarakhand`}
          exact
          component={DestinationKeyFacts}
        />

        <Route
          path={`${path}/shopping-and-entertainment-in-uttarakhand`}
          exact
          component={DestinationShoppingAndEntertainment}
        />
        <Route
          path={`${path}/uttarakhand-cuisine`}
          exact
          component={DestinationCuisine}
        />

        <Route
          path={`${path}/things-to-carry-while-traveling-to-uttarakhand`}
          exact
          component={DestinationThingsCarry}
        />

        <Route
          path={`${path}/uttarakhand-festivals-and-events`}
          exact
          component={DestinationFestivalsAndEvents}
        />
        <Route
          path={`${path}/uttarakhand-tour-packages`}
          exact
          component={DestinationTourPackages}
        />
      </Switch>
    </DestinationLayout>
  )
}

export default Destination
