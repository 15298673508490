import { IconButton } from "@material-ui/core";
import { ArrowRightAlt, CallMade } from "@material-ui/icons";

const LeftNavArrow = (props) => {
  const { style, className, onClick } = props;
  return (
    <IconButton
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#fff",
        alignSelf: "center",
        transform: "rotate(-135deg)",
        zIndex: 20,
      }}
      onClick={onClick}
      {...props}
    >
      <CallMade color="primary" />
    </IconButton>
  );
};

export default LeftNavArrow;
