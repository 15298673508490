import { IconButton } from "@material-ui/core"
import { ArrowRightAlt, CallMade } from "@material-ui/icons"

const RightNavArrow = (props) => {
  const { style, className, onClick } = props
  return (
    <IconButton
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#fff",
        alignSelf: "center",
        zIndex: 20,
        transform: "rotate(45deg)",
      }}
      onClick={onClick}
      {...props}
    >
      <CallMade color="primary" />
    </IconButton>
  )
}

export default RightNavArrow
