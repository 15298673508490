import { Button, makeStyles, TextField } from "@material-ui/core"
import { Grid } from "@mui/material"
import TownoThemedSelect from "../townoThemedSelect/townoThemedSelect.component"
import { useState } from "react"
import { useSelector } from "react-redux"
import { addDays, format } from "date-fns"
import { MobileDatePicker } from "@mui/x-date-pickers-pro"

const useStyles = makeStyles(() => ({
  container: {
    background: "#fff",
    padding: "0.5rem 0.5rem 2rem 0.5rem",
    borderRadius: "1.5rem",
  },
  selectContainer: {
    position: "relative",
  },
  selectLabel: {
    position: "absolute",
    inset: 0,
    zIndex: "3",
    margin: "-0.2rem 0rem 0rem 1rem",
    width: "",
    alignSelf: "flex-start",
    "& small": {
      background: "#fff",
      padding: "0rem 0.5rem",
      borderRadius: "0.4rem",
      fontSize: "0.7rem",
      color: "#f46d25",
      pointerEvents: "none",
    },
  },
}))

const RoomEnquiryForm = ({
  propertyName,
  city,
  state,
  starRating,
  roomOptions,
  onSubmit,
  noofRooms,
  onClose,
  propertyId,
  displayName,
  isSubmitting,
}) => {
  const classes = useStyles()

  const [roomOption, setRoomOption] = useState(roomOptions[0])
  const [checkIn, setCheckIn] = useState(new Date())
  const [checkout, setCheckout] = useState(addDays(new Date(), 1))
  const [selectedRooms, setSelectedRooms] = useState({ label: 1, value: 1 })
  const [adult, setAdult] = useState({ label: 1, value: 1 })
  const [child, setChild] = useState({ label: 0, value: 0 })
  const [mealplan, setMealplan] = useState({
    label: "CP Rate",
    value: "cpRate",
  })

  const handleSubmit = (e) => {
    const postData = {
      uniqueId: localStorage.getItem("unique_id"),
      propertyId,
      propertyName: displayName,
      displayName,
      noofrooms: selectedRooms.value,
      noofadults: adult.value,
      noofchild: child.value,
      mealplan: mealplan.value,
      checkin: format(new Date(checkIn), "yyyy-MM-dd"),
      checkout: format(new Date(checkout), "yyyy-MM-dd"),
      createdBy: localStorage.getItem("auth"),
    }
    console.log({ postData })
    onSubmit(postData)
  }

  return (
    <Grid container md={8} className={classes.container} spacing={2}>
      <Grid
        item
        md={12}
        style={{
          background:
            "linear-gradient(to right,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
          filter: "grayscale(10%)",
        }}
        padding={"1rem"}
        textAlign={"center"}
        color={"#fff"}
        fontSize={"2rem"}
        fontWeight={600}
        borderRadius={"1rem"}
      >
        Create New Query
      </Grid>
      <Grid item container md={12} spacing={1}>
        <Grid item md={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Property Name"
            value={propertyName}
            disabled
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="City"
            value={city}
            disabled
          />
        </Grid>

        <Grid item container md={12} alignItems={"center"} spacing={1}>
          <Grid item md={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Star Rating"
              value={starRating}
              disabled
            />
          </Grid>

          <Grid item container md={6} className={classes.selectContainer}>
            <Grid item md={12} className={classes.selectLabel}>
              <small>Select Room</small>
            </Grid>
            <Grid item md={12}>
              <TownoThemedSelect
                options={roomOptions}
                value={roomOption}
                onChange={(option) => setRoomOption(option)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <MobileDatePicker
            inputFormat="DD/MM/YYYY"
            // openTo="day"
            label="Check-In"
            value={checkIn}
            onChange={(date) => setCheckIn(date)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            )}
            closeOnSelect
            disablePast
          />
        </Grid>

        <Grid item md={6}>
          <MobileDatePicker
            inputFormat="DD/MM/YYYY"
            // openTo="day"
            label="Check-Out"
            value={checkout}
            onChange={(date) => setCheckout(date)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            )}
            closeOnSelect
            disablePast
          />
        </Grid>

        <Grid item container md={6} className={classes.selectContainer}>
          <Grid item md={12} className={classes.selectLabel}>
            <small>No of Rooms</small>
          </Grid>
          <Grid item md={12}>
            <TownoThemedSelect
              options={Array.from({ length: noofRooms }, (_, i) => ({
                label: i + 1,
                value: i + 1,
              }))}
              value={selectedRooms}
              onChange={(option) => setSelectedRooms(option)}
            />
          </Grid>
        </Grid>

        <Grid item container md={6} className={classes.selectContainer}>
          <Grid item md={12} className={classes.selectLabel}>
            <small>Select Adults</small>
          </Grid>
          <Grid item md={12}>
            <TownoThemedSelect
              options={Array.from({ length: 20 }, (_, i) => ({
                label: i + 1,
                value: i + 1,
              }))}
              value={adult}
              onChange={(option) => setAdult(option)}
            />
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.selectContainer}>
          <Grid item md={12} className={classes.selectLabel}>
            <small>Select Children</small>
          </Grid>
          <Grid item md={12}>
            <TownoThemedSelect
              options={Array.from({ length: 20 }, (_, i) => ({
                label: i + 1,
                value: i + 1,
              }))}
              value={child}
              onChange={(option) => setChild(option)}
            />
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.selectContainer}>
          <Grid item md={12} className={classes.selectLabel}>
            <small>Mealplan</small>
          </Grid>
          <Grid item md={12}>
            <TownoThemedSelect
              options={[
                { value: "epRate", label: "EP Rate" },
                { value: "cpRate", label: "CP Rate" },
                { value: "mapRate", label: "MAP Rate" },
                { value: "apRate", label: "AP Rate" },
              ]}
              value={mealplan}
              onChange={(option) => setMealplan(option)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "center",
          }}
        >
          <Button
            size="small"
            onClick={handleSubmit}
            disabled={isSubmitting}
            startIcon={
              isSubmitting ? (
                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              ) : null
            }
          >
            {isSubmitting ? "Sending" : "Send Query"}
          </Button>{" "}
          <Button
            size="small"
            style={{ opacity: 1, background: "#000" }}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
export default RoomEnquiryForm
