import { toast } from "react-toastify"
import Api from "../../Service/Api"
import { ActionTypes } from "./actionTypes"

const role = localStorage.getItem("role")

export const propertyListInitial = () => async (dispatch) => {
  let url =
    role === "Admin" ? "getAllProperties" : "propertybasicpropertydetailsall"
  dispatch({
    type: ActionTypes.PROPERTY_LIST_REQUEST,
  })
  await Api.get(`${url}`).then((res) => {
    dispatch({ type: ActionTypes.PROPERTY_LIST_SUCCESS, payload: res.data })
  })
}

export const propertyImageList = (propertyId) => async (dispatch) => {
  dispatch({ type: ActionTypes.PROPERTY_IMAGE_FETCHING })
  try {
    const response = await Api.post("propertyamenitiesphotovalue", {
      propertyId,
    })

    if (response.status == 200) {
      dispatch({
        type: ActionTypes.PROPERTY_IMAGE_LIST_SUCCESS,
        payload: { imageList: response.data },
      })
      return
    }
    return Promise.reject(response)
  } catch (error) {
    dispatch({ type: ActionTypes.PROPERTY_IMAGE_LIST_FAIL })
  }
}

export const propertyBasicData = (propertyId) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYBASIC_LIST_REQUEST,
  })
  await Api.get(`propertybasicpropertydetailsvalue/${propertyId}`).then(
    (res) => {
      console.log(res.data)
      dispatch({
        type: ActionTypes.PROPERTYBASIC_LIST_SUCCESS,
        payload: res.data,
      })
    }
  )
}

export const propertyDetialCreate =
  (createData, history) => async (dispatch) => {
    dispatch({
      type: ActionTypes.PROPERTYBASIC_CREATE_REQUEST,
      payload: createData,
    })
    await Api.post("propertybasicpropertydetails", createData).then((res) => {
      dispatch({
        type: ActionTypes.PROPERTYBASIC_CREATE_SUCCESS,
        payload: res.data,
      })
      history.push(`property/${res.data}`)
      // sessionStorage.setItem("propertyId", res.data);
      toast.success("Successfully Created")
      // onTab(2);
    })
  }

export const propertyDetialUpdate = (updateData, onTab) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYBASIC_UPDATE_REQUEST,
    payload: updateData,
  })

  console.log(updateData)

  await Api.put("propertybasicpropertydetailsupdate", updateData).then(
    (res) => {
      console.log(res)
      dispatch({
        type: ActionTypes.PROPERTYBASIC_UPDATE_SUCCESS,
        payload: res.data,
      })
      toast.success("Update SuccessFully")
      // onTab(2);
    }
  )
}

export const propertyContactData = (propertyId) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYCONTACT_LIST_REQUEST,
  })
  await Api.get(`propertybasiccontactvalue/${propertyId}`).then((res) => {
    console.log(res.data)
    dispatch({
      type: ActionTypes.PROPERTYCONTACT_LIST_SUCCESS,
      payload: res.data,
    })
  })
}

export const propertyContactCreate =
  (createData, history) => async (dispatch) => {
    dispatch({
      type: ActionTypes.PROPERTYCONTACT_CREATE_REQUEST,
      payload: createData,
    })
    await Api.post("propertybasiccontact", createData).then((res) => {
      dispatch({
        type: ActionTypes.PROPERTYCONTACT_CREATE_SUCCESS,
        payload: res.data,
      })
      // history.push("/addproperty/amenities");
      toast.success("Successfully Created")
    })
  }

export const propertyContactUpdate =
  (updateData, history) => async (dispatch) => {
    dispatch({
      type: ActionTypes.PROPERTYCONTACT_UPDATE_REQUEST,
      payload: updateData,
    })
    await Api.put("propertybasiccontactupdate", updateData).then((res) => {
      dispatch({
        type: ActionTypes.PROPERTYCONTACT_UPDATE_SUCCESS,
        payload: res.data,
      })
      // history.push("/addproperty/amenities");
      toast.success("Update SuccessFully")
    })
  }

export const propertyBankData = (propertyId) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYBANK_LIST_REQUEST,
  })
  await Api.get(`propertyamenitiesbankdetailsvalue/${propertyId}`).then(
    (res) => {
      console.log(res.data)
      dispatch({
        type: ActionTypes.PROPERTYBANK_LIST_SUCCESS,
        payload: res.data,
      })
    }
  )
}

export const propertyBankCreate = (createData, history) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYBANK_CREATE_REQUEST,
    payload: createData,
  })
  try {
    const { data } = await Api.post("propertyamenitiesbankdetails", createData)
    dispatch({
      type: ActionTypes.PROPERTYBANK_CREATE_SUCCESS,
      payload: data,
    })
    history.push("/konnect/property/${data.propertyId}/propertyAmenities")
    toast.success("Successfully Created")
  } catch (error) {
    toast.error(error.message)
  }
}

export const propertyBankUpdate = (updateData, history) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYBANK_UPDATE_REQUEST,
    payload: updateData,
  })

  try {
    const { data } = await Api.put(
      "propertyamenitiesbankdetailsupdate",
      updateData
    )
    dispatch({
      type: ActionTypes.PROPERTYBANK_UPDATE_SUCCESS,
      payload: data,
    })
    history.push(`/konnect/property/${data.propertyId}/propertyAmenities`)
    toast.success("Bank Details Updated")
  } catch (error) {
    toast.error(error.message)
  }
}

export const propertyTypeList = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.PROPERTYTYPE_LIST_REQUEST,
  })
  await Api.post("productdropdownonly/", {
    type: "Property Type",
  }).then((res) => {
    dispatch({
      type: ActionTypes.PROPERTYTYPE_LIST_SUCCESS,
      payload: res.data,
    })
  })
}

export const currencyList = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.CURRENCY_LIST_REQUEST,
  })
  await Api.post("productdropdownonly/", {
    type: "Currency",
  }).then((res) => {
    dispatch({ type: ActionTypes.CURRENCY_LIST_SUCCESS, payload: res.data })
  })
}
export const vCCCurrencyList = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.VCCCURRENCY_LIST_REQUEST,
  })
  await Api.post("productdropdownonly/", {
    type: "VCC Currency",
  }).then((res) => {
    dispatch({ type: ActionTypes.VCCCURRENCY_LIST_SUCCESS, payload: res.data })
  })
}
export const timezoneList = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.TIMEZONE_LIST_REQUEST,
  })
  await Api.post("productdropdownonly/", {
    type: "Timezone",
  }).then((res) => {
    dispatch({ type: ActionTypes.TIMEZONE_LIST_SUCCESS, payload: res.data })
  })
}

export const propertyRoomList = (propertyId) => async (dispatch) => {
  dispatch({ type: ActionTypes.REQUEST_FETCH_ROOM_LIST_DATA })
  const { data } = await Api.get(`propertyamenitiesroomallvalue/${propertyId}`)
  dispatch({
    type: ActionTypes.REQUEST_FETCH_ROOM_LIST_DATA_SUCCESS,
    payload: data,
  })
}
