import { useContext } from "react"
import { CaptchContext } from "../../B2C_Konnect/context/captcha.context"

export function useCaptchaContext() {
  const values = useContext(CaptchContext)

  if (values == null) {
    throw new Error(
      "Dependent component must be wrapped inside the captcha context provider"
    )
  }

  return values
}
