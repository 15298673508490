/**components import */

/**assets import */
import TownoLogo from "../../assets/logo/towno.fontImage.svg"

/**styles import */
import styles from "./Navbar.styles.module.css"
// import { Link, NavLink } from "react-router-dom"
import {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import NavIcon from "../NavIcon/NavIcon.component"
import { SideNavbarContext } from "../../context/sideNavBarContext"
import {
  Link,
  NavLink,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min"
import _, { transform } from "lodash"
import { ContactModalContext } from "../../context/contactModal.context"
import {
  AddCircleOutlined,
  Close,
  RemoveCircleOutlined,
} from "@material-ui/icons"
import {
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import DropdownMenu from "../DropdownMenuItem/dropdownMenu.component"
import { useDeviceWidth } from "../../../hooks/useWidth"
import { Grid } from "@mui/material"
import { headerIconsSrc } from "../Header/headerIconsSrc"
import MenuIcon from "@material-ui/icons/Menu"

const leftHandSideNavLinks = [
  {
    name: "Hotels",
    path: "/hotels",
  },
  { name: "Domestic" },

  { name: "Destinations", path: "" },
  { name: "Group/Corporate", path: "" },
  { name: "Weddings", path: "" },
]

const navlinks = [
  {
    name: "Hotels",
    path: "/hotels",
  },
  { name: "Domestic" },
  { name: "International" },
  { name: "Corporate" },
  { name: "Weddings", path: "" },
  { name: "Packages" },
  { name: "More" },
]

const rightHandSideNavLinks = [
  {
    name: "Blog",
    path: "https://blog.towno.in/",
    open: "newTab",
  },
  { name: "About Us", path: "/aboutUs" },
  { name: "Contact Us", path: "/contactUs" },
]

const dropdownOptions = {
  Domestic: [{ label: "Uttarakhand", link: "/uttarakhand" }],

  More: [
    { label: "About Us", link: "/aboutUs" },
    { label: "Blogs", link: "" },
    { label: "Contact Us", link: "/contactUs" },
  ],
}

const dataMap = new Map([
  [
    "Uttarakhand",
    [
      {
        title: "Hill Stations",
        navlinks: [{ name: "Nainital", link: "/uttarakhand" }],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  ],
])

const mobData = [
  {
    title: "Uttarakhand",
    subTitle: [
      {
        title: "Hill Stations",
        navlinks: [{ name: "Nainital", link: "/uttarakhand" }],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  },
  {
    title: "Goa",
    subTitle: [
      {
        title: "Hill Stations",
        navlinks: [],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  },
]

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem)

const useStyles = makeStyles((theme) => ({
  "@keyframes slide": {
    from: {
      transform: "translateX(-100%)",
    },
    to: {
      transform: "translateX(0%)",
    },
  },
  container: {
    position: "fixed",
    // width: "70vw",
    height: "100vh",
    // background: "red",
    inset: 0,
    backdropFilter: "blur(1rem)",
    transform: "reverse(-100%)",
  },
  navLinks: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    gap: "0.5rem",
    fontSize: "1.1rem",
  },
  innerContent: {
    position: "absolute",
    // inset: 0,
    top: "3rem",
    left: 0,
    // background: "#fff",
    "& > *": {
      // border: "1px solid red",
      alignSelf: "flex-start",
    },
  },
  activeMenu: {
    background: "#f46d25",
    color: "#fff",
  },
  "@keyframes rotate": {
    from: {
      transform: `rotate(0deg)`,
    },
    to: {
      transform: `rotate(90deg)`,
    },
  },

  rotateNow: {
    animationName: "$rotate",
    animationDuration: "250ms",
  },

  slideInDrawer: {
    animationName: "$slide",
    animationDuration: "250ms",
    animationTimingFunction: theme.transitions.easing.easeIn,
    animationFillMode: "forwards",
  },
  slideOutDrawer: {
    animationName: "$slide",
    animationDuration: "250ms",
    animationTimingFunction: theme.transitions.easing.easeOut,
    animationFillMode: "forwards",
    animationDirection: "reverse",
  },

  // "@keyfras"
}))

const Navbar = () => {
  const classes = useStyles()
  const [expandSubMenu, setExpandSubMenu] = useState(null)

  const [openDrawer, setOpenDrawer] = useState(false)

  const width = useDeviceWidth()
  const navbarRef = useCallback((node) => {
    if (node == null) return
    console.log(node)

    const handleScroll = () => {
      if (node.getBoundingClientRect().top == 0) {
        node.classList.add(styles["toggleBackground"])
      } else {
        node.classList.remove(styles["toggleBackground"])
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  })

  const [activeDestination, setActiveDestination] = useState("Uttarakhand")

  const dropDownCategories = useMemo(
    () => dataMap.get(activeDestination),
    [activeDestination]
  )

  return (
    <div className={styles.navbarContainer} ref={navbarRef}>
      {/* <div className="scroll-watcher"></div> */}
      <NavLink to="/" style={{ height: "2rem", zIndex: width < 768 && 20 }}>
        <img src={TownoLogo} style={{ width: "100%", height: "100%" }} />
      </NavLink>

      {width < 768 && (
        <IconButton
          onClick={() => {
            setOpenDrawer(true)
            console.log("Hello")
          }}
        >
          <MenuIcon style={{ color: "#fff" }} />
        </IconButton>
      )}

      {width > 768 ? (
        <ul className={styles.navlinkContainer}>
          {navlinks.map((n, index) => (
            <DropdownMenu
              label={n.name}
              key={`${n.name}-${index}}`}
              hasDropdown={dropdownOptions[n?.name]?.length > 0}
            >
              <DropdownMenu.MenuList>
                {dropdownOptions[n?.name]?.map((item, i) => (
                  <DropdownMenu.MenuItem>
                    <NavLink to={item.link} style={{ color: "#fff" }}>
                      {item.label}
                    </NavLink>
                  </DropdownMenu.MenuItem>
                ))}
              </DropdownMenu.MenuList>
            </DropdownMenu>
          ))}
        </ul>
      ) : (
        <Grid
          container
          className={[classes.container]}
          style={{
            transform: `translateX(${openDrawer ? "0%" : "-100%"})`,
            transition: `transform 250ms ${
              openDrawer ? "ease-in-out" : "ease-out"
            }`,
          }}
          xs={12}
        >
          <Grid item container xs={10} style={{ position: "relative" }}>
            <Grid
              item
              xs={12}
              style={{
                width: "fit-content",
                zIndex: 10,
                minHeight: "4rem",
                background: "rgba(14, 26, 41, 1)",
              }}
              textAlign={"end"}
              alignContent={"center"}
            >
              <IconButton
                onClick={() => {
                  setExpandSubMenu(null)
                  setOpenDrawer(false)
                }}
              >
                <Close style={{ fill: "#fff" }} />
              </IconButton>
            </Grid>

            <Grid item xs={12} style={{ height: "100%", background: "#fff" }}>
              <Grid item container xs={12} className={classes.innerContent}>
                <Grid item container xs={12} className={classes.navLinks}>
                  {navlinks.map((l, i) => (
                    <Grid item container xs={12}>
                      <span
                        style={{
                          fontWeight: 600,
                          padding: "0.2rem",
                          borderRadius: "0.2rem",
                        }}
                        onClick={() =>
                          setExpandSubMenu((c) => {
                            if (!c) {
                              return l.name
                            }
                            return l.name
                          })
                        }
                        className={[
                          expandSubMenu == l.name && classes.activeMenu,
                        ]}
                      >
                        {l.name}{" "}
                        {dropdownOptions[l.name] && (
                          <span
                            className={[classes.rotateNow]}
                            style={{
                              animationFillMode:
                                expandSubMenu == l.name
                                  ? "forwards"
                                  : "backwards",
                            }}
                          >
                            {" "}
                            {">"}{" "}
                          </span>
                        )}
                      </span>{" "}
                      {expandSubMenu == l.name && (
                        <Grid item container xs={12}>
                          {" "}
                          {dropdownOptions[l.name]?.map((sh) => (
                            <Grid
                              item
                              xs={12}
                              paddingLeft={"0.5rem"}
                              onClick={() => {
                                setExpandSubMenu(null)
                                setOpenDrawer(false)
                              }}
                            >
                              <NavLink to={sh.link} style={{ color: "#000" }}>
                                {sh.label}
                              </NavLink>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ height: "fit-content" }}
                  padding={"1rem 1rem 0rem 1rem"}
                >
                  <hr style={{ width: "100%" }} />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  padding={"0rem 1rem 1rem 1rem"}
                  alignSelf={"flex-start"}
                  alignItems={"center"}
                >
                  {headerIconsSrc.map((i) => (
                    <Grid item xs={2}>
                      <IconButton
                        size="small"
                        style={{ padding: "0.25rem" }}
                        onClick={() => {
                          window.open(i.link, "_blank")
                        }}
                      >
                        <i.src style={{ fill: "#f46d25", padding: "0rem" }} />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={5} paddingLeft={"1rem"}>
                  <Button
                    onClick={() => {
                      setOpenDrawer(false)
                      setExpandSubMenu(null)
                    }}
                  >
                    <NavLink
                      to="/konnect"
                      style={{ color: "#fff" }}
                      target="_blank"
                    >
                      KONNECT
                    </NavLink>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Navbar
