export const currencyFormatter = (amount) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "INR",
    // minimumFractionDigits: 2,
    maximumFractionDigits: 0,
  })

  return formatter.format(amount)
}
