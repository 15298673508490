export const twnButtonStyles = {
  allPages: {
    padding: "4.2% 1%",
    height: "00vh",
    fontSize: "14px",
    backgroundColor: "#F7F7F7",
  },
  allPagesWithoutPadding: {
    height: "100vh",
    fontSize: "14px",
    backgroundColor: "#F7F7F7",
  },
  paperStyle: {
    backgroundColor: "#fff",
    padding: "1% 1.5%",
    width: "100%",
    border: "0.1px solid #F4F4F4",
    display: "flex",
    flexDirection: "column",
  },
  titleStyle: { fontSize: "24px", fontWeight: "500", color: "#1F2937" },
  valueStyle: { fontSize: "23px", fontWeight: "bold", color: "#F46D25" },
  HotelSelectionStyle: {
    border: "none",
    outline: "none",
    scrollBehavior: "smooth",
    padding: "4px",
    borderRadius: "5px",
    margin: "0 25px 0 0",
  },
  orangeBtn: {
    fontSize: "14px",
    fontWeight: "600",
    background: "#F46D25",
    border: 0,
    cursor: "pointer",
    borderRadius: "5px",
    color: "white",
    padding: "5px 15px",
    height: "32px",
    textAlign: "center",
    boxShadow: "none",
  },
  blackBtn: {
    fontSize: "14px",
    fontWeight: "600",
    background: "#111",
    border: 0,
    cursor: "pointer",
    borderRadius: "5px",
    color: "white",
    padding: "5px 15px",
    height: "32px",
    textAlign: "center",
    boxShadow: "none",
  },
  linkOrangeBtn: {
    fontSize: "14px",
    fontWeight: "600",
    background: "#F46D25",
    border: 0,
    cursor: "pointer",
    borderRadius: "5px",
    color: "white",
    padding: "6px 15px",
    // height: "23px",
    textAlign: "center",
    boxShadow: "none",
  },
  linkBlackBtn: {
    fontSize: "14px",
    fontWeight: "600",
    background: "#000",
    border: 0,
    cursor: "pointer",
    borderRadius: "5px",
    color: "white",
    padding: "6px 15px",
    // height: "21px",
    textAlign: "center",
    boxShadow: "none",
  },
  disabledBtn: {
    fontSize: "14px",
    fontWeight: "600",
    background: "#cccccc",
    border: 0,
    borderRadius: "5px",
    padding: "6px 15px",
    height: "32px",
    textAlign: "center",
    boxShadow: "none",
  },

  // fonts
  xlFonts: { fontSize: "24px", color: "#F46D25", fontWeight: "bold" }, //titles and numbers
  lgFonts: { fonstSize: "18px", fontWeight: "500" }, //form titles
  mdFonts: { fonstSize: "15px" }, //general, semibold for list titles
  smFonts: { fonstSize: "12px" }, //input fields and tooltips

  //tables
  headerStyle: {
    height: "45px",
    backgroundColor: "#F46D25",
    color: "#fff",
    fonstSize: "14px",
  },
  rowStyle: {
    height: "42px",
    backgroundColor: "#fff",
    color: "#1F2937",
    boxShadow: "none",
    borderColor: "1px solid #F4F4F4",
    textAlign: "left",
    fontSize: "12px",
  },

  labelStyle: {
    fontWeight: "bold",
  },

  //fields
  fieldStyle: { height: "45px", color: "#F46D25" },
  // searchField:{height: '32px', color: '#1F2937',width:'220px',fonstSize: '12px'}
};

const innerWidth = window.innerWidth;

if (innerWidth <= 768) {
  twnButtonStyles.paperStyle = {
    ...twnButtonStyles.paperStyle,
    width: "max-width",
    display: "flex",
    flexDirection: "column",
    height: "70px",
    justifyContent: "space-between",
    marginBottom: "10px",
  };

  twnButtonStyles.labelStyle = {
    ...twnButtonStyles.labelStyle,
    width: "100px",
  };

  // twnButtonStyles.HotelSelectionStyle = {
  //   ":hover": {
  //     border: "1px solid black",
  //     width: "500px",
  //   },
  // };
}
