import Api from "../../Service/Api"
import { DROPDOWN_ACTIONS } from "../reducers/dropDownReducer"
import { ActionTypes } from "./actionTypes"
import { toast } from "react-toastify"

export const dropDownListInitial = (data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.DROPDOWN_LIST_REQUEST,
  })
  await Api.post("productdropdown", data).then((res) => {
    dispatch({ type: ActionTypes.DROPDOWN_LIST_SUCCESS, payload: res.data })
  })
}

export const dropDowndetailsInitial = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.DROPDOWN_DETAIL_REQUEST, payload: id })
  await Api.get(`productdropdown/${id}`).then((res) => {
    dispatch({ type: ActionTypes.DROPDOWN_DETAIL_SUCCESS, payload: res.data })
  })
}

export const dropDownCreateInitial =
  (createData, DropData) => async (dispatch) => {
    dispatch({
      type: ActionTypes.DROPDOWN_CREATE_REQUEST,
      payload: createData,
    })
    await Api.post("productdropdowncreate", createData).then((res) => {
      dispatch({
        type: ActionTypes.DROPDOWN_CREATE_SUCCESS,
        payload: res.data,
      })
      dispatch(dropDownListInitial(DropData))
      dispatch(dropDownclearFormInitial())
      toast.success("Successfully Created")
    })
  }

export const dropDownUpdateInitial =
  (updateData, DropData) => async (dispatch) => {
    dispatch({
      type: ActionTypes.DROPDOWN_UPDATE_REQUEST,
      payload: updateData,
    })
    await Api.put(`productdropdown/${updateData.id}`, updateData).then(
      (res) => {
        dispatch({
          type: ActionTypes.DROPDOWN_UPDATE_SUCCESS,
          payload: res.data,
        })
        dispatch(dropDownListInitial(DropData))
        toast.success("Update SuccessFully")
        dispatch(dropDownclearFormInitial())
      }
    )
  }

export const dropDownclearFormInitial = () => (dispatch) => {
  dispatch({ type: ActionTypes.DROPDOWN_CLEAR })
}

export const getCitiesDropdown = () => async (dispatch) => {
  dispatch({ type: DROPDOWN_ACTIONS.FETCHING })
  try {
    const res = await Api.get(
      `propertycitynameall/${localStorage.getItem("unique_id")}`
    )
    if (res.status == 200) {
      const citiesDropdown = res.data.map((c) => ({ label: c, value: c }))
      dispatch({
        type: DROPDOWN_ACTIONS.FETCHING_SUCCESS_CITY_DROPDOWN,
        payload: { citiesDropdown },
      })
      return
    }
    return Promise.reject(res)
  } catch (error) {
    toast.error("failed to fecth city list")
    dispatch({
      type: DROPDOWN_ACTIONS.FETCHING_SUCCESS_CITY_DROPDOWN,
      payload: { citiesDropdown: [] },
    })
  }
}

export const getPropertyListDropdown = (city) => async (dispatch) => {
  dispatch({ type: DROPDOWN_ACTIONS.FETCHING })
  try {
    const res = await Api.get(`propertycitynameidlistall/${city}`)
    if (res.status == 200) {
      const propertyDropdown = res.data.map((c) => ({
        label: c.displayName,
        value: c.propertyId,
      }))
      dispatch({
        type: DROPDOWN_ACTIONS.FETCHING_SUCCESS_PROPERTY_DROPDOWN,
        payload: { propertyDropdown },
      })
      return
    }
    return Promise.reject(res)
  } catch (error) {
    toast.error(
      "Failed to fetch property list. Please select any city from the options."
    )
    dispatch({
      type: DROPDOWN_ACTIONS.FETCHING_SUCCESS_PROPERTY_DROPDOWN,
      payload: { propertyDropdown: [] },
    })
  }
}
