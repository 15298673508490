import { makeStyles } from "@material-ui/core";
import { forwardRef, useRef } from "react";
import Slider from "react-slick";
import LeftNavArrow from "../LeftNavArrow/leftNavArrow.component";
import RightNavArrow from "../RightNavArrow/rightNavArrow.component";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    "@media (min-width:320px) and (max-width:375px)": {
      margin: "25px 0px",
    },
  },
  root: {
    padding: "1rem",
    zIndex: 10,
    "&.slick-slider > .slick-arrow": {
      //   background: "red",
      display: "none !important",
      [theme.breakpoints.down("xs")]: {
        display: "none !important",
      },
    },
    "&.slick-slider > .slick-dots > li": {
      margin: "0px !important",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  navArrowContainer: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

function CustomSlickSliderComponent(props, ref) {
  const { className } = props;
  const classes = useStyles();
  const sliderRef = useRef(null);
  return (
    <div className={classes.container}>
      <div className={classes.navArrowContainer}>
        <LeftNavArrow
          onClick={() => (ref ? ref : sliderRef)?.current.slickPrev()}
        />
        <RightNavArrow
          onClick={() => (ref ? ref : sliderRef)?.current.slickNext()}
        />
      </div>
      <Slider
        {...props}
        className={[className, classes.root]}
        ref={ref ? ref : sliderRef}
        arrows={false}
      >
        {props.children}
      </Slider>
    </div>
  );
}

export const CustomSlickSlider = forwardRef(CustomSlickSliderComponent);
