import Api from "../Api"

export const getAllPackageCards = () => {
  return Api.get("b2CPackageSellingPrice")
}

export const getAllActivePackageCards = () => {
  return Api.get("b2CPackageSellingPriceActive")
}

export const toggleActivePackage = (id, auth, status) => {
  return Api.get(`b2CPackageSellingPricetoggleActive/${id}/${auth}/${status}`)
}

export const getPackageCardInfo = (id) => {
  return Api.get(`B2CPackage/b2CPackageSellingPrice/${id}`)
}

export const deletePackageCard = (id, auth) => {
  //for deleting record we're setting status to 2
  return Api.get(`b2CPackageSellingPricetoggleActive/${id}/${auth}/2`)
}

export const searchForPackagesWithinPriceRange = (value) => {
  return Api.get(
    `B2CPackageSellingPrice/search?minPrice=${
      Number(value[0]) * 1000
    }&maxPrice=${Number(value[1]) * 1000}`
  )
}
