import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {showButton && (
        <div
          onClick={handleClick}
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
            background:
              " linear-gradient(180deg, rgba(194, 138, 7, 1), rgba(254, 88, 0, 1))",
            color: "#fff",
            padding: "10px",
            borderRadius: "50%",
            cursor: "pointer",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          <ArrowUpwardIcon />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
