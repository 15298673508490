import { useEffect, useState } from "react"

export const useDeviceWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleScreenWidthSize = () => {
      setWidth(window.innerWidth)
    }
    document.addEventListener("resize", handleScreenWidthSize)

    return () => document.removeEventListener("resize", handleScreenWidthSize)
  }, [width])

  return width
}
