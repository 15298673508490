import { combineReducers } from "redux"
import { AgentDetailReducer, AgentListReducer } from "./agentReducer"
import {
  airConditionReducer,
  butlerReducer,
  childCareServiceReducer,
  childrenplayareaReducer,
  conferenceareaReducer,
  firePlaceReducer,
  gymReducer,
  interComReducer,
  jacuzziReducer,
  kitchenReducer,
  laundryReducer,
  loungesReducer,
  outdoorFurnitureReducer,
  parkingReducer,
  restaurantReducer,
  roomServiceReducer,
  salonReducer,
  shoppingReducer,
  sightSeeingReducer,
  smokeDetectorReducer,
  spaReducer,
  speciallyAbledReducer,
  steamReducer,
  swimmingpoolReducer,
  templeReducer,
} from "./amenitiesDropDownReducer"
import { loginReducer } from "./authReducer"
import { BookingDetialListReducer, HistoryListReducer } from "./bookingReducer"
import { BranchDetailReducer, BranchListReducer } from "./branchReducer"
import { ClientDetailReducer, ClientListReducer } from "./clientReducer"
import {
  bookingSourceOptionsReducer,
  paymentModeOptionsReducer,
} from "./commonFetaureDropdown.reducer"
import { userReducer } from "./currentUserReducer"
import {
  DesinationDetailReducer,
  DesinationListReducer,
} from "./designationReducer"
import {
  DropDownDetailReducer,
  DropDownListReducer,
  propertyDropdownBasedOnCityReducer,
} from "./dropDownReducer"
import {
  BranchOnlyListReducer,
  DesignationOnlyListReducer,
  RoleOnlyListReducer,
} from "./dropsReducer"
import { hotelLoginTermsAndConditionsAndCancellationPolicyReducer } from "./hotelLoginTermsAndConditionReducer"
import {
  hotelDetailsListReducer,
  hotelRoomListReducer,
} from "./hotelViewReducer"
import {
  currencyListReducer,
  propertyBankDataReducer,
  propertyBasicDataReducer,
  propertyContactDataReducer,
  propertyImageListReducer,
  propertyListReducer,
  propertyTypeListReducer,
  roomListDetailsDetailsReducer,
  timeZoneListReducer,
  vccCurrencyListReducer,
} from "./propertyReducer"
import { RoleDetailReducer, RoleListReducer } from "./roleReducer"
import { roomPaymentListReducer } from "./roomPaymentReducer"
import { viewRoomReducer } from "./roomView.reducer"
import { StaffDetailReducer, StaffListReducer } from "./staffReducer"
import { leadCountReducer } from "./leadNotifyWebsocket.reducer"
import { clonePropertyAmenitiesReducer } from "./clonePropertyAmenities.reducer"
import { videoPlayerModalReducer } from "./videoPlayerModal.reducer"
import {
  checkoutRoomsReducer,
  searchPropertiesReducer,
} from "./bookingRoomReducer"

const rootReducer = combineReducers({
  clonePropertyAmenitiesReducer: clonePropertyAmenitiesReducer,
  // leadCountReducer: leadCountReducer,
  paymentModeOptionsReducer: paymentModeOptionsReducer,
  bookingSourceOptionsReducer: bookingSourceOptionsReducer,
  userReducer: userReducer,
  authLogin: loginReducer,
  branchList: BranchListReducer,
  branchDetails: BranchDetailReducer,
  clientList: ClientListReducer,
  clientDetails: ClientDetailReducer,
  agentList: AgentListReducer,
  agentDetails: AgentDetailReducer,
  designationList: DesinationListReducer,
  designationDetails: DesinationDetailReducer,
  dropDownList: DropDownListReducer,
  dropDownDetails: DropDownDetailReducer,
  roleList: RoleListReducer,
  roleDetails: RoleDetailReducer,
  staffList: StaffListReducer,
  staffDetails: StaffDetailReducer,
  branchOnlyList: BranchOnlyListReducer,
  designationOnlyList: DesignationOnlyListReducer,
  roleOnlyList: RoleOnlyListReducer,
  propertyList: propertyListReducer,
  propertyDropdownBasedOnCity: propertyDropdownBasedOnCityReducer,
  propertyTypeList: propertyTypeListReducer,
  timeZoneList: timeZoneListReducer,
  roomListDetails: roomListDetailsDetailsReducer,
  searchProperties: searchPropertiesReducer,
  vccCurrencyList: vccCurrencyListReducer,
  currencyList: currencyListReducer,
  propertyBasicData: propertyBasicDataReducer,
  propertyContactData: propertyContactDataReducer,
  propertyBankData: propertyBankDataReducer,
  propertyImageList: propertyImageListReducer,
  parkingList: parkingReducer,
  laundryList: laundryReducer,
  swimmingpoolList: swimmingpoolReducer,
  smokeDetectorList: smokeDetectorReducer,
  roomServiceList: roomServiceReducer,
  airConditionList: airConditionReducer,
  kitchenList: kitchenReducer,
  interComList: interComReducer,
  childCareServiceList: childCareServiceReducer,
  sightSeeingList: sightSeeingReducer,
  butlerList: butlerReducer,
  speciallyAbledList: speciallyAbledReducer,
  firePlaceList: firePlaceReducer,
  loungesList: loungesReducer,
  jacuzziList: jacuzziReducer,
  conferenceareaList: conferenceareaReducer,
  shoppingList: shoppingReducer,
  childrenplayareaList: childrenplayareaReducer,
  templeList: templeReducer,
  outdoorFurnitureList: outdoorFurnitureReducer,
  restaurantList: restaurantReducer,
  steamList: steamReducer,
  salonList: salonReducer,
  spaList: spaReducer,
  gymList: gymReducer,
  bookingDetails: BookingDetialListReducer,
  hotelDetail: hotelDetailsListReducer,
  hotelRoomListDetails: hotelRoomListReducer,
  hotelLogin: hotelLoginTermsAndConditionsAndCancellationPolicyReducer,
  historyList: HistoryListReducer,
  roomPaymentList: roomPaymentListReducer,
  viewRoom: viewRoomReducer,
  videoPlayerModalReducer,
  checkoutRooms: checkoutRoomsReducer,
})

export default rootReducer
