import { CHECKOUT_ACTION_TYPES } from "../types/checkout.types"
import { ActionTypes } from "./actionTypes"
import API from "../../Service/Api"
import { hotelDetailsInitial } from "./hotelViewAction"
import { toast } from "react-toastify"

export const handleChangeSearchParams = (name, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.HANDLE_CHANGE_VALUES,
    payload: {
      name,
      value: value,
    },
  })
}

export const toggleModalChildAndAdults = () => (dispatch) => {
  dispatch({ type: ActionTypes.TOGGLE_MODAL_PROPERTY_SEARCH })
}

const addRoom = (checkoutRoomDetails, roomToAdd) => {
  let existingRoom = checkoutRoomDetails.find(
    (r) => r.mealplan == roomToAdd.mealplan && roomToAdd.roomRent == r.roomRent
  )

  if (existingRoom) {
    checkoutRoomDetails = checkoutRoomDetails.map((r) =>
      r.mealplan == roomToAdd.mealplan && roomToAdd.roomRent == r.roomRent
        ? { ...r, noOfRooms: roomToAdd.noOfRooms }
        : r
    )
  } else {
    checkoutRoomDetails = [...checkoutRoomDetails, { ...roomToAdd }]
  }

  const totalAmount = checkoutRoomDetails.reduce(
    (pre, curr) => (pre += parseFloat(curr.noOfRooms * curr.roomRent)),
    0
  )

  const totalRooms = checkoutRoomDetails.reduce(
    (pre, curr) => (pre += parseInt(curr.noOfRooms)),
    0
  )

  return { checkoutRoomDetails, totalAmount, totalRooms }
}

/**adds room to checkout list */
export const addRoomToCheckout =
  (checkoutRoomDetails, roomToAdd) => (dispatch) => {
    let updatedCheckout = addRoom(checkoutRoomDetails, roomToAdd)

    dispatch({
      type: CHECKOUT_ACTION_TYPES.SET_CHECKOUT_ROOMS,
      payload: updatedCheckout,
    })
  }

const removeRoom = (checkoutRoomDetails, roomToRemove) => {
  let existingRoom = checkoutRoomDetails.find(
    (r) =>
      r.mealplan == roomToRemove.mealplan && roomToRemove.roomRent == r.roomRent
  )

  if (existingRoom.noOfRooms == 0) {
    checkoutRoomDetails = checkoutRoomDetails.filter(
      (r) =>
        r.mealplan == roomToRemove.mealplan &&
        roomToRemove.roomRent == r.roomRent
    )
    const totalAmount = checkoutRoomDetails.reduce(
      (pre, curr) => (pre += parseFloat(curr.noOfRooms * curr.roomRent)),
      0
    )
    return { checkoutRoomDetails, totalAmount }
  }

  checkoutRoomDetails = checkoutRoomDetails.map((r) =>
    r.mealplan == roomToRemove.mealplan && roomToRemove.roomRent == r.roomRent
      ? { ...r, noOfRooms: r.noOfRooms - 1 }
      : r
  )
  const totalAmount = checkoutRoomDetails.reduce(
    (pre, curr) => (pre += parseFloat(curr.noOfRooms * curr.roomRent)),
    0
  )
  return { checkoutRoomDetails, totalAmount }
}

export const removeRoomFromCheckout =
  (checkoutRoomDetails, roomToRemove) => (dispatch) => {
    const updatedCheckout = removeRoom(checkoutRoomDetails, roomToRemove)

    dispatch({
      type: CHECKOUT_ACTION_TYPES.SET_CHECKOUT_ROOMS,
      payload: { updatedCheckout },
    })
  }

export const resetPropertySearchParams = () => (dispatch) => {
  dispatch({ type: ActionTypes.RESET_SEARCH_PROPERTY_PARAMS })
}

export const resetPropertyRoomsCheckout = () => (dispatch) => {
  dispatch({ type: CHECKOUT_ACTION_TYPES.RESET_CHECKOUT_ROOMS })
}

export const getCheckoutBookingDetails =
  (bookingId, unique_id) => async (dispatch) => {
    dispatch({ type: CHECKOUT_ACTION_TYPES.FETCHING_BOOKING_DETAILS })
    try {
      const res = await API.get(`checkoutBookingById/${unique_id}/${bookingId}`)
      if (res.data == 200) {
        console.log(res.data)
        dispatch(hotelDetailsInitial({ propertyId: res.data.propertyId }))
        dispatch({
          type: CHECKOUT_ACTION_TYPES.BOOKING_DETAILS_SUCCESS,
          payload: res.data,
        })
      }
    } catch (error) {
      console.log(error)
      toast.error("Error")
    }
  }
