import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import API from "../../Service/Api"
import { toast } from "react-toastify"
import Loader from "../../components/Loader/Loader"
import { format } from "date-fns"
import { Visibility } from "@material-ui/icons"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem 1.5rem 4rem 1.5rem",
  },
}))

const header = [
  { label: "Booking ID", size: 2 },
  { label: "Name", size: 3 },
  { label: "Check-In", size: 2.5 },
  { label: "No of Nights", size: 1.5 },
  { label: "Total Rooms", size: 1.5 },
  { label: "Action", size: 1.5 },
]

const CheckoutBookingList = () => {
  const classes = useStyles()
  const history = useHistory()
  const unique_id = localStorage.getItem("unique_id")
  const [bookingList, setBookingList] = useState([])
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true)
      try {
        const res = await API.get(`checkoutBookingAll/${unique_id}`)
        if (res.status == 200) {
          setBookingList(res.data)
          return
        }
        return Promise.reject(res)
      } catch (error) {
        console.error(error)
        toast.error("Failed to fetch list")
      } finally {
        setLoading(false)
      }
    }
    fetchList()
  }, [])

  if (loading) return <Loader />

  return (
    <Grid container md={12} className={classes.container}>
      <Grid
        item
        md={12}
        fontSize={"2.5rem"}
        fontWeight={600}
        color={"#f46d25"}
        marginBottom={3}
      >
        Booking List
      </Grid>
      <Grid
        item
        container
        md={12}
        fontweight={600}
        borderRadius={"0.5rem 0.5rem 0rem 0rem"}
      >
        <TableContainer>
          <Table>
            <TableHead
              style={{
                color: "#fff",
                background: "#f46d25",
                borderRadius: "0.5rem 0.5rem 0rem 0rem",
              }}
            >
              {header.map((t, i) => (
                <TableCell style={{ color: "#fff", textAlign: "center" }}>
                  {t.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {bookingList.map((b) => (
                <TableRow key={b.id}>
                  <TableCell style={{ textAlign: "center" }}>
                    {b.bookingId}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {b.guestName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {format(new Date(b.checkIn), "dd MMM yyyy")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {b.noOfNights}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {b.totalRooms}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <IconButton
                      size="small"
                      color="primary"
                      title={`View ${b.bookingId}`}
                      // onClick={() => {
                      //   history.push(
                      //     `/konnect/checkoutBookingDetails/${b.bookingId}`
                      //   )
                      // }}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={bookingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default CheckoutBookingList
