import { ActionTypes } from "../actions/actionTypes"

export const DropDownListReducer = (
  state = {
    dropDownLists: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.DROPDOWN_LIST_SUCCESS:
      return { ...state, loading: false, dropDownLists: action.payload }
    default:
      return state
  }
}

export const DropDownDetailReducer = (
  state = { dropDownDetail: {}, loading: false },
  action
) => {
  switch (action.type) {
    case ActionTypes.DROPDOWN_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.DROPDOWN_DETAIL_SUCCESS:
      return { ...state, loading: false, dropDownDetail: action.payload }
    case ActionTypes.DROPDOWN_CLEAR:
      return {
        ...state,
        dropDownDetail: {},
      }
    default:
      return state
  }
}

export const DROPDOWN_ACTIONS = {
  FETCHING: "FETCHING",
  FETCHING_SUCCESS_PROPERTY_DROPDOWN: "FETCHING_SUCCESS_PROPERTY_DROPDOWN",
  FETCHING_SUCCESS_CITY_DROPDOWN: "FETCHING_SUCCESS_CITY_DROPDOWN",
}

export const propertyDropdownBasedOnCityReducer = (
  state = {
    citiesDropdown: [],
    propertyDropdown: [],
    loading: false,
  },
  action
) => {
  const { type, payload } = action

  switch (type) {
    case DROPDOWN_ACTIONS.FETCHING:
      return { ...state, loading: true }
    case DROPDOWN_ACTIONS.FETCHING_SUCCESS_PROPERTY_DROPDOWN:
      return {
        ...state,
        propertyDropdown: payload.propertyDropdown,
        loading: false,
      }
    case DROPDOWN_ACTIONS.FETCHING_SUCCESS_CITY_DROPDOWN:
      return {
        ...state,
        citiesDropdown: payload.citiesDropdown,
        loading: false,
      }
    default:
      return state
  }
}
