import { Button, IconButton, makeStyles } from "@material-ui/core";
import { baseurl } from "../../Service/httpCommon";
import { PhoneOutlined, Star } from "@material-ui/icons";
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter";
import _ from "lodash";
import DefaultImg from "../../B2C_Konnect/assets/homePageAssets/Domestic packages 6/Goa.webp";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    aspectRatio: 1 / 1.4,
    display: "grid",
    gridTemplateAreas: `"cardStack"`,
    padding: "0.3rem",
    background: "transparent",
    backdropFilter: "blur(0.5rem)",
    borderRadius: "0.8rem",
    boxShadow: "0rem 0rem 0.3rem #000",
    "& > *": {
      gridArea: "cardStack",
    },
    "@media (min-width:320px) and (max-width:375px)": {},
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateAreas: `"contentStack"`,
    borderRadius: "0.5rem",
    overflow: "hidden",
    "& > *": {
      gridArea: "contentStack",
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  overlay: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.54),rgba(56, 56, 60, 0.9))",
  },
  content: {
    padding: "0.5rem",
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    gap: "0.7rem",
    "@media (min-width:320px) and (max-width:375px)": {},
  },
  duration: {
    background: "rgba(255, 239, 231, 1)",
    padding: "0.4rem 0.5rem",
    fontSize: "0.8rem",
    width: "fit-content",
    color: "#000000 !important",
    zIndex: 1,
  },
  rating: {
    color: "#fff",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontSize: "clamp(1rem,10vw,1.2rem)",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    fontWeight: 600,
    alignItems: "center",
    "@media (min-width:320px) and (max-width:375px)": {
      fontSize: "0.8rem",
      marginBottom: "2rem",
    },
  },
}));

const CardComponentB2C = ({ card, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <img
          src={`${baseurl}getimage/${card?.imgSrc}`}
          onErrorCapture={(e) => {
            if (e.target.oldSrc) return;
            e.target.oldSrc = e.target.src;
            e.target.src = DefaultImg;
          }}
        />
        <div className={classes.overlay} />
        <div className={classes.content}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={classes.duration}>{card.duration}</div>
            <div className={classes.rating}>
              <Star color="primary" />
              {card.rating}
              <span style={{ color: "#ffffff70" }}>({card.totalReviews})</span>
            </div>
          </div>

          <div className={classes.info}>
            <h3 style={{ margin: 0 }}>{_.capitalize(card.name)}</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3rem",
              }}
            >
              <div
                style={{
                  fontSize: "clamp(0.5rem,5vw,0.65rem)",
                  fontWeight: "normal",
                }}
              >
                Starting From
              </div>
              <div>{currencyFormatter(card.price)}</div>
            </div>
          </div>

          <div
            style={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "0.3rem",
              fontSize: "0.85rem",
              padding: "0.5rem",
              color: "#fff",
            }}
          >
            {card.itineraryTimeLine}
          </div>

          <div
            style={{
              width: "100%",
              height: "0.1rem",
              background:
                "linear-gradient(to right,rgba(255, 243, 0, 0),rgba(244, 109, 37, 1),rgba(244, 109, 37, 1),rgba(255, 243, 0, 0))",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <a href="tel:+918006800727" style={{ textDecoration: "none" }}>
              <IconButton
                size="small"
                style={{
                  border: "2px solid #fff",
                  padding: "0.5rem",
                  alignSelf: "flex-start",
                }}
              >
                <PhoneOutlined
                  style={{ fill: "#fff", width: "1rem", height: "1rem" }}
                />
              </IconButton>
            </a>
            <Button
              fullWidth
              size="small"
              style={{
                alignSelf: "flex-start",
                background:
                  "linear-gradient(98.15deg, #FFF300 -52.88%, #F46D25 74.99%)",
              }}
              onClick={() => {
                onClick(card);
              }}
            >
              Enquire Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponentB2C;
