import { addDays, format } from "date-fns"
import Api from "../../Service/Api"
import { ActionTypes } from "./actionTypes"
import { toast } from "react-toastify"

export const hotelDetailsInitial = (value) => async (dispatch) => {
  await dispatch({
    type: ActionTypes.HOTELDETAIL_LIST_REQUEST,
  })

  const res = await Api.post("propertybasicpropertydetailsvalue", value)
  await dispatch({
    type: ActionTypes.HOTELDETAIL_LIST_SUCCESS,
    payload: res.data,
  })
}

export const resetHotelDetails = () => (dispatch) => {
  dispatch({ type: ActionTypes.HOTEL_DETAILS_RESET })
}

export const propertyRoomListDetails =
  (propertyId, checkInFrom = new Date(), checkInTo = addDays(new Date(), 1)) =>
  async (dispatch) => {
    dispatch({ type: ActionTypes.FETCHING_HOTEL_ROOM_DETAILS })

    checkInFrom = format(new Date(checkInFrom), "yyyy-MM-dd")
    checkInTo = format(new Date(checkInTo), "yyyy-MM-dd")

    try {
      const res = await Api.get(
        `CheckOut/${propertyId}/${checkInFrom}/${checkInTo}`
      )

      if (res.status == 200) {
        dispatch({
          type: ActionTypes.FETCHING_HOTEL_ROOM_DETAILS_SUCCESS,
          payload: res.data,
        })
        return
      }
      return Promise.reject(res)
    } catch (error) {
      console.log(error)

      dispatch({ type: ActionTypes.HOTEL_ROOM_DETAILS_RESET })
      toast.error("Failed to fetch details")
    }
  }
