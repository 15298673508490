import React, {
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import LandingPage from "../InnerCircle_Konnect/Pages/LandingPage/LandingPage";
import AboutUs from "./Pages/AboutUs/AboutUs.page";
import ContactPage from "./Pages/Contact/Contact.page";
import Destination from "./Pages/Destination/Destination.page";
import Home from "./Pages/Home/Home.page";
import Hotels from "./Pages/Hotels/Hotels.page";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.component";
import RefundAndCancellationPolicy from "./Pages/RefundAndCancellationPolicy/RefundAndCancellationPolicy.page";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions.page";
import "./RoutesB2C.css";
import ContactModal from "./components/ContactModal/ContactModal.component";
import Footer from "./components/Footer/Footer.component";
import Header from "./components/Header/header.component";
import Navbar from "./components/Navbar/Navbar.component";
import { ContactModalContext } from "./context/contactModal.context";
import { SideNavbarContext } from "./context/sideNavBarContext";
import TrekkingPage from "./Pages/Trekking/Trekking.Page";
import { useNotification } from "../hooks/useNotification";
import { NotificationProvider } from "./components/NotificationProvider/NotificationProvider.component";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";

const B2CHomepage = lazy(() => import("./Pages/Home/Home.page"));

export const BackgroundContext = createContext(null);

// the below meta info is for the route meta title keyword and description

const metaInfo = {
  "/": {
    title: "Affordable Travel Packages & Customized Tour Deals | Towno",
    keywords:
      "affordable travel packages, customized travel package, budget holiday packages, holiday travel deals, best travel itineraries, travel tour, tour packages, international tour packages, domestic tour packages",
    description:
      "Book affordable travel packages with Towno! Enjoy customized tours, budget deals, & hassle-free itineraries for both domestic and international destinations.",
  },
  "/uttarakhand": {
    title: "Uttarakhand Travel Guide | Best Tourist Places & Tours",
    keywords:
      "uttarakhand travel guide, uttarakhand tours, uttarakhand trip, tourism in Uttarakhand, uttarakhand tourism, uttarakhand tourist places",
    description:
      "Discover the ultimate Uttarakhand Travel Guide! Explore top Uttarakhand tourist places, exciting Uttarakhand tours, and experience Uttarakhand tourism.",
  },
};

const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    // Default to home page meta if no specific route found
    const metaData = metaInfo[location.pathname] || metaInfo["/"];

    // Update title
    document.title = metaData.title;

    // Update keywords
    const keywordsMetaTag = document.querySelector("meta[name='keywords']");
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", metaData.keywords);
    }

    // Update description
    const descriptionMetaTag = document.querySelector(
      "meta[name='description']"
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", metaData.description);
    }
  }, [location]);

  return null;
};

const RoutesB2C = () => {
  const { openSideNavbar, setOpenSideNavbar } = useContext(SideNavbarContext);

  const { open, onClose } = useContext(ContactModalContext);

  const { url } = useRouteMatch();

  const { pathname } = useLocation();

  const mainContainerRef = useRef();
  const watiRef = useRef();

  useEffect(() => {
    const pageContainer =
      mainContainerRef.current.querySelector("#page-container");
    const children = pageContainer.querySelectorAll("section");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let x = 80 * Number(window.scrollY / 10000);
          mainContainerRef.current.style.setProperty("--x", x + "%");
        }
      });
    });

    children.forEach((child) => observer.observe(child));

    return () => observer.disconnect();
  }, []);

  const { NotificationComponent } = useNotification();

  return (
    <NotificationProvider>
      <div className="main-container" ref={mainContainerRef}>
        <div id="b2cScreen">
          <div id="root-layout">
            <Header />
            <Navbar />
            <div id="page-container" onClick={() => setOpenSideNavbar(false)}>
              <TitleUpdater />
              <Switch>
                <Route path={`${url}`} component={Home} exact />
                <Route path={`${url}hotels`} component={Hotels} exact />
                <Route
                  path={`${url}terms`}
                  component={TermsAndConditions}
                  exact
                />
                <Route
                  path={`${url}refund`}
                  component={RefundAndCancellationPolicy}
                  exact
                />
                <Route path={`${url}privacy`} component={PrivacyPolicy} exact />
                <Route path={`${url}contactUs`} component={ContactPage} />
                <Route path={`${url}aboutUs`} component={AboutUs} />
                <Route path={`${url}innerCircle`}>
                  <Route path={`${url}`} component={LandingPage} />
                </Route>
                <Route path={`${url}uttarakhand`} component={Destination} />

                <Route path={`${url}trekking`} component={TrekkingPage} />

                <Redirect to="/" />
              </Switch>

              {/*            
              <Modal
                open={true}
                onClose={onClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    zIndex: "10000",
                    position: "relative",
                    backdropFilter: "blur(10px)",
                    padding: "1rem",
                    borderRadius: "0.8rem",
                  }}
                >
                  <h1 style={{ color: "red" }}>
                    Cant find what yo are looking for ?
                  </h1>
                  <h4 style={{ color: "red" }}>Drop in the details below</h4>
                  <QueryForm />
                </div>
              </Modal> */}
            </div>
          </div>
          <ContactModal />
          <Footer />
          {/* <ContactButton /> */}
          <ScrollToTopButton />
        </div>
      </div>
    </NotificationProvider>
  );
};

export default RoutesB2C;
