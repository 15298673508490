import styles from "./Destination.layout.module.css";
import BannerOne from "../../assets/illustrations/destination/banner_images/destination_bg_1.png";
import BannerTwo from "../../assets/illustrations/destination/banner_images/destination_bg2.png";
import BannerThree from "../../assets/illustrations/destination/banner_images/tourism.png";
import BannerFour from "../../assets/illustrations/destination/banner_images/thimgs_todo_banner.png";
import BannerFive from "../../assets/illustrations/destination/banner_images/key_facts.png";
import BannerSix from "../../assets/illustrations/destination/banner_images/shopping.png";
import BannerSeven from "../../assets/illustrations/destination/banner_images/cuisine.png";
import BannerEight from "../../assets/illustrations/destination/banner_images/things_to_carry.png";
import parser from "html-react-parser";
import BackgroundVideo from "../../assets/videos/Website Bg Video.mp4";

import {
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import { createContext, useState } from "react";
import usePathProvider from "../../hooks/usePathProvider.hook";
import BredCrumbs from "../../components/Bredcrumbs/BredCrumbs.component";
import AsideDestinationNavbar from "../../components/AsideDestinationNavbar/AsideDestinationNavbar.component";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayerModal from "../../components/VideoPlayerModal/VideoPlayerModal.component";
import { handleCloseVideoPlayerModal } from "../../../redux/actions/videoPlayerModal.action";

export const DestinationLayoutContext = createContext({
  banner: "",
  setBanner: () => null,
});

const DestinationLayout = ({ children }) => {
  const [banner, setBanner] = useState("home");
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { openModal, url } = useSelector(
    ({ videoPlayerModalReducer }) => videoPlayerModalReducer
  );
  console.log({ url });

  console.log(location);
  const path = usePathProvider(location.pathname);
  const pathSegments = Array.isArray(path) ? path : (path || "").split("/"); //added this line for array not use split
  console.log("Path:", pathSegments);

  const bannerSrc = {
    home: {
      src: BannerOne,
      bannerTitle: `Explore Like a Local? <br/>Here's Your Ultimate Uttarakhand Travel Guide`, //changed from Destination to Travel
    },
    subCity: { src: BannerTwo, bannerTitle: "Uttarakhand Destination" },
    tourism: {
      src: BannerThree,
      bannerTitle:
        "Explore the Best of Uttarakhand Tourism: A Land of Spirituality & Adventure",
      secondaryTitle: "",
    },
    places: {
      src: BannerThree,
      bannerTitle:
        "Top Places to Visit in Uttarakhand for an Unforgettable Experience",
      secondaryTitle: "",
    },
    bestTime: {
      src: BannerThree,
      bannerTitle: "Know the Best Time to Visit Uttarakhand for a Perfect Trip",
      secondaryTitle: "",
    },
    travelBy: {
      src: BannerThree,
      bannerTitle:
        "Complete Guide on How to Reach Uttarakhand by Air, Rail & Road",
      secondaryTitle: "",
    },
    thingsTodo: {
      src: BannerFour,
      bannerTitle:
        "Exciting Things to Do in Uttarakhand for Nature & Adventure Lovers",
      secondaryTitle: "",
    },
    keyFacts: {
      src: BannerFive,
      bannerTitle: "Must-Know Key Facts About Uttarakhand Before You Travel",
      secondaryTitle: "",
    },
    shopping: {
      src: BannerSix,
      bannerTitle:
        "Experience the Best Shopping and Entertainment in Uttarakhand",
      secondaryTitle: "",
    },
    cuisine: {
      src: BannerSeven,
      bannerTitle:
        "Taste the Flavors of Uttarakhand Cuisine: A Food Lover’s Guide",
      secondaryTitle: "",
    },
    thingsToCarry: {
      src: BannerEight,
      bannerTitle: "Essential Things to Carry While Traveling to Uttarakhand",
      secondaryTitle: "",
    },
    festivalsAndEvents: {
      src: BannerOne,
      bannerTitle:
        "Celebrate Uttarakhand Festivals and Events: A Cultural Delight",
      secondaryTitle: "",
    },
    tourPackages: {
      bannerTitle:
        "Discover the Best Uttarakhand Tour Packages for Your Dream Vacation",
    },
  }[banner];

  return (
    <DestinationLayoutContext.Provider value={{ setBanner }}>
      <section className={styles.destinationLayout}>
        <div className={styles.bannerContainer}>
          {/* <img src={bannerSrc.src} className={styles.banner} /> */}
          <video
            src={BackgroundVideo}
            autoStart
            autoPlay={true}
            controls={false}
            type="video/mp4"
            className={styles.banner}
            loop
            width="100%"
            height="100%"
          />
          <h1 className={styles.primaryTitle}>
            {parser(bannerSrc.bannerTitle)}
            <p className={styles.secondaryTitle}>{bannerSrc?.secondaryTitle}</p>
          </h1>
        </div>
        <AsideDestinationNavbar />
        <BredCrumbs pathname={location?.pathname} />
        {children}
        <VideoPlayerModal
          open={openModal}
          url={url}
          handleClose={() => {
            dispatch(handleCloseVideoPlayerModal());
          }}
        />
      </section>
    </DestinationLayoutContext.Provider>
  );
};

export default DestinationLayout;
