import styles from "./DestinationBestTime.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import parser from "html-react-parser"

import { Fragment, useContext, useLayoutEffect } from "react"

import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"

import SummerImg from "../../../../assets/illustrations/destination/Best Time To Visit/Summers in Uttarakhand.webp"
import MonsoonImg from "../../../../assets/illustrations/destination/Best Time To Visit/Monsoon in Uttarakhand.jpg"
import WinterImg from "../../../../assets/illustrations/destination/Best Time To Visit/Winter in Uttarakhand.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const bestTime = [
  {
    title: "Summers in Uttarakhand",
    content: `During the months of April to June, as scorching heat hits the rest of India, the temperatures in the hill stations of Uttarakhand ranges from 25 degrees Celsius to 35 degree Celsius. Uttarkhand is exceptionally spectacular during the summer months because the snow starts melting in the state. The rivers and springs come to life and flowers come to full bloom. To add to this, wildlife comes out of hibernation and combined with the beautiful green cover of the state, this makes summer is definitely the best season to visit Uttarakhand.  
 
<p>Summer is also the perfect time to try water sports. Rishikesh and Tehri Dam are popular locations to pursue these. Kanatal, Chopta and Rishikesh are great destinations for camping. The Char Dham Yatra Uttarakhand lasts from May to September and is one of the best treks for those who seek a mix of nature and heritage. Safaris to the Jim Corbett National Park are eventful as you can spot its abundance of wildlife, birds and plant species.
 </p>
Since summer is the favourite tourist season to head to Uttarakhand, you must book plenty in advance if you want the best deals for your holidays. If you are in Delhi or nearby cities, you can plan short weekend getaways that will give you shelter and a break from the scorching Indian summer. If you’re traveling with your family, you can take a week to traverse through its hill stations.`,
    src: SummerImg,
  },
  {
    title: "Monsoon in Uttarakhand",
    content: `July to September are the monsoon months in Uttarakhand. As the altitude increases the tenacity of rains increases as well. Some of the northern parts of Uttarakhand receive torrential rains and the state is extremely prone to landslides and floods. While the state is beautiful during the month of monsoon, it is recommended that you exercise caution if you are visiting the state during this season.
 
<p>While it is not advisable to visit the extreme north of the state during monsoons, hill stations such as Nainital, Almora, Kasauli, Dhanaulti and Mussoorie bloom to the fullest during the monsoon season. Navigation through the state via road transport can be slightly tricky as the risk of landslides increases with heavy rainfall especially during the months of July and August, but the beauty of Uttarakhand remains unparalleled.</p>
 
The Valley of flowers Trek is a unique trek which is best experienced during the months of July to September. Even though this overlaps with the monsoon season, you must plan to visit the Valley of flowers and Hemkund Sahib, as the flowers bloom only during this season after the first monsoon. Adventure activities such as river rafting, kayaking, bungee jumping, flying fox, and so on are often suspended during the season due to adverse weather and unpredictable river conditions. However a clever tip is that if you are looking for a vacation and are on a shoe-string budget, monsoons might be the best time to plan a trip to Uttarakhand as prices for hotels and activities are often slashed and you can get great bargains!`,
    src: MonsoonImg,
  },
  {
    title: "Winter in Uttarakhand",
    content: `The winter season in Uttarakhand is from November to February and this is the best time to visit Uttarakhand, if you love the chilly winters. The state turns into a magical paradise with a carpet of snow covering its mountains and valleys. Some parts of the state do become inaccessible to tourists due to the harsh winters and extreme snowfall. However, places such as Corbett, Auli Haridwar, Nainital, Almora Dehradun and Ranikhet are a must-visit during the winter season.
 
<p>From glacier trekking to snowboarding and skiing, you can try out an assortment of winter sports in the state especially in Auli. You can also attempt one of the famous winter treks such as Chopta, Tungnath and Chandrashila trek, Kedarkantha trek and Brahmatal trek. If adventure is not what your heart desires, you can just lay back in one of the Hill Stations and enjoy the view of the sunlight shining acrossthe snow as you sip a hot cup of tea or coffee. Again, it is recommended that you book your trip to Uttarakhand in advance in winters because hotels are often sold out when the snow falls.</p>`,
    src: WinterImg,
  },
]

const bestMonths = [
  {
    title: "May and June",
    content: `The summer months of May and June are undoubtedly the best months to visit Uttarakhand. In the month of May and June, most schools in India declare holidays and summer vacations and therefore tourists from different cities flock to these hill stations in Uttarakhand to escape the heat. The flowers and fauna are in full bloom during these months. As a result, popular hill stations such as Nainital and Mussoorie at often sold out.`,
    src: SummerImg,
  },
  {
    title: "February and March",
    content: `As snow hits the Himalayas, the tourists start trickling in as well. February and March are the best months to visit Uttarakhand if the frost winters, chilling breaths and picturesque pristine white views are your fantasy. Hotels in the state are well equipped to handle the cold, ensuring that you have a comfortable stay despite near-zero temperatures outside. Prices for tourist packages often soar high as hotels are fully booked during these months.`,
    src: WinterImg,
  },
]

const DestinationBestTime = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("bestTime")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            Best Time To Visit <span>Uttarakhand</span>
          </header>
          <div className={globalStyles.content}>
            {parser(`Uttarakhand is one of the few tourist destinations in India which
            you can visit at any time of the year. With its assortment of hill
            stations such as Nainital, Mussoorie and Ranikhet, the state is a
            tourist’s delight during the summer months.<p>The hill stations of
            Uttarakhand remain pleasant even when the heat wave hits the rest of
            India and summer is one of the best season to visit Uttarakhand.</p>
            <p>During winter, the scenes in the state turn absolutely magical with
            its snow-capped peaks, snow-carpeted meadows, frozen glaciers
            supplemented with a cool winter breeze.</p> <p>During monsoons, the state
            is no less magical, with its overflowing rivers and streams, but
            caution must be exerted as you traverse the state of Uttarakhand
            during monsoons.</p>So, if you’re planning an Uttarakhand trip,
            here’s our complete guide on what is the best time to visit
            Uttarakhand.`)}
          </div>
        </article>
        <article className={styles.cardList}>
          {bestTime.map((time, idx) => (
            <Fragment key={idx + 1}>
              <ImageAndContentCard>
                <ImageAndContentCard.Title>
                  {idx + 1}. {time.title}
                </ImageAndContentCard.Title>
                <ImageAndContentCard.Image src={time.src} />
                <ImageAndContentCard.Content
                  title={`${idx + 1}. ${time.title}`}
                >
                  {parser(time.content)}
                </ImageAndContentCard.Content>
              </ImageAndContentCard>
              {idx < bestTime.length - 1 && <hr className={styles.hr} />}
            </Fragment>
          ))}
        </article>

        <article className={styles.bestMonthsToVisit}>
          <h2>Best Months To Visit Uttarakhand</h2>
          <div className={styles.bestMonthsCardList}>
            {bestMonths.map((month, idx) => (
              <div className={styles.bestMonthsCard} key={idx}>
                <div className={styles.cardImageContainer}>
                  <img src={month.src} />
                </div>
                <div className={styles.cardContent}>
                  <h3>{month.title}</h3>
                  <footer>{month.content}</footer>
                </div>
              </div>
            ))}
          </div>
        </article>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationBestTime
