import DateFnsUtils from "@date-io/date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutline } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box, Grid, Typography } from "@mui/material";
import { addDays, differenceInDays, format, subDays } from "date-fns";
import MuiPhoneNumber from "material-ui-phone-number";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Api from "../../../Service/Api";
import { baseurl } from "../../../Service/httpCommon";
import { clientListInitial } from "../../../redux/actions/clientAction";
import {
  getBookingSourceOptions,
  getPaymentModeOptions,
} from "../../../redux/actions/commonFeatureDropdown.action";
import ClientForm from "../../Clients/ClientForm";

const useStyles = makeStyles(() => ({
  root: {
    padding: "5% 1.5% 1% 1.5%",
    margin: "0",
  },
  sectionHeader: {
    background: "#f46d25",
    color: "white",
    padding: "0px 10px",
    height: "3rem",
  },
  boxStyling: {
    padding: "10px 20px",
    background: "#eaeaea",
    borderRadius: "5px",
    marginTop: "1rem",
  },
  formControl: {
    width: "100%",
    background: "white",
  },
  select: {
    height: "40px",
  },
  addIcon: {
    color: "#f46d25",
    cursor: "pointer",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  selectFormHelperText: {
    color: "red",
    fontStyle: "italic",
    background: "#eaeaea",
    width: "100%",
    padding: "0",
    margin: "0",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  helperText: {
    backgroundColor: "#eaeaea",
    padding: "0",
    margin: "0",
    width: "100%",
    height: "100%",
    fontStyle: "italic",
  },
}));

const mealPlanOptions = [
  { value: "eprate", label: "EP" },
  { value: "cprate", label: "CP" },
  { value: "maprate", label: "MAP" },
  { value: "aprate", label: "AP" },
];

const bookingSourceOptions = [
  { value: "hotelWebsiteChat", label: "Hotel Website Chat" },
  { value: "justDial", label: "JustDial" },
  { value: "agentB2B", label: "B2B Agent" },
  { value: "agentB2c", label: "FIT" },
  { value: "socialMedia", label: "Social Media" },
  { value: "incomingCall", label: "Incoming Call" },
  { value: "holidayIq", label: "Holiday Iq" },
  { value: "tripoto", label: "Tripoto" },
];

const HotelNewBooking = () => {
  const classes = useStyles();
  var createdByName = localStorage.getItem("auth");
  var uniqueId = localStorage.getItem("unique_id");
  let createdBy = localStorage.getItem("auth");
  const [open, setOpen] = useState(false);
  const [selectedId] = useState(0);
  const [textToSearch, setTextToSearch] = useState("");
  const { bookingId } = useParams();
  const [id, setId] = useState(() => "");
  const dispatch = useDispatch();

  // this method is followed to set the clientName field with newly added client info.
  let getMobile = sessionStorage.getItem("mobile");

  const CloseDialog = () => {
    setOpen(false);
  };

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    bookingSource: "",
  });

  useEffect(() => {
    // for fetching the list of clientData
    dispatch(clientListInitial(uniqueId));
  }, [uniqueId]);

  // const [clientLists, setClientLists] = useState([]);
  const clientLists = useSelector(({ clientList }) => clientList.clientLists);

  const [hotelBookingPayments, setHotelBookingPayments] = useState([]);

  useEffect(() => {
    Api.get(`/clientall/${uniqueId}`).then((res) => {
      console.log(res.data, "<<<api");

      // setClientLists(res.data);
    });
  }, [uniqueId]);

  useEffect(() => {
    if (getMobile !== null || getMobile !== "") {
      console.log(sessionStorage.getItem("found"));
      let found =
        clientLists &&
        clientDropDown.find((client) => client.mobile == getMobile);
      console.log(found);

      setClientDetails({
        ...clientDetails,
        clientName: found?.value,
        clientEmail: found?.mailID,
        clientPhone: found.mobile,
      });
      sessionStorage.setItem("mobile", null);
    }
  }, [getMobile, clientLists]);

  const clientDropDown =
    clientLists &&
    clientLists.map((client) => ({
      id: client.id,
      label: `${client?.title !== null ? client?.title : ""}${
        client?.title !== null ? "." : ""
      } ${client?.firstName} ${client?.lastName}(${client.mail})`,
      value: `${client?.title}. ${client?.firstName} ${client?.lastName}`,
      mobile: client.mobile,
      mailID: client.mail,
    }));

  const [leadPaxDetails, setLeadPaxDetails] = useState([
    {
      id: "",
      leadPaxName: "",
      leadPaxMobileNo: "",
      leadPaxAltMobile: "",
      leadPaxEmail: "",
    },
  ]);

  const [roomDetails, setRoomDetails] = useState([
    {
      id: "",
      boardBasic: "",
      roomType: "",
      noOfRooms: 1,
      mealPlan: "",
      noOfAdults: "",
      noOfChildren: "",
      sellingPrice: 0,
    },
  ]);

  const [checkBoxes, setCheckBoxes] = useState({
    vaccinated: false,
    git: false,
    fullPayment: true,
    partialPayment: false,
  });

  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [numberOfNights, setNumberOfNights] = useState(0);

  const [inclusionDetails, setInclusionDetails] = useState([
    {
      id: "",
      inclusionName: "",
      inclusionSellingPrice: 0,
    },
  ]);

  const [totalRoomRent, setTotalRoomRent] = useState(0);
  const [totalInclusionAmount, setTotalInclusionAmount] = useState(0);
  const [totalBookingAmount, setTotalBookingAmount] = useState(0);
  const [amountRecieved, setAmountRecieved] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");
  const [balPayOnArrival, setBalPayOnArrival] = useState(0);
  const [referenceNo, setReferenceNumber] = useState("");
  const [balPayToTownO, setBalPayToTownO] = useState(0);
  const [hotelBookingTermCondition, setHotelBookingTermCondition] = useState({
    id: "",
    termCondition: "",
    cancellationPolicy: "",
  });

  const handleChangeTermsAndConditions = (e) => {
    const { name, value } = e.target;
    console.log(`${name}: ${value}`);
    setHotelBookingTermCondition({
      ...hotelBookingTermCondition,
      [name]: value,
    });
  };

  const handleAmountRecieved = (e) => {
    const { name, value } = e.target;
    setAmountRecieved(value);
    console.log(value);

    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;
    console.log(paymentRecieved);

    setBalPayOnArrival(
      parseFloat(totalBookingAmount) -
        parseFloat(paymentRecieved) -
        parseFloat(balPayToTownO) -
        parseFloat(value)
    );
  };

  const handlePaymentMode = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleChangeReferenceNo = (e) => {
    setReferenceNumber(e.target.value);
  };

  const handleChangeBalancePayableToTownO = (e) => {
    const { name, value } = e.target;
    setBalPayToTownO(value);
    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;
    console.log(paymentRecieved);

    setBalPayOnArrival(
      parseFloat(totalBookingAmount) -
        parseFloat(paymentRecieved) -
        parseFloat(amountRecieved) -
        parseFloat(value)
    );
  };

  const handleChangeInclusionInputs = (e, index) => {
    const { name, value } = e.target;
    const updatedInputs = inclusionDetails.map((inclusion, idx) => {
      if (idx === index) {
        return { ...inclusion, [name]: value };
      }
      return inclusion;
    });

    const updatedTotalInclusionAmount = updatedInputs.reduce((prev, curr) => {
      prev += parseFloat(curr.inclusionSellingPrice);
      return prev;
    }, 0);

    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;

    setTotalInclusionAmount(updatedTotalInclusionAmount);
    setBalPayOnArrival(
      parseFloat(totalRoomRent) +
        parseFloat(updatedTotalInclusionAmount) -
        parseFloat(amountRecieved) -
        parseFloat(balPayToTownO) -
        parseFloat(paymentRecieved)
    );
    setTotalBookingAmount(
      parseFloat(totalRoomRent) + parseFloat(updatedTotalInclusionAmount)
    );

    console.log(updatedInputs);
    setInclusionDetails(updatedInputs);
  };

  const handleAddInclusionField = () => {
    const updatedInclsionFields = [
      ...inclusionDetails,
      {
        id: "",
        inclusionName: "",
        inclusionSellingPrice: "",
      },
    ];
    setInclusionDetails(updatedInclsionFields);
  };

  const handleRemoveInclusionField = (index) => {
    const updatedFields = inclusionDetails.filter(
      (inclusion, idx) => idx !== index
    );

    const updatedTotalInclusionAmount = updatedFields.reduce((prev, curr) => {
      prev += parseFloat(curr.inclusionSellingPrice);
      return prev;
    }, 0);

    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;

    setTotalInclusionAmount(updatedTotalInclusionAmount);
    setTotalBookingAmount(
      parseFloat(totalRoomRent) + parseFloat(updatedTotalInclusionAmount)
    );

    setBalPayOnArrival(
      parseFloat(totalRoomRent) +
        parseFloat(updatedTotalInclusionAmount) -
        parseFloat(amountRecieved) -
        parseFloat(balPayToTownO) -
        parseFloat(paymentRecieved)
    );
    setInclusionDetails(updatedFields);
  };

  // client change handler
  const handleClientChange = (e) => {
    const { name, value } = e.target;
    const { id } = e.currentTarget;
    console.log(id);
    const individualClientData = clientLists.find(
      (client) => client.id == id || client.mobile == getMobile
    );
    console.log(individualClientData);
    if (name === "clientName") {
      setClientDetails({
        ...clientDetails,
        clientName: value,
        clientPhone: individualClientData.mobile,
        clientEmail: individualClientData.mail,
      });
      setCheckBoxes({
        ...checkBoxes,
        vaccinated: individualClientData.vaccinationStatus,
      });
    } else {
      setClientDetails({ ...clientDetails, [name]: value });
      console.log(`${name}:${value}`);
    }
  };

  //checkbox handler
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "partialPayment" && checked === true) {
      setCheckBoxes({
        ...checkBoxes,
        partialPayment: checked,
        fullPayment: false,
      });
    } else if (name === "fullPayment" && checked === true) {
      setCheckBoxes({
        ...checkBoxes,
        partialPayment: false,
        fullPayment: checked,
      });
    } else {
      setCheckBoxes({
        ...checkBoxes,
        [name]: checked,
      });
    }

    console.log(`${name}:${checked}`);
  };

  useEffect(() => {
    if (checkIn !== null && checkOut !== null) {
      const formattedCheckIn = format(
        bookingId ? new Date(checkIn) : checkIn,
        "yyyy-MM-dd"
      );
      const formattedCheckOut = format(
        subDays(bookingId ? new Date(checkOut) : checkOut, 1),
        "yyyy-MM-dd"
      );
      roomDetails.forEach(async (room) => {
        if (room.roomType !== "" || room.roomType !== null) {
          const { data } = await Api.get(
            `${baseurl}getroomrent/${uniqueId}/${
              room.roomType && room.roomType?.split(" ").join("%20")
            }/${formattedCheckIn}/${formattedCheckOut}`
          );
          console.log(await data[0]);
          room.noOfAdults = await data[0].adultsBase;
          room.noOfChildren = await data[0].childBase;
          room.mealPlan = await data[0].mealplan;
          setAdultBase(parseInt(data[0]?.adultsBase));
          setAdultLimit(parseInt(data[0]?.adultsMax));
          setChildBase(parseInt(data[0]?.childBase));
          setChildLimit(parseInt(data[0]?.childMax));
        }
      });
    }
  }, [roomDetails, checkIn, checkOut, bookingId]);

  //roomInputs handler
  const handleRoomInput = async (e, index) => {
    const { name, value } = e.target;

    // console.log("inside calc fn", data);

    // to set boardBasic key's value in roomDetails
    const currentSelectedRoomType = roomTypeDropdown.find(
      (room) => room.value === value
    );
    console.log(currentSelectedRoomType);

    console.log(index, name, value);
    const updatedRoomInputs = roomDetails.map((room, idx) => {
      if (idx === index) {
        return {
          ...room,
          [name]: value,
          boardBasic:
            name === "roomType"
              ? currentSelectedRoomType.label
              : room.boardBasic,
        };
      }
      return room;
    });

    const updatedTotalRoomRent = updatedRoomInputs.reduce((prev, curr) => {
      prev += parseFloat(curr.sellingPrice);
      return prev;
    }, 0);

    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;

    setTotalRoomRent(updatedTotalRoomRent);
    setTotalBookingAmount(
      parseFloat(updatedTotalRoomRent) + parseFloat(totalInclusionAmount)
    );
    setBalPayOnArrival(
      parseFloat(updatedTotalRoomRent) +
        parseFloat(totalInclusionAmount) -
        parseFloat(amountRecieved) -
        parseFloat(paymentRecieved) -
        parseFloat(balPayToTownO)
    );
    setRoomDetails(updatedRoomInputs);
    console.log(updatedRoomInputs);
  };

  //add roomInputs handler
  const handleAddRoomInputs = () => {
    const updatedRoomInputs = [
      ...roomDetails,
      {
        id: "",
        boardBasic: "",
        roomType: "",
        noOfRooms: 1,
        mealPlan: "",
        noOfAdults: "",
        noOfChildren: "",
        sellingPrice: 0,
      },
    ];
    setRoomDetails(updatedRoomInputs);
  };

  const handleDeleteRoomInputs = (index) => {
    const updatedRoomInputs = roomDetails.filter((room, idx) => idx !== index);

    const updatedTotalRoomRent = updatedRoomInputs.reduce((prev, curr) => {
      prev += parseFloat(curr.sellingPrice);
      return prev;
    }, 0);

    const paymentRecieved = hotelBookingPayments
      ? hotelBookingPayments.reduce(
          (prev, curr) => (prev += parseFloat(curr.paidAmount)),
          0
        )
      : 0;

    setTotalRoomRent(updatedTotalRoomRent);

    setBalPayOnArrival(
      parseFloat(updatedTotalRoomRent) +
        parseFloat(totalInclusionAmount) -
        parseFloat(paymentRecieved) -
        parseFloat(amountRecieved) -
        parseFloat(balPayToTownO)
    );
    setTotalBookingAmount(
      parseFloat(updatedTotalRoomRent) + parseFloat(totalInclusionAmount)
    );

    setRoomDetails(updatedRoomInputs);
  };

  //leadPax change handler
  const handleChangeLeadPax = (e, index) => {
    const { name, value } = e.target;
    console.log(index, name, value);
    const updatedLeadPax = leadPaxDetails.map((details, idx) => {
      if (idx === index) {
        return { ...details, [name]: value };
      }
      return details;
    });

    setLeadPaxDetails(updatedLeadPax);
    console.log(updatedLeadPax);
  };

  // delete leadPax handler
  const handleRemoveLeadPax = (idx) => {
    const updatedDetails = leadPaxDetails.filter(
      (value, index) => index !== idx
    );
    setLeadPaxDetails(updatedDetails);
  };

  // add leadPax handler
  const handleAddLeadPax = () => {
    const updatedDetails = [
      ...leadPaxDetails,
      {
        id: "",
        leadPaxName: "",
        leadPaxMobileNo: "",
        leadPaxAltMobile: "",
        leadPaxEmail: "",
      },
    ];
    setLeadPaxDetails(updatedDetails);
  };

  const handleCheckIn = (date) => {
    setCheckIn(date);
    setCheckOut(null);
    setNumberOfNights(0);
    console.log(format(date, "dd-MM-yyyy"));
  };

  const handleCheckOut = (date) => {
    setCheckOut(date);
    const diffInDays = differenceInDays(new Date(date), new Date(checkIn));
    console.log(diffInDays);
    setNumberOfNights(diffInDays);
  };

  const [roomTypeDropdown, setRoomTypeDropdown] = useState([]);
  const [childLimit, setChildLimit] = useState(0);
  const [adultLimit, setAdultLimit] = useState(0);
  const [childBase, setChildBase] = useState(0);
  const [adultBase, setAdultBase] = useState(0);

  useEffect(async () => {
    if (checkIn !== null && checkOut !== null) {
      console.log(checkIn, checkOut);
      const { data } = await Api.get(`${baseurl}getdisplayname/${uniqueId}`);
      const dropDown = await data.map((option) => ({
        label: option.visibleName,
        value: option.displayName,
      }));
      setRoomTypeDropdown(dropDown);
    }
  }, [checkIn, checkOut]);

  // useEffect(async () => {
  //   if (checkIn !== null && checkOut !== null) {
  //     const formattedCheckIn = format(
  //       bookingId ? new Date(checkIn) : checkIn,
  //       "yyyy-MM-dd"
  //     );
  //     const formattedCheckOut = format(
  //       subDays(bookingId ? new Date(checkOut) : checkOut, 1),
  //       "yyyy-MM-dd"
  //     );
  //     roomDetails.forEach(async (room) => {
  //       if (room.roomType !== "" || room.roomType !== null) {
  //         console.log(room.roomType);
  //         const { data } = await Api.get(
  //           `${baseurl}getroomrent/${uniqueId}/${
  //             room.roomType && room.roomType?.split(" ").join("%20")
  //           }/${formattedCheckIn}/${formattedCheckOut}`
  //         );
  //         console.log(await data[0]);

  //         setAdultBase(await parseInt(data[0]?.adultsBase));
  //         setAdultLimit(await parseInt(data[0]?.adultsMax));
  //         setChildBase(await parseInt(data[0]?.childBase));
  //         setChildLimit(await parseInt(data[0]?.childMax));
  //       }
  //     });
  //   }
  // }, [checkIn, checkOut, bookingId]);

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required("Select Any Client"),
    roomDetails: Yup.array().of(
      Yup.object().shape({
        roomType: Yup.string().required("Select Room Type"),
        sellingPrice: Yup.number()
          .moreThan(0, "Selling Price must be greater than zero")
          .required(),
      })
    ),
  });

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });
  };

  const history = useHistory();

  useEffect(async () => {
    if (bookingId) {
      const { data } = await Api.get(
        `${baseurl}getHotelBookingDetails/${uniqueId}/${bookingId}`
      );
      console.log(data);
      setId(data?.id);
      setClientDetails({
        ...clientDetails,
        bookingSource: data.bookingSource,
        clientName: data.clientName,
        clientPhone: data.clientMobile,
        clientEmail: data.clientEmail,
      });

      setCheckBoxes({
        ...checkBoxes,
        git: data?.git === "false" ? false : true,
        vaccinated: data?.vaccinated === "false" ? false : true,
        partialPayment: data?.paymentType === "Partial" ? true : false,
        fullPayment: data?.paymentType !== "Partial" ? true : false,
      });
      setLeadPaxDetails(data.hotelBookingLeads);
      setCheckIn(data?.checkIn);
      setCheckOut(data?.checkOut);
      setNumberOfNights(parseInt(data?.noOfNight));
      setRoomDetails(data?.hotelBookingRoom);
      setInclusionDetails(data?.hotelBookingInclusions);
      setHotelBookingPayments(data?.hotelBookingPayments);
      setTotalRoomRent(parseFloat(data?.totalRoomSellingAmount));
      setTotalInclusionAmount(parseFloat(data?.totalInclusionAmount));
      setTotalBookingAmount(parseFloat(data?.totalBookingAmount));

      setBalPayOnArrival(parseFloat(data?.balPayOnArrival));
      setBalPayToTownO(parseFloat(data?.partialPayment));
      setHotelBookingTermCondition(data?.hotelBookingTermCondition);
    }
  }, [bookingId]);

  const [openPreview, setOpenPreview] = useState(false);
  const [postData, setPostData] = useState({});

  const handlePreview = async (e) => {
    e.preventDefault();
    const postDataSchema = {
      id: bookingId ? id : "",
      uniqueId: uniqueId,
      bookingId: bookingId ? bookingId : "",
      clientName: clientDetails.clientName,
      clientMobile: clientDetails.clientPhone,
      clientEmail: clientDetails.clientEmail,
      bookingSource: clientDetails.bookingSource,
      hotelBookingLeads: leadPaxDetails,
      checkIn: format(new Date(checkIn), "yyyy-MM-dd"),
      checkOut: format(new Date(checkOut), "yyyy-MM-dd"),
      noOfNight: numberOfNights,
      hotelBookingRoom: roomDetails,
      hotelBookingInclusions: inclusionDetails,
      totalRoomSellingAmount: totalRoomRent,
      totalInclusionAmount: totalInclusionAmount,
      totalBookingAmount: totalBookingAmount,
      paidAmount: amountRecieved,
      pendingAmount:
        parseFloat(totalBookingAmount) - parseFloat(amountRecieved),
      bookingStatus: "Booked",
      balPayOnArrival: balPayOnArrival,
      vaccinated: checkBoxes.vaccinated,
      git: checkBoxes.git,
      partialPayment: balPayToTownO,
      paymentType: checkBoxes.partialPayment ? "Partial" : "Full",
      status: "1",
      createdBy: createdBy,
      hotelBookingPayments: [
        ...hotelBookingPayments,
        {
          id: "",
          paidAmount: amountRecieved,
          paymentMode: paymentMode,
          paymentRefNo: referenceNo,
        },
      ],
      hotelBookingTermCondition: hotelBookingTermCondition,
    };

    setPostData(postDataSchema);

    validationSchema
      .validate(
        {
          ...clientDetails,
          roomDetails,
        },
        { abortEarly: false }
      )
      .then(async () => {
        console.log(postDataSchema);

        setOpenPreview(true);
      })
      .catch((errors) => {
        const validationErrors = {};
        errors.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        console.log(validationErrors);
        setErrors(validationErrors);
      });
  };

  let inputRef = useRef(null);
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (e) => {
    await Api.post(`${baseurl}hotelBooking`, postData);
    let successMessage = bookingId
      ? "Booking Details Updated"
      : "New Booking Created";
    toast.success(successMessage);
    history.replace(`/hotelBookingList`);
  };

  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  );
  const { bookingSourceOptions } = useSelector(
    ({ bookingSourceOptionsReducer }) => bookingSourceOptionsReducer
  );

  useEffect(() => {
    dispatch(getBookingSourceOptions(uniqueId));
    dispatch(getPaymentModeOptions(uniqueId));
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form onSubmit={handlePreview}>
        <div className={classes.root}>
          <Typography variant="h4" fontWeight="bold" color="#f46d25">
            New Booking
          </Typography>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              marginBottom: "10px",
            }}
            defaultExpanded={true}
          >
            <AccordionSummary
              className={classes.sectionHeader}
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            >
              <h2>Basic Information</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box
                style={{
                  background: "#EAEAEA",
                  borderRadius: "5px",
                }}
                className={classes.boxStyling}
              >
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Client Details
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        shrink
                        placeholder="Select Client"
                        required
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          color: "#f46d25",
                        }}
                      >
                        Client Name
                      </InputLabel>

                      <Select
                        className={classes.select}
                        value={clientDetails.clientName}
                        name="clientName"
                        onChange={handleClientChange}
                        MenuProps={{
                          style: { height: "400px" },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          autoFocus: false,
                        }}
                        onBlur={handleInputBlur}
                        error={errors.clientName}
                        onAnimationEnd={() => inputRef.current.focus()}
                        renderValue={() => clientDetails.clientName}
                      >
                        <ListSubheader>
                          <TextField
                            ref={inputRef}
                            value={textToSearch}
                            onChange={(e) => setTextToSearch(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { height: "40px" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                            autoFocus
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {clientLists &&
                          clientDropDown
                            .filter((client) =>
                              client.label
                                .toLowerCase()
                                .includes(textToSearch.toLowerCase())
                            )
                            .map((client) => (
                              <MenuItem
                                id={client.id}
                                value={client.value}
                                style={{
                                  background:
                                    client.value === clientDetails.clientName &&
                                    "#f46d25",
                                  color:
                                    client.value === clientDetails.clientName &&
                                    "white",
                                  width: "100%",
                                }}
                              >
                                {client.label}
                              </MenuItem>
                            ))}
                      </Select>
                      <FormHelperText className={classes.selectFormHelperText}>
                        {errors.clientName && errors.clientName}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={0.4} style={{ marginTop: "0.56rem" }}>
                    <AddCircleOutline
                      className={classes.addIcon}
                      onClick={() => setOpen(true)}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MuiPhoneNumber
                      name="clientPhone"
                      variant="outlined"
                      label="Client Phone"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      defaultCountry={"in"}
                      required
                      value={clientDetails.clientPhone}
                      onChange={(value) => {
                        console.log(value);
                        setClientDetails({
                          ...clientDetails,
                          billerContactNo: value,
                        });
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2.6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Client Email"
                      fullWidth
                      autoComplete="off"
                      required
                      type="email"
                      value={clientDetails.clientEmail}
                      name="clientEmail"
                      onChange={handleClientChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        shrink
                        placeholder=""
                        required
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          color: "#f46d25",
                        }}
                      >
                        Booking Source
                      </InputLabel>
                      <Select
                        className={classes.select}
                        name="bookingSource"
                        value={clientDetails.bookingSource}
                        onChange={handleClientChange}
                        MenuProps={{
                          style: { height: "400px" },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {bookingSourceOptions.map((booking) => (
                          <MenuItem value={booking.value}>
                            {booking.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",

                      paddingLeft: "3rem",
                      justifyContent: "space-between",
                    }}
                    textAlign="end"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="vaccinated"
                          color="primary"
                          checked={checkBoxes.vaccinated}
                          onChange={handleCheckBox}
                        />
                      }
                      label="Vaccinated"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="git"
                          color="primary"
                          checked={checkBoxes.git}
                          onChange={handleCheckBox}
                        />
                      }
                      label="GIT"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxStyling}>
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Lead Pax
                </Typography>
                <Grid container spacing={1}>
                  {leadPaxDetails.map((leadPax, idx) => (
                    <>
                      <Grid item xs={0.2}>
                        {idx + 1}.
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="leadPaxName"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          label="Name"
                          value={leadPax.leadPaxName}
                          onChange={(e) => handleChangeLeadPax(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          name="leadPaxMobileNo"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          label="Mobile No"
                          value={leadPax.leadPaxMobileNo}
                          onChange={(e) => handleChangeLeadPax(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="leadPaxAltMobile"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          label=" Alt Mobile"
                          value={leadPax.leadPaxAltMobile}
                          onChange={(e) => handleChangeLeadPax(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          name="leadPaxEmail"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          label=" Email"
                          value={leadPax.leadPaxEmail}
                          onChange={(e) => handleChangeLeadPax(e, idx)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={0.8}
                        style={{ marginTop: "0.50rem" }}
                        textAlign="end"
                      >
                        {leadPaxDetails.length !== 1 && (
                          <DeleteIcon
                            onClick={() => handleRemoveLeadPax(idx)}
                            className={classes.addIcon}
                          />
                        )}
                        {leadPaxDetails.length - 1 === idx && (
                          <AddCircleOutline
                            onClick={handleAddLeadPax}
                            size="small"
                            className={classes.addIcon}
                          />
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ boxShadow: "none", marginBottom: "10px" }}
          >
            <AccordionSummary
              className={classes.sectionHeader}
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            >
              <h2>Booking Information</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classes.boxStyling}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        name="checkIn"
                        required
                        label="Check-In"
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        value={checkIn}
                        onChange={handleCheckIn}
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        disablePast="true"
                        autoOk="true"
                      />
                      <DateRangeIcon className={classes.icon} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        name="checkOut"
                        required
                        label="Check-Out"
                        value={checkOut}
                        inputVariant="outlined"
                        size="small"
                        onChange={handleCheckOut}
                        minDate={addDays(new Date(checkIn), 1)}
                        fullWidth
                        format="dd/MM/yyyy"
                        animateYearScrolling
                        variant="inline"
                        disablePast="true"
                        autoOk="true"
                      />
                      <DateRangeIcon className={classes.icon} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="night1"
                      type="number"
                      value={checkIn && numberOfNights}
                      disabled
                      label="Number of Nights (N)"
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxStyling}>
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Room Details
                </Typography>
                <Grid container alignItems="baseline">
                  {roomDetails.map((room, idx) => (
                    <>
                      <Grid item xs={0.4}>
                        {idx + 1}.
                      </Grid>
                      <Grid container xs={11} spacing={1}>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              shrink
                              placeholder=""
                              required
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                color: "#f46d25",
                              }}
                            >
                              Room Type
                            </InputLabel>
                            <Select
                              className={classes.select}
                              name="roomType"
                              value={room.roomType}
                              onChange={(e) => handleRoomInput(e, idx)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              onBlur={handleInputBlur}
                              error={errors[`roomDetails[${idx}].roomType`]}
                            >
                              {roomTypeDropdown &&
                                roomTypeDropdown.map((room) => (
                                  <MenuItem value={room.value}>
                                    {room.label}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                              className={classes.selectFormHelperText}
                            >
                              {errors[`roomDetails[${idx}].roomType`] &&
                                errors[`roomDetails[${idx}].roomType`]}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {checkBoxes.git && (
                          <Grid item xs={3}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel
                                shrink
                                placeholder=""
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                }}
                              >
                                No of Rooms
                              </InputLabel>
                              <Select
                                className={classes.select}
                                value={room.noOfRooms}
                                name="noOfRooms"
                                onChange={(e) => handleRoomInput(e, idx)}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {Array.from({ length: 10 }, (_, i) => (
                                  <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              shrink
                              placeholder=""
                              required
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                color: "#f46d25",
                              }}
                            >
                              Meal Plan
                            </InputLabel>
                            <Select
                              className={classes.select}
                              value={room.mealPlan}
                              name="mealPlan"
                              onChange={(e) => handleRoomInput(e, idx)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              {mealPlanOptions.map((meal) => (
                                <MenuItem value={meal.value}>
                                  {meal.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              shrink
                              placeholder=""
                              required
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                color: "#f46d25",
                              }}
                            >
                              No.of Adults
                            </InputLabel>
                            <Select
                              className={classes.select}
                              name="noOfAdults"
                              value={room.noOfAdults}
                              onChange={(e) => handleRoomInput(e, idx)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              {Array.from(
                                { length: adultLimit * room.noOfRooms },
                                (_, i) => (
                                  <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              shrink
                              placeholder=""
                              required
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                color: "#f46d25",
                              }}
                            >
                              No.of Children
                            </InputLabel>
                            <Select
                              className={classes.select}
                              value={room.noOfChildren}
                              name="noOfChildren"
                              onChange={(e) => handleRoomInput(e, idx)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              {Array.from(
                                { length: childLimit + room.noOfRooms },
                                (_, i) => (
                                  <MenuItem value={i}>{i}</MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            type="number"
                            name="sellingPrice"
                            value={room.sellingPrice}
                            size="small"
                            label="Selling Price"
                            style={{ width: "100%", alignSelf: "flex-start" }}
                            required
                            onChange={(e) => handleRoomInput(e, idx)}
                            error={errors[`roomDetails[${idx}].sellingPrice`]}
                            helperText={
                              errors[`roomDetails[${idx}].sellingPrice`] &&
                              errors[`roomDetails[${idx}].sellingPrice`]
                            }
                            FormHelperTextProps={{
                              classes: { root: classes.helperText },
                              style: { padding: "0", margin: "0" },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={0.6}
                        style={{ marginTop: "0.50rem" }}
                        textAlign="end"
                      >
                        {roomDetails.length !== 1 && (
                          <DeleteIcon
                            onClick={() => handleDeleteRoomInputs(idx)}
                            className={classes.addIcon}
                          />
                        )}
                        {roomDetails.length - 1 === idx && (
                          <AddCircleOutline
                            onClick={handleAddRoomInputs}
                            size="small"
                            className={classes.addIcon}
                          />
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
              <Box className={classes.boxStyling}>
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Inclusion
                </Typography>
                <Grid container spacing={1}>
                  {inclusionDetails.map((inclusion, idx) => (
                    <>
                      <Grid item xs={0.2}>
                        {idx + 1}
                      </Grid>
                      <Grid item xs={5.6}>
                        <TextField
                          name="inclusionName"
                          label="Inclusion Name"
                          value={inclusion.inclusionName}
                          variant="outlined"
                          fullWidth
                          size="small"
                          autoComplete="off"
                          onChange={(e) => handleChangeInclusionInputs(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={5.6}>
                        <TextField
                          name="inclusionSellingPrice"
                          label="Inclusion Selling Price"
                          value={inclusion.inclusionSellingPrice}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="number"
                          autoComplete="off"
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: "#fff",
                              background: "#f46d25",
                              borderRadius: "4px",
                              padding: "2px 4px",
                            },
                          }}
                          onChange={(e) => handleChangeInclusionInputs(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={0.6}>
                        {inclusionDetails.length !== 1 && (
                          <DeleteIcon
                            onClick={() => handleRemoveInclusionField(idx)}
                            className={classes.addIcon}
                          />
                        )}
                        {inclusionDetails.length - 1 === idx && (
                          <AddCircleOutline
                            onClick={handleAddInclusionField}
                            size="small"
                            className={classes.addIcon}
                          />
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ boxShadow: "none", marginBottom: "10px" }}
          >
            <AccordionSummary
              className={classes.sectionHeader}
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            >
              <h2>Payment Details</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classes.boxStyling}>
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Payment Breakup
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4} direction="row" style={{ display: "flex" }}>
                    <Typography
                      style={{
                        height: "40px",
                        width: "60%",
                        border: "1px solid #deffde",
                        background: "white",
                        paddingLeft: "10px",
                        color: "#f46d25",
                        borderRadius: "5px 0px 0px 5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Total Room Rent (R)
                    </Typography>

                    <TextField
                      name="totalRoomRent"
                      value={totalRoomRent}
                      variant="outlined"
                      style={{
                        backgroundColor: "#ffe2d7",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "#fff",
                          background: "#f46d25",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ display: "flex" }}>
                    <Typography
                      style={{
                        height: "40px",
                        width: "60%",
                        border: "1px solid #deffde",
                        background: "white",
                        paddingLeft: "10px",
                        color: "#f46d25",
                        borderRadius: "5px 0px 0px 5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Total Inclusion Amount (I)
                    </Typography>

                    <TextField
                      name="totalInclusionAmount"
                      value={totalInclusionAmount}
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#ffe2d7",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "#fff",
                          background: "#f46d25",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} display="flex">
                    <Typography
                      style={{
                        height: "40px",
                        width: "60%",
                        border: "1px solid #deffde",
                        background: "white",
                        paddingLeft: "10px",
                        color: "#f46d25",
                        borderRadius: "5px 0px 0px 5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Total Booking Amount (R+I)
                    </Typography>

                    <TextField
                      name="totalBookingAmount"
                      value={totalBookingAmount}
                      defaultValue={0}
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#ffe2d7",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: "#fff",
                          background: "#f46d25",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxStyling}>
                <Typography variant="h6" fontWeight="bold" color="#f46d25">
                  Transaction Summary
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{
                        color: "#138128",
                        borderColor: "#deffde",
                        width: "60%",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          color: "#f46d25",
                        },
                      }}
                      label={"Amount Received"}
                      disabled
                    />
                    <TextField
                      name="amountRecieved"
                      value={amountRecieved}
                      onChange={handleAmountRecieved}
                      variant="outlined"
                      style={{
                        backgroundColor: "#ffe2d7",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      size="small"
                      autoComplete="off"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{
                        color: "#138128",
                        borderColor: "#deffde",
                        width: "60%",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          color: "#464141",
                        },
                      }}
                      label={"Payment Mode"}
                      disabled
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ width: "40%", marginLeft: "-1.2rem" }}
                    >
                      <Select
                        className={classes.select}
                        value={paymentMode}
                        name="paymentMode"
                        onChange={handlePaymentMode}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {paymentModeOptions.map((mode) => (
                          <MenuItem value={mode.value}>{mode.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{
                        color: "#138128",
                        borderColor: "#deffde",
                        width: "60%",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          color: "#f46d25",
                        },
                      }}
                      label={"Balance Payable on Arrival (BPAH)"}
                      disabled
                    />
                    <TextField
                      name="balPayOnArrival"
                      value={balPayOnArrival}
                      style={{
                        backgroundColor: "#ffe2d7",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      disabled
                      variant="outlined"
                      size="small"
                      type="number"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{
                        color: "#282828",
                        borderColor: "#a1a1a1",
                        width: "60%",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          color: "#464141",
                        },
                      }}
                      label={"Reference Number"}
                      disabled
                    />
                    <TextField
                      name="referenceNo"
                      value={referenceNo}
                      style={{
                        borderColor: "#a1a1a1",
                        marginLeft: "-20px",
                        width: "40%",
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      onChange={handleChangeReferenceNo}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="fullPayment"
                          color="primary"
                          checked={checkBoxes.fullPayment}
                          onChange={handleCheckBox}
                        />
                      }
                      label="Full Payment"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="partialPayment"
                          color="primary"
                          checked={checkBoxes.partialPayment}
                          onChange={handleCheckBox}
                        />
                      }
                      label="Partial Payment"
                    />
                  </Grid>
                </Grid>
                {checkBoxes.partialPayment && (
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{
                          color: "#138128",
                          borderColor: "#deffde",
                          width: "60%",
                        }}
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            color: "#f46d25",
                          },
                        }}
                        label={"Balance payable to Towno"}
                        disabled
                      />
                      <TextField
                        name="balPayToTownO"
                        value={balPayToTownO}
                        type="number"
                        variant="outlined"
                        style={{
                          backgroundColor: "#ffe2d7",
                          marginLeft: "-20px",
                          width: "40%",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        onChange={handleChangeBalancePayableToTownO}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>

              {bookingId && (
                <Box className={classes.boxStyling}>
                  <Typography variant="h6" fontWeight="bold" color="#f46d25">
                    Payment Details
                  </Typography>
                  <Grid container marginTop="-0.8rem" spacing={1}>
                    <Grid item xs={3}>
                      <h5>Sl No.</h5>
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                      <h5 style={{ color: "#f46d25" }}>Amount in INR</h5>
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                      <h5 style={{ color: "#f46d25" }}>Payment Mode</h5>
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                      <h5 style={{ color: "#f46d25" }}>Payment Ref No.</h5>
                    </Grid>
                  </Grid>
                  {hotelBookingPayments.map((payment, idx) => (
                    <Grid container>
                      <Grid item xs={3}>
                        {idx + 1}.
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        Rs. {payment?.paidAmount}
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        {payment?.paymentMode?.toUpperCase()}
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        {payment?.paymentRefNo}
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              className={classes.sectionHeader}
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            >
              <h2>Terms & Conditions</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classes.boxStyling}>
                <TextareaAutosize
                  name="termCondition"
                  typeof="string"
                  placeholder="Terms and Conditions"
                  value={hotelBookingTermCondition.termCondition}
                  onChange={handleChangeTermsAndConditions}
                  style={{
                    width: "100%",
                    border: "1px solid #f45d25",
                    height: "100px",
                    overflowY: "scroll",
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              className={classes.sectionHeader}
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            >
              <h2>Cancellation Policy</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classes.boxStyling}>
                <TextareaAutosize
                  name="cancellationPolicy"
                  value={hotelBookingTermCondition.cancellationPolicy}
                  placeholder="Cancellation Policy"
                  onChange={handleChangeTermsAndConditions}
                  style={{
                    width: "100%",
                    border: "1px solid #f45d25",
                    height: "100px",
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion> */}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="submit" style={{ marginRight: "10px" }}>
            Preview
          </Button>
          <Button
            onClick={() => history.goBack()}
            style={{ background: "black" }}
          >
            Back
          </Button>
        </div>
      </form>
      <ClientForm
        open={open}
        onClose={CloseDialog}
        selectedId={selectedId}
        BookingLocal={"yes"}
      />
      <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        style={{ position: "fixed" }}
      >
        <Box
          style={{
            background: "white",
            margin: "23px",
            padding: "1% 1% 2% 1%",
            borderRadius: "5px",
            height: "85%",
            overflowY: "scroll",
          }}
        >
          <>
            <h2 style={{ color: "#f46d25" }}>Booking Details</h2>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
                fontWeight: "bold",
              }}
            >
              Basic Information
            </Typography>
            <Paper
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", display: "flex" }}
            >
              <Grid container>
                <Grid container>
                  <Grid item xs={2} fontWeight="bold">
                    Name
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {clientDetails.clientName}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} fontWeight="bold">
                    Mobile No
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {clientDetails.clientPhone}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} fontWeight="bold">
                    Email
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {clientDetails.clientEmail}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container>
                  <Grid item xs={3} fontWeight="bold">
                    Booking Source
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {clientDetails.bookingSource}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} fontWeight="bold">
                    Vaccinated
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {checkBoxes.vaccinated ? "Yes" : "No"}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} fontWeight="bold">
                    GIT
                  </Grid>
                  <Grid
                    item
                    xs={0.1}
                    style={{ color: "#f46d25", fontWeight: "bold" }}
                  >
                    :
                  </Grid>
                  <Grid item xs={3} textAlign="left">
                    {checkBoxes.git ? "Yes" : "No"}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
          <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Lead Pax Details
            </Typography>
            <Paper
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", display: "flex" }}
            >
              <Grid container direction="column">
                {leadPaxDetails.map((lead, idx) => (
                  <Grid container>
                    <Grid container>
                      <Grid item xs container>
                        <Grid item xs={2} fontWeight="bold">
                          Name
                        </Grid>
                        <Grid
                          item
                          xs={0.1}
                          style={{ color: "#f46d25", fontWeight: "bold" }}
                        >
                          :
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          {lead.leadPaxName}
                        </Grid>
                      </Grid>
                      <Grid item xs container>
                        <Grid item xs={3} fontWeight="bold">
                          Mobile No
                        </Grid>
                        <Grid
                          item
                          xs={0.1}
                          style={{ color: "#f46d25", fontWeight: "bold" }}
                        >
                          :
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          {lead.leadPaxMobileNo}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs container>
                        <Grid item xs={2} fontWeight="bold">
                          Email
                        </Grid>
                        <Grid
                          item
                          xs={0.1}
                          style={{ color: "#f46d25", fontWeight: "bold" }}
                        >
                          :
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          {lead.leadPaxEmail}
                        </Grid>
                      </Grid>
                      <Grid item xs container>
                        <Grid item xs={3} fontWeight="bold">
                          Alternate No.
                        </Grid>
                        <Grid
                          item
                          xs={0.1}
                          style={{ color: "#f46d25", fontWeight: "bold" }}
                        >
                          :
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          {lead.leadPaxAltMobile}
                        </Grid>
                      </Grid>
                    </Grid>
                    {idx < leadPaxDetails.length - 1 && (
                      <hr
                        style={{ border: "1px solid #f46d25", width: "100%" }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Room Details
            </Typography>
            <Paper
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {roomDetails.map((room, idx) => (
                <Grid container>
                  <Grid item xs container>
                    <Grid item container>
                      <Grid item xs={2} fontWeight="bold">
                        Room Type
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={3} textAlign="left">
                        {room.roomType}
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={2} fontWeight="bold">
                        Rooms
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={3} textAlign="left">
                        {room.noOfRooms}
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={2} fontWeight="bold">
                        Children
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={3} textAlign="left">
                        {room.noOfChildren}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs container>
                    <Grid item container>
                      <Grid item xs={3} fontWeight="bold">
                        Adults
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={2} textAlign="left">
                        {room.noOfAdults}
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3} fontWeight="bold">
                        Meal Plan
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={2} textAlign="left">
                        {room.mealPlan}
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3} fontWeight="bold">
                        Selling Price
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={2} textAlign="left">
                        Rs. {room.sellingPrice}
                      </Grid>
                    </Grid>
                  </Grid>
                  {idx < roomDetails.length - 1 && (
                    <hr
                      style={{ border: "1px solid #f46d25", width: "100%" }}
                    ></hr>
                  )}
                </Grid>
              ))}
            </Paper>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Inclusion Details
            </Typography>
            <Paper
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {inclusionDetails.map((inclusion, idx) => (
                <Grid container>
                  <Grid item xs container>
                    <Grid item xs={2} fontWeight="bold">
                      Name
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      {inclusion.inclusionName}
                    </Grid>
                  </Grid>
                  <Grid item xs container>
                    <Grid item xs={3} fontWeight="bold">
                      Selling Price
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {inclusion.inclusionSellingPrice}
                    </Grid>
                  </Grid>
                  {idx < inclusionDetails.length - 1 && (
                    <hr
                      style={{ border: "1px solid #f46d25", width: "100%" }}
                    ></hr>
                  )}
                </Grid>
              ))}
            </Paper>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Payment Details
            </Typography>
            <Paper
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container>
                <Grid xs={6} container>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Total Room Amount(R)
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {totalRoomRent}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Total Inclusion Amount (I)
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {totalInclusionAmount}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Total Booking Amount (R+I)
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {totalBookingAmount}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Amount Received
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {amountRecieved}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={6} container>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      BPAH
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      Rs. {balPayOnArrival}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Payment Mode
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      {paymentMode}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Payment Type
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      {checkBoxes.partialPayment ? "Partial" : "Full"} Payment
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={4} fontWeight="bold">
                      Payment Ref No.
                    </Grid>
                    <Grid
                      item
                      xs={0.1}
                      style={{ color: "#f46d25", fontWeight: "bold" }}
                    >
                      :
                    </Grid>
                    <Grid item xs={3} textAlign="left">
                      {referenceNo}
                    </Grid>
                  </Grid>
                  {checkBoxes.partialPayment && (
                    <Grid item container>
                      <Grid item xs={4} fontWeight="bold">
                        Balance Payable to Towno
                      </Grid>
                      <Grid
                        item
                        xs={0.1}
                        style={{ color: "#f46d25", fontWeight: "bold" }}
                      >
                        :
                      </Grid>
                      <Grid item xs={3} textAlign="left">
                        Rs. {balPayToTownO}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>
          {/* <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Terms & Conditions
            </Typography>
            <Paper
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {hotelBookingTermCondition.termCondition}
            </Paper>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Typography
              style={{
                background: "#f46d25",
                color: "white",
                fontSize: "1.1rem",
                padding: "0.8rem 1rem 0.3rem 1rem",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Cancellation Policy
            </Typography>
            <Paper
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {hotelBookingTermCondition.cancellationPolicy}
            </Paper>
          </div> */}
          <Grid container justifyContent="center" padding="20px">
            <Grid item>
              <Button
                type="submit"
                style={{ marginRight: "10px" }}
                onClick={handleSubmit}
              >
                {bookingId ? "Update" : "Book"}
              </Button>
              <Button
                onClick={() => setOpenPreview(false)}
                style={{ background: "black", color: "white" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </MuiPickersUtilsProvider>
  );
};

export default HotelNewBooking;
