import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core"
import {
  InfoOutlined,
  LocationOnOutlined,
  StarBorder,
} from "@material-ui/icons"
import BusinessIcon from "@material-ui/icons/Business"
import PersonIcon from "@material-ui/icons/Person"
import { Rating } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import { Box, Grid, Modal } from "@mui/material"
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { differenceInDays } from "date-fns"
import { useEffect, useRef, useState } from "react"
import { FaArrowRightLong, FaMinus, FaPlus } from "react-icons/fa6"
import { IoBedOutline, IoPeopleOutline } from "react-icons/io5"
import { MdOutlineSupportAgent } from "react-icons/md"
import { RxDimensions } from "react-icons/rx"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import Select from "react-select"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import {
  addRoomToCheckout,
  handleChangeSearchParams,
  resetPropertyRoomsCheckout,
  toggleModalChildAndAdults,
} from "../../redux/actions/bookingRoomAction"
import {
  getCitiesDropdown,
  getPropertyListDropdown,
} from "../../redux/actions/dropDownAction"
import {
  hotelDetailsInitial,
  propertyRoomListDetails,
} from "../../redux/actions/hotelViewAction"
import { propertyImageList } from "../../redux/actions/propertyAction"
import { baseurl } from "../../Service/httpCommon"
import Loader from "../../components/Loader/Loader"
import RoomEnquiryForm from "../../components/roomEnquiryForm/roomEnquiryForm.component"
import API from "../../Service/Api"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  main: {
    position: "absolute",
    inset: 0,
    fontFamily: "Poppins",
  },
  header: {
    height: "20rem",
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerInfoContainer: {
    background: "#fff",
    borderRadius: "0.5rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  hotelDetailsContainer: {
    padding: "0rem 1.5rem 9rem 1.5rem",
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: "-3rem",
    zIndex: 10,
  },
  container: {
    boxShadow: "0rem 0rem 0.5rem rgba(231, 231, 231, 1)",
    padding: "0.6rem 1rem",
    borderRadius: "0.5rem",
    background: "#ffffff",
    // backdropFilter: "blur(1rem)",
  },
  amenitiesIcon: {
    fill: "rgba(154, 158, 166, 1)",
  },

  propertyImageSliderContainer: {},
  continueToPaymentContainer: (totalBookingAmount = 0) => ({
    position: "fixed",
    bottom: 0,
    background: "rgba(247, 247, 254, 1)",
    boxShadow: "1rem 0rem 0.5rem #E7E7E7",
    transform: `translateY(${totalBookingAmount == 0 ? 100 : 0}%)`,
    transition: "transform 250ms",
  }),
  continueButton: {
    borderRadius: "2rem",
    fontSize: "0.8rem !important",
    background: "#f46d25",
    border: "1px solid #f46d25",
  },
}))

export function propertyAddress(info) {
  return `${info.propertyAddress}${info.streetAddress}, ${info.city}, ${info.state}, ${info.country} - ${info.zipcode}`
}

const PropertyDetails = () => {
  const { propertyId, city } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  // const [checkoutRooms, setCheckoutRooms] = useState([])

  const { checkoutRoomDetails, totalBookingAmount, totalRooms } = useSelector(
    ({ checkoutRooms }) => checkoutRooms
  )

  const totalDays = useRef(null)

  const {
    citiesDropdown,
    propertyDropdown,
    loading: dropDownLoading,
  } = useSelector(
    ({ propertyDropdownBasedOnCity }) => propertyDropdownBasedOnCity
  )

  const classes = useStyles(totalBookingAmount)

  const addRoom = (checkoutRoomDetails, room) => {
    let body = {
      roomName: room.visibleName,
      displayName: room.displayName,
      mealplan: room.mealplan,
      adults: room.adult,
      children: room.child,
      roomRent: room.roomRent,
      noOfRooms: room.noOfRooms,
      infant: room?.infant ?? 1,
      maxStayLimit: room.maxStayLimit,
    }

    dispatch(addRoomToCheckout(checkoutRoomDetails, body))
  }

  const {
    location,
    propertyId: propertyIdSearch,
    checkinFrom,
    checkinTo,
    rooms,
    children,
    adults,
    openModal,
    infant,
  } = useSelector(({ searchProperties }) => searchProperties)
  const { loading: hotelLoading, hotelDetails } = useSelector(
    ({ hotelDetail }) => hotelDetail
  )

  const { loading: imageLoading, imageList } = useSelector(
    ({ propertyImageList }) => propertyImageList
  )

  const { hotelRoomList, loading: roomLoading } = useSelector(
    ({ hotelRoomListDetails }) => hotelRoomListDetails
  )

  useEffect(() => {
    dispatch(resetPropertyRoomsCheckout())
    dispatch(hotelDetailsInitial({ propertyId }))
    dispatch(propertyImageList(propertyId))
    dispatch(propertyRoomListDetails(propertyId, checkinFrom, checkinTo))
    dispatch(getCitiesDropdown())
    dispatch(getPropertyListDropdown(city))
  }, [])

  const mealPlanLabels = {
    epRate: "EP Rate",
    cpRate: "CP Rate",
    mapRate: "MAP Rate",
    apRate: "AP Rate",
  }

  const [openQueryModal, setOpenQueryModal] = useState(false)
  const [isSubmitting, setIsSumbitting] = useState(false)
  const sendQuery = async (values) => {
    setIsSumbitting(true)
    try {
      const res = await API.post("agenttryingbooking", values)
      toast.success("Query Sent! We'll Contact You Soon...")
    } catch (error) {
      toast.error("Something went wrong. Try again after sometime.")
    } finally {
      setOpenQueryModal(false)
      setIsSumbitting(false)
    }
  }

  return (
    <>
      <main className={classes.main}>
        <section className={classes.header}>
          <Grid
            className={classes.headerInfoContainer}
            container
            md={10}
            alignItems={"center"}
          >
            <Grid
              item
              md={2}
              className={classes.flex}
              borderRight={"2px solid rgba(217, 217, 217, 1)"}
              padding={1}
            >
              <LocationOnOutlined color="primary" />
              <Grid item md={10.5}>
                <Select
                  options={citiesDropdown}
                  value={location}
                  onChange={(option) => {
                    dispatch(handleChangeSearchParams("location", option))
                    dispatch(
                      handleChangeSearchParams("propertyId", {
                        label: "",
                        value: "",
                      })
                    )
                    dispatch(handleChangeSearchParams("propertyId", null))
                    dispatch(getPropertyListDropdown(option.value))
                  }}
                />
              </Grid>
              {/* <span>{hotelDetails?.state}</span> */}
            </Grid>
            <Grid
              item
              md={3}
              className={classes.flex}
              borderRight={"2px solid rgba(217, 217, 217, 1)"}
              padding={1}
              container
            >
              <BusinessIcon color="primary" />
              <Grid item md={10}>
                <Select
                  options={propertyDropdown}
                  value={propertyIdSearch}
                  defaultValue={propertyId}
                  onChange={(option) => {
                    dispatch(handleChangeSearchParams("propertyId", option))
                  }}
                />
              </Grid>
              {/* <span style={{ fontSize: "0.85rem" }}>
                {hotelDetails?.propertyName}
              </span> */}
            </Grid>
            <Grid
              item
              container
              md={4}
              borderRight={"2px solid rgba(217, 217, 217, 1)"}
              padding={1}
              margin={0}
            >
              {/* <DatePicker
              showIcon
              icon={<BsCalendar2Month />}
              dateFormat="do MMM yyyy"
              selectsRange
              startDate={checkinFrom || new Date(checkinFrom)}
              endDate={checkinTo || new Date(checkinTo)}
              placeholderText="Check-In"
              monthsShown={2}
              openToDate={checkinFrom || new Date(checkinFrom)}
              minDate={new Date()}
              onChange={(dates) => {
                const [start, end] = dates
                console.log({ start, end })
                dispatch(handleChangeSearchParams("checkinFrom", start))
                dispatch(handleChangeSearchParams("checkinTo", end))
              }}
              withPortal
              size="small"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                width: "100% important",
              }}
            /> */}
              <DateRangePicker
                value={[checkinFrom, checkinTo]}
                onChange={(dates) => {
                  const [start, end] = dates
                  dispatch(handleChangeSearchParams("checkinFrom", start))
                  dispatch(handleChangeSearchParams("checkinTo", end))
                }}
                calendars={2}
                disablePast
                inputFormat="DD MMM YYYY"
                autoFocus
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      label="Check-In"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField
                      {...endProps}
                      label="Check-Out"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>

            <Grid
              item
              md={2}
              padding={1}
              display="flex"
              alignItems={"center"}
              style={{ gap: "0.5rem", cursor: "pointer" }}
              onClick={() => dispatch(toggleModalChildAndAdults())}
            >
              <IoPeopleOutline style={{ color: "#f46d25" }} />
              <div style={{ fontSize: "0.8rem" }}>
                <span>
                  {adults} adult/s, {children} children, {infant} infant,{" "}
                  {rooms} room/s
                </span>
              </div>
            </Grid>

            <Grid item md={1} padding={1}>
              <Button
                size="small"
                fullWidth
                onClick={() => {
                  dispatch(resetPropertyRoomsCheckout())
                  dispatch(
                    propertyRoomListDetails(
                      propertyIdSearch.value,
                      checkinFrom,
                      checkinTo
                    )
                  )
                  totalDays.current = differenceInDays(
                    new Date(checkinTo),
                    new Date(checkinFrom)
                  )
                  dispatch(
                    hotelDetailsInitial({ propertyId: propertyIdSearch.value })
                  )
                  dispatch(propertyImageList(propertyIdSearch.value))
                  history.push(
                    `/konnect/propertyNewView/${location.value}/${propertyIdSearch.value}`
                  )
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </section>
        <Grid container className={classes.hotelDetailsContainer}>
          <Grid item container md={12} display={"flex"} gap={1}>
            <Grid item md={3.9} className={classes.container}>
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    fontSize: "0.8rem",
                    color: "rgba(154, 158, 166, 1)",
                    mixBlendMode: "difference",
                  }}
                >
                  {hotelDetails.starRating} Star Hotel in {hotelDetails.state}
                </span>
                <div style={{ fontSize: "1.6rem", fontWeight: 600 }}>
                  {hotelDetails.displayName}
                </div>
                <Rating
                  readOnly
                  value={Number(hotelDetails.starRating)}
                  size="small"
                  emptyIcon={<StarBorder fontSize="inherit" />}
                  classes={{
                    iconFilled: {
                      style: {
                        background:
                          "linear-gradient(rgba(252, 214, 53, 1),rgba(247, 169, 40, 1))",
                      },
                    },
                  }}
                />
                <div
                  style={{
                    fontSize: "0.85rem",
                    color: "rgba(154, 158, 166, 1)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocationOnOutlined color="primary" />
                  <>{propertyAddress(hotelDetails)}</>
                </div>

                <Button
                  endIcon={<MdOutlineSupportAgent />}
                  style={{ margin: "1rem 0rem 0.5rem 0rem" }}
                  onClick={() => setOpenQueryModal(true)}
                >
                  Send an Enquiry
                </Button>
              </div>
            </Grid>

            <Grid
              item
              md={8}
              className={classes.container}
              display="flex"
              container
            >
              {imageList?.length == 0 ? (
                <Grid
                  item
                  md={12}
                  fontWeight={600}
                  textAlign={"center"}
                  fontSize={"3rem"}
                  color={"#5E6282"}
                >
                  IMAGE NOT AVALABLE
                </Grid>
              ) : (
                <Grid item container md={12} spacing={1}>
                  <Grid item md={9}>
                    <img
                      key={imageList[0]?.id}
                      src={`${baseurl}getimage/${imageList[0]?.imagepath}`}
                      style={{
                        width: "100%",
                        aspectRatio: "1/0.5",
                        borderRadius: "0.5rem",
                      }}
                    />
                  </Grid>
                  <Grid item container md={3}>
                    {imageList
                      ?.filter((img, imgIndex) => imgIndex < 3)
                      .map((imgSrc) => (
                        <Grid item md={12}>
                          <img
                            src={`${baseurl}getimage/${imgSrc?.imagepath}`}
                            style={{
                              width: "100%",
                              borderRadius: "0.5rem",
                              objectFit: "cover",
                              aspectRatio: "1/0.5",
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item container md={12} className={classes.container}>
            <Grid item md={12} fontWeight="bold">
              About
            </Grid>
            <Grid item md={12} color="rgba(154, 158, 166, 1)">
              {hotelDetails?.description}
            </Grid>
          </Grid>

          <Grid item container md={12} className={classes.container}>
            <Grid item md={12} fontWeight={600}>
              Rooms Available
            </Grid>
            <Grid
              item
              container
              md={12}
              margin={"0.5rem 0rem 0rem 0rem"}
              padding={1}
              style={{ background: "rgba(232, 235, 247, 1)" }}
              borderRadius="0.5rem 0.5rem 0rem 0rem"
              fontWeight={600}
            >
              <Grid item container md={10}>
                <Grid item md={2} textAlign={"center"}>
                  Room Type
                </Grid>
                <Grid
                  item
                  md={3}
                  textAlign={"center"}
                  style={{ wordWrap: "break-word" }}
                >
                  Number Of Guests
                </Grid>

                <Grid item md={4} textAlign={"center"}>
                  {totalDays.current == null || totalDays.current == 1
                    ? "Todays"
                    : `${totalDays.current} Days`}{" "}
                  Price
                </Grid>

                <Grid item md={3} textAlign={"center"}>
                  Select Rooms
                </Grid>
              </Grid>
              <Grid
                item
                md={2}
                textAlign={"center"}
                style={{ wordWrap: "break-word" }}
              >
                Total in INR
              </Grid>
            </Grid>

            <Grid
              item
              container
              md={12}
              style={{ background: "rgba(247, 247, 254, 1)" }}
              borderRadius={"0rem 0rem 0.5rem 0.5rem"}
            >
              {roomLoading ? (
                <Grid item md={10}>
                  <Loader />
                </Grid>
              ) : (
                <Grid item container md={10}>
                  {hotelRoomList?.map((room, idx) => (
                    <Grid
                      item
                      container
                      md={12}
                      padding={1}
                      key={room.id}
                      borderBottom={
                        idx < hotelRoomList.length - 1 &&
                        "1.5px solid rgba(231, 231, 231, 1)"
                      }
                      borderRight={"1.5px solid rgba(231, 231, 231, 1)"}
                    >
                      <Grid
                        item
                        md={2}
                        borderRight={"1.5px solid rgba(231, 231, 231, 1)"}
                      >
                        <Grid
                          item
                          md={12}
                          fontWeight={600}
                          style={{ fontSize: "1.1rem" }}
                          marginBottom={1.5}
                        >
                          {room.displayName}
                        </Grid>
                        <Grid
                          item
                          container
                          md={12}
                          fontSize={"0.8rem"}
                          spacing={0.5}
                        >
                          {[
                            {
                              label: "Room Size",
                              value: `${room.roomSize}`,
                              icon: RxDimensions,
                            },
                            {
                              label: "Bed Type",
                              value: room.bedType,
                              icon: IoBedOutline,
                            },
                            {
                              label: "Max Adults",
                              value: room.adultsMax,
                              icon: IoPeopleOutline,
                            },
                          ].map((a) => (
                            <Grid item container md={12} alignItems="center">
                              <Grid item md={1}>
                                <a.icon style={{ color: "#f46d25" }} />
                              </Grid>
                              <Grid item md={4}>
                                {a.label}
                              </Grid>
                              : {a.value}
                            </Grid>
                          ))}
                          {/* roomArea: '30', roomAreaType: 'sqmtr', */}
                        </Grid>
                      </Grid>
                      <Grid item container md={10} spacing={3}>
                        {room?.checkOutPageRoomDetails?.map((r, i) => (
                          <Grid
                            item
                            container
                            md={12}
                            spacing={3}
                            key={`${i}-{r.mealplan}`}
                          >
                            <Grid
                              item
                              md={4}
                              textAlign={"center"}
                              borderRight={"1.5px solid rgba(231, 231, 231, 1)"}
                              alignItems={"center"}
                              color={"#f46d25"}
                            >
                              {Array.from(
                                { length: parseInt(r.adult) },
                                (_, i) => (
                                  <PersonIcon color="primary" />
                                )
                              )}{" "}
                              +{" "}
                              {Array.from(
                                { length: parseInt(r.child) },
                                (_, i) => (
                                  <PersonIcon color="primary" />
                                )
                              )}{" "}
                              <Tooltip
                                size="small"
                                title={`${r.adult} adult/s, ${r.child} children`}
                                style={{ cursor: "pointer" }}
                              >
                                <InfoOutlined
                                  color="primary"
                                  style={{ width: "1rem", cursor: "pointer" }}
                                />
                              </Tooltip>
                              {/* {r.adult} adult/s, {r.child} children */}
                            </Grid>
                            <Grid
                              item
                              container
                              md={4}
                              textAlign={"center"}
                              borderRight={"1.5px solid rgba(231, 231, 231, 1)"}
                            >
                              <Grid
                                item
                                md={12}
                                style={{ fontSize: "0.8rem", color: "#979797" }}
                              >
                                {mealPlanLabels[r.mealplan]}
                              </Grid>
                              <Grid
                                item
                                md={12}
                                fontWeight={600}
                                color={"#f46d25"}
                              >
                                {currencyFormatter(r.roomRent)}
                              </Grid>
                            </Grid>

                            <Grid item md={4}>
                              <Select
                                placeholder="Select Rooms"
                                options={Array.from(
                                  {
                                    length: r?.roomsAvailable + 1,
                                  },
                                  (_, i) => ({ value: i, label: i })
                                )}
                                defaultValue={{ value: 0, label: 0 }}
                                onChange={(option) => {
                                  const details = {
                                    ...room,
                                    ...r,
                                    noOfRooms: option.value,
                                  }
                                  addRoom(checkoutRoomDetails, details)
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}

              <Grid
                container
                item
                md={2}
                textAlign="start"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <Grid
                  item
                  md={12}
                  textAlign={"center"}
                  padding={2}
                  fontWeight={600}
                  color={"#f46d25"}
                >
                  {currencyFormatter(totalBookingAmount)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          className={classes.continueToPaymentContainer}
          padding={1}
        >
          <Grid item container md={10} padding={1}>
            <Grid item md={12} fontSize={"0.9rem"} color={"#505661"}>
              You selected
            </Grid>
            <Grid item md={12} fontWeight={600}>
              {totalRooms} x Room | {currencyFormatter(totalBookingAmount)}
            </Grid>
          </Grid>

          <Grid item md={2} textAlign="center">
            <Button
              style={{ borderRadius: "2rem" }}
              endIcon={<FaArrowRightLong size={14} />}
              className={classes.continueButton}
              onClick={() =>
                history.replace("/konnect/propertyNewView/checkout")
              }
            >
              Continue To Payment
            </Button>
          </Grid>
        </Grid>

        <Modal
          open={openModal}
          onClose={() => {}}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            md={2.5}
            style={{ background: "#fff" }}
            padding={1}
            borderRadius={1}
            spacing={1.5}
          >
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={4}>
                Adults
              </Grid>
              <Grid item md={1}>
                :
              </Grid>
              <Grid item md={7}>
                <TextField
                  name="adults"
                  value={adults}
                  onChange={(e) => {
                    const { name, value } = e.target
                    dispatch(handleChangeSearchParams(name, value))
                  }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={adults <= 1}
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("adults", adults - 1)
                            )
                          }}
                        >
                          <FaMinus />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("adults", adults + 1)
                            )
                          }}
                        >
                          <FaPlus />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { textAlign: "center" } }}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={4}>
                Children
              </Grid>
              <Grid item md={1}>
                :
              </Grid>
              <Grid item md={7}>
                <TextField
                  name="children"
                  value={children}
                  onChange={(e) => {
                    const { name, value } = e.target
                    dispatch(handleChangeSearchParams(name, value))
                  }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={children <= 1}
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("children", children - 1)
                            )
                          }}
                        >
                          <FaMinus />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("children", children + 1)
                            )
                          }}
                        >
                          <FaPlus />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { textAlign: "center" } }}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={4}>
                Infant
              </Grid>
              <Grid item md={1}>
                :
              </Grid>
              <Grid item md={7}>
                <TextField
                  name="infant"
                  value={infant}
                  onChange={(e) => {
                    const { name, value } = e.target
                    dispatch(handleChangeSearchParams(name, value))
                  }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={infant <= 1}
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("infant", infant - 1)
                            )
                          }}
                        >
                          <FaMinus />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={(e) => {
                            dispatch(
                              handleChangeSearchParams("infant", infant + 1)
                            )
                          }}
                        >
                          <FaPlus />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { textAlign: "center" } }}
                  disabled
                />
              </Grid>

              <Grid item container md={12} alignItems={"center"}>
                <Grid item md={4}>
                  Rooms
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid item md={7}>
                  <TextField
                    name="rooms"
                    value={rooms}
                    onChange={(e) => {
                      const { name, value } = e.target
                      dispatch(handleChangeSearchParams(name, value))
                    }}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size="small"
                            color="primary"
                            disabled={rooms <= 1}
                            onClick={(e) => {
                              dispatch(
                                handleChangeSearchParams("rooms", rooms - 1)
                              )
                            }}
                          >
                            <FaMinus />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              dispatch(
                                handleChangeSearchParams("rooms", rooms + 1)
                              )
                            }}
                          >
                            <FaPlus />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ style: { textAlign: "center" } }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} textAlign={"center"}>
              <Button onClick={() => dispatch(toggleModalChildAndAdults())}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={openQueryModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RoomEnquiryForm
            {...hotelDetails}
            roomOptions={hotelRoomList?.map((r) => ({
              label: r.visibleName,
              value: r.visibleName,
            }))}
            onClose={() => setOpenQueryModal(false)}
            isSubmitting={isSubmitting}
            onSubmit={sendQuery}
          />
        </Modal>
      </main>
      <ScreenLoader open={dropDownLoading} />
    </>
  )
}

export default PropertyDetails
