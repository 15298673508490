import DateFnsUtils from "@date-io/date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Dialog, Slide, Stack, TextareaAutosize } from "@mui/material";
import { addDays, differenceInDays, format, parse } from "date-fns";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import Api from "../../Service/Api";
import {
  getBookingSourceOptions,
  getPaymentModeOptions,
} from "../../redux/actions/commonFeatureDropdown.action";
import { twnButtonStyles } from "../../utils/townoStyle";
import Loader from "./../../components/Loader/Loader";
import AgentBookingPreview from "./AgentBookingPreview";
import AgentBookingRefund from "./AgentBookingRefund";
import BookingClientForm from "./BookingClientForm";

//select field
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};
const selectStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "#f46d25" },
    borderColor: "#f46d25",
    boxShadow: "none",
    width: "100%",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
    background: "#fff",
    padding: "0px 5px",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: "14px",
  }),
};
//select field

//styles start
const useStyles = makeStyles((theme) => ({
  topBorder: {
    position: "absolute",
    top: "-9px",
    zIndex: "10",
    left: "12px",
    color: "rgb(0 0 0 / 54%)",
    background: "#f1f1f1",
    borderRadius: "4px",
    padding: "0px 4px",
    fontSize: "12px",
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    paddingRight: "5px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
}));
const buttonDisabledStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  background: "#cccccc",
  borderRadius: "5px",
  color: "#666666",
  height: "34px!important",
  textAlign: "center",
  padding: "5px 15px",
};
//styles end

const bookingSourceOptions = [
  { value: "hotelWebsiteChat", label: "Hotel Website Chat" },
  { value: "justDial", label: "JustDial" },
  { value: "agentB2B", label: "B2B Agent" },
  { value: "fit", label: "FIT" },
  { value: "socialMedia", label: "Social Media" },
  { value: "incomingCall", label: "Incoming Call" },
  { value: "holidayIq", label: "Holiday Iq" },
  { value: "tripoto", label: "Tripoto" },
];

const mealPlanOptions = [
  { value: "EP", label: "EP" },
  { value: "CP", label: "CP" },
  { value: "MAP", label: "MAP" },
  { value: "AP", label: "AP" },
];

const paymentModeOptions = [
  { value: "cash", label: "Cash" },
  { value: "upi", label: "UPI" },
  { value: "BankTransfer", label: "Bank Transfer" },
  { value: "Instamojo", label: "Instamojo" },
  { value: "creditnote", label: "Credit Note" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

//new
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

const objectReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};
const checkFields = [
  "clientName",
  "destinationCity",
  "bookingSource",
  "hotelName",
  "checkIn",
  "checkOut",
  "roomType",
  "totalRoomSellingAmount",
  "totalNetAmount",
];

export default function NewAgentBooking() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { bookingId } = useParams();
  var uniqueid = localStorage.getItem("unique_id");
  var createdBy = localStorage.getItem("auth");
  const [clientList, setClientList] = useState([]);
  const [destinationCity, setDestinationCity] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [hotelRoomType, setHotelRoomType] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedId] = useState(0);
  const [paymentType, setPaymentType] = useState("Full");
  const [checkin1, setCheckin1] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [paidAmountPrevious, setPaidAmountPrevious] = useState(0);
  const clientListFromReducer = useSelector(
    ({ clientList }) => clientList.clientLists
  );
  const MobileGet = sessionStorage.getItem("mobile");

  // all single data variable
  const [formData, setFormData] = useReducer(formReducer, {
    clientName: " ",
    clientMobile: " ",
    clientEmail: " ",
    destinationCity: " ",
    bookingSource: " ",
    hotelName: " ",
    hotelPhone: " ",
    starRating: " ",
    hotelAddress: " ",
    hotelEmail: " ",
    checkIn: null,
    checkOut: null,
    totalRoomNetAmount: " ",
    totalRoomSellingAmount: "",
    totalInclusionAmount: " ",
    totalVendorAmount: "1",
    totalBookingAmount: " ",
    paidAmount: "0",
    profit: "1",
    pendingAmount: " ",
    bookingStatus: " ",
    balancePayableHotel: "",
    vaccinated: true,
    git: false,
    partialPayment: "0",
    paymentType: "Full",
    paymentMode: "",
    paymentRefNo: " ",
    id: "",
    uniqueId: uniqueid,
    bookingId: "",
    agentBookingPayments: [],
  });

  //terms,condition and cancellation
  const [agentBkngTrmCndData, setAgentBkngTrmCndData] = useReducer(
    objectReducer,
    { id: "", teamCondition: "", cancellationPolicy: "" }
  );

  const minCheckInDate = formData.checkIn
    ? parse(formData.checkIn, "yyyy-MM-dd", new Date()) < new Date()
      ? parse(formData.checkIn, "yyyy-MM-dd", new Date())
      : new Date()
    : new Date();

  //bookingLeads variable and function starts
  const [bookingLeads, setBookingLeads] = useState([
    { id: "", name: "", mobile: "", altMobile: "", email: "" },
  ]);

  const handleLeadChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...bookingLeads];
    list[index][name] = value;
    setBookingLeads(list);
  };

  const handleRemoveLeadPerson = (index) => {
    const list = [...bookingLeads];
    list.splice(index, 1);
    setBookingLeads(list);
  };

  const handleAddLeadPerson = () => {
    setBookingLeads([
      ...bookingLeads,
      { id: "", name: "", mobile: "", altMobile: "", email: "" },
    ]);
  };

  useEffect(() => {
    if (formData.paymentType === "Full") {
      formData.partialPayment = 0;
    }
    formData.balancePayableHotel =
      formData.totalBookingAmount -
      formData.paidAmount -
      formData.partialPayment -
      paidAmountPrevious;
  }, [formData.totalBookingAmount]);
  function balanceHotelPayableFull() {
    setFormData({ name: "partialPayment", value: "0" });
    setFormData({ name: "paymentType", value: "Full" });
    setPaymentType("Full");
    var res =
      formData.totalBookingAmount - formData.paidAmount - paidAmountPrevious;
    setFormData({ name: "balancePayableHotel", value: res });
  }
  function balanceHotelPayablePartial(e) {
    setFormData({
      name: "balancePayableHotel",
      value:
        formData.totalBookingAmount -
        paidAmountPrevious -
        e.target.value * 1 -
        formData.paidAmount,
    });
  }
  function amountrecfun(e) {
    setFormData({ name: "paidAmount", value: e.target.value });
    setFormData({
      name: "balancePayableHotel",
      value:
        formData.totalBookingAmount -
        paidAmountPrevious -
        formData.partialPayment -
        e.target.value,
    });
  }
  //RoomCategories variables and function starts
  const [roomCategories, setRoomCategories] = useState([
    {
      id: "",
      boardBasic: " ",
      roomType: " ",
      mealplan: " ",
      adult: "1",
      child: "0",
      rooms: "1",
      sellingPrice: "",
      netPrice: "",
      totalNetPrice: "",
    },
  ]);

  const [mlos, setMlos] = useState(null);

  const handleRoomChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...roomCategories];
    list[index][name] = value;
    setRoomCategories(list);
  };
  const handleRoomOptionsChange = (name, value, index, updatedLists) => {
    const list = updatedLists ? updatedLists : [...roomCategories];
    list[index][name] = value;
    setRoomCategories(list);
  };

  const RoomDisplayName =
    hotelRoomType &&
    hotelRoomType.map((roomType) => ({ label: roomType, value: roomType }));

  //Default Room display name and meal plan
  useEffect(() => {
    console.log(hotelRoomType);
    if (hotelRoomType.length > 0 && checkin1 !== null && !bookingId) {
      let found = RoomDisplayName.find((room, idx) => idx === 0);
      console.log(found);
      let mealDefault = mealPlanOptions.find((plan, idx) => idx === 1);
      console.log(mealDefault);

      handleRoomOptionsChange(
        "roomType",
        found.value,
        roomCategories.length - 1
      );
      handleRoomOptionsChange(
        "mealplan",
        mealDefault.value,
        roomCategories.length - 1
      );
    }
  }, [hotelRoomType]);

  const handleRemoveRoomPerson = (index) => {
    const list = [...roomCategories];
    console.log("before delete");
    list.splice(index, 1);
    console.log("after delete", list);
    setRoomCategories(list);
  };

  const handleAddRoomPerson = async () => {
    const roomcategoryValues = [
      ...roomCategories,
      {
        id: "",
        boardBasic: " ",
        roomType: " ",
        mealplan: " ",
        adult: "1",
        child: "0",
        rooms: "1",
        sellingPrice: "0",
        netPrice: "0",
        totalNetPrice: "0",
      },
    ];

    let found = await RoomDisplayName.find((room, idx) => idx === 0);
    console.log(found);
    let mealDefault = mealPlanOptions.find((plan, idx) => idx === 1);
    console.log(mealDefault);

    await handleRoomOptionsChange(
      "roomType",
      found.value,
      roomcategoryValues.length - 1,
      roomcategoryValues
    );
    await handleRoomOptionsChange(
      "mealplan",
      mealDefault.value,
      roomcategoryValues.length - 1,
      roomcategoryValues
    );
  };
  //RoomCategories variables and function ends

  // inclusion variable and function starts
  const [bookingInclusions, setBookingInclusions] = useState([
    { id: "", inclusion: "", sellingPrice: "", vendorPrice: "" },
  ]);

  const handleInclusionsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...bookingInclusions];
    list[index][name] = value;
    setBookingInclusions(list);
  };
  const handleRemoveInclusionPerson = (index) => {
    const list = [...bookingInclusions];
    list.splice(index, 1);
    setBookingInclusions(list);
  };

  const handleAddInclusionPerson = () => {
    setBookingInclusions([
      ...bookingInclusions,
      { id: "", inclusion: "", sellingPrice: "" },
    ]);
  };
  //inclusion variable and function ends

  //new
  const [error, setError] = useState({
    clientName: "",
    destinationCity: "",
    bookingSource: "",
    hotelName: "",
  });
  const validateField = (name, value) => {
    let temp_error = error;
    switch (name) {
      case "clientName":
      case "destinationCity":
      case "bookingSource":
      case "hotelName":
        temp_error[name] = value.length < 3 ? `${name} required` : "";
        break;
    }
    setError(temp_error);
  };

  const validateAllFields = () => {
    let valid = true;
    let formDataCheck = Object.entries(formData);

    //validating selling price value for all room Inputs.

    roomCategories.forEach((roomCategory) => {
      if (
        roomCategory.sellingPrice <
        roomCategory.netPrice * daysDiff() * roomCategory.rooms
      ) {
        valid = false;
      }
    });

    //validating booking inclusion

    bookingInclusions.forEach((inclusion) => {
      if (inclusion.sellingPrice && inclusion.vendorPrice) {
        if (
          parseInt(inclusion.sellingPrice) < parseInt(inclusion.vendorPrice) ||
          !inclusion.sellingPrice
        ) {
          valid = false;
        }
      }
    });

    formDataCheck = formDataCheck.filter((e) => {
      return checkFields.includes(e[0]);
    });
    console.log(
      "NewAgentBooking|validateAllFields|formDataCheck:",
      formDataCheck
    );
    console.log(Object.values(formDataCheck[6]));
    Object.values(formDataCheck).forEach((val) => {
      if (val[1] !== null && val[1].toString().trim().length <= 0) {
        valid = false;
        // let temp = errorArr;
        // temp[i][val[0]] = "Required"
        // setErrorArr(temp);
      } else if (val[1] === null) {
        valid = false;
      }
      if (["totalRoomSellingAmount", "totalNetAmount"].includes(val[0])) {
        if (val[1] / 1 <= 0) {
          valid = false;
        }
      }
    });
    return valid;
  };

  const daysDiff = () => {
    if (formData.checkOut) {
      // parse date and calculate diff between the dates
      let d1 = parse(formData.checkIn, "yyyy-MM-dd", new Date());
      let d2 = parse(formData.checkOut, "yyyy-MM-dd", new Date());
      return differenceInDays(d2, d1);
    } else {
      return "";
    }
  };

  const handleFormChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
    validateField(event.target.name, event.target.value);
  };

  useEffect(() => {
    roomCategories.forEach((element) => {
      element.rooms = 1;
      element.totalNetPrice = element.netPrice * 1 * daysDiff();
      // element.totalNetPrice = element.netPrice * 1 * differenceInDays(formData.checkOut, formData.checkIn);
    });
    let tempTotalRoomNetAmount = roomCategories.reduce((total, e) => {
      return total + e.totalNetPrice * 1;
    }, 0);
    let tempTotalInclusionAmount = bookingInclusions.reduce((total, e) => {
      return total + e.sellingPrice * 1;
    }, 0);
    let tempTotalRoomSellingAmount = roomCategories.reduce((total, e) => {
      return total + e.sellingPrice * 1;
    }, 0);
    let tempTotalVendorAmount = bookingInclusions.reduce((total, e) => {
      return total + e.vendorPrice * 1;
    }, 0);
    let tempTotalNetAmount = tempTotalRoomNetAmount + tempTotalVendorAmount;
    let tempCommission =
      tempTotalInclusionAmount +
      tempTotalRoomSellingAmount -
      tempTotalNetAmount;

    if (tempTotalRoomNetAmount === null) {
      return 0;
    }
    if (tempTotalNetAmount === null) {
      return 0;
    }
    if (tempCommission === null) {
      return 0;
    }

    setFormData({ name: "totalRoomNetAmount", value: tempTotalRoomNetAmount });
    setFormData({ name: "totalNetAmount", value: tempTotalNetAmount });
    setFormData({ name: "profit", value: tempCommission });
  }, [formData.git]);

  useEffect(() => {
    let tempTotalRoomNetAmount = roomCategories.reduce((total, e) => {
      return total + e.totalNetPrice * 1;
    }, 0);
    let tempTotalInclusionAmount = bookingInclusions.reduce((total, e) => {
      return total + e.sellingPrice * 1;
    }, 0);
    let tempTotalRoomSellingAmount = roomCategories.reduce((total, e) => {
      return total + e.sellingPrice * 1;
    }, 0);
    let tempTotalVendorAmount = bookingInclusions.reduce((total, e) => {
      return total + e.vendorPrice * 1;
    }, 0);
    let tempTotalNetAmount = tempTotalRoomNetAmount + tempTotalVendorAmount;
    let tempCommission =
      tempTotalInclusionAmount +
      tempTotalRoomSellingAmount -
      tempTotalNetAmount;

    if (tempTotalRoomNetAmount === null) {
      return 0;
    }
    if (tempTotalInclusionAmount === null) {
      return 0;
    }
    if (tempTotalRoomSellingAmount === null) {
      return 0;
    }
    if (tempTotalVendorAmount === null) {
      return 0;
    }
    if (tempTotalNetAmount === null) {
      return 0;
    }
    if (tempCommission === null) {
      return 0;
    }

    setFormData({ name: "totalRoomNetAmount", value: tempTotalRoomNetAmount });
    setFormData({
      name: "totalInclusionAmount",
      value: tempTotalInclusionAmount,
    });
    setFormData({
      name: "totalBookingAmount",
      value: tempTotalRoomSellingAmount + tempTotalInclusionAmount,
    });
    setFormData({
      name: "totalRoomSellingAmount",
      value: tempTotalRoomSellingAmount,
    });
    setFormData({ name: "totalVendorAmount", value: tempTotalVendorAmount });
    setFormData({ name: "totalNetAmount", value: tempTotalNetAmount });
    setFormData({ name: "profit", value: tempCommission });
  }, [roomCategories, bookingInclusions]);

  // Client Starts
  const handleClientChange = (option) => {
    console.log("client|option", option);
    setFormData({ name: "clientName", value: option.value });
    setFormData({ name: "clientMobile", value: option.phoneNo });
    setFormData({ name: "clientEmail", value: option.mailId });
  };

  //autoFill client details whenever a new client is added to clientList
  useEffect(() => {
    console.log(clientList);
    if (clientList.length > 0 && MobileGet !== null) {
      let found = clientList.find((client) => MobileGet === client.phoneNo);

      handleClientChange(found);
      sessionStorage.setItem("mobile", null);
    }
  }, [MobileGet, clientList]);
  // Client Ends

  //destinationCity-HotelList function Starts
  const handleCityChange = (option) => {
    Api.get(`/agentbookingcityproperty/${uniqueid}/${option.value}`).then(
      (res) => {
        let hotelVar = res.data.map((each, index) => {
          return { label: each.displayName, value: each.propertyId };
        });
        setFormData({ name: "destinationCity", value: option.value });
        setHotelList(hotelVar);
      }
    );
  };

  const handleHotelChange = (option) => {
    console.log(option);
    sessionStorage.setItem("propertyIdForMlos", option.value);
    Api.get(`/agentbookingpropertydetail/${uniqueid}/${option.value}`).then(
      (res) => {
        console.log("hotelList:", option, res.data);
        setFormData({ name: "hotelName", value: option.label });
        setFormData({ name: "hotelAddress", value: res.data.hotelAddress });
        setFormData({ name: "starRating", value: res.data.hotelStarRating });
        setFormData({ name: "hotelPhone", value: res.data.hotelMobile });
        setFormData({ name: "hotelEmail", value: res.data.hotelEmail });
        let tempHotelRoomTypes = res.data.roomType.map((each, index) => {
          return { label: each, value: each };
        });
        setHotelRoomType(res.data.roomType);
      }
    );
  };
  //destinationCity-HotelList function Ends

  //accordian variable and function starts
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  //accordian variable and function ends

  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [agentBookingPayments, setAgentBookingPayments] = useReducer(
    objectReducer,
    { id: "", paidAmount: "", paymentMode: "", paymentRefNo: "" }
  );
  /*useEffect(() => {
        let previousPaidAmt = formData.agentBookingPayments.reduce((target, each) => { return target + (each.paidAmount / 1) }, 0);
        setTotalAmountPaid(previousPaidAmt + (agentBookingPayments.paidAmount / 1))
        return () => { }
    }, [agentBookingPayments])*/

  const onSubmitFun = async () => {
    setIsClicked(true);
    console.log(error);
    console.log("no data");
    console.log("roomCategories while Submission");
    if (validateForm(error) && validateAllFields()) {
      let postData = {
        ...formData,
        agentBookingLeads: bookingLeads,
        agentBookingRoomCategories: roomCategories,
        agentBookingInclusions: bookingInclusions,
        createdBy: createdBy,
        agentBookingPayments: [
          {
            id: "",
            paidAmount: formData.paidAmount ? formData.paidAmount : "0",
            paymentMode: formData.paymentMode ? formData.paymentMode : "",
            paymentRefNo: formData.paymentRefNo ? formData.paymentRefNo : "",
          },
        ],
        amountPaid: totalAmountPaid,
        agentBookingTeamCondition: agentBkngTrmCndData,
        noOfNight: daysDiff(),
      };
      console.log("Preiview Open Clicked", postData);
      Api.post("agentBooking", postData).then((res) => {
        console.log(res.data);
        bookingId
          ? toast.success(`Update Successfull`)
          : toast.success("New Booking Created");
        setTimeout(() => {
          window.open("/konnect/agentBookings", "_self");
        }, 2000);
      });
    } else {
      toast.error("Please correct Errors");
      setIsClicked(false);
    }
  };

  // Api call for ClientList,DestinationCity,AllHotelList Start
  useEffect(() => {
    Api.get(`/clientall/${uniqueid}`).then((res) => {
      let clientVar = res.data.map((each, index) => {
        return {
          label: each.firstName + " " + each.lastName,
          value: each.firstName + " " + each.lastName,
          phoneNo: each.mobile,
          mailId: each.mail,
        };
      });
      setClientList(clientVar);
    });

    Api.get(`/agentbookingpropertycity/${uniqueid}`).then((res) => {
      let cityVar = res.data.map((each, index) => {
        return { label: each, value: each };
      });
      setDestinationCity(cityVar);
    });

    //this useEffect is for updating/editing the form
    if (bookingId) {
      setLoading(true);
      Api.get(`agentBookingById/${uniqueid}/${bookingId}`).then((res) => {
        Object.entries(res.data).forEach(([key, value]) => {
          if (key != "paidAmount") setFormData({ name: key, value: value });
        });
        //setFormData({ name: "paymentMode", value: res.data.agentBookingPayments[0].paymentMode })
        //setFormData({ name: "paymentRefNo", value: res.data.agentBookingPayments[0].paymentRefNo })
        setPaymentType(res.data.paymentType);
        setPaidAmountPrevious(res.data.paidAmount);
        setBookingLeads(res.data.agentBookingLeads);
        Api.get(
          `/agentbookingcityproperty/${uniqueid}/${res.data.destinationCity}`
        )
          .then((propertyResData) => {
            console.log(
              "propertyName|propertyResData.data: ",
              propertyResData.data
            );
            let allHotelVar = propertyResData.data.map((each, index) => {
              return { label: each.displayName, value: each.propertyId };
            });
            setHotelList(allHotelVar);
            let selectedHotel = allHotelVar.filter(
              (i) => i.label === res.data.hotelName
            );
            return selectedHotel;
          })
          .then((selectedHotel) => {
            Api.get(
              `/agentbookingpropertydetail/${uniqueid}/${selectedHotel[0].value}`
            ).then((roomTypeResData) => {
              let tempHotelRoomTypes = roomTypeResData.data.roomType.map(
                (each, index) => {
                  return { label: each, value: each };
                }
              );
              setHotelRoomType(roomTypeResData.data.roomType);
            });
          });
        setRoomCategories(res.data.agentBookingRoomCategories);
        setBookingInclusions(res.data.agentBookingInclusions);
        setTotalAmountPaid(
          res.data.agentBookingPayments.reduce((target, each) => {
            return target + each.paidAmount / 1;
          }, 0)
        );
        if (
          res.data.agentBookingTeamCondition != undefined &&
          (res.data.agentBookingTeamCondition != undefined) != null
        ) {
          Object.entries(res.data.agentBookingTeamCondition).forEach(
            ([key, value]) => {
              setAgentBkngTrmCndData({ target: { name: key, value: value } });
            }
          );
        }
        setAmountRefunded(res.data.amountRefunded);
        setAmountRetained(res.data.amountRetained);
        setRevisedCommission(res.data.revisedCommission);
        if (res.data.amountRefunded > 0) {
          setShowRefund(true);
        }
        setCheckin1(addDays(new Date(res.data.checkIn), 1));
        setLoading(false);
      });
    } else {
      Api.get(
        `agenthoteltermandcondition/${uniqueid}/hotelBookingTermCondition`
      ).then((res) => {
        setAgentBkngTrmCndData({
          target: { name: "teamCondition", value: res.data },
        });
      });
      Api.get(
        `agenthoteltermandcondition/${uniqueid}/hotelBookingCancellationPolicy`
      ).then((res) => {
        setAgentBkngTrmCndData({
          target: { name: "cancellationPolicy", value: res.data },
        });
      });
    }
  }, []);
  //Api call of ClientList,DestinationCity,AllHotelList Ends

  const handleFormDateChange = async (name, e) => {
    setFormData({
      name: name,
      value: format(e, "yyyy-MM-dd"),
    });
    //console.log("name: ",name);
    if (name === "checkIn") {
      const formattedDate = format(e, "yyyy-MM-dd");
      const propertyId = sessionStorage.getItem("propertyIdForMlos");
      const { data } = await Api.get(
        `getMlosDetails/${propertyId}/${formattedDate}`
      );
      setMlos(data);
      console.log(data);
      setCheckin1(addDays(e, data ?? 1));
    }
  };

  // refund starts
  const [amountRefunded, setAmountRefunded] = useState(0);
  const [amountRetained, setAmountRetained] = useState(0);
  const [revisedCommission, setRevisedCommission] = useState(0);
  const [showRefund, setShowRefund] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);

  const handleRefund = () => {
    const postData = {
      uniqueId: uniqueid,
      packageId: bookingId,
      amountRefunded: amountRefunded,
      amountRetained: amountRetained,
      revisedCommission: revisedCommission,
    };
    console.log("postdata|refund: ", postData);
    setOpenRefund(false);
    setShowRefund(true);
    Api.post("cancelHotelBooking", postData).then((res) => {
      toast.success("Cancellation Success Updated");
    });
  };
  // refund ends

  const { bookingSourceOptions } = useSelector(
    ({ bookingSourceOptionsReducer }) => bookingSourceOptionsReducer
  );

  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  );

  useEffect(() => {
    dispatch(getPaymentModeOptions(uniqueid));
    dispatch(getBookingSourceOptions(uniqueid));
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ ...twnButtonStyles.allPages, paddingTop: "7%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {bookingId ? (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    style={{ ...twnButtonStyles.xlFonts, marginTop: "1.5%" }}
                  >
                    Update Booking
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      marginTop: "2%",
                      color: "#f46d25",
                      fontWeight: "bold",
                    }}
                  >
                    {bookingId}
                  </Typography>
                </Stack>
              </>
            ) : (
              <Typography
                style={{
                  ...twnButtonStyles.xlFonts,
                  marginTop: window.innerWidth <= 768 ? "15%" : "-2%",
                }}
              >
                New Booking
              </Typography>
            )}

            {/* Basic Information Accordian Starts  */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              style={{ margin: "5px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                id="panel1a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.8%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5" style={{ padding: "0%" }}>
                  Basic Information
                </Typography>
              </AccordionSummary>

              {/* client details   */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Client Details
                  </Typography>
                  <Stack
                    direction={window.innerWidth <= 768 ? "column" : "row"}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Client Name*</span>
                      <Select
                        name="clientName"
                        defaultValue={formData.clientName}
                        value={clientList.filter(
                          (i) => i.value === formData.clientName
                        )}
                        isSearchable
                        options={clientList}
                        onChange={handleClientChange}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                        error={error}
                      />
                    </div>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "20px",
                        color: "#f46d25",
                        cursor: "pointer",
                        marginTop: window.innerWidth <= 768 ? "8px" : "5px",
                        position: window.innerWidth <= 768 && "absolute",
                        marginLeft: window.innerWidth <= 768 && "91%",
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="clientMobile"
                        label="Client Phone*"
                        value={formData.clientMobile}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="clientEmail"
                        label="Client Email*"
                        value={formData.clientEmail}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>

                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>
                        Destination City*
                      </span>
                      <Select
                        name="destinationCity"
                        isSearchable
                        value={
                          destinationCity
                            ? destinationCity.filter(
                                (i) => i.value === formData.destinationCity
                              )
                            : null
                        }
                        options={destinationCity}
                        onChange={handleCityChange}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                        error={error}
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Booking Source*</span>
                      <Select
                        options={bookingSourceOptions}
                        isSearchable
                        value={
                          bookingSourceOptions
                            ? bookingSourceOptions.filter(
                                (i) => i.value === formData.bookingSource
                              )
                            : null
                        }
                        onChange={(option) =>
                          setFormData({
                            name: "bookingSource",
                            value: option.value,
                          })
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                        error={error}
                      />
                    </div>
                  </Stack>
                  <Stack direction="row" spacing={5}>
                    <div>
                      <Checkbox
                        type="checkbox"
                        checked={formData.vaccinated}
                        onChange={(e) => {
                          setFormData({
                            name: "vaccinated",
                            value: e.target.checked,
                          });
                        }}
                        color="primary"
                      />
                      <label>Vaccinated</label>
                    </div>
                    <div>
                      <Checkbox
                        type="checkbox"
                        checked={formData.git}
                        onChange={(e) => {
                          setFormData({ name: "git", value: e.target.checked });
                        }}
                        color="primary"
                      />
                      <label>GIT</label>
                    </div>
                  </Stack>
                  {/* </Stack> */}
                </Stack>
              </AccordionDetails>

              {/* hotel details  */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Hotel Details
                  </Typography>
                  <Stack
                    direction={window.innerWidth <= 768 ? "column" : "row"}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Hotel Name*</span>
                      <Select
                        name="hotelName"
                        value={
                          hotelList
                            ? hotelList.filter(
                                (i) => i.label === formData.hotelName
                              )
                            : null
                        }
                        isSearchable
                        options={hotelList}
                        onChange={handleHotelChange}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                        error={error}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="starRating"
                        label="Star Category"
                        value={formData.starRating}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="hotelPhone"
                        label="Hotel Phone*"
                        value={formData.hotelPhone}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="hotelEmail"
                        label="Hotel Email*"
                        value={formData.hotelEmail}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="hotelAddress"
                        label="Hotel Address*"
                        value={formData.hotelAddress}
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                  </Stack>
                </Stack>
              </AccordionDetails>

              {/* leadpax details  */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Lead Pax
                  </Typography>
                  {bookingLeads.map((x, i) => (
                    <Stack
                      direction={window.innerWidth <= 768 ? "column" : "row"}
                      spacing={3}
                      justifyContent="space-between"
                      style={{
                        width: "100%",
                        marginBottom: window.innerWidth < 768 && "10%",
                      }}
                      key={i}
                    >
                      <>
                        <div style={{ display: "flex", width: "100%" }}>
                          <span>{i + 1}</span> &nbsp;
                          <CustomTextInlineField
                            name="name"
                            label="Name"
                            type="text"
                            value={x.name}
                            onChange={(e) => handleLeadChange(e, i)}
                          />
                        </div>
                      </>
                      <CustomTextInlineField
                        name="mobile"
                        label="Mobile No"
                        type="tel"
                        value={x.mobile}
                        onChange={(e) => handleLeadChange(e, i)}
                      />
                      <CustomTextInlineField
                        name="altMobile"
                        label="Alt Mobile"
                        type="tel"
                        value={x.altMobile}
                        onChange={(e) => handleLeadChange(e, i)}
                      />
                      <CustomTextInlineField
                        name="email"
                        label="Email"
                        type="email"
                        value={x.email}
                        onChange={(e) => handleLeadChange(e, i)}
                      />
                      <div
                        style={{
                          display: window.innerWidth < 768 && "flex",
                          marginTop: window.innerWidth < 768 && "0",
                          marginRight: window.innerWidth < 768 && "2%",
                        }}
                      >
                        {bookingLeads.length - 1 === i && (
                          <AddCircleOutlineIcon
                            size="small"
                            className={classes.plus}
                            style={{
                              marginTop: window.innerWidth < 768 && "0",
                              marginLeft: window.innerWidth < 768 && "90%",
                            }}
                            onClick={handleAddLeadPerson}
                          />
                        )}
                        {bookingLeads.length > 1 && (
                          <DeleteIcon
                            className={classes.plus}
                            onClick={() => handleRemoveLeadPerson(i)}
                            style={{
                              marginLeft:
                                window.innerWidth < 768 &&
                                bookingLeads.length - 1 !== i &&
                                "95%",
                            }}
                          />
                        )}
                      </div>
                    </Stack>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
            {/* Basic Information Accordian Ends  */}

            {/* Booking Information Accordian Starts  */}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              style={{ margin: "5px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                id="panel1a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.8%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5" style={{ padding: "0%" }}>
                  Booking Information
                </Typography>
              </AccordionSummary>

              {/* Checkin,Checkout,Nights Starts  */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack
                  direction={window.innerWidth < 768 ? "column" : "row"}
                  spacing={2}
                  justifyContent="space-around"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <DatePicker
                    animateYearScrolling
                    autoOk="true"
                    format="dd-MM-yyyy"
                    fullWidth
                    inputVariant="outlined"
                    label="Check-In"
                    minDate={minCheckInDate}
                    onChange={(e) => handleFormDateChange("checkIn", e)}
                    required
                    size="small"
                    value={
                      formData.checkIn
                        ? parse(formData.checkIn, "yyyy-MM-dd", new Date())
                        : null
                    }
                    variant="inline"
                  />
                  <DatePicker
                    required
                    label="Check-Out"
                    size="small"
                    fullWidth
                    inputVariant="outlined"
                    format="dd-MM-yyyy"
                    animateYearScrolling
                    // onChange={(e) => setFormData({ name: 'checkOut', value: e })}
                    value={
                      formData.checkOut
                        ? parse(formData.checkOut, "yyyy-MM-dd", new Date())
                        : null
                    }
                    onChange={(e) => handleFormDateChange("checkOut", e)}
                    variant="inline"
                    autoOk="true"
                    minDate={checkin1}
                  />
                  <CustomTextInlineField
                    name="noOfNight"
                    label="No. of Nights"
                    value={daysDiff()}
                  />
                </Stack>
              </AccordionDetails>
              {mlos && (
                <div>
                  <small>
                    <em style={{ color: "red" }}>
                      Note : Min. length of stay should be {">"}= {mlos}
                    </em>
                  </small>
                </div>
              )}
              {/* Checkin,Checkout,Nights Ends  */}

              {/* Room Details Starts  */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack style={{ width: "100%", marginTop: "10px" }}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Room Details
                  </Typography>
                  {roomCategories.map((x, i) => (
                    <div key={i}>
                      <div style={{ textAlign: "right" }}>
                        {roomCategories.length > 1 && (
                          <DeleteIcon
                            className={classes.plus}
                            onClick={() => handleRemoveRoomPerson(i)}
                          />
                        )}
                        {roomCategories.length - 1 === i && (
                          <AddCircleOutlineIcon
                            size="small"
                            className={classes.plus}
                            onClick={handleAddRoomPerson}
                          />
                        )}
                      </div>

                      <Stack
                        direction={window.innerWidth < 768 ? "column" : "row"}
                        spacing={2}
                        justifyContent="space-between"
                        style={{ width: "100%", marginTop: "5px" }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        >
                          <span className={classes.topBorder}>Room Type*</span>
                          <Select
                            name="roomType"
                            isSearchable
                            // placeholder='Room Type'
                            value={
                              x.roomType
                                ? RoomDisplayName.filter(
                                    (i) => i.value === x.roomType
                                  )
                                : null
                            }
                            options={RoomDisplayName}
                            onChange={(e) =>
                              handleRoomOptionsChange("roomType", e.value, i)
                            }
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            styles={selectStyle}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        >
                          <span className={classes.topBorder}>Meal Plan*</span>
                          <Select
                            name="mealplan"
                            value={
                              x.mealplan
                                ? mealPlanOptions.filter(
                                    (i) => i.value === x.mealplan
                                  )
                                : null
                            }
                            options={mealPlanOptions}
                            isSearchable
                            placeholder="Meal Plan"
                            onChange={(e) =>
                              handleRoomOptionsChange("mealplan", e.value, i)
                            }
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            styles={selectStyle}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <CustomTextInlineField
                            name="adult"
                            label="Adults"
                            required
                            type="number"
                            value={x.adult}
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onChange={(e) => handleRoomChange(e, i)}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <CustomTextInlineField
                            name="child"
                            label="Children*"
                            type="number"
                            value={x.child}
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onChange={(e) => handleRoomChange(e, i)}
                          />
                        </div>
                      </Stack>
                      <Stack
                        direction={window.innerWidth < 768 ? "column" : "row"}
                        spacing={2}
                        justifyContent="space-between"
                        style={{ marginTop: "10px" }}
                      >
                        {formData.git && (
                          <CustomTextInlineField
                            name="rooms"
                            label="No. of Rooms"
                            value={x.rooms}
                            type="number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onChange={(e) => {
                              handleRoomChange(e, i);
                              handleRoomOptionsChange(
                                "totalNetPrice",
                                x.netPrice * daysDiff() * x.rooms,
                                i
                              );
                            }}
                          />
                        )}

                        <CustomTextInlineField
                          name="sellingPrice"
                          label="Selling Price*"
                          type="number"
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          value={x.sellingPrice}
                          onChange={(e) => handleRoomChange(e, i)}
                        />

                        <CustomTextInlineField
                          name="netPrice"
                          label="Net to Hotel per Night*"
                          value={x.netPrice}
                          type="number"
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onChange={(e) => {
                            handleRoomChange(e, i);
                            handleRoomOptionsChange(
                              "totalNetPrice",
                              x.netPrice * daysDiff() * x.rooms,
                              i
                            );
                          }}
                        />

                        <CustomTextInlineField
                          name="totalNetPrice"
                          label="Total Net Price"
                          type="number"
                          value={
                            formData.checkOut
                              ? x.netPrice * daysDiff() * x.rooms
                              : 0
                          }
                          disabled={true}
                        />
                        {formData.git ? null : <Grid container></Grid>}
                      </Stack>
                    </div>
                  ))}
                </Stack>
              </AccordionDetails>
              {/* Room Details Ends  */}

              {/* Inclusion Starts  */}
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Inclusion
                  </Typography>
                  {bookingInclusions.map((x, i) => (
                    <Stack
                      direction={window.innerWidth < 768 ? "column" : "row"}
                      spacing={2}
                      key={i}
                    >
                      <CustomTextInlineField
                        name="inclusion"
                        label="Inclusion Name"
                        type="text"
                        value={x.inclusion}
                        onChange={(e) => handleInclusionsChange(e, i)}
                      />
                      <CustomTextInlineField
                        name="sellingPrice"
                        label="Inclusion Selling Price"
                        type="number"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        required
                        value={x.sellingPrice}
                        onChange={(e) => handleInclusionsChange(e, i)}
                      />
                      <CustomTextInlineField
                        name="vendorPrice"
                        label="Net to Vendor"
                        type="number"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        value={x.vendorPrice}
                        onChange={(e) => handleInclusionsChange(e, i)}
                      />
                      <div
                        style={{
                          display: window.innerWidth < 768 && "flex",
                          marginLeft: window.innerWidth < 768 && "85%",
                          marginTop: window.innerWidth < 768 && "0",
                        }}
                      >
                        {bookingInclusions.length - 1 === i && (
                          <AddCircleOutlineIcon
                            size="small"
                            className={classes.plus}
                            onClick={handleAddInclusionPerson}
                          />
                        )}{" "}
                        {bookingInclusions.length > 1 && (
                          <DeleteIcon
                            className={classes.plus}
                            onClick={() => handleRemoveInclusionPerson(i)}
                            style={{
                              marginLeft:
                                window.innerWidth < 768 &&
                                bookingInclusions.length - 1 !== i &&
                                "60%",
                            }}
                          />
                        )}
                      </div>
                    </Stack>
                  ))}
                </Stack>
              </AccordionDetails>
              {/* Inclusion Ends  */}
            </Accordion>
            {/* Booking Information Accordian Ends  */}

            {/* Payment Details Starts  */}
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              style={{ margin: "5px 0", backgroundColor: "#eee" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                id="panel1a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.8%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5" style={{ padding: "0%" }}>
                  Payment Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Payment Breakup
                  </Typography>
                  <Stack
                    direction={window.innerWidth <= 768 ? "column" : "row"}
                    spacing={5}
                  >
                    {/* left side fields  */}
                    <Grid
                      item
                      lg={6}
                      style={{
                        display: window.innerWidth <= 768 && "flex",
                        flexDirection: window.innerWidth <= 768 && "column",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-11%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                              },
                            }}
                            label={"Total Room Rent (R)"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="totalRoomNetAmount"
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              marginTop: window.innerWidth <= 768 && "-31%",
                              width: window.innerWidth <= 768 && "80%",
                            }}
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            value={formData.totalRoomSellingAmount || ""}
                            autoComplete="off"
                            disabled
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#f46d25",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-11%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                                marginTop: window.innerWidth <= 768 && "-5%",
                              },
                            }}
                            label={"Total Inclusion Amount (I)"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="totalInclusionAmount"
                            value={formData.totalInclusionAmount || ""}
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              marginTop: window.innerWidth <= 768 && "-31%",
                              width: window.innerWidth <= 768 && "80%",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            autoComplete="off"
                            disabled
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#f46d25",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                          />
                        </Grid>{" "}
                      </Grid>
                      {bookingId ? (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginBottom: window.innerWidth <= 768 && "-11%",
                          }}
                        >
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#4e8c50",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "#f46d25",
                                },
                              }}
                              label={"Total Amount Recieved"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              name="totalAmountPaid"
                              value={totalAmountPaid}
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#ffe2d7",
                                marginLeft:
                                  window.innerWidth <= 768 ? "99%" : "-20px",
                                marginTop: window.innerWidth <= 768 && "-31%",
                                width: window.innerWidth <= 768 && "80%",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginBottom: window.innerWidth <= 768 && "-11%",
                          }}
                        >
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#138128",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "#f46d25",
                                  marginTop: window.innerWidth <= 768 && "-5%",
                                },
                              }}
                              label={"Total Booking Amount (R+I)"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              name="totalBookingAmount"
                              value={formData.totalBookingAmount || ""}
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#ffe2d7",
                                marginLeft:
                                  window.innerWidth <= 768 ? "99%" : "-20px",
                                marginTop: window.innerWidth <= 768 && "-31%",
                                width: window.innerWidth <= 768 && "80%",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {/* right side fields  */}
                    <Grid item lg={6}>
                      {bookingId ? (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginBottom: window.innerWidth <= 768 && "-11%",
                          }}
                        >
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#138128",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "#464141",
                                },
                              }}
                              label={"Total Booking Amount (R+I)"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                            <TextField
                              name="totalBookingAmount"
                              value={formData.totalBookingAmount || ""}
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#ffe2d7",
                                marginLeft:
                                  window.innerWidth <= 768 ? "99%" : "-20px",
                                marginTop: window.innerWidth <= 768 && "-31%",
                                width: window.innerWidth <= 768 && "80%",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-13%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#282828",
                              borderColor: "#a1a1a1",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#464141",
                              },
                            }}
                            label={"Net Payout (H+V)"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            // name="netValue"
                            variant="outlined"
                            fullWidth
                            size="small"
                            style={{
                              borderColor: "#a1a1a1",
                              backgroundColor: "#ffe2d7",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              marginTop: window.innerWidth <= 768 && "-31%",
                              width: window.innerWidth <= 768 && "80%",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            value={formData.totalNetAmount || ""}
                            autoComplete="off"
                            disabled
                          />
                          {/* <p className="errors">
                                                {formError.totalNetPrice}
                                            </p> */}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-11%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#4e8c50",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#067d04",
                              },
                            }}
                            label={"Commission"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="profitValue"
                            value={formData.profit || ""}
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#deffde",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              marginTop: window.innerWidth <= 768 && "-31%",
                              width: window.innerWidth <= 768 && "80%",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            autoComplete="off"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#00a300",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </AccordionDetails>

              {/* Transaction Summary Starts  */}
              <AccordionDetails>
                <Stack style={{ width: "100%" }} spacing={2}>
                  <Typography variant="h6" style={{ color: "#f46d25" }}>
                    Transaction Summary
                  </Typography>
                  <Stack
                    direction={window.innerWidth <= 768 ? "column" : "row"}
                    spacing={5}
                  >
                    <Grid item lg={6}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-13%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                              },
                            }}
                            label={"Amount Received"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="paidAmount"
                            variant="outlined"
                            fullWidth
                            type="number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              width: window.innerWidth <= 768 && "80%",
                              marginTop: window.innerWidth <= 768 && "-31%",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            value={formData.paidAmount}
                            defaultValue={0}
                            onChange={(e) => {
                              handleFormChange(e);
                              setAgentBookingPayments(e);
                              amountrecfun(e);
                            }}
                            autoComplete="off"
                            // type="number"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-10%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                                marginTop: window.innerWidth <= 768 && "-5%",
                              },
                            }}
                            label={"Balance Payable on Arrival (BPAH)"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="balancePayableHotel"
                            value={formData.balancePayableHotel}
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              width: window.innerWidth <= 768 && "80%",
                              marginTop: window.innerWidth <= 768 && "-31%",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            disabled
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="number"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-7%",
                        }}
                      >
                        {" "}
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#464141",
                              },
                            }}
                            label={"Payment Mode"}
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          style={{
                            marginLeft:
                              window.innerWidth <= 768 ? "0" : "-20px",
                          }}
                          xs={window.innerWidth <= 768 && 7}
                        >
                          <div
                            style={{
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              width: window.innerWidth <= 768 && "80%",
                              marginTop: window.innerWidth <= 768 && "-31%",
                            }}
                          >
                            <Select
                              options={paymentModeOptions}
                              onChange={(option) =>
                                setFormData({
                                  name: "paymentMode",
                                  value: option.label,
                                })
                              }

                              // value={formData.paymentMode ? paymentModeOptions.filter(i => i.label === formData.paymentMode) : null}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginBottom: window.innerWidth <= 768 && "-10%",
                        }}
                      >
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#282828",
                              borderColor: "#a1a1a1",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#464141",
                              },
                            }}
                            label={"Reference Number"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6} xs={window.innerWidth <= 768 && 7}>
                          <TextField
                            name="paymentRefNo"
                            // value={formData.paymentRefNo || ""}
                            onChange={handleFormChange}
                            style={{
                              borderColor: "#a1a1a1",
                              marginLeft:
                                window.innerWidth <= 768 ? "99%" : "-20px",
                              width: window.innerWidth <= 768 && "80%",
                              marginTop: window.innerWidth <= 768 && "-31%",
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid item lg={6}>
                    <Stack direction="row" spacing={5}>
                      <div
                        style={{
                          display: window.innerWidth <= 768 && "flex",
                          justifyContent: window.innerWidth <= 768 && "center",
                          alignItems: window.innerWidth <= 768 && "center",
                        }}
                      >
                        <Checkbox
                          type="checkbox"
                          checked={paymentType === "Full" ? true : false}
                          onClick={(e) => {
                            balanceHotelPayableFull();
                          }}
                          // onClick={() => setPaymentType("Full")}
                          style={{
                            color: "#f46d25",
                            fontSize: "18px",
                          }}
                        />
                        <label style={{ fontSize: "18px" }}>Full Payment</label>
                      </div>
                      <div
                        style={{
                          display: window.innerWidth <= 768 && "flex",
                          justifyContent: window.innerWidth <= 768 && "center",
                          alignItems: window.innerWidth <= 768 && "center",
                        }}
                      >
                        <Checkbox
                          type="checkbox"
                          checked={paymentType === "Partial" ? true : false}
                          onClick={(e) => {
                            setFormData({
                              name: "paymentType",
                              value: "Partial",
                            });
                            setPaymentType("Partial");
                          }}
                          // onClick={() => setPaymentType("Partial")}
                          style={{
                            color: "#f46d25",
                            fontSize: "15px",
                          }}
                        />
                        <label style={{ fontSize: "18px" }}>
                          Partial Payment
                        </label>
                      </div>
                    </Stack>
                  </Grid>
                  {paymentType === "Full" ? null : (
                    <Grid container spacing={2}>
                      <Grid item lg={3}>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            marginLeft: "10px",
                            color: "#138128",
                            borderColor: "#deffde",
                          }}
                          InputLabelProps={{
                            shrink: false,
                            style: {
                              color: "#f46d25",
                            },
                          }}
                          label={"Balance Payable"}
                          disabled
                        />
                      </Grid>
                      <Grid item lg={3}>
                        <TextField
                          name="partialPayment"
                          value={formData.partialPayment || ""}
                          type="number"
                          variant="outlined"
                          style={{
                            backgroundColor: "#ffe2d7",
                            marginLeft: "-20px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          onChange={(e) => {
                            handleFormChange(e);
                            balanceHotelPayablePartial(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Stack>
              </AccordionDetails>
              {/* Transaction Summary Ends  */}

              {/* Transcation Table  */}
              {bookingId && formData.agentBookingPayments && (
                <AccordionDetails
                  style={{
                    border: "1px solid #111",
                    margin: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <span
                        style={{
                          color: "#f46d25",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Date
                      </span>
                    </Grid>
                    <Grid item xs={3}>
                      <span
                        style={{
                          color: "#f46d25",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Amount Recieved
                      </span>
                    </Grid>
                    <Grid item xs={3}>
                      <span
                        style={{
                          color: "#f46d25",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Payment Mode
                      </span>
                    </Grid>
                    <Grid item xs={3}>
                      <span
                        style={{
                          color: "#f46d25",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Reference Number
                      </span>
                    </Grid>
                    {formData.agentBookingPayments.map((each, index) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={3}
                            style={{
                              color: "#111",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {each.createdAt
                              ? format(
                                  parse(
                                    each.createdAt,
                                    "yyyy-MM-dd HH:mm:ss",
                                    new Date()
                                  ),
                                  "dd MMM yy, hh:mm a"
                                )
                              : null}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              color: "#111",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {each.paidAmount}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              color: "#111",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {each.paymentMode}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              color: "#111",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {each.paymentRefNo}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              )}
            </Accordion>
            {/* Payment Details Ends  */}

            {/* Terms and Conditions Starts */}
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              style={{ marginTop: "0.3%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                aria-controls="panel4a-content"
                id="panel4a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.5%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5">Terms and Conditions</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <TextareaAutosize
                  name="teamCondition"
                  onChange={setAgentBkngTrmCndData}
                  defaultValue={agentBkngTrmCndData.teamCondition}
                  style={{
                    maxWidth: "99.5%",
                    minHeight: "4em",
                    minWidth: "99.4%",
                    fontSize: "15px",
                    borderColor: "#f46d25",
                  }}
                />
              </AccordionDetails>
            </Accordion>
            {/* Terms and Conditions Ends */}

            {/* Cancellation Policy Starts */}
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              style={{ marginTop: "0.3%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                aria-controls="panel5a-content"
                id="panel5a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.5%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5">Cancellation Policy</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <TextareaAutosize
                  name="cancellationPolicy"
                  onChange={setAgentBkngTrmCndData}
                  defaultValue={agentBkngTrmCndData.cancellationPolicy}
                  style={{
                    maxWidth: "99.5%",
                    minHeight: "4em",
                    minWidth: "99.4%",
                    fontSize: "15px",
                    borderColor: "#f46d25",
                  }}
                />
              </AccordionDetails>
            </Accordion>
            {/* Cancellation Policy Ends */}

            {/* Refund Details Starts  */}
            {showRefund ? (
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                style={{ marginTop: "0.3%" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  id="panel6a-header"
                  style={{
                    backgroundColor: "#f46d25",
                    color: "#fff",
                    marginTop: "0.5%",
                    height: "0.5em",
                  }}
                >
                  <Typography variant="h5">Refund Details</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#eee" }}>
                  <Stack style={{ width: "100%" }} spacing={2}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#f46d25",
                        paddingTop: "1%",
                        lineHeight: "0",
                      }}
                    >
                      Refund
                    </Typography>
                    <Stack direction="row">
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                              },
                            }}
                            label={"Amount Refunded"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <TextField
                            name="amountRefunded"
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft: "-20px",
                            }}
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            value={amountRefunded}
                            disabled
                            autoComplete="off"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#f46d25",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "#f46d25",
                              },
                            }}
                            label={"Amount Retained"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <TextField
                            name="amountRetained"
                            value={amountRetained}
                            disabled={true}
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#ffe2d7",
                              marginLeft: "-20px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            autoComplete="off"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#f46d25",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              color: "#138128",
                              borderColor: "#deffde",
                            }}
                            InputLabelProps={{
                              shrink: false,
                              style: {
                                color: "rgb(19, 129, 40)",
                              },
                            }}
                            label={"Revised Commission"}
                            disabled
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <TextField
                            name="revisedCommission"
                            value={revisedCommission}
                            disabled
                            variant="outlined"
                            fullWidth
                            style={{
                              backgroundColor: "#deffde",
                              marginLeft: "-20px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            autoComplete="off"
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#fff",
                                background: "#f46d25",
                                borderRadius: "4px",
                                padding: "2px 4px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* Refund Details Ends  */}
            <br />
            <Stack direction="row" spacing={2} justifyContent="center">
              {validateForm(error) &&
              validateAllFields() &&
              showRefund === false ? (
                window.innerWidth <= 768 ? (
                  <Button
                    style={
                      isClicked
                        ? twnButtonStyles.disabledBtn
                        : twnButtonStyles.orangeBtn
                    }
                    onClick={onSubmitFun}
                  >
                    Submit
                  </Button>
                ) : (
                  <button
                    onClick={() => setOpenPreview(true)}
                    style={twnButtonStyles.orangeBtn}
                  >
                    Preview
                  </button>
                )
              ) : (
                <button style={twnButtonStyles.disabledBtn}>Preview</button>
              )}
              {bookingId && showRefund === false && (
                <button
                  style={twnButtonStyles.orangeBtn}
                  onClick={() => {
                    setOpenRefund(true);
                  }}
                >
                  Cancel Booking
                </button>
              )}
              <Link
                to={{ pathname: "/konnect/agentBookings" }}
                style={twnButtonStyles.linkBlackBtn}
              >
                Cancel
              </Link>
            </Stack>
            <br />
            <BookingClientForm
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              selectedId={selectedId}
              BookingLocal={"yes"}
            />
          </MuiPickersUtilsProvider>

          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            sx={{ maxHeight: "100vh" }}
            open={openPreview}
            onClose={() => setOpenPreview(false)}
            TransitionComponent={Transition}
          >
            <div>
              <AgentBookingPreview
                data={{
                  ...formData,
                  bookingLeads: bookingLeads,
                  roomCategories: roomCategories,
                  bookingInclusions: bookingInclusions,
                  packageTeamCondition: agentBkngTrmCndData,
                }}
              />
            </div>
            <div style={{ backgroundColor: "#eee" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                style={{ margin: "1%" }}
              >
                {isClicked ? (
                  <Button style={twnButtonStyles.disabledBtn} disabled>
                    Submit
                  </Button>
                ) : (
                  <Button
                    style={twnButtonStyles.orangeBtn}
                    onClick={onSubmitFun}
                  >
                    Submit
                  </Button>
                )}
                <Button
                  style={twnButtonStyles.blackBtn}
                  autoFocus
                  color="inherit"
                  onClick={() => setOpenPreview(false)}
                >
                  Edit
                </Button>
              </Stack>
            </div>
          </Dialog>

          <AgentBookingRefund
            openRefund={openRefund}
            closeFn={() => setOpenRefund(false)}
            handleRefund={handleRefund}
            totalBookingAmount={formData.totalBookingAmount}
            totalAmountPaid={totalAmountPaid}
            profit={formData.profit}
            amountRefunded={amountRefunded}
            setAmountRefunded={setAmountRefunded}
            amountRetained={amountRetained}
            setAmountRetained={setAmountRetained}
            revisedCommission={revisedCommission}
            setRevisedCommission={setRevisedCommission}
          />
        </div>
      )}
    </>
  );
}

function CustomTextInlineField(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          name={props.name}
          fullWidth={props.fullWidth}
          type={props.type}
          label={props.label}
          value={props.value}
          required={props.required}
          variant="outlined"
          size="small"
          style={{ backgroundColor: "#fff" }}
          onChange={props.onChange}
          disabled={props.disabled}
          onWheel={props.onWheel}
          onKeyDown={props.onKeyDown}
        />
      </Grid>
      {props.error[props.name] && props.error[props.name].length > 0 && (
        <Grid item xs={12}>
          <span style={{ color: "#ff0000" }}>{props.error[props.name]}</span>
        </Grid>
      )}
    </Grid>
  );
}

CustomTextInlineField.defaultProps = {
  fullWidth: true,
  disabled: false,
  error: {},
};
