import { ReactComponent as FacebookIcon } from "../../assets/icons/header-icons/facebook.svg"
import { ReactComponent as InstagramIcon } from "../../assets/icons/header-icons/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../assets/icons/header-icons/twitter.svg"
import { ReactComponent as YoutubeIcon } from "../../assets/icons/header-icons/youtube.svg"

export const headerIconsSrc = [
  {
    id: 1,
    src: FacebookIcon,
    alt: "facebook",
    link: "https://www.facebook.com/ExploreWithTowno",
  },
  {
    id: 2,
    src: InstagramIcon,
    alt: "instagram",
    link: "https://www.instagram.com/explorewithtowno/",
  },
  {
    id: 3,
    src: TwitterIcon,
    alt: "twitter",
    link: "https://www.twitter.com/",
  },
  {
    id: 4,
    src: YoutubeIcon,
    alt: "youtube",
    link: "https://www.youtube.com/@explorewithtowno",
  },
]
