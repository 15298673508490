import { addDays } from "date-fns"
import { ActionTypes } from "../actions/actionTypes"
import { CHECKOUT_ACTION_TYPES } from "../types/checkout.types"

export const searchPropertiesReducer = (
  state = {
    location: { label: "", value: "" },
    propertyId: { label: "", value: "" },
    checkinFrom: new Date(),
    checkinTo: addDays(new Date(), 1),
    rooms: 1,
    children: 1,
    adults: 1,
    infant: 1,
    openModal: false,
    hotelRoomList: [],
    loading: false,
  },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.HANDLE_CHANGE_VALUES:
      return { ...state, [payload.name]: payload.value }
    case ActionTypes.TOGGLE_MODAL_PROPERTY_SEARCH:
      return { ...state, openModal: !state.openModal }
    case ActionTypes.RESET_SEARCH_PROPERTY_PARAMS:
      return {
        location: "",
        propertyId: "",
        checkinFrom: new Date(),
        checkinTo: addDays(new Date(), 1),
        rooms: 1,
        children: 1,
        infant: 1,
        adults: 1,
        openModal: false,
        hotelRoomList: [],
        loading: false,
      }
    default:
      return state
  }
}

export const checkoutRoomsReducer = (
  state = {
    propertyId: null,
    checkoutRoomDetails: [],
    paymentDetails: {},
    totalBookingAmount: 0,
    totalRooms: 0,
    fetching: false,
  },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case CHECKOUT_ACTION_TYPES.FETCHING_BOOKING_DETAILS:
      return { ...state, fetching: true }
    case CHECKOUT_ACTION_TYPES.SET_CHECKOUT_PROPERTYID:
      return { ...state, propertyId: payload.propertyId }
    case CHECKOUT_ACTION_TYPES.SET_CHECKOUT_ROOMS:
      return {
        ...state,
        checkoutRoomDetails: payload.checkoutRoomDetails,
        totalBookingAmount: payload.totalAmount,
        totalRooms: payload.totalRooms,
      }
    case CHECKOUT_ACTION_TYPES.RESET_CHECKOUT_ROOMS:
      return {
        propertyId: null,
        checkoutRoomDetails: [],
        paymentDetails: {},
        totalBookingAmount: 0,
        totalRooms: 0,
        fetching: false,
      }

    case CHECKOUT_ACTION_TYPES.BOOKING_DETAILS_SUCCESS:
      return { ...state, ...payload, fetching: false }
    default:
      return state
  }
}

/** 
const postBody = {
  propertyId: null,
  checkIn: null,
  checkOut: null,
  noOfNights: 0,
  totalRooms: 0,
  checkoutRoomDetails: [
    {
      roomName: "",
      mealPlan: "",
      adults: 0,
      children: 0,
      roomRent: 0,
      noOfRooms: 0,
    },
  ],
  paymentDetails: {
    razorpay_payment_id: "pay_PAPWpmRuivTggj",
    razorpay_order_id: "order_PAPWis87pOalQB",
    razorpay_signature:
      "e890fe2fca60e99230ae5e4a3f5ce4683a8fc9ceb9cc747c3f98b878955d4b5e",
  },
  totalBookingAmount: 0,
  guestName: "", //*
  guestMobileNo: "", //*
  guestEmail: "", //*
  additionalGuestInfo: [{ name: "", mobileNo: "" }],
}
// * marked variables are mandatory. Please provide backend validations for these variables.*/
