import { NavLink } from "react-router-dom"
import styles from "./AsideDestinationNavbar.module.css"

const links = [
  {
    title: "Tourism",
    // isHeader: true,
    navLink: "/uttarakhand/uttarakhand-tourism",
  },
  {
    title: "Places To Visit",
    navLink: "/uttarakhand/places-to-visit-in-uttarakhand",
  },
  { title: "Tour Packages", navLink: "/uttarakhand/uttarakhand-tour-packages" },
  {
    title: "Best Time To Visit",
    navLink: "/uttarakhand/best-time-to-visit-uttarakhand",
  },
  {
    title: "Things To Do",
    navLink: "/uttarakhand/things-to-do-in-uttarakhand",
  },
  {
    title: "How To Reach",
    // isHeader: true,
    navLink: "/uttarakhand/how-to-reach-uttarakhand",
  },
  { title: "Key Facts", navLink: "/uttarakhand/key-facts-about-uttarakhand" },
  {
    title: "Shopping & Entertainment",
    navLink: "/uttarakhand/shopping-and-entertainment-in-uttarakhand",
  },
  { title: "Cuisine", navLink: "/uttarakhand/uttarakhand-cuisine" },
  {
    title: "Things To Carry",
    navLink: "/uttarakhand/things-to-carry-while-traveling-to-uttarakhand",
  },
  {
    title: "Festivals and Events",
    navLink: "/uttarakhand/uttarakhand-festivals-and-events",
  },
]

const AsideDestinationNavbar = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.ul}>
        {links.map((link) => (
          <li key={link.title} className={styles.link}>
            <NavLink
              exact
              to={link?.navLink}
              className={(isActive) =>
                `${styles.navLink} ${isActive ? styles.active : ""}`
              }
            >
              {link.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AsideDestinationNavbar
