import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { AddCircleOutline, DeleteForeverOutlined } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { DatePicker } from "@material-ui/pickers"
import { Grid } from "@mui/material"
import { addDays } from "date-fns"
import { ErrorMessage, Field, FieldArray } from "formik"

const TransferByFlightForm = ({ data, setFieldValue, styles }) => {
  const uniqueId = localStorage.getItem("unique_id")
  const FlightTripOption = [
    { label: "Oneway", value: "Oneway" },
    { label: "Round", value: "Round" },
  ]

  return (
    <FieldArray
      name="flightDetails"
      render={(arrayHelpers) => (
        <Grid container rowSpacing={2}>
          {data?.map((flight, index) => (
            <Grid item container md={12} spacing={1} key={index}>
              <Grid item md={12} textAlign="end">
                {data?.length !== 1 && (
                  <IconButton
                    size="small"
                    onClick={() => arrayHelpers.remove(index)}
                    style={{ padding: "0rem" }}
                  >
                    <DeleteForeverOutlined color="primary" />
                  </IconButton>
                )}
                {data.length - 1 == index && (
                  <IconButton
                    style={{ padding: "0rem" }}
                    size="small"
                    onClick={() => {
                      arrayHelpers.push({
                        id: null,
                        uniqueId,
                        transferId: "",
                        flightTrip: "",
                        flightName: "",
                        flightFrom: "",
                        flightTo: "",
                        flightDepartDate: null,
                        flightReturnDate: null,
                        flightPnr: "",
                        flightAdults: 0,
                        flightChild: 0,
                        flightInclusion: 0,
                        flightAmount: 0,
                        flightComission: 0,
                      })
                    }}
                  >
                    <AddCircleOutline
                      color="primary"
                      style={{ padding: "0rem" }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid item container md={12}>
                <Grid item md={0.2}>
                  {index + 1}.
                </Grid>
                <Grid item container md={11.8} spacing={2}>
                  <Grid item md={3}>
                    <Field
                      as={Autocomplete}
                      options={FlightTripOption}
                      getOptionLabel={(option) => option.label}
                      size="small"
                      value={FlightTripOption.find(
                        (f) => f.value == flight.flightTrip
                      )}
                      onChange={(event, option) => {
                        if (option.value == "Oneway") {
                          setFieldValue(
                            `flightDetails[${index}].flightReturnDate`,
                            null
                          )
                        }
                        setFieldValue(
                          `flightDetails[${index}].flightTrip`,
                          option.value
                        )
                      }}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          label="Trip"
                          variant="outlined"
                          fullWidth
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightName`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Flight Name"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightFrom`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="From"
                      required
                    />
                    <ErrorMessage name={`flightDetails[${index}].flightFrom`}>
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightTo`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="To"
                      required
                    />
                    <ErrorMessage name={`flightDetails[${index}].flightTo`}>
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={3}>
                    <Field
                      as={DatePicker}
                      required
                      label="Departure Date"
                      inputVariant="outlined"
                      name={`flightDetails[${index}].flightDepartDate`}
                      fullWidth
                      size="small"
                      value={flight.flightDepartDate}
                      onChange={(date) => {
                        setFieldValue(
                          `flightDetails[${index}].flightDepartDate`,
                          date
                        )
                      }}
                      minDate={new Date(flight?.flightDepartDate)}
                      animateYearScrolling
                      format="dd/MM/yyyy"
                      variant="inline"
                      disablePast
                      autoOk="true"
                    />
                    <ErrorMessage
                      name={`flightDetails[${index}].flightDepartDate`}
                    >
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>

                  {flight.flightTrip === "Round" ? (
                    <Grid item md={3}>
                      <Field
                        as={DatePicker}
                        label="Return Date"
                        inputVariant="outlined"
                        name={`flightDetails[${index}].flightReturnDate`}
                        fullWidth
                        size="small"
                        value={flight.flightReturnDate}
                        onChange={(date) => {
                          setFieldValue(
                            `flightDetails[${index}].flightReturnDate`,
                            date
                          )
                        }}
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        disablePast
                        autoOk="true"
                        required={flight.flightTrip == "Round"}
                        minDate={addDays(new Date(flight.flightDepartDate), 1)}
                      />
                      <ErrorMessage
                        name={`flightDetails[${index}].flightReturnDate`}
                      >
                        {(msg) => <div className={styles.error}>{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  ) : null}

                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightPnr`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="PNR No."
                      required
                    />
                    <ErrorMessage name={`flightDetails[${index}].flightPnr`}>
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightAdults`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Adults"
                      required
                      onFocus={() => {
                        if (flight?.flightAdults == "0") {
                          setFieldValue(
                            `flightDetails[${index}].flightAdults`,
                            null
                          )
                        }
                      }}
                      onBlur={() => {
                        if (flight?.flightAdults == null) {
                          setFieldValue(
                            `flightDetails[${index}].flightAdults`,
                            0
                          )
                        }
                      }}
                      type="number"
                    />
                    <ErrorMessage name={`flightDetails[${index}].flightAdults`}>
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>

                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightChild`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Children"
                    />
                  </Grid>

                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightInclusion`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Inclusion"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightAmount`}
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      label="Amount"
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage name={`flightDetails[${index}].flightAmount`}>
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>

                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      name={`flightDetails[${index}].flightComission`}
                      fullWidth
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Commission"
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name={`flightDetails[${index}].flightComission`}
                    >
                      {(msg) => <div className={styles.error}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    />
  )
}

export default TransferByFlightForm
