export const ActionTypes = {
  LEAD_COUNT_DATA: "LEAD_COUNT_DATA", //websocket
  TOGGLE_CLONE_T_AND_C: "TOGGLE_CLONE_T_AND_C",
  TOGGLE_CLONE_DIALOG: "TOGGLE_CLONE_DIALOG",
  FETCH_PAYMENT_MODE_OPTIONS: "FETCH_PAYMENT_MODE_OPTIONS",
  FETCH_BOOKING_SOURCE_OPTIONS: "FETCH_BOOKING_SOURCE_OPTIONS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGOUT: "USER_LOGOUT",
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  BRANCH_CREATE_REQUEST: "BRANCH_CREATE_REQUEST",
  BRANCH_CREATE_SUCCESS: "BRANCH_CREATE_SUCCESS",
  BRANCH_LIST_REQUEST: "BRANCH_LIST_REQUEST",
  BRANCH_LIST_SUCCESS: "BRANCH_LIST_SUCCESS",
  BRANCH_UPDATE_REQUEST: "BRANCH_UPDATE_REQUEST",
  BRANCH_UPDATE_SUCCESS: "BRANCH_UPDATE_SUCCESS",
  BRANCH_DETAIL_REQUEST: "BRANCH_DETAIL_REQUEST",
  BRANCH_DETAIL_SUCCESS: "BRANCH_DETAIL_SUCCESS",
  BRANCH_CLEAR: "BRANCH_CLEAR",
  CLIENT_LIST_REQUEST: "CLIENT_LIST_REQUEST",
  CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
  CLIENT_CREATE_REQUEST: "CLIENT_CREATE_REQUEST",
  CLIENT_CREATE_SUCCESS: "CLIENT_CREATE_SUCCESS",
  CLIENT_UPDATE_REQUEST: "CLIENT_UPDATE_REQUEST",
  CLIENT_UPDATE_SUCCESS: "CLIENT_UPDATE_SUCCESS",
  CLIENT_DETAIL_REQUEST: "CLIENT_DETAIL_REQUEST",
  CLIENT_DETAIL_SUCCESS: "CLIENT_DETAIL_SUCCESS",
  CLIENT_CLEAR: "BRANCH_CLEAR",

  HANDLE_CHANGE_VALUES: "HANDLE_CHANGE_VALUES",
  TOGGLE_MODAL_PROPERTY_SEARCH: "TOGGLE_MODAL_PROPERTY_SEARCH",
  RESET_SEARCH_PROPERTY_PARAMS: "RESET_SEARCH_PROPERTY_PARAMS",

  DESIGNATION_CREATE_REQUEST: "DESIGNATION_CREATE_REQUEST",
  DESIGNATION_CREATE_SUCCESS: "DESIGNATION_CREATE_SUCCESS",
  DESIGNATION_LIST_REQUEST: "DESIGNATION_LIST_REQUEST",
  DESIGNATION_LIST_SUCCESS: "DESIGNATION_LIST_SUCCESS",
  DESIGNATION_UPDATE_REQUEST: "DESIGNATION_UPDATE_REQUEST",
  DESIGNATION_UPDATE_SUCCESS: "DESIGNATION_UPDATE_SUCCESS",
  DESIGNATION_DETAIL_REQUEST: "DESIGNATION_DETAIL_REQUEST",
  DESIGNATION_DETAIL_SUCCESS: "DESIGNATION_DETAIL_SUCCESS",
  DESIGNATION_CLEAR: "DESIGNATION_CLEAR",
  DROPDOWN_CREATE_REQUEST: "DROPDOWN_CREATE_REQUEST",
  DROPDOWN_CREATE_SUCCESS: "DROPDOWN_CREATE_SUCCESS",
  DROPDOWN_LIST_REQUEST: "DROPDOWN_LIST_REQUEST",
  DROPDOWN_LIST_SUCCESS: "DROPDOWN_LIST_SUCCESS",
  DROPDOWN_UPDATE_REQUEST: "DROPDOWN_UPDATE_REQUEST",
  DROPDOWN_UPDATE_SUCCESS: "DROPDOWN_UPDATE_SUCCESS",
  DROPDOWN_DETAIL_REQUEST: "DROPDOWN_DETAIL_REQUEST",
  DROPDOWN_DETAIL_SUCCESS: "DROPDOWN_DETAIL_SUCCESS",
  DROPDOWN_CLEAR: "DROPDOWN_CLEAR",

  AGENTROLE_CREATE_REQUEST: "AGENTROLE_CREATE_REQUEST",
  AGENTROLE_CREATE_SUCCESS: "AGENTROLE_CREATE_SUCCESS",
  AGENTROLE_LIST_REQUEST: "AGENTROLE_LIST_REQUEST",
  AGENTROLE_LIST_SUCCESS: "AGENTROLE_LIST_SUCCESS",
  AGENTROLE_UPDATE_REQUEST: "AGENTROLE_UPDATE_REQUEST",
  AGENTROLE_UPDATE_SUCCESS: "AGENTROLE_UPDATE_SUCCESS",
  AGENTROLE_DETAIL_REQUEST: "AGENTROLE_DETAIL_REQUEST",
  AGENTROLE_DETAIL_SUCCESS: "AGENTROLE_DETAIL_SUCCESS",
  AGENTROLE_CLEAR: "AGENTROLE_CLEAR",

  ROLE_CREATE_REQUEST: "ROLE_CREATE_REQUEST",
  ROLE_CREATE_SUCCESS: "ROLE_CREATE_SUCCESS",
  ROLE_LIST_REQUEST: "ROLE_LIST_REQUEST",
  ROLE_LIST_SUCCESS: "ROLE_LIST_SUCCESS",
  ROLE_UPDATE_REQUEST: "ROLE_UPDATE_REQUEST",
  ROLE_UPDATE_SUCCESS: "ROLE_UPDATE_SUCCESS",
  ROLE_DETAIL_REQUEST: "ROLE_DETAIL_REQUEST",
  ROLE_DETAIL_SUCCESS: "ROLE_DETAIL_SUCCESS",
  ROLE_CLEAR: "ROLE_CLEAR",
  STAFF_CREATE_REQUEST: "STAFF_CREATE_REQUEST",
  STAFF_CREATE_SUCCESS: "STAFF_CREATE_SUCCESS",
  STAFF_LIST_REQUEST: "STAFF_LIST_REQUEST",
  STAFF_LIST_SUCCESS: "STAFF_LIST_SUCCESS",
  STAFF_UPDATE_REQUEST: "STAFF_UPDATE_REQUEST",
  STAFF_UPDATE_SUCCESS: "STAFF_UPDATE_SUCCESS",
  STAFF_DETAIL_REQUEST: "STAFF_DETAIL_REQUEST",
  STAFF_DETAIL_SUCCESS: "STAFF_DETAIL_SUCCESS",
  STAFF_CLEAR: "STAFF_CLEAR",
  STAFF_UPLOAD_REQUEST: "STAFF_UPLOAD_REQUEST",
  STAFF_UPLOAD_SUCCESS: "STAFF_UPLOAD_SUCCESS",
  ROLEONLY_LIST_REQUEST: "ROLEONLY_LIST_REQUEST",
  ROLEONLY_LIST_SUCCESS: "ROLEONLY_LIST_SUCCESS",
  DESIGNATIONONLY_LIST_REQUEST: "DESIGNATIONONLY_LIST_REQUEST",
  DESIGNATIONONLY_LIST_SUCCESS: "DESIGNATIONONLY_LIST_SUCCESS",
  BRANCHONLY_LIST_REQUEST: "BRANCHONLY_LIST_REQUEST",
  BRANCHONLY_LIST_SUCCESS: "BRANCHONLY_LIST_SUCCESS",
  PROPERTY_LIST_REQUEST: "PROPERTY_LIST_REQUEST",
  PROPERTY_IMAGE_FETCHING: "PROPERTY_IMAGE_FETCHING",
  PROPERTY_IMAGE_LIST_SUCCESS: "PROPERTY_IMAGE_LIST_SUCCESS",
  PROPERTY_IMAGE_LIST_FAIL: "PROPERTY_IMAGE_LIST_FAIL",
  PROPERTY_LIST_SUCCESS: "PROPERTY_LIST_SUCCESS",
  PROPERTYTYPE_LIST_REQUEST: "PROPERTYTYPE_LIST_REQUEST",
  PROPERTYTYPE_LIST_SUCCESS: "PROPERTYTYPE_LIST_SUCCESS",
  CURRENCY_LIST_REQUEST: "CURRENCY_LIST_REQUEST",
  CURRENCY_LIST_SUCCESS: "CURRENCY_LIST_SUCCESS",
  VCCCURRENCY_LIST_REQUEST: "VCCCURRENCY_LIST_REQUEST",
  VCCCURRENCY_LIST_SUCCESS: "VCCCURRENCY_LIST_SUCCESS",
  TIMEZONE_LIST_REQUEST: "TIMEZONE_LIST_REQUEST",
  TIMEZONE_LIST_SUCCESS: "TIMEZONE_LIST_SUCCESS",
  PROPERTYBASIC_LIST_REQUEST: "PROPERTYBASIC_LIST_REQUEST",
  PROPERTYBASIC_LIST_SUCCESS: "PROPERTYBASIC_LIST_SUCCESS",
  PROPERTYBASIC_CREATE_REQUEST: "PROPERTYBASIC_CREATE_REQUEST",
  PROPERTYBASIC_CREATE_SUCCESS: "PROPERTYBASIC_CREATE_SUCCESS",
  PROPERTYBASIC_UPDATE_REQUEST: "PROPERTYBASIC_UPDATE_REQUEST",
  PROPERTYBASIC_UPDATE_SUCCESS: "PROPERTYBASIC_UPDATE_SUCCESS",
  PROPERTYCONTACT_LIST_REQUEST: "PROPERTYCONTACT_LIST_REQUEST",
  PROPERTYCONTACT_LIST_SUCCESS: "PROPERTYCONTACT_LIST_SUCCESS",
  PROPERTYCONTACT_CREATE_REQUEST: "PROPERTYCONTACT_CREATE_REQUEST",
  PROPERTYCONTACT_CREATE_SUCCESS: "PROPERTYCONTACT_CREATE_SUCCESS",
  PROPERTYCONTACT_UPDATE_REQUEST: "PROPERTYCONTACT_UPDATE_REQUEST",
  PROPERTYCONTACT_UPDATE_SUCCESS: "PROPERTYCONTACT_UPDATE_SUCCESS",
  PROPERTYBANK_LIST_REQUEST: "PROPERTYBANK_LIST_REQUEST",
  PROPERTYBANK_LIST_SUCCESS: "PROPERTYBANK_LIST_SUCCESS",
  PROPERTYBANK_CREATE_REQUEST: "PROPERTYBANK_CREATE_REQUEST",
  PROPERTYBANK_CREATE_SUCCESS: "PROPERTYBANK_CREATE_SUCCESS",
  PROPERTYBANK_UPDATE_REQUEST: "PROPERTYBANK_UPDATE_REQUEST",
  PROPERTYBANK_UPDATE_SUCCESS: "PROPERTYBANK_UPDATE_SUCCESS",
  REQUEST_FETCH_ROOM_LIST_DATA: "REQUEST_FETCH_ROOM_LIST_DATA",
  REQUEST_FETCH_ROOM_LIST_DATA_SUCCESS: "REQUEST_FETCH_ROOM_LIST_DATA_SUCCESS",

  BOOKINGDETIAL_LIST_REQUEST: "BOOKINGDETIAL_LIST_REQUEST",
  BOOKINGDETIAL_LIST_SUCCESS: "BOOKINGDETIAL_LIST_SUCCESS",
  ROOM_CHECK_IN_DATE: "ROOM_CHECK_IN_DATE",

  // DrowDown
  AIRCONDITIONING_LIST_REQUEST: "AIRCONDITIONING_LIST_REQUEST",
  AIRCONDITIONING_LIST_SUCCESS: "AIRCONDITIONING_LIST_SUCCESS",
  KITCHEN_LIST_REQUEST: "KITCHEN_LIST_REQUEST",
  KITCHEN_LIST_SUCCESS: "KITCHEN_LIST_SUCCESS",
  ROOMSERVICE_LIST_REQUEST: "ROOMSERVICE_LIST_REQUEST",
  ROOMSERVICE_LIST_SUCCESS: "ROOMSERVICE_LIST_SUCCESS",
  SMOKEDETECTOR_LIST_REQUEST: "SMOKEDETECTOR_LIST_REQUEST",
  SMOKEDETECTOR_LIST_SUCCESS: "SMOKEDETECTOR_LIST_SUCCESS",
  SWIMMINGPOOL_LIST_REQUEST: "SWIMMINGPOOL_LIST_REQUEST",
  SWIMMINGPOOL_LIST_SUCCESS: "SWIMMINGPOOL_LIST_SUCCESS",
  LAUNDRY_LIST_REQUEST: "LAUNDRY_LIST_REQUEST",
  LAUNDRY_LIST_SUCCESS: "LAUNDRY_LIST_SUCCESS",
  PARKING_LIST_REQUEST: "PARKING_LIST_REQUEST",
  PARKING_LIST_SUCCESS: "PARKING_LIST_SUCCESS",
  INTERCOM_LIST_REQUEST: "INTERCOM_LIST_REQUEST",
  INTERCOM_LIST_SUCCESS: "INTERCOM_LIST_SUCCESS",
  CHILDCARESERVICE_LIST_REQUEST: "CHILDCARESERVICE_LIST_REQUEST",
  CHILDCARESERVICE_LIST_SUCCESS: "CHILDCARESERVICE_LIST_SUCCESS",
  BUTLER_LIST_REQUEST: "BUTLER_LIST_REQUEST",
  BUTLER_LIST_SUCCESS: "BUTLER_LIST_SUCCESS",
  SPECIALLYABLE_LIST_REQUEST: "SPECIALLYABLE_LIST_REQUEST",
  SPECIALLYABLE_LIST_SUCCESS: "SPECIALLYABLE_LIST_SUCCESS",
  SIGHTSEEING_LIST_REQUEST: "SIGHTSEEING_LIST_REQUEST",
  SIGHTSEEING_LIST_SUCCESS: "SIGHTSEEING_LIST_SUCCESS",
  FIREPLACE_LIST_REQUEST: "FIREPLACE_LIST_REQUEST",
  FIREPLACE_LIST_SUCCESS: "FIREPLACE_LIST_SUCCESS",
  LOUNGES_LIST_REQUEST: "LOUNGES_LIST_REQUEST",
  LOUNGES_LIST_SUCCESS: "LOUNGES_LIST_SUCCESS",
  JACUZZI_LIST_REQUEST: "JACUZZI_LIST_REQUEST",
  JACUZZI_LIST_SUCCESS: "JACUZZI_LIST_SUCCESS",
  SHOPPING_LIST_REQUEST: "SHOPPING_LIST_REQUEST",
  SHOPPING_LIST_SUCCESS: "SHOPPING_LIST_SUCCESS",
  CONFERENCEAREA_LIST_REQUEST: "CONFERENCEAREA_LIST_REQUEST",
  CONFERENCEAREA_LIST_SUCCESS: "CONFERENCEAREA_LIST_SUCCESS",
  CHILDRENPLAYAREA_LIST_REQUEST: "CHILDRENPLAYAREA_LIST_REQUEST",
  CHILDRENPLAYAREA_LIST_SUCCESS: "CHILDRENPLAYAREA_LIST_SUCCESS",
  TEMPLECHAPELPRAYERROOM_LIST_REQUEST: "TEMPLECHAPELPRAYERROOM_LIST_REQUEST",
  TEMPLECHAPELPRAYERROOM_LIST_SUCCESS: "TEMPLECHAPELPRAYERROOM_LIST_SUCCESS",
  OUTDOORFURNITURE_LIST_REQUEST: "OUTDOORFURNITURE_LIST_REQUEST",
  OUTDOORFURNITURE_LIST_SUCCESS: "OUTDOORFURNITURE_LIST_SUCCESS",
  RESTAURANT_LIST_REQUEST: "RESTAURANT_LIST_REQUEST",
  RESTAURANT_LIST_SUCCESS: "RESTAURANT_LIST_SUCCESS",
  SPA_LIST_REQUEST: "SPA_LIST_REQUEST",
  SPA_LIST_SUCCESS: "SPA_LIST_SUCCESS",
  GYM_LIST_REQUEST: "GYM_LIST_REQUEST",
  GYM_LIST_SUCCESS: "GYM_LIST_SUCCESS",
  SALON_LIST_REQUEST: "SALON_LIST_REQUEST",
  SALON_LIST_SUCCESS: "SALON_LIST_SUCCESS",
  STEAMSAUNA_LIST_REQUEST: "STEAMSAUNA_LIST_REQUEST",
  STEAMSAUNA_LIST_SUCCESS: "STEAMSAUNA_LIST_SUCCESS",

  HOTELDETAIL_LIST_REQUEST: "HOTELDETAIL_LIST_REQUEST",
  HOTELDETAIL_LIST_SUCCESS: "HOTELDETAIL_LIST_SUCCESS",
  HOTEL_DETAILS_RESET: "HOTEL_DETAILS_RESET",
  HOTEL_LOGIN_TERMS_AND_CONDITION: "HOTEL_LOGIN_TERMS_AND_CONDITION",
  HOTEL_LOGIN_CANCELLATION_POLICY: "HOTEL_LOGIN_CANCELLATION_POLICY",
  HOTEL_LOGIN_MEALPLAN_POLICY: "HOTEL_LOGIN_MEALPLAN_POLICY",
  HISTROY_LIST_REQUEST: "HISTROY_LIST_REQUEST",
  HISTROY_LIST_SUCCESS: "HISTROY_LIST_SUCCESS",
  ROOMPAYMENT_LIST_REQUEST: "ROOMPAYMENT_LIST_REQUEST",
  ROOMPAYMENT_LIST_SUCCESS: "ROOMPAYMENT_LIST_SUCCESS",

  //hotelRoomDetails
  FETCHING_HOTEL_ROOM_DETAILS: "FETCHING_HOTEL_ROOM_DETAILS",
  FETCHING_HOTEL_ROOM_DETAILS_SUCCESS: "FETCHING_HOTEL_ROOM_DETAILS_SUCCESS",
  HOTEL_ROOM_DETAILS_RESET: "HOTEL_ROOM_DETAILS_RESET",

  //VideoPlayer
  TOGGLE_VIDEO_PLAYER_MODAL: "TOGGLE_VIDEO_PLAYER_MODAL",
  SET_VIDEO_URL: "SET_VIDEO_URL",
  SET_VIDEO_URL_TO_NULL: "SET_VIDEO_URL_TO_NULL",
}
