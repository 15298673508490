import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  "@keyframes l7": {
    "0%": { backgroundSize: `100% 40%,8px 8px` },
    "70%": { backgroundSize: `100% 40%,8px 8px` },
    "85%": { backgroundSize: `100% 120%,8px 8px` },
    "100%": { backgroundSize: `100% 40%,8px 8px` },
  },
  loader: {
    display: "inline-flex",
    gap: "10px",
    "&:before, &:after": {
      content: `""`,
      height: "20px",
      aspectRatio: 1,
      borderRadius: "50%",
      background: `linear-gradient(#222 0 0) top/100% 40% no-repeat,
    radial-gradient(farthest-side,#000 95%,#0000) 50%/8px 8px no-repeat
    #fff`,
      animation: `$l7 1.5s infinite alternate ease-in`,
    },
  },
}))

function LoaderEyeVariant() {
  const classes = useStyles()
  return <div className={classes.loader}></div>
}

export default LoaderEyeVariant
