import { Route, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
import EditPropertyRoomRent from "./Pages/PropertyV2.0/editPropertyRoomRent/editPropertyRoomRent.component"
import PropertyV2 from "./Pages/PropertyV2.0/property-v2.component"
import RoomMain from "./Pages/RoomList/RoomMain"

import { Suspense, lazy } from "react"
import BestExperiences from "./B2C_Konnect/components/BestExperience/BestExperience.component"
import MostPopularHotels from "./B2C_Konnect/components/MostPopularHotels/MostPopularHotels.component"
import MostPopularPackages from "./B2C_Konnect/components/MostPopularPackages/MostPopularPackages.component"
import AdminRoute from "./components/AdminRoute"
import AgentRoute from "./components/AgentRoute"
import CommonRoute from "./components/CommonRoute"
import Loader from "./components/Loader/Loader"
import ProtectedRoute from "./components/ProtectedRoute"
import TravelAgentCommonCalenderRoute from "./components/TravelAgentCommonCalenderRoute"
import Activity from "./Pages/Activity/activity.component"
import ActivityList from "./Pages/Activity/activityList.component"
import CopyActivity from "./Pages/Activity/copyActivity.component"
import ViewActivity from "./Pages/Activity/viewActivity.component"
import AddB2CContent from "./Pages/AddB2CContent/AddB2CContent.page"
import ViewContentB2C from "./Pages/AddB2CContent/ViewContentB2C"
import AgentBookingList from "./Pages/AgentBookings/AgentBookingList"
import AgentBookingView from "./Pages/AgentBookings/AgentBookingView"
import AgentSearch from "./Pages/AgentBookings/AgentSearch"
import NewAgentBooking from "./Pages/AgentBookings/NewAgentBooking"
import AgentEnquiries from "./Pages/AgentEnquires/agentEnquires.component"
import AgentHome from "./Pages/AgentProperty/AgentHome"
import AgentHotelCopy from "./Pages/AgentProperty/AgentHotelCopy"
import AgentRoleList from "./Pages/AgentRole/AgentRoleList"
import CompanyRegister from "./Pages/Auth/CompanyRegister"
import Login from "./Pages/Auth/Login"
import FavouriteDestination from "./Pages/B2C/FavouriteDestination/FavouriteDestination"
import BookingList from "./Pages/Booking/BookingList"
import NewBooking from "./Pages/Booking/NewBooking"
import Branch from "./Pages/Branch/Branch"
import Client from "./Pages/Clients/Client"
import AgentCreatePackage from "./Pages/CreatePackage/AgentCreatePackage"
import CopyPageKGL from "./Pages/CreatePackage/CopyPageKGL"
import CreatePackage from "./Pages/CreatePackage/CreatePackage"
import Package from "./Pages/CreatePackage/package"
import Preview from "./Pages/CreatePackage/Preview"
import ViewPackage from "./Pages/CreatePackage/ViewPackage"
import Designation from "./Pages/Designation/Designation"
import GenerateInvoice from "./Pages/GST-Invoice/generateInvoice"
import GstInvoiceList from "./Pages/GST-Invoice/gstInvoiceList"
import GstInvoiceVoucher from "./Pages/GST-Invoice/gstInvoiceVoucher"
import ViewGstInvoice from "./Pages/GST-Invoice/viewGstInvoice"
import { Homepage } from "./Pages/Homepage"
import HotelBookingList from "./Pages/HotelLogin/Hotel Booking/hotelBookingList"
import HotelNewBooking from "./Pages/HotelLogin/Hotel Booking/hotelNewBooking"
import TownoViewBookingDetails from "./Pages/HotelLogin/Hotel Booking/townoViewBookingDetails"
import HotelCalenderView from "./Pages/HotelLogin/Hotel Calender View/hotelCalenderView"
import HotelDashboard from "./Pages/HotelLogin/hotelDashboard"
import ItineraryBuilder from "./Pages/ItineraryBuilder/itineraryBuilder.component"
import ItineraryList from "./Pages/ItineraryBuilder/itineraryList.component"
import ItineraryVoucher from "./Pages/ItineraryBuilder/itineraryVoucher.component"
import LeadMaster from "./Pages/Lead/leadMaster"
import Commonfeature from "./Pages/LeadCommon/Common"
import AgentClientVoucher from "./Pages/OrderBooking/AgentClientVoucher"
import AgentHotelVoucher from "./Pages/OrderBooking/AgentHotelVoucher"
import BookingEdit from "./Pages/OrderBooking/BookingEdit"
import ClientVoucher from "./Pages/OrderBooking/ClientVoucher"
import HotelLoginHotelVoucher from "./Pages/OrderBooking/HotelLoginHotelVoucher"
import HotelVoucher from "./Pages/OrderBooking/HotelVoucher"
import Dropdown from "./Pages/ProductDropdown/Dropdown"
import ChangePassword from "./Pages/Profile/ChangePassword"
import Profile from "./Pages/Profile/Profile"
import AddAgentProperty from "./Pages/Property/PropertyList/AddAgentProperty"
import Properties from "./Pages/Property/PropertyList/properties"
import PropertyList from "./Pages/Property/PropertyList/PropertyList"
import ViewAgentProperty from "./Pages/Property/PropertyList/ViewAgentProperty"
import HotelView from "./Pages/Property/View/HotelView"
import RoomView from "./Pages/Property/View/RoomView/RoomView"
import PropertyViewNew from "./Pages/PropertyView/propertyViewNew.page"
import Report from "./Pages/Report/Report"
import Role from "./Pages/Role/Role"
import Bulkupdate from "./Pages/RoomList/Bulkupdate"
import Staff from "./Pages/Staff/Staff"
import CopyTransferPageKGL from "./Pages/Transfer/CopyTransferPageKGL"
import PreviewPage from "./Pages/Transfer/PreviewPage"
import TransferList from "./Pages/Transfer/TransferList"
import ViewTransfer from "./Pages/Transfer/ViewTransfer"
import TransferFormV2 from "./Pages/Transfer_2.0/TransferFormV2"
import AgencyOnboarding from "./Pages/TravelAgencyOnboarding/AgencyOnboarding"
import CreateUpdateOnboard from "./Pages/TravelAgencyOnboarding/CreateUpdateOnboard"
import ViewOnboard from "./Pages/TravelAgencyOnboarding/ViewOnboard"
import TravelAgent from "./Pages/TravelAgent/TravelAgent"
import AgentDashboard from "./Pages/TravelAgentDashboard/AgentDashboard"
import OnBoardTravelAgents from "./Pages/TravelAgentsOnBoarding/onBoardTravelAgents.components"
import TravelAgentList from "./Pages/TravelAgentsOnBoarding/travelAgentList.component"
import BestSelling from "./Pages/ViewPage/BestSelling/BestSelling"
import DealoftheWeek from "./Pages/ViewPage/DealoftheWeek/DealoftheWeek"
import LastMinuteDeal from "./Pages/ViewPage/LastMinuteDeals/LastMinuteDeal"
import MostPopular from "./Pages/ViewPage/MostPopular/MostPopular"
import DemoVoucher from "./Pages/Vouchers/DemoVoucher.component"
import CheckoutBookingList from "./Pages/PropertyView/checkoutBookingList"
import CheckoutPage from "./Pages/PropertyView/checkOut"
import CardUploadForm from "./Pages/CardUploadForm/cardUploadForm.component"
import PackageCardList from "./Pages/PackageCardList/packageCardList.page"

const AddNewProperty = lazy(() => import("./Pages/Property/AddNewProperty"))
const Dashboard = lazy(() => import("./Pages/Dashboard/DashboardCopy"))

const KonnectRouter = () => {
  const { path, url } = useRouteMatch()

  return (
    <>
      <Route exact path={url} component={Homepage} />
      <Route path={`${url}/login`} component={Login} />

      <Suspense fallback={<Loader />}>
        <AdminRoute path={`${url}/addproperty`} component={AddNewProperty} />
        <ProtectedRoute
          path={`${url}/property/:propertyId?`}
          component={PropertyV2}
        />
        <AdminRoute
          path={`${url}/editPropertyRoomRent/:propertyId?`}
          component={EditPropertyRoomRent}
        />
        <CommonRoute path={`${url}/dashboard`} exact component={Dashboard} />
        <TravelAgentCommonCalenderRoute
          path={`${url}/calenderView`}
          component={RoomMain}
        />
      </Suspense>

      <Route path={`${url}/companyregister`} component={CompanyRegister} />
      <AdminRoute path={`${url}/viewB2cContent`} component={ViewContentB2C} />
      <AdminRoute path={`${url}/dropdown`} component={Dropdown} />
      <AdminRoute path={`${url}/branch`} component={Branch} />
      <AdminRoute path={`${url}/travelAgent`} component={TravelAgent} />
      <AdminRoute path={`${url}/addB2CContent`} component={AddB2CContent} />
      <CommonRoute path={`${url}/commonfeature`} component={Commonfeature} />
      <CommonRoute path={`${url}/profile`} component={Profile} />
      <CommonRoute path={`${url}/properties`} component={Properties} />
      <CommonRoute
        path={`${url}/addAgentProperty/:propertyId?`}
        component={AddAgentProperty}
      />
      <CommonRoute
        path={`${url}/generateInvoice/:invoiceId?`}
        component={GenerateInvoice}
      />
      <CommonRoute
        path={`${url}/hotelNewBooking/:bookingId?`}
        component={HotelNewBooking}
      />
      <CommonRoute path={`${url}/hotelDashBoard`} component={HotelDashboard} />
      <CommonRoute
        path={`${url}/viewTownoBooking/:bookingId?`}
        component={TownoViewBookingDetails}
      />
      <CommonRoute
        path={`${url}/hotelCalenderView`}
        component={HotelCalenderView}
      />
      <AdminRoute
        path={`${url}/hotelBookingList`}
        component={HotelBookingList}
      />
      <CommonRoute path={`${url}/invoiceList`} component={GstInvoiceList} />
      <CommonRoute
        path={`${url}/viewGstInvoice/:invoiceNo`}
        component={ViewGstInvoice}
      />
      <CommonRoute
        path={`${url}/gstInvoiceVoucher/:invoiceId`}
        component={GstInvoiceVoucher}
      />
      <CommonRoute path={`${url}/propertyList`} component={PropertyList} />
      <CommonRoute
        path={`${url}/viewAgent/:propertyId`}
        component={ViewAgentProperty}
      />
      <CommonRoute path={`${url}/changePassword`} component={ChangePassword} />
      <CommonRoute path={`${url}/client`} component={Client} />
      <CommonRoute path={`${url}/roomListView`} component={RoomMain} />
      <CommonRoute path={`${url}/newBooking`} component={NewBooking} />
      <CommonRoute path={`${url}/lead`} component={LeadMaster} />
      <CommonRoute path={`${url}/agentBookings`} component={AgentBookingList} />
      <CommonRoute
        path={`${url}/viewAgentBooking/:bookingId`}
        component={AgentBookingView}
      />
      <Route path={`${url}/agentSearch`} component={AgentSearch} />
      <CommonRoute
        path={`${url}/newAgentBooking/:bookingId?`}
        component={NewAgentBooking}
      />
      <CommonRoute path={`${url}/roleList`} component={AgentRoleList} />
      <CommonRoute path={`${url}/activityList`} component={ActivityList} />
      <CommonRoute path={`${url}/activity/:activityID?`} component={Activity} />

      {/* agency onboarding from here */}
      <CommonRoute
        path={`${url}/agencyOnboarding`}
        component={AgencyOnboarding}
      />
      <CommonRoute
        path={`${url}/creatUpdateOnboard/:id?`}
        component={CreateUpdateOnboard}
      />
      <CommonRoute path={`${url}/viewOnboard/:id?`} component={ViewOnboard} />

      {/* admin  */}

      <CommonRoute path={`${url}/designation`} component={Designation} />
      <CommonRoute path={`${url}/role`} component={Role} />
      <CommonRoute path={`${url}/staff`} component={Staff} />
      <CommonRoute
        path={`${url}/mostPopularPackages`}
        component={MostPopularPackages}
      />
      <CommonRoute
        path={`${url}/mostPopularHotels`}
        component={MostPopularHotels}
      />
      <CommonRoute
        path={`${url}/bestExperiences`}
        component={BestExperiences}
      />
      <CommonRoute
        path={`${url}/favouriteDestination`}
        component={FavouriteDestination}
      />
      <CommonRoute
        path={`${url}/packageOrQuotation/:pkgOrQtn`}
        component={Package}
      />
      <CommonRoute
        path={`${url}/createPackageOrQuotation/:pkgOrQtn?/:displayLeads?/:id?`}
        component={CreatePackage}
      />
      <CommonRoute
        path={`${url}/agentCreatePackageOrQuotation/:pkgOrQtn?/:displayLeads?/:id?`}
        component={AgentCreatePackage}
      />
      <CommonRoute
        path={`${url}/viewPackageOrQuotation/:pkgOrQtn/:id`}
        component={ViewPackage}
      />
      <CommonRoute path={`${url}/preview`} component={Preview} />
      <Route path={`${url}/copyPage/:pkgOrQtn/:id`} component={CopyPageKGL} />
      <CommonRoute
        path={`${url}/transferForm/:transferId?`}
        component={TransferFormV2}
      />
      <CommonRoute path={`${url}/previewPage`} component={PreviewPage} />
      <CommonRoute path={`${url}/transferList`} component={TransferList} />
      <CommonRoute path={`${url}/demoVoucher`} component={DemoVoucher} />
      <CommonRoute
        path={`${url}/viewTransfer/:transferId`}
        component={ViewTransfer}
      />
      <CommonRoute
        path={`${url}/propertyNewView`}
        component={PropertyViewNew}
      />
      <Route
        path={`${url}/copyTransferPage/:transferId`}
        component={CopyTransferPageKGL}
      />
      <AdminRoute
        path={`${url}/bulkupdate/:propertyId`}
        component={Bulkupdate}
      />
      <AdminRoute path={`${url}/bestSelling`} component={BestSelling} />
      <AdminRoute path={`${url}/lastMinuteDeals`} component={LastMinuteDeal} />
      <AdminRoute
        path={`${url}/addPackageCard/:cardId?`}
        component={CardUploadForm}
      />
      <AdminRoute path={`${url}/packageCardList`} component={PackageCardList} />
      <AdminRoute path={`${url}/DealoftheWeek`} component={DealoftheWeek} />
      <AdminRoute path={`${url}/mostPopular`} component={MostPopular} />
      <ProtectedRoute
        path={`${url}/bookinglist`}
        forceRefresh={true}
        component={BookingList}
      />
      <ProtectedRoute
        path={`${url}/bookingvoucher/:id`}
        component={BookingEdit}
      />
      <CommonRoute
        path={`${url}/roomdetails/:id/:roomid`}
        component={RoomView}
      />
      <CommonRoute
        path={`${url}/checkoutBookingList`}
        component={CheckoutBookingList}
      />
      <CommonRoute
        path={`${url}/checkoutBookingDetails/:bookingId`}
        component={CheckoutPage}
      />
      <CommonRoute path={`${url}/report`} component={Report} />
      <CommonRoute path={`${url}/hotelView/:id`} component={HotelView} />
      <AgentRoute path={`${url}/agent`} component={AgentHome} />
      <CommonRoute path={`${url}/agentDashboard`} component={AgentDashboard} />
      {/* <Route
              path="/loadingAnimation"
              component={LoadingAnimation}
            /> */}

      <CommonRoute path={`${url}/itineraryList`} component={ItineraryList} />
      <Route
        path={`${url}/clientVoucher/:bookingid`}
        component={ClientVoucher}
      />
      <Route
        path={`${url}/agentClientVoucher/:bookingId`}
        component={AgentClientVoucher}
      />
      <Route path={`${url}/hotelVoucher/:bookingid`} component={HotelVoucher} />
      <Route
        path={`${url}/hotelBookingVoucher/:bookingId`}
        component={HotelLoginHotelVoucher}
      />
      <Route
        path={`${url}/itineraryVoucher/:itineraryId?`}
        component={ItineraryVoucher}
      />
      <Route
        path={`${url}/agentHotelVoucher/:bookingId`}
        component={AgentHotelVoucher}
      />

      <CommonRoute
        path={`${url}/onboardTravelAgentForm/:id?`}
        component={OnBoardTravelAgents}
      />
      <CommonRoute path={`${url}/agencyList`} component={TravelAgentList} />
      <CommonRoute path={`${url}/agentHotelCopy`} component={AgentHotelCopy} />
      <CommonRoute path={`${url}/myEnquiries`} component={AgentEnquiries} />
      <CommonRoute
        path={`${url}/itineraryBuilder/:itineraryId?`}
        component={ItineraryBuilder}
      />

      <CommonRoute
        path={`${url}/viewActivity/:activityId`}
        component={ViewActivity}
      />
      <Route
        path={`${url}/copyActivity/:activityId`}
        component={CopyActivity}
      />
    </>
  )
}

export default KonnectRouter
