import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Grid } from "@mui/material"

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: "5rem 2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    minWidth: "50%",
    maxWidth: "50%",
    borderRadius: 0,
    padding: "1rem 0rem",
    boxShadow: "0rem 0rem 0.5rem #f46d25",
  },
  thead: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "lighter !important",
  },
  td: {
    fontSize: "0.8em",
    fontWeight: "600",
    border: "1px solid",
  },
  table: {
    borderCollapse: "collapse !important",
    border: "1px solid",
    display: "flex",
  },
}))

const DemoVoucher = () => {
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Grid container padding={1}>
          <Grid item container md={10} border={1}>
            <Grid item md={12} borderBottom={1}>
              Hotel Booking Voucher
            </Grid>
            <Grid item md={5}>
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <th className={styles.td}>Booking ID</th>
                  <th className={styles.td}>Reference Number</th>
                  <th style={{ padding: "0rem" }}>
                    <tr
                      style={{
                        padding: "0rem",
                        border: "1px solid",
                        fontSize: "0.8em",
                        fontWeight: "600",
                      }}
                    >
                      <td style={{ padding: "0rem 0.4rem" }}>
                        Hotel Check-In Date
                      </td>
                      <td
                        style={{
                          border: "1px solid",
                          paddingLeft: "0.4em",
                        }}
                      >
                        Date 1
                      </td>
                    </tr>

                    <tr
                      style={{
                        padding: "0rem",
                        border: "1px solid",
                        fontSize: "0.8em",
                        fontWeight: "600",
                      }}
                    >
                      <td style={{ padding: "0rem 0.4rem" }}>
                        Hotel Check-Out Date
                      </td>
                      <td
                        style={{
                          border: "1px solid",
                          paddingLeft: "0.4em",
                        }}
                      >
                        Date 2
                      </td>
                    </tr>
                  </th>
                  <th className={styles.td}>Return</th>
                  <th className={styles.td}>Reference ID</th>
                  <th className={styles.td}>Number of Pax</th>
                  <th className={styles.td}>Customer Name</th>
                  <th className={styles.td}>Total Cost</th>
                </thead>

                <thead className={styles.thead} style={{ flexGrow: "1" }}>
                  <th className={styles.td}>1234</th>
                  <th className={styles.td}>67894</th>
                  <th
                    style={{
                      padding: "0rem",
                      width: "100% !important",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <tr
                      style={{
                        padding: "0rem",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        width: "100% !important",
                        border: "1px solid",
                        borderBottom: "none",
                        flexGrow: "1",
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: "0.4em",
                          width: "100% !important",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Date 1
                      </td>
                    </tr>

                    <tr
                      style={{
                        padding: "0rem",
                        border: "1px solid",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        textAlign: "center",
                        flexGrow: "1",
                      }}
                    >
                      <td
                        style={{
                          paddingLeft: "0.4em",
                          width: "100% !important",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Date 1
                      </td>
                    </tr>
                  </th>
                  <th className={styles.td}>Return</th>
                  <th className={styles.td}>Reference ID</th>
                  <th className={styles.td}>Number of Pax</th>
                  <th className={styles.td}>Customer Name</th>
                  <th className={styles.td}>Total Cost</th>
                </thead>
              </table>
            </Grid>

            <Grid item md={4}>
              <Grid item container md={12}>
                <Grid item md={12}></Grid>
              </Grid>
            </Grid>
            <Grid item container md={3}>
              <Grid item md={12} border={1}></Grid>
              <Grid item md={12} fontSize="0.8em" border={1} textAlign="center">
                Issue Date
              </Grid>
              <Grid item md={12} border={1}>
                {" "}
              </Grid>
              <Grid item md={12} fontSize="0.8em" border={1} textAlign="center">
                Approved By
              </Grid>

              <Grid
                item
                md={12}
                fontSize="0.8em"
                border={1}
                style={{ borderRight: "none" }}
              ></Grid>
            </Grid>
          </Grid>
          <Grid item container md={2}>
            <Grid item md={12} border={1}>
              Hotel
            </Grid>
            <Grid item md={12}>
              {" "}
            </Grid>
            <Grid item md={12} border={1}>
              {new Date().toLocaleDateString()}
            </Grid>
            <Grid item md={12}>
              {" "}
            </Grid>
            <Grid item md={12} border={1}>
              {new Date().toLocaleDateString()}
            </Grid>
            <Grid item md={12} border={1}></Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default DemoVoucher
