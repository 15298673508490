import {
  Button,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Stack } from "@mui/material"
import { format } from "date-fns"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { baseurl } from "../../Service/httpCommon"
import AgentHotelCopyPropertyCardSkeleton from "../../components/skeletonComponents/agentHotelCopyPropertyCardSkeleton.component"
import { formatCurrency, formatter } from "../../utils/formatNumber"
import RatingMail from "../OrderBooking/RatingMail"
import "./Table.css"
import CreateQueryForm from "./queryForm.component"
import { useDispatch } from "react-redux"
import { handleChangeSearchParams } from "../../redux/actions/bookingRoomAction"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "2px",
    margin: "0px",
    "@media (max-width: 867px)": {
      margin: "0px 10px",
    },
  },
}))

export default function FilterHotels(props) {
  const { Data, loading } = props
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      {loading ? (
        <AgentHotelCopyPropertyCardSkeleton length={4} />
      ) : (
        <Container>
          {Data.length === 0 ? (
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h2> No Properties Found</h2>
            </Grid>
          ) : (
            <div className="hotelView" style={{ paddingTop: "5px" }}>
              <Card>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table style={{ bordercolor: "black" }}>
                    <TableBody>
                      {Data.map((item) => (
                        <TableRow key={item.id} tabIndex={1}>
                          <TableCell>
                            <Grid container spacing={1}>
                              <Grid item sm={4}>
                                <img
                                  src={`${baseurl}getimage/${item.photo}`}
                                  alt="HotelImage"
                                />
                              </Grid>
                              <Grid item lg={8}>
                                <h4>{item.displayName}</h4>
                                <RatingMail
                                  style={{ fontSize: "20px" }}
                                  rating={item.starRating}
                                />
                                <Stack direction="row" spacing={4}>
                                  <p>{item.city}</p>
                                  {/* <Stack direction='row' style={{marginTop:'5px',fontSize:"15px"}}>
                                <span><span style={{color:"#f46d25",fontWeight:"bold"}}>Rate</span> : {item.rent}  |</span>
                                <span>| <span style={{color:"#f46d25",fontWeight:"bold"}}>Net</span> : 8000 |</span>
                                <span>| <span style={{color:"#f46d25",fontWeight:"bold"}}>Percentage</span> : 40%  </span>
                              </Stack> */}
                                </Stack>
                                <br />
                                <Grid container spacing={1}>
                                  <Grid item sm={4}>
                                    <Button
                                      onClick={(e) => {
                                        dispatch(
                                          handleChangeSearchParams("location", {
                                            label: item.city,
                                            value: item.city,
                                          })
                                        )
                                        dispatch(
                                          handleChangeSearchParams(
                                            "propertyId",
                                            {
                                              label: item.displayName,
                                              value: item.propertyId,
                                            }
                                          )
                                        )

                                        history.push(
                                          `/konnect/propertyNewView/${item.city}/${item.propertyId}`
                                        )
                                        sessionStorage.setItem(
                                          "blackout",
                                          item.blackout
                                        )
                                        sessionStorage.setItem(
                                          "checkInDate",
                                          props.checkIn
                                            ? format(
                                                new Date(props.checkIn),
                                                "yyyy-MM-dd"
                                              )
                                            : format(new Date(), "yyyy-MM-dd")
                                        )
                                      }}
                                      fullWidth
                                    >
                                      Search Rooms
                                    </Button>
                                  </Grid>
                                  <Grid item sm={8}>
                                    <Button
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "propertyId",
                                          item.propertyId
                                        )

                                        setOpen(true)
                                      }}
                                    >
                                      {item.blackout
                                        ? "Send A Query"
                                        : `${formatCurrency(
                                            item.rent
                                          )} (Night)`}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </div>
          )}
          {/* <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button>Load More...</Button>
          </Grid> */}
        </Container>
      )}
      <CreateQueryForm open={open} setOpen={setOpen} />
    </div>
  )
}
