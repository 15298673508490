import { Fragment, useContext, useLayoutEffect } from "react"
import globalStyles from "../../../../globalStyles.module.css"
import styles from "./PlacesToVisit.module.css"

import parser from "html-react-parser"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"

import DehradunImg from "../../../../assets/illustrations/destination/where-you-headed/Dehradun.jpg"
import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import MussoorieImg from "../../../../assets/illustrations/destination/where-you-headed/Mussoorie.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import NainitalImg from "../../../../assets/illustrations/destination/where-you-headed/nanital.jpg"

import ChardhamImg from "../../../../assets/illustrations/destination/where-you-headed/Chardham-Yatra.jpeg"
import JimCorbettImg from "../../../../assets/illustrations/destination/where-you-headed/Jeep Safari in Jim Corbett National Park4.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const places = [
  {
    title: "Dehradun",
    content: `The capital city of Uttarakhand, Dehradun is strategically located in the Doon Valley on the foothills of the Himalayas, flanked between the river Ganges on one side and the river Yamuna on other side. It is also one of Uttarkhand’s famous tourist places.

<p>You can see the city of Mussoorie, located just 40 km away, sparkling against the hills in the form of scintillating Mussoorie Lights at night. Also popular amongst tourists are Dehradun’s cafes with their quaint outdoors and elaborate menus. The Forest Research Institute is the oldest and biggest forest-based training institute with its intricate colonial architecture is also a must-visit for all photographers and an amazing place to see in Uttarakhand.</p>`,
    src: DehradunImg,
  },
  {
    title: "Mussoorie",
    content: `A composite portrait of breath-taking landscapes, Mussoorie is definitely one of the top 5 places to visit in Uttarakhand. You can hike towards Happy Valley from Library Head or plan for a longer trek to the Nag Tibba Summit. Either ways, you can easily lose yourself in the captivating backdrop of mountains and valleys. Another popular tourist destination in Uttarakhand is Kempty Falls, the perfect place for a day picnic, just 13 km from Mussoorie. 
   <p> You should also definitely plan to visit Mall Road, as it is the ultimate shopping destination for tourists for woollen clothes, wooden artefacts and even cheap electronic goods. With an endless line of stalls, roadside vendors and restaurants, the road is heaven for foodies and shopaholics alike.</p>`,

    src: MussoorieImg,
  },

  {
    title: "Nainital",
    content: `One of the most popular hill stations in Uttarakhand is the city of Nainital. Also called the Lake District of India, Nainital is uniquely located in a valley that contains the crescent-shaped freshwater Nainital Lake surrounded by towering mountains. If you enjoy boating and paddling along with sightseeing in Uttarakhand, Nainital Lake is the perfect destination for you. 
<p>You can also go on an expedition to explore the surrounding lakes of Bhimtal, Naukuchiatal, Khurpatal, and Sattal. The panoramic views from Naini Peak and Tiffin Top are every photographer’s dream. Don’t forget to take away one of the scrumptious bottles of jam or exquisitely carved candles as a souvenir for yourself and your loved ones as you visit this place.</p>`,

    src: NainitalImg,
  },

  {
    title: "Rishikesh",
    content: `For adventure lovers, Rishikesh is the perfect Uttarakhand tourist spot. From bungee jumping to rafting to rappelling and camping under the stars you can try it all. An ideal getaway for friends, family and couples, Rishikesh is a unique mix of adrenalizing escapades and mesmerising mystic experiences.

From rafting to bungee jumping to camping and bonfire, Rishikesh provides all the adventure needed for your soul.  You can relax with your loved ones with the Ganga flowing besides you and the stars sparkling above in the sky. Rishikesh also provides for extreme adventure activities such as Flying Fox, Cliff jumping, Bodysurfing Trekking, Rappelling, and Rock Climbing. 

<p>If you feel that you need time out for yourself, then an Ayurvedic massage and therapy is what you can seek in this amazing city.  Yoga and meditation are another inseparable part of the city. There are abundant yoga and meditation centres which can heal your mind and your soul, making it one the best Uttarakhand tourist places. A visit to Rishikesh is incomplete without a tribute to the Beatles Ashram where the Beatles stayed in the early 1960s.</p>

The Ganga Aarti at the banks of the river Ganga in the evening is a must attend for people of all ages and backgrounds, as the mystical vibes combined with the chants of the Aarti will rejuvenate your mind and soul.`,

    src: RishikeshImg,
  },

  {
    title: "Haridwar",
    content: `If you are looking to find a deeper meaning to life or simply wanting to know your heritage better, a trip to Haridwar, one of the celebrated Uttarakhand tourist places, is a must. Take a holy dip in the Ganga at Har ki Pauri, which legend says, will wash away all your sins.

<p>You can also spend your time listening to Sadhus and Gurus, who have been deciphering the meaning of life since time memorial. The local food in the busy by lanes of Haridwar is definitely a must try. Spicy and aromatic, the kachoris and the aloo puris will leave you wanting for more. We also recommend you attend the evening Aarti at the banks of Ganga, which will leave you mesmerized irrespective of your beliefs.</p>`,

    src: HaridwarImg,
  },
  {
    title: "Jim Corbett National Park",
    content: `Home to the Royal Bengal Tiger and over 500+ birds as well as other plants and animals, Jim Corbett is a perfect place to visit in Uttarakhand for nature enthusiasts and photographers. You can opt for a jeep Safari that will take you deep in the jungle with an experienced guide or you can plan your own excursions, with permission of course.

<p>For those planning sightseeing in Uttarakhand, a visit to Jim Corbett is definitely recommended. Beyond the Jungle Safari and Elephant ride, a 66 feet high waterfall within Corbett is another attraction and so is the Girija Temple dedicated to Goddess Parvati. Another place to visit is the Kosi river, which provides water to wildlife in the Park. The Kois river does not enter the park, but runs close to the park boundaries and provides a great view of the Shivalik Hills against the flowing water. You can also go fishing in the fishing camps of Kosi for Mahseer.</p>`,
    src: JimCorbettImg,
  },

  {
    title: "Char Dham - Yamunotri, Gangotri, Badrinath and Kedarnath",
    content: `The best of spiritual treats is the Char Dham Yatra, a trip to four sacred places called as Char Dham, can help you attain Moksha (salvation).<p>

Historically known as the origin of Yamuna river, Yamunotri is one of the most famous Uttarakhand tourist spots and pilgrimages. Similarly, Gangotri is the origin of the river Ganga. While Badrinath and Gangotri are easily accessible by road, Kedarnath and Yamunotri require a much more concentrated effort from travellers.</p>`,
    src: ChardhamImg,
  },
]

const PlacesToVisit = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("places")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <section className={globalStyles.topMostSection}>
          <header>
            Places To Visit In <span>Uttarakhand</span>
          </header>
          <div className={globalStyles.content}>
            With its unique mix of snow-clad mountains, lush-green limitless
            meadows and dense forests, Uttarakhand is indeed unique tourist
            destination. Whether you're looking for adventure, leisure or deep
            introspection, there are plenty of places to visit in Uttarakhand.
            <p>
              Popular amongst domestic as well as foreign travellers,
              Uttarakhand is one of the fastest-growing destinations in India.
              You can take a jeep safari through the covert forests of Jim
              Corbett to spot the Royal Bengal Tiger or you can trek through the
              alpine meadows and budgyals at Auli or you can simply enjoy sit
              back and relax and enjoy the breath-taking view of the snow-clad
              Himalayas from the Queen of The Hills, Mussoorie.
            </p>
              If you are culturally inquisitive, the Ganga Aarti at Haridwar and
            Rishikesh will leave you enthralled, a trip to Badrinath with its
            twin sentinels the Nar and Narayan mountains will leave you
            spellbound. 
            <p>
              Depending on your budget and your vacation goal, here is a list of
              the most popular places to visit in Uttarakhand:
            </p>
          </div>
          <div
            className={styles.placeToVisitList}
            style={{ marginTop: "3rem" }}
          >
            {places.map((place, idx) => (
              <Fragment key={idx + 1}>
                <ImageAndContentCard>
                  <ImageAndContentCard.Title>
                    {idx + 1}. {place.title}
                  </ImageAndContentCard.Title>
                  <ImageAndContentCard.Image src={place.src} />
                  <ImageAndContentCard.Content
                    title={`${idx + 1}. ${place.title}`}
                  >
                    {parser(place.content)}
                  </ImageAndContentCard.Content>
                </ImageAndContentCard>
                {idx < places.length - 1 && <hr className={styles.hr} />}
              </Fragment>
            ))}
          </div>
        </section>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default PlacesToVisit
