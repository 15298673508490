import { endOfMonth } from "date-fns"

export function calculateTimeDifference() {
  const now = new Date()
  const endOfCurrentMonth = endOfMonth(now)

  const totalSeconds = Math.floor((endOfCurrentMonth - now) / 1000)

  const days = Math.floor(totalSeconds / (24 * 60 * 60))
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
  const seconds = totalSeconds % 60

  return [
    { title: "Days", value: days < 10 ? `0${days}` : days },
    { title: "Hrs", value: hours < 10 ? `0${hours}` : hours },
    { title: "Mins", value: minutes < 10 ? `0${minutes}` : minutes },
    { title: "Secs", value: seconds < 10 ? `0${seconds}` : seconds },
  ]
}
