// import TawkMessengerReact from "@tawk.to/tawk-messenger-react"
import { StompSessionProvider } from "react-stomp-hooks";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SideNavbarContext,
  SideNavbarContextProvider,
} from "./B2C_Konnect/context/sideNavBarContext.jsx";
import ConnectionLost from "./assets/illustrations/connectionLost.component";
//import LoadingAnimation from "./components/loadingScreen/loadingAnimation.component"
import "./B2C_Konnect/layout/RootLayout/RootLayout.css";

import RoutesB2C from "./B2C_Konnect/RoutesB2C.jsx";
import { CaptchaProvider } from "./B2C_Konnect/context/captcha.context.jsx";
import { ContactModalProvider } from "./B2C_Konnect/context/contactModal.context.jsx";
import KonnectRouter from "./konnectRouter.jsx";
import { websocketbaseurl } from "./Service/httpCommon.js";

const AddNewProperty = React.lazy(() =>
  import("./Pages/Property/AddNewProperty")
);
const Dashboard = React.lazy(() => import("./Pages/Dashboard/DashboardCopy"));

const HomePage = React.lazy(() =>
  import("./B2C_Konnect/Pages/Home/Home.page.jsx")
);

export const websocketMessageBody = {
  type: "week",
  senderName: localStorage.getItem("auth"),
  receiverName: localStorage.getItem("unique_id"),
  createdBy: localStorage.getItem("auth"),
  message: "Hello",
  seen: true,
};

export default function App() {
  const { pathname } = useLocation();
  const role = localStorage.getItem("role");
  const agent = localStorage.getItem("agent");
  const location = useLocation();
  const { currentUser } = useSelector(({ userReducer }) => userReducer);
  const TAWK_TO_PROPERTY_ID = "64f04a03a91e863a5c10cf9e";
  const TAWK_TO_WIDGET_ID = "1h95a281h";
  const dispatch = useDispatch();
  const { openSideNavbar, setOpenSideNavbar } = useContext(SideNavbarContext);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", () => handleOnline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [isOnline]);

  const history = useHistory();
  useEffect(() => {
    // if (pathname === "/") {
    //   history.push("/landing")
    // }
    window.scrollTo(0, 0);
  }, [pathname]);

  window.addEventListener("beforeunload", function () {
    localStorage.removeItem("$navigator.locks-requestQueueMap");
    localStorage.removeItem("$navigator.locks-clientIds");
    localStorage.removeItem("$navigator.locks-heldLockSet");
  });

  return (
    <>
      <StompSessionProvider url={websocketbaseurl}>
        {process.env.NODE_ENV == "development" || isOnline ? (
          <>
            <ToastContainer />
            {/* {currentUser?.role === "Agent Admin" &&
          (location.pathname !== "/konnect/login" ||
            location.pathname !== "/konnect") ? (
            <TawkMessengerReact
              propertyId={TAWK_TO_PROPERTY_ID}
              widgetId={TAWK_TO_WIDGET_ID}
            />
          ) : null} */}

            <Switch>
              <Route path="/konnect">
                <KonnectRouter />
              </Route>

              <ContactModalProvider>
                <SideNavbarContextProvider>
                  <CaptchaProvider>
                    <div onClick={() => setOpenSideNavbar(false)}>
                      {/* <TawkMessengerReact
                        propertyId={TAWK_TO_PROPERTY_ID}
                        widgetId={TAWK_TO_WIDGET_ID}
                      /> */}
                      <Route path="/">
                        <RoutesB2C />
                      </Route>
                    </div>
                  </CaptchaProvider>
                </SideNavbarContextProvider>
              </ContactModalProvider>
            </Switch>
          </>
        ) : (
          <ConnectionLost />
        )}
      </StompSessionProvider>
    </>
  );
}
