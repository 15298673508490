import styles from "./PlanningNextTrip.styles.module.css";
import NextTripBanner from "../../../assets/illustrations/destination/destination_intermediate_banner/background_next_trip.png";
import { useContext } from "react";
import { ContactModalContext } from "../../../context/contactModal.context";

const PlanningNextTripBanner = () => {
  const { toggleModal } = useContext(ContactModalContext);

  return (
    <section className={styles.nextTrip}>
      <div
        style={{ background: `url(${NextTripBanner})` }}
        className={styles.nextTripBanner}
      />
      <div className={styles.nextTripContent}>
        <header>
          {" "}
          <h2> Planning Your Next Trip to Uttarakhand</h2>
        </header>
        <p>
          {/* Plan your tour to Uttarakhand with our handpicked travel itineraries
          and tour packages. Browse packages of your choice by destination or
          number of days you have for travel. */}
          {/* changed from above to below */}
          Planning your trip to Uttarakhand becomes so much easier with a
          well-structured itinerary. I’d definitely suggest looking into
          Uttarakhand tour packages that match your interests—whether you're
          into adventure, leisure, or spirituality, there’s something for
          everyone!
        </p>
        <button type="button" onClick={toggleModal}>
          Get A CallBack
        </button>
      </div>
    </section>
  );
};

export default PlanningNextTripBanner;
