import styles from "./DestinationHowToReach.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"

import Air from "../../../../assets/illustrations/destination/mock_card_images/travel_by_air.png"
import Rail from "../../../../assets/illustrations/destination/mock_card_images/travel_by_rail.png"
import Road from "../../../../assets/illustrations/destination/mock_card_images/travel_by_road.png"
import parser from "html-react-parser"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import { useContext, useLayoutEffect } from "react"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const travelModes = [
  {
    imgSrc: Air,
    title: "How To Reach Uttarakhand By Air",
    content: `The State of Uttarakhand has two airports.  The first one is
                Jolly Grant Airport which is located in Dehradun and the second
                Airport is Naini Saini Airport which is located in Pithoragarh.
                <p>
                  The Jolly Grant Airport is extremely well-connected and
                  connects to most of the major cities in India.
                </p>`,
  },
  {
    imgSrc: Rail,
    title: "How to Reach Uttarakhand By Rail",
    content: `Uttarakhand is also very well connected by rail. 16 large and small railway stations connect different cities and towns in Uttarakhand with major Indian cities such as Delhi, Kolkata Lucknow, and so on.<p>The three main stations for tourists to disembark on the Uttarakhand route are Dehradun, Haridwar, and Rishikesh, as well as Kathgodam near Haldwani, which is also important starting point for a lot of tricks.</p>`,
  },
  {
    imgSrc: Road,
    title: "How to Reach Uttarakhand By Bus",
    content: `The road remains the best way to go to Uttarakhand. The state is very well connected through state and national highways.
<p>While you can definitely use the abundantly connected public bus transport, having a taxi or cab at your disposal is definitely recommended as you traverse through the hilly terrain.</p>`,
  },
]

const DestinationHowToReach = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("travelBy")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            How To Reach <span>Uttarakhand</span>
          </header>
          <div className={globalStyles.content}>
            If you are looking for a weekend getaway or to spend quality time
            with your family, Uttarakhand is the perfect tourist destination for
            you.  As you plan your vacation to Uttarakhand, this land of a
            mixture of mountains, grasslands, and hilly terrains awaits you.
            <p>
              As you plan your Uttarakhand route, it is important to know that
              the fast-emerging tourist destination is extremely accessible
              through rail, road, and air.
            </p>
          </div>
        </article>
        <article className={styles.travelModes}>
          <div className={styles.travelCardList}>
            {travelModes.map((mode, idx) => (
              <div className={styles.travelCard} key={idx}>
                <img src={mode.imgSrc} />
                <div className={styles.description}>
                  <h3>{mode.title}</h3>
                  <div className={styles.instructions}>
                    {parser(mode.content)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </article>
        <ImageAndContentCard>
          <ImageAndContentCard.Title>
            How to reach Uttarakhand from Major Cities in India
          </ImageAndContentCard.Title>
          <ImageAndContentCard.Content>
            The cheapest option to reach Uttarakhand from Delhi is to take a bus
            with leaves from ISBT Kashmiri Gate or Anand Vihar ISBT which takes
            approximately 5-7 hours to reach from Delhi to Dehradun. You can
            either take a Volvo or a State Transport bus. If you are wondering
            how to reach Uttarakhand from Delhi by train, the best way is to
            take the Shatabdi train which leaves in the morning and takes
            approximately 6 hours.
            <p>
              The other popular trains available are Nanda Devi Express and
              Dehradun Express, you can also take a flight from Delhi to Jolly
              Grant Airport in Dehradun, which would be the fastest way to reach
              Uttarakhand.
            </p>
            <p>
              If you are starting your journey from Mumbai and are wondering
              what the best way is to reach Uttarakhand from Mumbai, you have
              the option of taking a flight to Dehradun or a train. The Dehradun
              Express would take 32.5 hours to reach Dehradun from Delhi,
              whereas the flight would consume 2.5 hours.
            </p>
            If you are planning how to reach Uttarakhand from Bangalore, then
            the best way would be to travel to Delhi by train or flight and then
            disembark for an onward journey to Uttarakhand. You can also take a
            flight to Dehradun. For those of you who are planning to reach
            Uttarakhand from Hyderabad, you would also need to travel to Delhi
            by train or flight and plan your journey ahead.
          </ImageAndContentCard.Content>
        </ImageAndContentCard>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationHowToReach
