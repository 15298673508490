import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "./ImageSlider.css"
import { baseurl } from "../../Service/httpCommon"
import Api from "../../Service/Api"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { handleChangeSearchParams } from "../../redux/actions/bookingRoomAction"
export default function ImageSliderLast() {
  const [LastMinute, setLastMinute] = useState([])
  const [deviceInnerWidth, setDeviceInnerWidth] = useState(window.innerWidth)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleWindowSizeChange = () => {
    setDeviceInnerWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    Api.get("agentlastminutedealactive").then((res) => {
      setLastMinute(res.data)
      console.log("last minute deals", res.data)
    })
  }, [])
  var settings = {
    dots: false,
    slidesToShow: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    infinite: LastMinute.length > 3,
  }

  return (
    <div className="imm">
      <Slider {...settings}>
        {LastMinute.map((item, index) => (
          <div
            className="card-item"
            key={index}
            onClick={() => {
              dispatch(
                handleChangeSearchParams("location", {
                  label: item.city,
                  value: item.city,
                })
              )
              dispatch(
                handleChangeSearchParams("propertyId", {
                  label: item.propertyName,
                  value: item.propertyId,
                })
              )
              history.push(
                `/konnect/propertyNewView/${item.city}/${item.propertyId}`
              )
            }}
          >
            <div className="card-inner">
              <div className="card-top">
                {item.topLeftLabel && (
                  <span style={{ borderRadius: "0 5px 5px 0" }}>
                    {item.topLeftLabel}
                  </span>
                )}
                <img
                  src={`${baseurl}getimage/${item.image}`}
                  alt="HotelImage"
                  style={{
                    height: "10%",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    width: "100%",
                    height: "150px",
                  }}
                />
              </div>
              <div className="card-bottom">
                <div className="card-info">
                  <h5>{item.propertyName}</h5>
                  <p>{item.city}</p>

                  <div>
                    <span
                      style={{
                        fontWeight: "normal",
                        width: "100%",
                        fontSize: "14px",
                        marginRight: "2%",
                      }}
                    >
                      Starting From
                    </span>
                    <span
                      style={{
                        color: "#f46d25",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      Rs.{item.minmumprice}
                    </span>
                  </div>
                  <div style={{ width: "40%", display: "inline-block" }}>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Per Night
                    </span>
                  </div>
                  {item.offerVaild !== null &&
                  item.offerVaild !== "undefined" &&
                  item.offerVaild !== "" ? (
                    <div style={{ width: "60%", display: "inline-block" }}>
                      <p
                        style={{
                          background: "#f46d25",
                          color: "#fff",
                          padding: "4px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {item.offerVaild}
                      </p>
                    </div>
                  ) : null}

                  <div style={{ width: "40%", display: "inline-block" }}>
                    {item.offerPercentage !== 0 ? (
                      <span
                        style={{
                          background: "#f46d25",
                          color: "#fff",
                          padding: "4px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {item.offerPercentage} % Off
                      </span>
                    ) : null}
                  </div>
                  {item.offerVaild !== null &&
                  item.offerVaild !== "undefined" &&
                  item.offerVaild !== "" ? (
                    <div style={{ width: "60%", display: "inline-block" }}>
                      <span
                        style={{
                          color: "#f46d25",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {item.offerLabel}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
