import styles from "./homeCardItem.module.css"

function HomeCardItem({
  props,
  style,
  className,
  children,
  onClick,
  type = null,
}) {
  return (
    <div
      {...props}
      onClick={onClick}
      style={{ ...style, cursor: onClick ? "pointer" : "default" }}
      className={`${styles.homeCard} ${
        type == "limited" ? styles.limitedType : null
      }`}
    >
      {children}
    </div>
  )
}

function HomeCardImgItem(props) {
  return <img {...props} className={styles.homeCardImgItem} />
}

function Overlay({ props, children, style }) {
  return (
    <div style={style} {...props}>
      {children}
    </div>
  )
}

HomeCardItem.Img = HomeCardImgItem
HomeCardItem.Overlay = Overlay

export default HomeCardItem
