/**assests import */

import React, {
  lazy,
  memo,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Home.styles.css";

import Group from "../../assets/illustrations/ourBlog/Group.png";
import Image1 from "../../assets/illustrations/ourBlog/Rectangle 4389.png";
import Image2 from "../../assets/illustrations/ourBlog/Rectangle 4391.png";
import Image3 from "../../assets/illustrations/ourBlog/Rectangle 4392.png";
import Image4 from "../../assets/illustrations/ourBlog/Rectangle 4394.png";
import Image5 from "../../assets/illustrations/ourBlog/Rectangle 4395.png";

import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import {
  ArrowRightAlt,
  CallMade,
  Person,
  PhoneRounded,
  Room,
  StarRate,
} from "@material-ui/icons";
import Slider from "react-slick";
import { wait } from "../../../utils/wait";
import KathmanduHero from "../../assets/homePageAssets/Hero Section 1/kathmandu.jpg";
import DubaiHero from "../../assets/illustrations/home_page_b2c_Hero_ section/dubai.jpg";
import GoaHero from "../../assets/illustrations/home_page_b2c_Hero_ section/Goa.jpg";
import ThailandHero from "../../assets/illustrations/home_page_b2c_Hero_ section/Thailand.jpg";
import VietnamHero from "../../assets/illustrations/home_page_b2c_Hero_ section/vietnam.jpg";
import IndiaMap from "../../assets/illustrations/india-map.png";
import WorldMap from "../../assets/illustrations/World_Map.png";
import { ContactModalContext } from "../../context/contactModal.context";
import { currencyFormatter } from "../../utility/currencyFormatter";
import styles from "./Homes.styles.module.css";

import CIcon from "../../assets/homePageAssets/Top Values 2/customized.png";
import EIcon from "../../assets/homePageAssets/Top Values 2/Experiential.png";
import TIcon from "../../assets/homePageAssets/Top Values 2/Trustworthy.png";

import Dubai from "../../assets/homePageAssets/Popular destination 4/dubai.jpg";
import Phuket from "../../assets/homePageAssets/Popular destination 4/phuket thailand.jpg";
import Srinagar from "../../assets/homePageAssets/Popular destination 4/srinagar.jpg";
import UttarakhandMPD from "../../assets/homePageAssets/Popular destination 4/Uttarakhand.jpg";
import ThailandMPD from "../../assets/homePageAssets/Popular destination 4/thailand.jpg";
import VietnamMPD from "../../assets/homePageAssets/Popular destination 4/vietnam.jpg";

import PattBan from "../../assets/homePageAssets/Explore international packages 5/bangkok.jpg";
import DubaiInt from "../../assets/homePageAssets/Explore international packages 5/dubai.jpg";
import KathmanduInt from "../../assets/homePageAssets/Explore international packages 5/kathmandu.jpg";
import SingaporeInt from "../../assets/homePageAssets/Explore international packages 5/Singapore.jpg";
import Vietnam from "../../assets/homePageAssets/Explore international packages 5/vietnam.jpg";
import HomeCardItem from "../../components/HomeCardItem/homeCardItem.component";

import PhuketBgMD from "../../assets/homePageAssets/dealsYouCantMiss/bg.jpg";
import PhuketMD from "../../assets/homePageAssets/dealsYouCantMiss/phuket krabi.jpg";

import { calculateTimeDifference } from "../../../utils/relativeTime";

import { Grid } from "@mui/material";
import LoaderEyeVariant from "../../../components/LoaderEyeV/loaderEyeVariant.component";
import { useInViewObserver } from "../../../hooks/useInView";
import { useDeviceWidth } from "../../../hooks/useWidth";
import GoaDL from "../../assets/homePageAssets/Domestic packages 6/Goa.webp";
import HimachalDL from "../../assets/homePageAssets/Domestic packages 6/himanchal pradesh.jpg";
import UttarkhandDL from "../../assets/homePageAssets/Domestic packages 6/uttarakhand.jpg";
import HeroCTAForm from "../../components/CTAForms/HeroCTA/HeroCTAForm.component";
import { CustomSlickSlider } from "../../components/CustomSlickSlider/customSlickSlider.component";
import LeftNavArrow from "../../components/LeftNavArrow/leftNavArrow.component";
import RightNavArrow from "../../components/RightNavArrow/rightNavArrow.component";

import { NavLink } from "react-router-dom";
import AffliationSection from "../../components/AffliationSection/affliationSection.component";
import { submitCTAForm } from "../../../Service/api/ctaFormApi";
import { toast } from "react-toastify";
import { useNotification } from "../../../hooks/useNotification";

const MonthlyDealsSection = lazy(() =>
  wait(200).then(() =>
    import("../../components/MonthDealsSection/monthlyDeals.component")
  )
);
const TravelItineries = lazy(() =>
  wait(200).then(() =>
    import("../../components/TravelItineries/travelItineries.component")
  )
);

const NewOfferBanner = lazy(() =>
  wait(200).then(() =>
    import("../../components/NewOfferBanner/newOfferBanner.component")
  )
);

const ExperientialComponent = lazy(() =>
  wait(200).then(() =>
    import("../../components/ExperientialSection/experiential.component")
  )
);
const TravelOptions = lazy(() =>
  wait(200).then(() =>
    import("../../components/TravelOptionsSection/travelsOptions.component")
  )
);

const TestimonialSection = lazy(() =>
  wait(200).then(() =>
    import("../../components/TestimonialSection/testimonialSection.component")
  )
);

const BookForHolidayForm = lazy(() =>
  wait(200).then(() =>
    import("../../components/BookForHolidayForm/bookForHolidayForm.component")
  )
);

const FooterNewV2 = lazy(() =>
  wait(200).then(() =>
    import("../../components/FooterNewV2/footerNewV2.component")
  )
);

const useStyles = makeStyles((theme) => ({
  sliderContainerHero: {
    width: "57%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& .slick-list > .slick-track": {
      display: "flex !important",
      gap: "1.2rem",
    },
    "& .slick-slider > button": {
      display: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // "@media (min-width:320px) and (max-width:375px)": {
    //   height: "100px",
    // },
  },
  heroCarouselCard: {
    width: "100%",
    aspectRatio: "1/1.4",
    padding: "0.2rem",
    borderRadius: "0.7rem",
    overflow: "hidden",
    backgroundColor: "#fff",
    cursor: "pointer",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "0.5rem",
    },
  },

  navContainer: {
    display: "flex",
    gap: "1rem",
    padding: "0rem 0rem 0rem 2rem",
  },

  navArrowContainer: {
    display: "flex",
    gap: "0.5rem",
    justifyContent: "space-around",
  },

  slickTracker: {
    // flex: "1 1 0",
    minHeight: "0.1rem",
    background: "#fff",
    width: "70%",
    alignSelf: "center",
    position: "relative",
    // border: "1px solid #fff",
  },
  tracker: {
    position: "absolute",
    inset: 0,
  },
  domesticNavContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0rem !important",
    },
  },
  domesticContent: {
    margin: 0,
    textAlign: "center",
    fontWeight: "normal",
    padding: "0rem 2rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      padding: "0rem 1rem",
    },
  },

  sliderStyling: {
    zIndex: 10,
  },

  parent: {
    // "& > *": {
    //   border: '1px solid red'
    // }
  },
  dlteHeader: {
    fontSize: "3.125rem",
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      textAlign: "left !important",
      fontSize: "1.75rem !important",
      fontWeight: 600,
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  intlHeader1: {
    fontSize: "3.125rem !important",
    fontWeight: "bold",
    fontFamily: "Poppins",
    minWidth: "45%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      textAlign: "left !important",
      fontSize: "1.75rem !important",
      fontWeight: 600,
    },
  },
  domesticHeader: {
    fontSize: "3.125rem !important",
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left !important",
      padding: "2rem 1rem 0rem 1rem",
      fontSize: "1.75rem !important",
      fontWeight: 600,
    },
  },
}));

const heroSectionSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const illustrationCollection = [Group, Image1, Image2, Image3, Image4, Image5];

const blogData = illustrationCollection.map((v) => ({ imgPath: v }));

function usePrevious(value) {
  const ref = useRef(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref?.current;
}

const topSectionCardsContent = [
  {
    Icon: CIcon,
    header: "Customized",
    body: "Experience the perfect getaway with our tailored holiday packages, designed to match your preferences and create lasting memories.",
  },
  {
    Icon: EIcon,
    header: "Experiential",
    body: "Our experiential holiday packages are carefully designed to bring you unique local flavour and personalized moments.",
  },
  {
    Icon: TIcon,
    header: "Trustworthy",
    body: "Discover peace of mind with our exclusive network of hotel partners, ensuring the highest standards of hospitality.",
  },
];

const dealsWeCantMissCardsContent = [
  {
    src: UttarakhandMPD,
    country: "",
    location: "Uttarakhand",
    translateX: 10,
    hasRoutes: true,
  },
  {
    src: GoaHero,
    country: "",
    location: "Goa",
    translateX: 10,
    hasRoutes: false,
  },
  {
    src: ThailandMPD,
    country: "",
    location: "Thailand",
    translateX: 0,
    hasRoutes: false,
  },
  {
    src: Vietnam,
    country: "",
    location: "Vietnam",
    translateX: -10,
    hasRoutes: false,
  },
  {
    src: UttarakhandMPD,
    country: "",
    location: "Uttarakhand",
    translateX: 10,
    hasRoutes: true,
  },
  {
    src: ThailandMPD,
    country: "",
    location: "Thailand",
    translateX: 0,
    hasRoutes: false,
  },
  {
    src: Vietnam,
    country: "",
    location: "Vietnam",
    translateX: -10,
    hasRoutes: false,
  },
];

const dealsYouCantMiss = [
  {
    location: "Phuket/Krabi",
    price: 23725,
    src: PhuketMD,
    bgSrc: PhuketBgMD,
  },
  { location: "Dubai", price: 27500, src: DubaiInt, bgSrc: DubaiHero },
  {
    location: "Nepal (Kathmandu - Pokhara)",
    price: 17000,
    src: KathmanduInt,
    bgSrc: KathmanduHero,
  },
  {
    location: "Singapore",
    price: 41537,
    src: SingaporeInt,
    bgSrc: SingaporeInt,
  },
  {
    location: "Scenic Himachal",
    price: 19500,
    src: HimachalDL,
    bgSrc: HimachalDL,
  },
];

const Home = () => {
  const classes = useStyles();
  const { toggleModal } = useContext(ContactModalContext);
  const [openHeroCtaForm, setOpenHeroCtaForm] = useState(false);
  const internationalPackageSliderRef = useRef(null);
  const domesticPackageSliderRef = useRef(null);
  const [popularDomesticLocations, setPoularDomesticLocations] = useState([
    {
      posX: 19,
      posY: 26,
      content: 1,
      scale: 1,
      country: "Uttarakhand",
      location: "Uttarakhand",
      description: "2N Haridwar - 2N Mussoorie - 2D Rishikesh-Haridwar",
      price: 15500,
      night: 3,
      day: 4,
      rating: 5.0,
      reviewsCount: 43,
      imgSrc: UttarkhandDL,
    },
    {
      posX: 16.5,
      posY: 20,
      content: 2,
      scale: 2,
      country: "Himachal Pradesh",
      location: "Scenic Himachal ",
      description: "2N Dalhousie - 2N Dharamshala",
      price: 19500,
      night: 4,
      day: 5,
      rating: 4.9,
      reviewsCount: 86,
      imgSrc: HimachalDL,
    },
    {
      posX: 12,
      posY: 69,
      content: 3,
      scale: 2,
      country: "Goa",
      location: "Goa",
      description: "3N Goa",
      price: 14900,
      night: 3,
      day: 4,
      rating: 4.5,
      reviewsCount: 98,
      imgSrc: GoaDL,
    },
    {
      posX: 19,
      posY: 26,
      content: 1,
      scale: 1,
      country: "Uttarakhand",
      location: "Uttarakhand",
      description: "2N Haridwar - 2N Mussoorie - 2D Rishikesh-Haridwar",
      price: 15500,
      night: 3,
      day: 4,
      rating: 5.0,
      reviewsCount: 43,
      imgSrc: UttarkhandDL,
    },
    {
      posX: 16.5,
      posY: 20,
      content: 2,
      scale: 2,
      country: "Himachal Pradesh",
      location: "Scenic Himachal ",
      description: "2N Dalhousie - 2N Dharamshala",
      price: 19500,
      night: 4,
      day: 5,
      rating: 4.9,
      reviewsCount: 86,
      imgSrc: HimachalDL,
    },
    {
      posX: 12,
      posY: 69,
      content: 3,
      scale: 2,
      country: "Goa",
      location: "Goa",
      description: "3N Goa",
      price: 14900,
      night: 3,
      day: 4,
      rating: 4.5,
      reviewsCount: 98,
      imgSrc: GoaDL,
    },
  ]);

  // const offerTagRef = useRef(null)

  // useEffect(() => {
  //   function callBack(entries) {
  //     entries.forEach((entry) => {
  //       setIsIntersecting(entry.isIntersecting)
  //     })
  //   }
  //   const options = {
  //     threshold: 1,
  //   }
  //   const observer = new IntersectionObserver(callBack, options)

  //   const element = offerTagRef.current

  //   console.log(element)

  //   observer.observe(element)

  //   if (isIntersecting) {
  //     element.classList.add("show-offer")
  //     console.log("inside")
  //   }

  //   return () => {
  //     element.classList.remove("show-offer")
  //     observer.disconnect()
  //   }
  // }, [isIntersecting])

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [items, setItems] = useState([
    {
      key: 1,
      value: "Item 3",
      left: 0,
      scaleX: 1,
      imgSrc: GoaHero,
      location: "Goa",
      locationStay: "3N | 4D",
      rating: "5.0 | 98",
      content:
        "Explore Goa, a coastal paradise known for its stunning beaches, vibrant nightlife, and rich Portuguese heritage. With our customized travel packages, you can create a personalized itinerary that includes relaxing by the Arabian Sea, exploring historic churches, and indulging in delicious Goan cuisine. Whether you’re traveling solo or with a group, Goa promises an unforgettable experience.",
      rate: 13999,
      description: "3N Phuket - 2N Krabi",
    },
    {
      key: 2,
      value: "Item 2",
      left: 0,
      scaleX: 1,
      imgSrc: ThailandHero,
      location: "Thailand",
      locationStay: "5N | 6D",
      rating: "4.9 | 325",
      content:
        "Discover the vibrant culture, stunning beaches, and delicious cuisine of Thailand. With our affordable Thailand travel packages, you can experience the beauty of this tropical paradise without breaking the bank. From the bustling streets of Bangkok to the serene islands of Phuket, Thailand is a must-visit destination!",
      rate: 23725,
      description: "3N Phuket - 2N Krabi",
    },
    // {
    //   key: 2,
    //   value: "Item 3",
    //   left: 0,
    //   scaleX: 1,
    //   imgSrc: KathmanduHero,
    //   location: "Nepal",
    //   locationStay: "4N | 5D",
    //   rating: "5.0 | 98",
    //   content:
    //     "Explore Nepal, a country known for its breathtaking landscapes and rich cultural heritage. With our customized travel packages, you can create a personalized itinerary that includes thrilling Himalayan treks and visits to ancient temples. Whether you’re traveling solo or with a group, Nepal promises an unforgettable experience.",
    //   rate: 23275,
    //   description: "3N Phuket - 2N Krabi",
    // },

    {
      key: 3,
      value: "Item 4",
      left: 0,
      scaleX: 1,
      imgSrc: VietnamHero,
      location: "Vietnam",
      locationStay: "6N | 7D",
      rating: "4.7 | 156",
      content:
        "Discover Vietnam’s rich history and vibrant culture. From the bustling streets of Hanoi to the tranquil beauty of Ha Long Bay, our best travel itineraries ensure you make the most of your trip. Enjoy delicious street food, explore historic sites, and immerse yourself in the local way of life.",
      rate: 23275,
      description: "3N Phuket - 2N Krabi",
    },
    {
      key: 0,
      value: "Item 1",
      left: 0,
      scaleX: 1,
      imgSrc: DubaiHero,
      location: "Dubai",
      locationStay: "3N | 4D",
      rating: "4.9 | 125",
      content:
        "Experience the ultimate blend of luxury and adventure in Dubai. From the iconic Burj Khalifa to thrilling desert safaris, Dubai offers experiences like no other. Our customized travel packages let you design a trip that matches your preferences, ensuring a truly memorable escape.",
      rate: 23275,
      description: "3N Phuket - 2N Krabi",
    },
  ]);
  const [currentItem, setCurrentItem] = useState(items[0]);
  const [transitioning, setTransitioning] = useState(false);
  const carouselItemList = useRef(null);
  const [scaleBackground, setScaleBackground] = useState(1);
  const [bgOpacity, setBgOpacity] = useState(1);
  const [showPointer, setShowPointer] = useState(true);

  const [countDown, setCountDown] = useState(calculateTimeDifference());

  const [currentDomesticPointerPos, setCurrentDomesticPointerPos] = useState([
    popularDomesticLocations[0].posX,
    popularDomesticLocations[0].posY,
  ]);

  const calculateOffsets = () => {
    const children = carouselItemList?.current?.children;
    return Array?.from(children)?.map((child) => child?.offsetLeft);
  };

  const nextItem = () => {
    if (carouselItemList.current != null) {
      carouselItemList.current.slickNext();
    }
  };

  const previousItem = () => {
    if (carouselItemList.current != null) {
      carouselItemList.current.slickPrev();
    }
  };

  const popularDestinationSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
        },
      },
    ],
  };

  const internationalPackagesSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 5,
    speed: 1000,
    dots: true,
    afterChange: () => console.log("Hello"),
    focusOnSelect: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dealYouCantMissSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const [internationalPkg, setInternationalPkg] = useState([
    {
      posX: -110,
      posY: 30,
      content: 1,
      scale: 3,
      country: "Vietnam",
      location: "Vietnam",
      description: "2D Ha noi - 1D Ha long - 2D Da nang - 2D Ho chi minh",
      price: 24229,
      night: 6,
      day: 7,
      rating: 4.9,
      reviewsCount: 78,
      imgSrc: Vietnam,
    },
    {
      posX: -65,
      posY: 10,
      content: 2,
      scale: 2,
      country: "Thailand",
      location: "Pattaya-Bangkok",
      description: "2N Pattaya - 2N Bangkok",
      price: 23434,
      night: 4,
      day: 5,
      rating: 5.0,
      reviewsCount: 98,
      imgSrc: PattBan,
    },
    {
      posX: -5,
      posY: 16,
      content: 3,
      scale: 1,
      country: "United Arab Emirates",
      location: "Dubai",
      description: "4D Dubai",
      price: 27500,
      night: 3,
      day: 4,
      rating: 5.0,
      reviewsCount: 156,
      imgSrc: DubaiInt,
    },
    {
      posX: -47,
      posY: 33,
      content: 4,
      scale: 2,
      country: "Nepal",
      location: "Kathmandu-Pokhara",
      description: "2N Kathmandu - 2N Pokhara",
      price: 17000,
      night: 4,
      day: 5,
      rating: 4.9,
      reviewsCount: 86,
      imgSrc: KathmanduInt,
    },
    {
      posX: -68.5,
      posY: -8,
      content: 5,
      scale: 2,
      country: "Singapore",
      location: "Singapore",
      description: "4D Singapore",
      price: 41537,
      night: 3,
      day: 4,
      rating: 4.8,
      reviewsCount: 51,
      imgSrc: SingaporeInt,
    },
    {
      posX: -110,
      posY: 30,
      content: 1,
      scale: 3,
      country: "Vietnam",
      location: "Vietnam",
      description: "2D Ha noi - 1D Ha long - 2D Da nang - 2D Ho chi minh",
      price: 24229,
      night: 6,
      day: 7,
      rating: 4.9,
      reviewsCount: 78,
      imgSrc: Vietnam,
    },
    {
      posX: -65,
      posY: 10,
      content: 2,
      scale: 2,
      country: "Thailand",
      location: "Pattaya-Bangkok",
      description: "2N Pattaya - 2N Bangkok",
      price: 23434,
      night: 4,
      day: 5,
      rating: 5.0,
      reviewsCount: 98,
      imgSrc: PattBan,
    },
    {
      posX: -5,
      posY: 16,
      content: 3,
      scale: 1,
      country: "United Arab Emirates",
      location: "Dubai",
      description: "4D Dubai",
      price: 27500,
      night: 3,
      day: 4,
      rating: 5.0,
      reviewsCount: 156,
      imgSrc: DubaiInt,
    },
    {
      posX: -47,
      posY: 33,
      content: 4,
      scale: 2,
      country: "Nepal",
      location: "Kathmandu-Pokhara",
      description: "2N Kathmandu - 2N Pokhara",
      price: 17000,
      night: 4,
      day: 5,
      rating: 4.9,
      reviewsCount: 86,
      imgSrc: KathmanduInt,
    },
    {
      posX: -68.5,
      posY: -8,
      content: 5,
      scale: 2,
      country: "Singapore",
      location: "Singapore",
      description: "4D Singapore",
      price: 41537,
      night: 3,
      day: 4,
      rating: 4.8,
      reviewsCount: 51,
      imgSrc: SingaporeInt,
    },
  ]);

  const [posX, setPosX] = useState(internationalPkg[0].posX);
  const [posY, setPosY] = useState(internationalPkg[0].posY);
  const [scaleMapOne, setScaleMapOne] = useState(internationalPkg[0].scale);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(calculateTimeDifference());
    }, 1000);

    return () => clearInterval(interval);
  });

  const { isIntersecting: mdIsIntersecting, containerRef: mdContainerRef } =
    useInViewObserver();
  const { isIntersecting: tiIsIntersecting, containerRef: tiContainerRef } =
    useInViewObserver();
  const { isIntersecting: nobIsIntersecting, containerRef: nobContainerRef } =
    useInViewObserver();
  const { isIntersecting: ehIsIntersecting, containerRef: ehContainerRef } =
    useInViewObserver();
  const { isIntersecting: toIsIntersecting, containerRef: toContainerRef } =
    useInViewObserver();
  const { isIntersecting: tstIsIntersecting, containerRef: tstContainerRef } =
    useInViewObserver();
  const { isIntersecting: bfnIsIntersecting, containerRef: bfnContainerRef } =
    useInViewObserver();
  const { isIntersecting: fnIsIntersecting, containerRef: fnContainerRef } =
    useInViewObserver();

  const width = useDeviceWidth();
  const [ctaFormCurrentItem, setCTAFormCurrentItem] = useState(null);

  return (
    <section
      style={{ boxSizing: "border-box", fontFamily: "Poppins" }}
      className={classes.parent}
    >
      <section className="home-header-section">
        <div
          className="current-content"
          style={{
            transform: `scale(${scaleBackground})`,
            opacity: bgOpacity,
            transition: "all 1s ease-in-out",
            // transformOrigin: "left",
          }}
        >
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={currentItem.imgSrc}
          />
        </div>
        <div className="content">
          <div className="current-location-info">
            <header>
              <div>Holiday Like a Local</div>
              <h2
                style={{
                  color: "#fff",
                  padding: "0rem 0rem 0.5rem 0rem",
                  margin: "0",
                }}
              >
                {currentItem.location}
              </h2>
            </header>
            <div
              className="body"
              style={{
                textShadow: "0rem 0.2rem 0.3rem #000",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: "1rem" }}>{currentItem.content}</div>
              <div
                className="location-stay-info"
                style={{
                  display: "flex",
                  gap: "12rem",
                  fontSize: "clamp(0.6rem, 0.188vw + 0.6rem, 0.9rem)",
                  alignItems: "center",
                  padding: "1rem 0rem",
                }}
              >
                <span
                  style={{
                    background: "rgba(255, 239, 231, 1)",
                    color: "#000",
                    padding: "0.2rem",
                    textShadow: "none",
                  }}
                >
                  {currentItem.locationStay}
                </span>
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  <StarRate style={{ color: "goldenrod" }} />
                  {currentItem.rating}
                </span>
              </div>
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.2)",
                  padding: "0.4rem",
                  borderRadius: "0.4rem",
                  fontSize: "clamp(0.75rem, 0.335vw + 0.552rem, 0.9rem)",
                }}
              >
                {currentItem.description}
              </div>
              <div style={{ padding: "0.5rem 0rem" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <div
                    style={{
                      fontSize: "clamp(0.656rem, 0.231vw + 0.656rem, 0.813rem)",
                    }}
                  >
                    Starting
                    <br />
                    From
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "clamp(0.75rem, 1.111vw + 0.75rem, 1.5rem)",
                    }}
                  >
                    {currencyFormatter(currentItem.rate)}/-
                  </div>
                  <Person />
                </div>
              </div>
              <div className="contact">
                <a href="tel:+918006800727" style={{ textDecoration: "none" }}>
                  {" "}
                  <IconButton style={{ border: "1px solid #fff" }}>
                    <PhoneRounded
                      style={{ color: "#fff", height: "1rem", width: "1rem" }}
                    />
                  </IconButton>
                </a>
                <Button
                  className="linear-gradient-button"
                  onClick={() => {
                    setOpenHeroCtaForm(true);
                    setCTAFormCurrentItem(currentItem);
                  }}
                >
                  Book Now
                </Button>
              </div>
            </div>
            <HeroCTAForm
              open={openHeroCtaForm}
              onClose={() => setOpenHeroCtaForm(false)}
              item={ctaFormCurrentItem}
              onSubmit={async (
                values,
                setSubmitting,
                setValid,
                showNotification
              ) => {
                setSubmitting(true);
                try {
                  const res = await submitCTAForm(values);
                  if (res.status == 200) {
                    showNotification(
                      "Thank you for submitting the form. We have received your details and will get back to you soon.",
                      "success"
                    );

                    return;
                  } else {
                    return Promise.reject(res);
                  }
                } catch (e) {
                  showNotification(
                    "An Error occured. Please try again after sometime.",
                    "error"
                  );
                } finally {
                  setSubmitting(false);
                  setValid(false);
                  setOpenHeroCtaForm(false);
                }
              }}
            />
          </div>
          {/* <div
            className="carousel-container"
            style={{ "--item-index": currentItemIndex }}
          >
            <ul className="carousel-items-list" ref={carouselItemList}>
              {items.map((v, i) => (
                <li
                  className="carousel-item"
                  key={v.key}
                  style={{
                    transform: `translateX(${v.left}px)  scale(${v.scaleX})`,
                    zIndex: v?.zIndex,
                    transition: v.left ? "all 300ms ease-out" : "none",
                    transformOrigin: "center",
                    position: "relative",
                  }}
                  data-key={v.key}
                >
                  <img
                    src={v.imgSrc}
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </li>
              ))}
            </ul>

            <div className="slider-section">
              <div className="slider-buttons">
                <IconButton
                  onClick={previousItem}
                  size="small"
                  style={{
                    border: "1px solid #fff",
                    backdropFilter: "blur(2rem)",
                  }}
                  disabled={transitioning}
                >
                  <ArrowRightAlt style={{ fill: "#fff", rotate: "180deg" }} />
                </IconButton>

                <IconButton
                  onClick={nextItem}
                  size="small"
                  style={{
                    border: "1px solid #fff",
                    backdropFilter: "blur(2rem)",
                  }}
                  disabled={transitioning}
                >
                  <ArrowRightAlt style={{ fill: "#fff" }} />
                </IconButton>
              </div>
              <div className="slider-indicator" />
            </div>
          </div> */}
          <div className={classes.sliderContainerHero}>
            <Slider
              {...heroSectionSliderSettings}
              ref={carouselItemList}
              beforeChange={(oldIndex, newIndex) => {
                setCurrentItemIndex(newIndex);
                setCurrentItem(items[newIndex]);
              }}
            >
              {items.map((item, idx) => (
                <div className={classes.heroCarouselCard}>
                  <img src={item.imgSrc} />
                </div>
              ))}
            </Slider>
            <div className={classes.navContainer}>
              <div className={classes.navArrowContainer}>
                <IconButton
                  style={{
                    border: "2px solid #fff",
                    rotate: "180deg",
                    padding: "0.4rem",
                    transform: "rotate(45deg)",
                  }}
                  onClick={previousItem}
                  size="small"
                >
                  <CallMade style={{ fill: "#fff" }} />
                </IconButton>

                <IconButton
                  style={{
                    border: "2px solid #fff",
                    padding: "0.4rem",
                    transform: "rotate(45deg)",
                  }}
                  onClick={nextItem}
                  size="small"
                >
                  <CallMade style={{ fill: "#fff" }} />
                </IconButton>
              </div>
              <div className={classes.slickTracker}>
                <div
                  className={classes.tracker}
                  style={{
                    width: `${100 / items.length}%`,
                    background: "#f46d25",
                    left: `${(currentItemIndex * 100) / items.length}%`,
                    transition: "left 250ms ease-out",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Affordable travel package section */}
      <section className={styles.topValuesSection}>
        <div className={styles.topValuesContent}>
          <div className={styles.topSectionInfo}>
            <div style={{ color: "#f46d25" }} className={styles.subHeader}>
              Why Towno is Best for
            </div>
            <h1 className={styles.header}>Affordable Travel Packages</h1>
            <div style={{ fontSize: "1rem" }}>
              We specialize in delivering affordable travel packages that cater
              to your unique preferences and aspirations.
            </div>
          </div>

          <div className={styles.topSectionCardsContainer}>
            {topSectionCardsContent.map((card, index) => (
              <div className={styles.topSectionCard}>
                <div style={{ width: "3rem" }}>
                  <img src={card.Icon} style={{ width: "100%" }} />
                </div>
                <h3 style={{ margin: 0 }}>{card.header}</h3>
                <div
                  style={{
                    fontSize: "0.85rem",
                    lineHeight: "1.2rem",
                  }}
                >
                  {card.body}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/**Affliation section starts */}
      <section>
        <AffliationSection />
      </section>

      <section ref={mdContainerRef}>
        {mdIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                xs={12}
                textAlign={"center"}
                justifyContent={"center"}
                style={{ aspectRatio: "1/0.2" }}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <MonthlyDealsSection />
          </Suspense>
        )}
      </section>

      <section className="popular-destinations-container">
        <div className="section-info">
          <Typography variant="h2" className={classes.dlteHeader}>
            Destinations You Love to Explore!
          </Typography>
          <div style={{ lineHeight: "1.5rem" }}>
            Our travel experts recommend the places you just can't get enough
            of! From must-see attractions to hidden gems, our destination guides
            are packed with insider tips and personalized suggestions, inspired
            by what travelers like you love to discover. Your next favorite
            adventure starts here!
          </div>
          {/* <Button
            style={{
              color: "#fff",
              background:
                "linear-gradient(98.15deg, #FFF300 -52.88%, #F46D25 74.99%)",
              margin: "1rem 0rem",
            }}
          >
            View More
          </Button> */}
        </div>
        <div className="popular-slider">
          <CustomSlickSlider {...popularDestinationSliderSettings}>
            {dealsWeCantMissCardsContent.map((_, i) => (
              <div key={i} className={styles.exploreDestinationsCard}>
                <img
                  src={_.src}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                    borderRadius: "0.5rem",
                  }}
                />
                <div
                  className={styles.cardOverlay}
                  style={{
                    textAlign: "center",
                    padding: "2rem 0.5rem 1rem 0.5rem",
                    textShadow: "0rem 0.3rem 0.3rem #000",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div
                    style={{
                      transform: `translateX(${_.translateX}%)`,
                      fontSize: "1.1rem",
                    }}
                  >
                    {_.country}
                  </div> */}
                  <h2 className="dlteHeader">{_.location}</h2>

                  {_.hasRoutes && (
                    <NavLink
                      to={`/${_.location.toLowerCase()}`}
                      target="_blank"
                    >
                      <Button
                        style={{
                          background:
                            "linear-gradient(98.15deg, #FFF300 -52.88%, #F46D25 74.99%)",
                        }}
                        fullWidth
                      >
                        View
                      </Button>
                    </NavLink>
                  )}
                </div>
              </div>
            ))}
          </CustomSlickSlider>
        </div>
      </section>

      {/**Explore International Packages Starts*/}
      <section className="international-packages-section">
        <div className="world-map-image">
          <div className="location-pointer">
            <div></div>
          </div>
          <img
            src={WorldMap}
            style={{
              transform: `translate(${posX}%,${posY}%) scale(${scaleMapOne})`,
              transition: "all 0.5s ease-out",
            }}
          />
        </div>
        <div className="international-package-content">
          <div className="content-1">
            <Typography variant="h2" className={classes.intlHeader1}>
              Explore Our International packages
            </Typography>
            <div className="body">
              <div>
                Pack your bags on a world tour with our expertly crafted
                international tour packages, offering diverse experiences from
                serene landscapes to bustling cities, all at unbeatable prices!
              </div>
              {/* <Button className="linear-gradient-button" size="large">
                View More
              </Button> */}
            </div>
          </div>

          <div className="internation-packages-carousel">
            <Slider
              {...internationalPackagesSettings}
              beforeChange={(ci, ni) => {
                const currentItem = internationalPkg[ni];
                setPosX(currentItem?.posX);
                setPosY(currentItem?.posY);
                setScaleMapOne(currentItem.scale);
              }}
              ref={internationalPackageSliderRef}
              className={classes.sliderStyling}
            >
              {internationalPkg.map((_, i) => (
                <HomeCardItem key={`${_.country}-${i}`}>
                  <HomeCardItem.Img src={_.imgSrc} alt={_.location} />
                  <HomeCardItem.Overlay
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                      zIndex: "3",
                      // zIndex: 50,
                    }}
                  >
                    <div
                      className={styles.intlPackageCardContent}
                      style={{
                        zIndex: "10",
                        textShadow: "0rem 0.1rem 0.2rem #000",
                        padding: "0.7rem",
                        height: "95%", // added this line for ios safari website
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.2rem",
                          height: "100%", //added this line for ios safari website
                          justifyContent: "end", //changing this line space-betweeen to end for ios safari
                        }}
                      >
                        <div
                          style={{
                            fontSize:
                              "clamp(0.563rem, 0.352vw + 0.563rem, 0.8rem)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Room color="primary" />
                            <span>{_.country}</span>
                          </div>
                          <div
                            style={{
                              paddingRight: "0.8rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <StarRate color="primary" />
                            <span>
                              {_.rating} ({_.reviewsCount})
                            </span>
                          </div>
                        </div>
                        <h3
                          style={{
                            fontSize: "1.3rem",
                            fontWeight: 600,
                            margin: 0,
                          }}
                          className={styles.locationName}
                        >
                          {_.location}
                        </h3>

                        <div
                          style={{
                            background: "rgba(0, 0, 0, 0.3)",
                            margin: "0.3rem",
                            borderRadius: "0.3rem",
                            fontSize: "0.8rem",
                            // aspectRatio: "1/0.15", // commented this line for ios safari website
                            display: "flex",
                            alignItems: "center",
                            padding: "0.3rem",
                          }}
                          className={styles.description}
                        >
                          {_.description}
                        </div>

                        <div
                          className={styles.cardPriceInfo}
                          style={{
                            // padding: "0.3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // gap: "0.5rem", this is old one justifyContent is added,
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                wordWrap: "break-word",
                                fontSize: "0.8rem",
                                width: "3.5rem",
                              }}
                              className={styles.startingFrom}
                            >
                              Starting from
                            </div>
                            <div
                              style={{ fontWeight: 600, fontSize: "1.3rem" }}
                              className={styles.price}
                            >
                              {currencyFormatter(_.price)}/-
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: "0.8rem",
                              background: "rgba(255, 239, 231, 1)",
                              padding: "0.3rem",
                              borderRadius: "0.3rem",
                              color: "#000",
                              textShadow: "none",
                              fontWeight: 600,
                            }}
                            className={styles.nightDay}
                          >
                            {_.night}N | {_.day}D
                          </div>
                        </div>

                        <div
                          style={{
                            // margin: "0.3rem",
                            background:
                              "linear-gradient(to right,rgba(255, 243, 0, 0),rgba(244, 109, 37, 1),rgba(244, 109, 37, 1),rgba(255, 243, 0, 0))",
                            height: "0.1rem",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "0.7rem",
                          }}
                        >
                          <a
                            href="tel:+918006800727"
                            style={{ textDecoration: "none" }}
                          >
                            <IconButton
                              style={{ border: "1px solid #fff" }}
                              size="small"
                            >
                              <PhoneRounded
                                style={{
                                  color: "#fff",
                                }}
                              />
                            </IconButton>
                          </a>
                          <Button
                            className="linear-gradient-button"
                            fullWidth
                            size="large"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCTAFormCurrentItem(_);
                              setOpenHeroCtaForm(true);
                            }}
                          >
                            Book Now
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        margin: "0.3rem",
                        background:
                          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                        borderRadius: "0.7rem",
                      }}
                    />
                  </HomeCardItem.Overlay>
                </HomeCardItem>
              ))}
            </Slider>

            <div
              style={{
                position: "absolute",
                inset: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "center",
              }}
            >
              <LeftNavArrow
                onClick={() =>
                  internationalPackageSliderRef.current.slickPrev()
                }
              />{" "}
              <RightNavArrow
                onClick={() =>
                  internationalPackageSliderRef.current.slickNext()
                }
              />
            </div>
          </div>
        </div>
      </section>
      {/**Explore International Packages Ends*/}

      {/**Explore Domestic Packages Starts*/}
      <section className="domestic-packages-section">
        <div className="india-map-container">
          <img src={IndiaMap} />
          <div
            className="location-pointer"
            style={{
              transform: `translateX(${currentDomesticPointerPos[0]}%) translateY(${currentDomesticPointerPos[1]}%)`,
              transition: "all 1s",
              inset: 0,
              content: "",
              width: "100%",
              height: "100%",
              zIndex: 20,
            }}
          >
            <div></div>
          </div>
        </div>

        <div className="domestic-packages-content">
          <div className="dom-header">
            <Typography variant="h2" className={classes.domesticHeader}>
              {" "}
              Explore Our Domestic packages
            </Typography>
            <h3 className={classes.domesticContent}>
              Incredible India Awaits! Discover the hidden gems of India with
              our tailored domestic tour packages, designed for scenic retreats
              and cultural excursions, offering hassle-free planning and local
              experiences. After all, you must holiday like a local with Towno.
            </h3>
          </div>

          <div className="domestic-packages-carousel">
            <Slider
              {...popularDestinationSliderSettings}
              beforeChange={(currIdx, nextIdx) => {
                let currLocation = popularDomesticLocations[nextIdx];
                setCurrentDomesticPointerPos([
                  currLocation.posX,
                  currLocation.posY,
                ]);
              }}
              ref={domesticPackageSliderRef}
              className={classes.sliderStyling}
            >
              {popularDomesticLocations.map((_, i) => (
                <HomeCardItem
                  key={`${_.country}-${i}`}
                  style={{ margin: "0 auto" }}
                >
                  <HomeCardItem.Img src={_.imgSrc} alt={_.location} />
                  <HomeCardItem.Overlay
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                      zIndex: "3",
                    }}
                  >
                    <div
                      className={styles.intlPackageCardContent}
                      style={{
                        zIndex: "10",
                        textShadow: "0rem 0.1rem 0.2rem #000",
                        padding: "0.7rem",
                        height: "95%", // added this line for ios safari website
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // gap: "0.2rem",
                          justifyContent: "end", //changing this line space-betweeen to end for ios safari
                          height: "100%", //added this line for ios safari website
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          className={styles.intCardRating}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Room color="primary" />
                            <span>{_.country}</span>
                          </div>
                          <div
                            style={{
                              paddingRight: "0.8rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="ratingAndReviewCount"
                          >
                            <StarRate color="primary" />
                            <span>
                              {_.rating} ({_.reviewsCount})
                            </span>
                          </div>
                        </div>
                        <h3
                          style={{
                            // fontSize: "1.3rem",
                            fontWeight: 600,
                            margin: 0,
                          }}
                          className={styles.locationName}
                        >
                          {_.location}
                        </h3>

                        <div
                          style={{
                            background: "rgba(0, 0, 0, 0.3)",
                            margin: "0.3rem",
                            borderRadius: "0.3rem",
                            fontSize: "0.8rem",
                            // aspectRatio: "1/0.15", //commented this line for ios safari website
                            display: "flex",
                            alignItems: "center",
                            padding: "0.3rem",
                          }}
                          className={styles.description}
                        >
                          {_.description}
                        </div>

                        <div
                          className={styles.cardPriceInfo}
                          style={{
                            padding: "0.3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // gap: "0.5rem", this is old one justifyContent is added,
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                wordWrap: "break-word",
                                fontSize: "0.8rem",
                                width: "3.5rem",
                              }}
                            >
                              Starting from
                            </div>
                            <div
                              style={{ fontWeight: 600, fontSize: "1.3rem" }}
                              className={styles.price}
                            >
                              {currencyFormatter(_.price)}/-
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: "0.8rem",
                              background: "rgba(255, 239, 231, 1)",
                              padding: "0.3rem",
                              borderRadius: "0.3rem",
                              color: "#000",
                              textShadow: "none",
                              fontWeight: 600,
                            }}
                            className={styles.nightDay}
                          >
                            {_.night}N | {_.day}D
                          </div>
                        </div>

                        <div
                          style={{
                            // margin: "0.3rem",
                            background:
                              "linear-gradient(to right,rgba(255, 243, 0, 0),rgba(244, 109, 37, 1),rgba(244, 109, 37, 1),rgba(255, 243, 0, 0))",
                            height: "0.1rem",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1rem",
                          }}
                        >
                          <a
                            href="tel:+918006800727"
                            style={{ textDecoration: "none" }}
                          >
                            <IconButton
                              style={{ border: "1px solid #fff" }}
                              size="small"
                            >
                              <PhoneRounded
                                style={{
                                  color: "#fff",
                                }}
                              />
                            </IconButton>
                          </a>
                          <Button
                            className="linear-gradient-button"
                            fullWidth
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCTAFormCurrentItem(_);
                              setOpenHeroCtaForm(true);
                            }}
                          >
                            Book Now
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        margin: "0.3rem",
                        background:
                          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
                        borderRadius: "0.7rem",
                      }}
                    />
                  </HomeCardItem.Overlay>
                </HomeCardItem>
              ))}
            </Slider>

            <div
              style={{
                position: "absolute",
                inset: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={classes.domesticNavContainer}
                style={{
                  width: "100%",
                  // padding: "0rem 1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "-1.3rem",
                }}
              >
                <LeftNavArrow
                  onClick={() => domesticPackageSliderRef.current.slickPrev()}
                />{" "}
                <RightNavArrow
                  onClick={() => domesticPackageSliderRef.current.slickNext()}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/**Explore Domestic Packages Ends*/}

      {/**Travel Itinerary Section Starts */}
      <section ref={tiContainerRef}>
        {tiIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <TravelItineries />
          </Suspense>
        )}
      </section>
      {/**Travel Itinerary Section ends*/}

      {/**Offer Banner Section starts */}
      <section ref={nobContainerRef}>
        {nobIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <NewOfferBanner />
          </Suspense>
        )}
      </section>
      {/**Offer Banner Section ends */}

      <section ref={ehContainerRef}>
        {ehIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <ExperientialComponent />
          </Suspense>
        )}
      </section>

      <section ref={toContainerRef}>
        {toIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <TravelOptions />
          </Suspense>
        )}
      </section>

      <section ref={tstContainerRef}>
        {tstIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <TestimonialSection />
          </Suspense>
        )}
      </section>

      {/**Affliation section ends */}

      {
        <section ref={bfnContainerRef}>
          {bfnIsIntersecting && (
            <Suspense
              fallback={
                <Grid
                  container
                  md={12}
                  xs={12}
                  textAlign={"center"}
                  justifyContent={"center"}
                >
                  <LoaderEyeVariant />
                </Grid>
              }
            >
              <BookForHolidayForm />
            </Suspense>
          )}
        </section>
      }

      <section ref={fnContainerRef}>
        {fnIsIntersecting && (
          <Suspense
            fallback={
              <Grid
                container
                md={12}
                x
                style={{ aspectRatio: "1/0.3" }}
                s={12}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <LoaderEyeVariant />
              </Grid>
            }
          >
            <FooterNewV2 />
          </Suspense>
        )}
      </section>

      {/** Most Popular Packages Home Page starts*/}
      {/* <MostPopularPackages /> */}
      {/** Most Popular Packages Home Page ends*/}

      {/**best experiences starts */}
      {/* <BestExperiences /> */}
      {/**best experience ends */}

      {/**Offer Banner Starts*/}
      {/* <section className="offer-banner-container">
        <img className="offer-banner-background-img" src={OfferBanner} />
        <div className="offer-body-container">
          <>
            <div className="banner-primary-text">
              Bigger Group? Get Special
              <p>
                Offers upto <span className="home-valid-off">50% Off!</span>
              </p>
              <p className="banner-secondary-text">
                Towno holiday packages weave in a generous amount of authentic
                and offbeat travel experiences and stays in our Towno partner
                network.
              </p>
            </div>
            <button className="offer-btn" onClick={toggleModal}>
              Get A Callback
            </button>
          </>
        </div>
      </section> */}
      {/**Offer Banner ends*/}

      {/**About info starts*/}
      {/* <section className="about-towno">
        <div className="about-text-container">
          <div className="about-header">About Us</div>
          <div className="about-us-primary-text">
            Book An <span className="boldtext">Experiential Holiday</span> With
            Us.
          </div>
          <div className="about-us-secondary-text">
            Towno holiday packages weave in a generous amount of authentic and
            offbeat travel experiences and stays in our Towno partner network.
          </div>
          <button className="contact-us" onClick={toggleModal}>
            Contact Us
          </button>
        </div>
        <img src={OfferSectionImage} className="explore-illustration" />
      </section> */}
      {/**About info ends*/}

      {/** Most Popular Hotels Home Page Starts*/}
      {/* <MostPopularHotels /> */}
      {/** Most Popular Hotels Home Page ends*/}

      {/**Keypoints section starts */}
      {/* <KeyPoints /> */}
      {/**KeyPoints section ends */}

      {/**Blog section starts */}
      {/* <section className="home-blog-section">
        <header className="blog-title">
          <div>
            These Are Our <span> Blogs</span>
          </div>
          <sub className="blog-sub-title">
            These Are The Best Destinations Offered These Are The Best
            Destinations
          </sub>
        </header>
        <div className="home-blog-container"></div>
      </section> */}
      {/**Blog section ends */}
    </section>
  );
};

export default Home;
