/* eslint-disable eqeqeq */
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core/"
import { Visibility, VisibilityOff } from "@material-ui/icons/"
import Stack from "@mui/material/Stack"
import axios from "axios"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import * as yup from "yup"
import { loginbaseurl } from "../../Service/httpCommon"
import TKonnect1 from "../../assets/logo/TKonnect1.png"
import TKonnect2 from "../../assets/logo/TKonnect2.png"
import towno from "../../assets/logo/towno_logo.png"
import login from "../../assets/pictures/login.png"
import { setCurrentUser } from "../../redux/actions/user.action"
import "./auth.scss"

var CryptoJS = require("crypto-js")

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const unique = localStorage.getItem("unique_id")
  const role = localStorage.getItem("role")
  const designation = localStorage.getItem("designation")
  const token = localStorage.getItem("token")
  const checkAgent = localStorage.getItem("agent")
  const dispatch = useDispatch()
  const { path, url } = useRouteMatch()
  console.log({ path, url })

  /*useEffect(() => {
    if (unique && role !== "Agent Admin") {
      history.push(`/dashboard`);
    } else if (unique && role == "Agent Admin") {
      history.push(`/agent`);
    }
  }, [history, unique, role]);*/

  const initialValues = {
    username: "",
    password: "",
  }
  const validationSchema = yup.object({
    username: yup.string().strict().trim().required("Please Enter the Email "),
    password: yup
      .string()
      .strict()
      .trim()
      .required("Please Enter the Password"),
  })

  const clearBrowserStorage = () => {
    localStorage.clear()
    sessionStorage.clear()
  }

  useEffect(() => {
    window.addEventListener("load", clearBrowserStorage)
    return () => window.removeEventListener("load", clearBrowserStorage)
  }, [])

  const onSubmit = (data) => {
    console.log(data)
    /*var ciphertextupdate = CryptoJS.AES.encrypt(JSON.stringify(data.password), 'my-secret-key@123').toString();
    data.password = ciphertextupdate;*/

    setLoading(true)
    axios
      .post(loginbaseurl + "api/v1/loginnew", data)
      .then((res) => {
        if (res.data.id !== null) {
          localStorage.setItem("slashRtcID", data.username)
          console.log(res.data)
          dispatch(setCurrentUser(res.data))
          window.sessionStorage.setItem("token", res.data.token)
          localStorage.setItem("unique_id", res.data.uniqueId)
          localStorage.setItem("employee_id", res.data.id)
          localStorage.setItem(
            "auth",
            res.data.username.replace(/^\w/, (c) => c.toUpperCase())
          )
          localStorage.setItem("role", res.data.role)
          localStorage.setItem("designation", res.data.designation)
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("typeAgent", res.data.typeAgent)

          if (res.data.agent) {
            localStorage.setItem("agent", res.data.agent)
          }
          setTimeout(() => {
            if (res.data.role === "Agent Admin" || res.data.agent === "Agent") {
              // history.push(`/agent`);
              window.open("/konnect/agent", "_self")
            } else if (res.data.role === "Common") {
              window.open("/konnect/calenderView", "_self")
              // history.push(`/calenderView`);
            } else {
              window.open(
                res.data.role === "Hotel Admin"
                  ? `/konnect/hotelDashboard`
                  : `/konnect/dashboard`,
                "_self"
              )
              //window.open(baseurl+"dashboard", "_self");
            }

            setLoading(false)
          }, 2000)
        } else {
          localStorage.removeItem("unique_id")
          localStorage.removeItem("employee_id")
          localStorage.removeItem("auth")
          localStorage.removeItem("role")
          localStorage.removeItem("designation")
          localStorage.removeItem("token")
          toast.error("Invalid Password or Email")
          setLoading(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Invalid Credentials")
        }
        setLoading(false)
      })
  }

  return (
    <>
      {/* Navbar  */}
      <div style={{ backgroundColor: "#111" }}>
        <Stack direction="row" justifyContent="space-between">
          <img
            src={TKonnect1}
            alt="logo"
            style={{
              width: "15em",
              height: "4em",
              marginLeft: "2em",
              cursor: "pointer",
            }}
            onClick={() => window.open("/", "_self")}
          />
        </Stack>
      </div>
      {/* Navbar Ends  */}
      <div className="container">
        <div className="contentLogin">
          <Stack direction="row">
            <h1>Log In To </h1>
            <div
              style={{ marginTop: window.innerWidth <= 768 ? "0" : "-10px" }}
            >
              <img src={TKonnect2} alt="logo" />
            </div>
          </Stack>

          <p>Enter your details below</p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form autoComplete="off">
              <Field
                as={TextField}
                name="username"
                label="Email"
                size="small"
                required
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <ErrorMessage name="username">
                {(error) => <div className="error">{error}</div>}
              </ErrorMessage>
              <Field
                as={TextField}
                name="password"
                label="Password"
                required
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage name="password">
                {(error) => <div className="error">{error}</div>}
              </ErrorMessage>
              {loading ? (
                <Button type="submit" fullWidth disabled>
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{
                      marginLeft: "-12px",
                      marginRight: "8px",
                    }}
                  ></i>
                  Login
                </Button>
              ) : (
                <Button type="submit" fullWidth>
                  Login
                </Button>
              )}
              {/* <p className="linking">
                Don't have an account ?{" "}
                <Link to="/companyregister">Register</Link>
              </p> */}
              <div className="powered">
                <h3>
                  Powered By <img src={towno} alt="logo" className="towno" />
                </h3>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="sideimage">
          <img src={login} alt="login" />
        </div>
      </div>
    </>
  )
}

export default Login
