import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { AddCircle, SearchOutlined } from "@material-ui/icons"
import DateRangeIcon from "@material-ui/icons/DateRange"
import DeleteIcon from "@material-ui/icons/Delete"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Grid } from "@mui/material"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import Select from "react-select"
import { toast } from "react-toastify"
import * as Yup from "yup"
import API from "../../Service/Api"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import {
  getBookingSourceOptions,
  getPaymentModeOptions,
} from "../../redux/actions/commonFeatureDropdown.action"
import ClientForm from "../Clients/ClientForm"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0px",
    padding: "75px 18px 70px 25px",
  },
  pageHeader: {
    fontSize: "clamp(25px,10vw,16px)",
    color: "#f46d25",
    fontWeight: "bold",
  },
  sectionHeader: {
    background: "#f46d25",
    color: "white",
    fontWeight: "bold",
    padding: "9px",
    borderRadius: "6px",

    fontSize: "20px",
  },
  sectionStyles: {
    marginTop: "15px",
  },
  inputLabelProps: {
    color: "black",
  },
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
    },
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  innerSectionHeader: {
    fontWeight: "bold",
    color: "#000000",
    margin: "10px 0px",
  },
  error: {
    marginBottom: "3px",
    color: "red",
    textDecoration: "italic",
  },
}))

const initialValues = {
  clientName: "",
  clientNumber: "",
}

const Activity = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const unique_id = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")
  const [clientList, setClientList] = useState([])
  const selectedId = 0

  const { activityID } = useParams()
  const history = useHistory()

  const [formInitialData, setFormInitialData] = useState({
    uniqueId: unique_id,
    bookingId: "",
    clientName: "",
    clientNumber: "",
    clientEmail: "",
    bookingSource: "",
    vaccinated: false,
    git: false,
    corporate: false,
    totalAmount: 0,
    netPayoutToVendor: 0,
    comission: 0,
    amountReceived: 0,
    paymentMode: "",
    bpah: 0,
    refNumber: "",
    paymentType: "fullPayment",
    balPayToTowno: 0,
    totalGrossAmount: 0,
    profitAfterTax: 0,
    activityBookingActivityDetails: [
      {
        activityName: "",
        vendorName: "",
        number: "",
        email: "",
        activityType: "",
        dateOfActivity: null,
        noOfPax: 0,
        address: "",
      },
    ],
    activityBookingLeadPaxs: [
      { name: "", mobile: "", altMobile: "", email: "" },
    ],
  })
  const [loading, setLoading] = useState(() => false)
  const [open, setOpen] = useState(() => false)
  const [initialLengthOfClientsList, setInitialLengthOfClientsList] =
    useState(0)
  let newClientMobileNo = sessionStorage.getItem("mobile")

  const { bookingSourceOptions } = useSelector(
    ({ bookingSourceOptionsReducer }) => bookingSourceOptionsReducer
  )

  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  )

  useEffect(() => {
    dispatch(getPaymentModeOptions(unique_id))
    dispatch(getBookingSourceOptions(unique_id))
  }, [])

  const fetchIndividualData = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`getActivityBooking/${activityID}`)
      setFormInitialData(data)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const fetchClientLists = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`clientall/${unique_id}`)
      setClientList(data)
      console.log({ data })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (activityID) {
      fetchIndividualData()
    }
  }, [activityID])

  useEffect(() => {
    fetchClientLists()
    console.log("Hello")
  }, [unique_id, newClientMobileNo])

  const clientOptions =
    clientList &&
    clientList.map((client) => {
      let first = client.firstName
      let last = client.lastName
      let mail = client.mail
      let combine = `${first} ${last} (${mail})`
      let combine1 = `${first} ${last}`
      return {
        label: combine,
        value: combine1,
        id: client.id,
        clientNumber: client.mobile,
        clientEmail: mail,
      }
    })

  useEffect(() => {
    if (initialLengthOfClientsList === 0) {
      setInitialLengthOfClientsList(clientList.length)
    } else if (clientList.length > initialLengthOfClientsList) {
      let found = clientOptions.find(
        (client, idx) => idx === clientOptions.length - 1
      )
      console.log(found)
      setFormInitialData({
        ...formInitialData,
        clientName: found.value,
        clientEmail: found.clientEmail,
        clientNumber: found.clientNumber,
      })
    }
  }, [clientList])

  /**validation schema starts*/
  const validationSchema = Yup.object().shape({
    // bookingId: Yup.string().required("Enter Booking Id to continue"),
    clientName: Yup.string().required(
      "Select any client or click the plus icon to add new client"
    ),
    activityBookingActivityDetails: Yup.array().of(
      Yup.object().shape({
        activityName: Yup.string()
          .min(3, "must be greater than 3")
          .required("Activity name required"),
        dateOfActivity: Yup.date().required("Select the Date"),
        vendorName: Yup.string()
          .min(3, "must be greater than 3")
          .required("Vendor name required"),
        number: Yup.string()
          .typeError("Must be number")
          .min(10, "Enter valid phone number")
          .max(10, "Enter valid phone number(max 10)")
          .required("enter valid phone number"),
      })
    ),
    activityBookingLeadPaxs: Yup.array().of(
      Yup.object().shape({
        mobile: Yup.number().min(10, "Enter valid phone number"),
        altMobile: Yup.number().min(10, "Enter valid phone number"),
      })
    ),

    totalAmount: Yup.number().min(1, "Enter valid amount").required("Required"),
  })
  /**validation schema ends*/

  const CloseDialog = () => {
    setOpen(false)
  }

  const handleOnWheel = (e) => {
    e.target.blur()
  }

  const handleSubmit = async (fields, { setSubmitting }) => {
    setSubmitting(true)
    if (activityID) {
      fields.id = activityID
      fields.updatedBy = auth
    } else {
      fields.createdBy = auth
    }
    try {
      const response = await API.post("createOrEditActivityBooking", fields)
      console.log(response.data)
      toast.success(activityID ? "Activity Updated" : "New Activity Created")
      setSubmitting(false)
      sessionStorage.clear()
      history.push("/konnect/activityList")
    } catch (error) {
      console.error(error)
      toast.error(error.message)
      setSubmitting(false)
    }
  }

  const handleSearch = (bookingId) => async () => {
    let url =
      localStorage.getItem("role") === "Hotel Admin"
        ? "bookingDetailsForHotelLogin"
        : "bookingdetails"
    try {
      const res = await API.get(`checkActivityBookingId/${bookingId}`)

      if (res.data == "Already Added")
        return toast.warn("Activity ID already exists for this Booking ID")

      const { data } = await API.get(`${url}/${unique_id}/${bookingId}`)
      console.log({ data })

      if (_.isEmpty(data)) return toast.error("Invalid Booking ID")

      const alteredClientName = data?.clientName?.replace(/\.|\t/g, " ").trim()
      console.log({ alteredClientName })
      setFormInitialData({
        ...formInitialData,
        clientName: alteredClientName,
        clientEmail: data.clientEmail,
        clientNumber: data.clientMobile,
        bookingId: data.bookingId,
      })
      if (_.isEmpty(bookingId)) {
        setFormInitialData({
          ...formInitialData,
          clientName: "",
          clientEmail: "",
          clientNumber: "",
        })
      }
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <section className={classes.root}>
        <header className={classes.pageHeader}>New Activity Booking</header>
        <Formik
          initialValues={formInitialData}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            values,
            setFieldValue,
            setSubmitting,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <section className={classes.sectionStyles}>
                <Grid container padding="10px 0px">
                  <Grid item xs={3}>
                    <Field
                      as={TextField}
                      name="bookingId"
                      type="search"
                      label="Booking ID"
                      value={values.bookingId}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target
                        if (_.isEmpty(value)) {
                          setFieldValue("clientName", "")
                          setFieldValue("clientEmail", "")
                          setFieldValue("clientNumber", "")
                        }
                        setFieldValue("bookingId", value)
                      }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.inputLabelProps,
                      }}
                      placeholder="Booking ID"
                      InputProps={{
                        style: {
                          height: "38px",
                          borderRadius: "5px 20px 20px 5px",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ padding: "0px" }}
                          >
                            <IconButton
                              onClick={handleSearch(values.bookingId)}
                              style={{ padding: "5px", background: "#f46d25" }}
                              disabled={activityID}
                            >
                              <SearchOutlined
                                style={{
                                  color: "white",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { borderRadius: "5px 20px 20px 5px" },
                      }}
                      autoFocus
                      disabled={activityID}
                    />
                    {/* <ErrorMessage name={`bookingId`}>
                      {(msg) => <div className={classes.error}>{msg}</div>}
                    </ErrorMessage> */}
                  </Grid>
                </Grid>
                <section>
                  <header className={classes.sectionHeader}>
                    Basic Information
                  </header>
                  <header className={classes.innerSectionHeader}>
                    Client Details
                  </header>
                  <section className={classes.innerSectionStyles}>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        xs={6}
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={11}>
                          <Field
                            as={Select}
                            name="clientName"
                            value={
                              clientList &&
                              clientOptions.find(
                                (client) => client.value == values.clientName
                              )
                            }
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: "#f46d25",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#f46d25",
                                primary: "#f46d25",
                              },
                            })}
                            options={clientOptions}
                            onChange={(option) => {
                              console.log({ option })
                              console.log(values.clientName)

                              setFieldValue("clientName", option?.value)
                              setFieldValue("clientNumber", option.clientNumber)
                              setFieldValue("clientEmail", option.clientEmail)
                            }}
                            placeholder="Select Client"
                          />
                          <ErrorMessage name={`clientName`}>
                            {(msg) => (
                              <div className={classes.error}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            title="add-client"
                            onClick={() => setOpen(true)}
                          >
                            <AddCircle color="primary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="clientNumber"
                          label="Mobile No."
                          type="text"
                          value={values.clientNumber}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="clientEmail"
                          label="Email"
                          type="email"
                          value={values.clientEmail}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item container xs={12} spacing={1}>
                        <Grid item xs={3}>
                          <Field
                            as={Select}
                            name="bookingSource"
                            value={bookingSourceOptions?.find(
                              (source) => source.value == values.bookingSource
                            )}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: "#f46d25",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#f46d25",
                                primary: "#f46d25",
                              },
                            })}
                            options={bookingSourceOptions}
                            placeholder="Booking Source"
                            onChange={(option) =>
                              setFieldValue("bookingSource", option.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            as={FormControlLabel}
                            control={
                              <Field
                                as={Checkbox}
                                name="vaccinated"
                                className={classes.checkboxStyling}
                                size="small"
                                checked={values.vaccinated}
                              />
                            }
                            label="Vaccinated"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            as={FormControlLabel}
                            control={
                              <Field
                                as={Checkbox}
                                name="git"
                                className={classes.checkboxStyling}
                                size="small"
                                checked={values.git}
                              />
                            }
                            label="GIT"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <Field
                            as={FormControlLabel}
                            control={
                              <Field
                                as={Checkbox}
                                name="corporate"
                                className={classes.checkboxStyling}
                                size="small"
                                checked={values.corporate}
                              />
                            }
                            label="Corporate"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </section>

                  <>
                    <header className={classes.innerSectionHeader}>
                      Lead Pax
                    </header>
                    <section className={classes.innerSectionStyles}>
                      <FieldArray
                        name="activityBookingLeadPaxs"
                        render={(arrayHelpers) => (
                          <Grid container spacing={2}>
                            {values.activityBookingLeadPaxs.map(
                              (leadPax, index) => (
                                <Grid
                                  item
                                  container
                                  key={index}
                                  xs={12}
                                  spacing={1}
                                  display="flex"
                                  alignItems="first baseline"
                                >
                                  <Grid item xs={0.2}>
                                    {index + 1}.
                                  </Grid>
                                  <Grid item container xs={11.3} spacing={1}>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingLeadPaxs[${index}].name`}
                                        label="Name"
                                        type="text"
                                        value={leadPax.name}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingLeadPaxs[${index}].mobile`}
                                        label="Mobile"
                                        type="text"
                                        value={leadPax.mobile}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingLeadPaxs[${index}].mobile`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingLeadPaxs[${index}].altMobile`}
                                        label="Alt Mobile"
                                        type="text"
                                        value={leadPax.altMobile}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingLeadPaxs[${index}].altMobile`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingLeadPaxs[${index}].email`}
                                        label="Email"
                                        type="email"
                                        value={leadPax.email}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item container xs={0.5} spacing={1}>
                                    {values.activityBookingLeadPaxs.length -
                                      1 ===
                                      index && (
                                      <Grid item xs={6}>
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.push({
                                              name: "",
                                              mobile: "",
                                              altMobile: "",
                                              email: "",
                                            })
                                          }
                                        >
                                          <AddCircle
                                            color="primary"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    )}
                                    {values.activityBookingLeadPaxs.length !==
                                      1 && (
                                      <Grid item xs={6}>
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(`${index}`)
                                          }
                                        >
                                          <DeleteIcon
                                            color="primary"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        )}
                      />
                    </section>
                  </>
                </section>
                {/**Activity Information */}
                <section className={classes.sectionStyles}>
                  <header
                    className={classes.sectionHeader}
                    style={{ marginBottom: "10px" }}
                  >
                    Activity Information
                  </header>
                  <>
                    <header className={classes.innerSectionHeader}>
                      Activity Details
                    </header>
                    <section className={classes.innerSectionStyles}>
                      <FieldArray
                        name="activityBookingActivityDetails"
                        render={(arrayHelpers) => (
                          <Grid container spacing={2}>
                            {values.activityBookingActivityDetails.map(
                              (activity, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  display="flex"
                                  alignItems="first baseline"
                                  key={index}
                                >
                                  <Grid item xs={0.3}>
                                    {index + 1}.
                                  </Grid>
                                  <Grid item container xs={11.4} spacing={1}>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].activityName`}
                                        label="Activity Name"
                                        type="text"
                                        value={activity.activityName}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingActivityDetails[${index}].activityName`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].vendorName`}
                                        label="Vendor Name"
                                        type="text"
                                        value={activity.vendorName}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingActivityDetails[${index}].vendorName`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].number`}
                                        label="Number"
                                        type="number"
                                        value={activity.number}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        // onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingActivityDetails[${index}].number`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].email`}
                                        label="Email"
                                        type="email"
                                        value={activity.email}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].activityType`}
                                        label="Activity Type"
                                        type="text"
                                        value={activity.activityType}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        as={DatePicker}
                                        name={`activityBookingActivityDetails[${index}].dateOfActivity`}
                                        label="Date of Activity"
                                        inputVariant="outlined"
                                        fullWidth
                                        size="small"
                                        value={activity.dateOfActivity}
                                        onChange={(date) => {
                                          console.log(date)
                                          setFieldValue(
                                            `activityBookingActivityDetails[${index}].dateOfActivity`,
                                            date
                                          )
                                        }}
                                        animateYearScrolling
                                        format="dd/MM/yyyy"
                                        variant="inline"
                                        autoOk="true"
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <DateRangeIcon color="primary" />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`activityBookingActivityDetails[${index}].dateOfActivity`}
                                      >
                                        {(msg) => (
                                          <div className={classes.error}>
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].noOfPax`}
                                        label="No. of Pax"
                                        type="number"
                                        value={activity.noOfPax}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                        onWheel={handleOnWheel}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Field
                                        as={TextField}
                                        name={`activityBookingActivityDetails[${index}].address`}
                                        label="Address"
                                        type="address"
                                        value={activity.address}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          className: classes.inputLabelProps,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item container xs={0.3}>
                                    {values.activityBookingActivityDetails
                                      .length -
                                      1 ===
                                      index && (
                                      <Grid item xs={12}>
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.push({
                                              activityName: "",
                                              vendorName: "",
                                              number: "",
                                              email: "",
                                              activityType: "",
                                              dateOfActivity: null,
                                              noOfPax: 0,
                                              address: "",
                                            })
                                          }
                                        >
                                          <AddCircle
                                            color="primary"
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    )}

                                    {values.activityBookingActivityDetails
                                      .length !== 1 && (
                                      <Grid item xs={12}>
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(`${index}`)
                                          }
                                        >
                                          <DeleteIcon
                                            color="primary"
                                            style={{
                                              height: "20px",
                                              width: "20px",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        )}
                      />
                    </section>
                  </>
                </section>

                {/**Payment Section  Starts*/}
                <section className={classes.sectionStyles}>
                  <header className={classes.sectionHeader}>
                    Payment Details
                  </header>
                  <>
                    <header className={classes.innerSectionHeader}>
                      Payment Breakup
                    </header>
                    <section className={classes.innerSectionStyles}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Field
                            as={TextField}
                            name="totalAmount"
                            label="Total Amount"
                            type="number"
                            value={values.totalAmount}
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => {
                              setFieldValue("totalAmount", e.target.value)
                              setFieldValue("bpah", e.target.value)
                              setFieldValue("netPayoutToVendor", 0)
                              setFieldValue("comission", 0)
                            }}
                            InputLabelProps={{
                              className: classes.inputLabelProps,
                              shrink: true,
                            }}
                            onWheel={handleOnWheel}
                          />
                          <ErrorMessage name={`totalAmount`}>
                            {(msg) => (
                              <div className={classes.error}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            as={TextField}
                            name="netPayoutToVendor"
                            label="Net Payout To Vendor"
                            type="number"
                            value={values.netPayoutToVendor}
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => {
                              const { value } = e.target
                              setFieldValue("netPayoutToVendor", value)
                              setFieldValue(
                                "comission",
                                values.totalAmount - value
                              )
                              setFieldValue(
                                "profitAfterTax",
                                parseFloat(
                                  values.totalAmount -
                                    value -
                                    (values.totalAmount -
                                      value -
                                      (values.totalAmount - value) *
                                        (100 / 118))
                                ).toFixed(0)
                              )
                            }}
                            InputLabelProps={{
                              className: classes.inputLabelProps,
                              shrink: true,
                            }}
                            onWheel={handleOnWheel}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            as={TextField}
                            name="comission"
                            label="Comission"
                            type="number"
                            value={values.comission}
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{
                              className: classes.inputLabelProps,
                              shrink: true,
                              style: { color: "#5B9F6A" },
                            }}
                            onWheel={handleOnWheel}
                          />
                        </Grid>
                      </Grid>
                    </section>
                  </>
                  <>
                    <header className={classes.innerSectionHeader}>
                      Transaction Summary
                    </header>
                    <section className={classes.innerSectionStyles}>
                      <Grid container spacing={2}>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Amount Recieved"
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{ color: "#f46d25" }}
                              InputProps={{
                                readOnly: true,
                                style: { color: "#f46d25" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={TextField}
                              name="amountReceived"
                              type="number"
                              value={values.amountReceived}
                              size="small"
                              fullWidth
                              variant="outlined"
                              InputProps={{
                                style: { background: "#FFE2D7" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              onWheel={handleOnWheel}
                              onChange={(e) => {
                                const { value } = e.target
                                setFieldValue("amountReceived", value)
                                setFieldValue(
                                  "bpah",
                                  values.totalAmount - value
                                )
                                setFieldValue(
                                  "totalGrossAmount",
                                  parseFloat(value) +
                                    parseFloat(values.balPayToTowno)
                                )
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Payment Mode"
                              variant="outlined"
                              size="small"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { color: "#000000" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={Select}
                              name="paymentMode"
                              value={paymentModeOptions?.find(
                                (mode) => mode.value == values.paymentMode
                              )}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: "#f46d25",
                                  height: "40px",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f46d25",
                                  primary: "#f46d25",
                                },
                              })}
                              options={paymentModeOptions}
                              onChange={(option) => {
                                console.log("Selected option", option)

                                setFieldValue("paymentMode", option.value)
                              }}
                              placeholder="Select Payment Mode"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Balance Payable On Arrival (BPAH)"
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{ color: "#f46d25" }}
                              InputProps={{
                                readOnly: true,
                                style: { color: "#f46d25" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={TextField}
                              name="bpah"
                              type="number"
                              value={values.bpah}
                              size="small"
                              fullWidth
                              onWheel={(e) => e.target.blur()}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                style: { background: "#FFE2D7" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Reference No."
                              variant="outlined"
                              size="small"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                style: { color: "#000000" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={TextField}
                              name="refNumber"
                              type="text"
                              value={values.refNumber}
                              size="small"
                              fullWidth
                              onWheel={(e) => e.target.blur()}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Field
                            as={RadioGroup}
                            name="paymentType"
                            value={values.paymentType}
                            onChange={(e) => {
                              const { value } = e.target
                              setFieldValue("paymentType", value)
                              value === "fullPayment" &&
                                setFieldValue("balPayToTowno", 0)
                              setFieldValue(
                                "bpah",
                                values.totalAmount - values.amountReceived
                              )
                              value === "fullPayment" &&
                                setFieldValue(
                                  "totalGrossAmount",
                                  parseFloat(values.amountReceived) + 0
                                )
                            }}
                            style={{ width: "100%" }}
                          >
                            <Grid item container xs={12}>
                              <Grid item xs={6}>
                                <Field
                                  as={FormControlLabel}
                                  control={
                                    <Field
                                      as={Radio}
                                      className={classes.checkboxStyling}
                                      value="fullPayment"
                                      size="small"
                                    />
                                  }
                                  label="Full Payment"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  as={FormControlLabel}
                                  control={
                                    <Field
                                      as={Radio}
                                      className={classes.checkboxStyling}
                                      value="partialPayment"
                                      size="small"
                                    />
                                  }
                                  label="Partial Payment"
                                />
                              </Grid>
                            </Grid>
                          </Field>
                          {values.paymentType === "partialPayment" && (
                            <Grid item container xs={12}>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  value="Balance Payable To Towno"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                    style: { color: "#f46d25" },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} marginLeft="-10px">
                                <Field
                                  as={TextField}
                                  name="balPayToTowno"
                                  type="number"
                                  value={values.balPayToTowno}
                                  size="small"
                                  fullWidth
                                  onWheel={(e) => e.target.blur()}
                                  variant="outlined"
                                  onChange={(e) => {
                                    const { value } = e.target
                                    setFieldValue("balPayToTowno", value)
                                    setFieldValue(
                                      "bpah",
                                      values.totalAmount -
                                        values.amountReceived -
                                        value
                                    )
                                    setFieldValue(
                                      "totalGrossAmount",
                                      parseFloat(value) +
                                        parseFloat(values.amountReceived)
                                    )

                                    setFieldValue(
                                      "profitAfterTax",
                                      parseFloat(values.comission) -
                                        0.05 *
                                          (parseFloat(value) +
                                            parseFloat(values.amountReceived))
                                    )
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </section>
                  </>
                  <>
                    <header className={classes.innerSectionHeader}>
                      Booking P&L
                    </header>
                    <section className={classes.innerSectionStyles}>
                      <Grid container spacing={2}>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Total Gross Amount (Projected)"
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{ color: "#f46d25" }}
                              InputProps={{
                                readOnly: true,
                                style: { color: "#f46d25" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={TextField}
                              name="totalGrossAmount"
                              type="number"
                              value={values.totalGrossAmount}
                              size="small"
                              fullWidth
                              onWheel={(e) => e.target.blur()}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                style: { background: "#CCCCCC" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              value="Profit After Tax"
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{ color: "#f46d25" }}
                              InputProps={{
                                readOnly: true,
                                style: { color: "#5B9F6A" },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} marginLeft="-10px">
                            <Field
                              as={TextField}
                              name="profitAfterTax"
                              type="number"
                              value={values.profitAfterTax}
                              size="small"
                              fullWidth
                              onWheel={(e) => e.target.blur()}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                style: { background: "#DEFFDE" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </section>
                  </>
                </section>
              </section>

              <center style={{ marginTop: "30px" }}>
                <Button
                  size="small"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginRight: "10px" }}
                >
                  {activityID ? "Update Activity" : "Create Activity"}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    history.goBack()
                    sessionStorage.clear()
                  }}
                  style={{ background: "#000000", marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </center>
            </Form>
          )}
        </Formik>
        <ScreenLoader open={loading} />
      </section>
      <ClientForm
        open={open}
        onClose={CloseDialog}
        selectedId={selectedId}
        BookingLocal={"yes"}
        pageType="activityPage"
      />
    </MuiPickersUtilsProvider>
  )
}

export default Activity
