import {
  Button,
  Grow,
  IconButton,
  InputAdornment,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  CalendarTodayOutlined,
  CalendarTodayRounded,
  CallOutlined,
  CloseOutlined,
  MailOutlineOutlined,
} from "@material-ui/icons"
import { Grid } from "@mui/material"
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers-pro"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { Field, Form, Formik } from "formik"
import { useDeviceWidth } from "../../../../hooks/useWidth"
import { NavLink } from "react-router-dom"
import { format } from "date-fns"
import * as Yup from "yup"
import { phoneRegExp } from "../../../../utils/regex"
import Captcha from "../../Captcha/Captcha.component"
import { useCaptchaContext } from "../../../../hooks/captcha/useCaptchaContext"
import { memo, useContext } from "react"
import { CaptchContext } from "../../../context/captcha.context"
import { useNotification } from "../../../../hooks/useNotification"
import { useNotificationProvider } from "../../NotificationProvider/NotificationProvider.component"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 0.5rem",
    },
  },
  formContainer: {
    width: "100%",
    borderRadius: "0.5rem 0rem 0rem 0.5rem",
    backdropFilter: "blur(1rem)",
    background: "rgba(255, 239, 231, 1)",
    boxShadow: "0rem 0rem 0.5rem #000",
    padding: "1rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      borderRadius: "0.5rem",
    },
  },
  form: {},
}))

const formSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone no. is not valid")
    .required()
    .min(10, "Mob no. length should be of 10 digits")
    .max(10, "Mob no. length shouldn't exceed 10 digits"),
  travelDate: Yup.string().required(),
  additionalMessage: Yup.string().nullable(),
})

function HeroCTAForm({ open, onClose, item, onSubmit }) {
  const classes = useStyles()
  const { valid, setValid, userInput, captchaText } = useCaptchaContext()
  const formData = {
    name: "",
    email: "",
    phone: "",
    travelDate: null,
    noOfAdults: 1,
    destination: item?.location,
    additionalMessage: "",
  }

  const width = useDeviceWidth()

  const handleSubmit = (values, { setSubmitting }) => {
    const currentValues = { ...values }
    currentValues.travelDate = format(
      new Date(values.travelDate),
      "dd MMM yyyy"
    )
    console.log(currentValues)

    onSubmit(currentValues, setSubmitting, setValid, showNotification)
  }

  const { ErrorN, Success, NotificationComponent } = useNotification()
  const { showNotification } = useNotificationProvider()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal open={open} className={classes.container}>
        <Grow in={open}>
          <Formik
            initialValues={formData}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={formSchema}
            validateOnMount
            validateOnChange
          >
            {({
              values,
              isSubmitting,
              setFieldValue,
              isValid,
              errors,
              touched,
            }) => (
              <Form className={classes.container}>
                <Grid container md={7} xs={12}>
                  <Grid
                    item
                    container
                    md={6}
                    xs={12}
                    className={classes.formContainer}
                  >
                    {width < 768 ? (
                      <Grid item xs={12} textAlign={"end"}>
                        <IconButton
                          onClick={onClose}
                          size="small"
                          style={{
                            position: "absolute",
                            right: "0.5rem",
                            top: "0.5rem",
                            background: "#fff",
                          }}
                          color="primary"
                        >
                          <CloseOutlined />
                        </IconButton>
                      </Grid>
                    ) : null}
                    <Grid item container md={12}>
                      <Grid item md={12}>
                        <Typography variant="h4" style={{ fontWeight: 600 }}>
                          Excited About Your New Adventure?
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Typography variant="h6" color="primary">
                          Fill Out Your Details Below to Plan or Customize Your
                          Journey!
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        md={12}
                        spacing={1}
                        className={classes.form}
                      >
                        <Grid item md={6} xs={6}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="Your Name"
                            name="name"
                            fullWidth
                          />
                          {errors.name && touched.name ? (
                            <small style={{ color: "red" }}>
                              {errors?.name}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="Mobile Number"
                            name="phone"
                            fullWidth
                          />
                          {errors.phone && touched.phone ? (
                            <small style={{ color: "red" }}>
                              {errors?.phone}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="Your Email"
                            name="email"
                            fullWidth
                            type="email"
                          />
                          {errors.email && touched.email ? (
                            <small style={{ color: "red" }}>
                              {errors?.email}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <MobileDatePicker
                            value={values.travelDate}
                            onChange={(date) => {
                              setFieldValue("travelDate", date)
                            }}
                            showToolbar
                            inputFormat="DD/MM/YYYY"
                            closeOnSelect
                            minDate={dayjs(new Date())}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                name="travelDate"
                                fullWidth
                                placeholder="Travel Date"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton color="primary" size="small">
                                        <CalendarTodayRounded />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {errors.travelDate && touched.travelDate ? (
                            <small style={{ color: "red" }}>
                              {errors?.travelDate}
                            </small>
                          ) : null}
                          {/* <Field
                          as={TextField}
                          variant="outlined"
                          size="small"
                          placeholder="Travel Date"
                          name="travelDate"
                          fullWidth
                        /> */}
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="No. of Adults"
                            name="noOfAdults"
                            fullWidth
                            type="number"
                          />
                          {errors.noOfAdults && touched.noOfAdults ? (
                            <small style={{ color: "red" }}>
                              {errors?.noOfAdults}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="Destination"
                            name="destination"
                            fullWidth
                            disabled
                          />
                          {errors.destination && touched.destination ? (
                            <small style={{ color: "red" }}>
                              {errors?.destination}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            placeholder="Additional Message"
                            name="additionalMessage"
                            fullWidth
                            multiline={true}
                            minRows={2}
                            maxRows={2}
                          />
                          {errors.additionalMessage &&
                          touched.additionalMessage ? (
                            <small style={{ color: "red" }}>
                              {errors?.additionalMessage}
                            </small>
                          ) : null}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Captcha />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Button
                            size="small"
                            fullWidth
                            style={{
                              background:
                                "linear-gradient(276.29deg, #F46D25 0.92%, #FFF300 198.09%)",
                              opacity:
                                isSubmitting || !isValid || !valid ? 0.5 : 1,
                            }}
                            disabled={isSubmitting || !isValid || !valid}
                            type="submit"
                            startIcon={
                              isSubmitting ? (
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                              ) : null
                            }
                          >
                            Request Callback
                          </Button>
                        </Grid>
                        <Grid item md={12} xs={12} textAlign={"center"}>
                          <Typography variant="h5">
                            Can’t wait? Contact Us
                          </Typography>
                        </Grid>
                        <Grid item container md={12} xs={12} spacing={1}>
                          <Grid item md={6} xs={12}>
                            <Button
                              size="small"
                              fullWidth
                              style={{
                                background:
                                  "linear-gradient(276.29deg, #F46D25 0.92%, #FFF300 198.09%)",
                                textAlign: "left",
                                borderRadius: "0.8rem",
                              }}
                              startIcon={
                                <IconButton
                                  size="small"
                                  style={{
                                    background: "#fff",
                                    padding: "0.5rem",
                                  }}
                                >
                                  <CallOutlined
                                    color="primary"
                                    style={{
                                      width: "1.3rem",
                                      height: "1.3rem",
                                    }}
                                  />
                                </IconButton>
                              }
                            >
                              Phone <br /> +91 8006 8007 03
                            </Button>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Button
                              size="small"
                              fullWidth
                              style={{
                                background:
                                  "linear-gradient(276.29deg, #F46D25 0.92%, #FFF300 198.09%)",
                                textAlign: "left",
                                borderRadius: "0.8rem",
                              }}
                              startIcon={
                                <IconButton
                                  size="small"
                                  style={{
                                    background: "#fff",
                                    padding: "0.5rem",
                                  }}
                                >
                                  <MailOutlineOutlined
                                    color="primary"
                                    style={{
                                      width: "1.3rem",
                                      height: "1.3rem",
                                    }}
                                  />
                                </IconButton>
                              }
                              onClick={() => {
                                window.location.href(
                                  "mailto:holidays@towno.in",
                                  "_blank"
                                )
                              }}
                            >
                              Email <br />
                              holidays@towno.in
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {width > 768 ? (
                    <Grid
                      item
                      container
                      md={6}
                      xs={0}
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          borderRadius: "0rem 0.5rem 0.5rem 0rem",
                        }}
                      >
                        <img
                          src={item?.imgSrc}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <IconButton
                        onClick={onClose}
                        size="small"
                        style={{
                          position: "absolute",
                          right: "0.5rem",
                          top: "0.5rem",
                          background: "#fff",
                        }}
                        color="primary"
                      >
                        <CloseOutlined />
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grow>
      </Modal>
    </LocalizationProvider>
  )
}

export default HeroCTAForm
