import {
  Button,
  Grow,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useMemo, useState } from "react"
import {
  deletePackageCard,
  getAllActivePackageCards,
  getAllPackageCards,
  getPackageCardInfo,
  toggleActivePackage,
} from "../../Service/api/packageCardApi"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import { toast } from "react-toastify"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import { DeleteForever, Edit } from "@material-ui/icons"
import { BsEye } from "react-icons/bs"
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { baseurl } from "../../Service/httpCommon"
import CardComponentB2C from "../../components/CardComponentB2C/cardComponentB2C.component"

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#f46d25",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}))

const useStyles = makeStyles(() => ({
  container: {
    padding: "5rem 2rem 1rem 2rem",
  },
  previewContainer: {
    display: "grid",
    gridTemplateAreas: `"stack"`,
    background: "#fff",
    aspectRatio: 1 / 1.4,
    padding: "0.5rem",
    borderRadius: "0.8rem",
    width: "100%",
    "& > *": {
      gridArea: "stack",
    },
  },
}))

const columns = [
  { field: "name", headerName: "Package Name" },
  { field: "price", headerName: "Price" },
  { field: "duration", headerName: "Package Duration" },
  { field: "action", headerName: "Action" },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const PackageCardList = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const history = useHistory()
  const auth = localStorage.getItem("auth")

  //table properties
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(8)

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy == property && order == "asc"
    setOrder(isAsc ? "asc" : "desc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const paginatedRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, loading, rowsPerPage, orderBy, order]
  )

  const fetchList = async () => {
    setLoading(true)
    try {
      const res = await getAllPackageCards()
      console.log(res.data)
      const dataWithIds = await res.data.map((item, index) => ({
        ...item,
        id: item.id || index,
      }))
      setRows(dataWithIds)
    } catch (error) {
      toast.error("Unable to fetch the list")
    } finally {
      setLoading(false)
    }
  }

  const handleDeletePackage = async (id) => {
    setLoading(true)
    try {
      const res = await deletePackageCard(id, auth)
      await fetchList()
      toast.success("Deleted successfully")
      await fetchList()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const toggleActive = async (id, checked) => {
    setLoading(true)
    try {
      const value = checked ? 1 : 0
      const res = await toggleActivePackage(id, auth, value)
      fetchList()
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <Grid container md={12} className={classes.container} spacing={1}>
      <Grid item container md={12}>
        <Grid item md={10}>
          <Typography variant="h5" color="primary">
            Package Card List
          </Typography>
        </Grid>
        <Grid item md={2} textAlign={"end"}>
          <Button
            size="small"
            onClick={() => history.push("/konnect/addPackageCard")}
          >
            {" "}
            Add Package Card
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#f46d25" }}>
              {columns.map((c) => (
                <TableCell style={{ color: "#fff" }}>{c.headerName}</TableCell>
              ))}
            </TableHead>

            <TableBody>
              {paginatedRows?.map((r) => (
                <PackageCardRow
                  r={r}
                  key={r.id}
                  handleDeletePackage={handleDeletePackage}
                  classes={classes}
                  toggleActive={toggleActive}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <ScreenLoader open={loading} />
    </Grid>
  )
}

function PackageCardRow({ r, handleDeletePackage, classes, toggleActive }) {
  const [viewCard, setViewCard] = useState(false)
  const [cardDetails, setCardDetails] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchCardDetails = async (id) => {
    setLoading(true)
    try {
      const res = await getPackageCardInfo(id)
      setCardDetails(res.data)
      setLoading(false)
    } catch (error) {}
  }
  return (
    <TableRow>
      {" "}
      <TableCell>{r?.name}</TableCell>
      <TableCell>{currencyFormatter(r?.price)}</TableCell>
      <TableCell>{r?.duration}</TableCell>
      <TableCell>
        <NavLink to={`/konnect/addPackageCard/${r.id}`}>
          <IconButton size="small">
            <Edit color="primary" />
          </IconButton>
        </NavLink>
        <IconButton
          size="small"
          onClick={() => {
            fetchCardDetails(r.id)
            setViewCard(true)
          }}
        >
          <BsEye fill="#f46d25" />
        </IconButton>
        <IconButton size="small" onClick={() => handleDeletePackage(r.id)}>
          <DeleteForever color="primary" />
        </IconButton>
        <IconButton size="small">
          <AntSwitch
            checked={Number(r?.status)}
            size="small"
            onChange={(e) => toggleActive(r.id, e.target.checked)}
          />
        </IconButton>
      </TableCell>
      <Modal
        open={viewCard}
        onClose={() => {
          setViewCard(false)
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grow in={viewCard}>
          <Grid container md={2.5}>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <CardComponentB2C card={cardDetails} />
            )}
          </Grid>
        </Grow>
      </Modal>
    </TableRow>
  )
}

export default PackageCardList
