import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import { ImageOutlined, Phone, PhoneOutlined, Star } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { Grid, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import Api from "../../Service/Api"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { useDebounce } from "../../hooks/useDebounce"
import { Field, Form, Formik } from "formik"
import { baseurl } from "../../Service/httpCommon"
import { getPackageCardInfo } from "../../Service/api/packageCardApi"
import Loader from "../../components/Loader/Loader"

const useStyles = makeStyles(() => ({
  container: {
    padding: "4rem 1.5rem",
    boxSizing: "border-box !important",
    background: "#F7F7F7",
  },
  infoForm: {
    borderRadius: "0.5rem",
    boxShadow: "0rem 0rem 0.1rem #000",
    padding: "0.5rem",
    boxSizing: "border-box",
    background: "#fff",
  },
  imgContainer: {
    padding: "1rem",
    aspectRatio: "1/1.3",
  },
  imgHolder: {
    display: "grid",
    gridTemplateAreas: `"stack"`,
    overflow: "hidden",
    borderRadius: "0.5rem",
    border: "1px solid #f46d25",
    // background: "blue",
    aspectRatio: 1 / 1.4,
    "& > *": {
      // gridColumn: 1,
      // gridRow: 1,
      // gridArea: 1 / 1,
      gridArea: "stack",
    },
  },

  input: {
    display: "none",
  },
  label: {
    flexDirection: "column",
    border: "1px dashed rgba(157, 157, 157, 1)",
    width: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    gap: "1rem",
    borderRadius: "0.5rem",
    color: "rgba(157, 157, 157, 1)",
    cursor: "pointer",
  },
  previewContainer: {
    padding: "1.2rem",
    boxShadow: "0rem 0rem 0.1rem #000",
    borderRadius: "0.5rem",
    marginLeft: "0rem",
    background: "#fff",
  },

  displayImg: {
    "&:hover ~ $imgOverlay": {
      zIndex: "10",
    },
  },
  imgOverlay: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.2rem",
    borderRadius: "0.5rem",
    width: "100%",
  },
  previewHolder: {
    border: "1px solid #f46d25",
    borderRadius: "0.4rem",
    overflow: "hidden",
    aspectRatio: 1 / 1.4,
    display: "grid",
    gridTemplateAreas: `"previewStack"`,
    "& > *": {
      gridArea: "previewStack",
    },
  },
  transformOverlay: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.2rem",
    borderRadius: "0.5rem",
  },
  previewCardDetails: {
    background:
      "linear-gradient(rgba(0, 0, 0, 0),rgba(40, 40, 40, 0.54),rgba(0, 96, 150, 0.9))",
  },
  previewCardContent: {
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    color: "#fff",
    gap: "0.7rem",
  },
}))

function getObjectUrl(obj) {
  return URL.createObjectURL(obj)
}

const CardUploadForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const { cardId } = useParams()
  const [fetching, setFetching] = useState(false)
  const [previewCard, setPreviewCard] = useState(false)
  const [cardDetails, setCardDetails] = useState({
    id: "",
    name: "",
    price: "",
    duration: "",
    itineraryTimeLine: "",
    imgSrc: null,
    rating: "",
    totalReviews: "",
    uniqueId: localStorage.getItem("unique_id"),
    createdBy: localStorage.getItem("auth"),
  })

  // const fetchCardDetails = async (id) => {
  //   setLoading(true)
  //   try {
  //     const res = await getPackageCardInfo(id)
  //     setCardDetails(res.data)
  //     setLoading(false)
  //   } catch (error) {}
  // }

  const fetchCardDetails = async (cardId) => {
    setFetching(true)
    try {
      const res = await getPackageCardInfo(cardId)
      if (res.status == 200) {
        res.data.duration = {
          label: res.data.duration,
          value: res.data.duration,
        }
        setCardDetails(res.data)
      }
    } catch (error) {
      toast.error("Error while fetching the data.")
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    if (cardId) {
      fetchCardDetails(cardId)
    }
  }, [])

  useEffect(() => {
    console.log("inside card useEffect")
    return () => {
      console.log("cleanup fn card useEffect")
    }
  }, [])

  const handleChangeCardValues = (e) => {
    const { name, value } = e.target
    console.log({ [name]: value })

    setCardDetails((c) => ({ ...c, [name]: value }))
  }

  const convertBlobUrlToFile = async (blobUrl, fileName = "file.txt") => {
    try {
      const response = await fetch(blobUrl)
      const blob = await response.blob()

      const file = new File([blob], fileName, { type: blob.type })

      console.log("Converted File:", file)
      return file
    } catch (error) {
      console.error("Error converting blob URL to file:", error)
    }
  }

  // const [submitting, setSubmitting] = useState(false)
  // const dSubmitting = useDebounce(submitting, 2000)
  const handleSaveCard = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const formData = new FormData()

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (key == "duration") {
          formData.set(key, values[key].value)
        } else {
          formData.set(key, values[key])
        }
      }
    }

    formData.set("imgSrc", await convertBlobUrlToFile(values.imgSrc))

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }
    try {
      const res = await Api.post("b2CPackageSellingPrice", formData)

      if (res.status >= 200 && res.status < 400) {
        toast.success("Added new card")
        history.push("/konnect/packageCardList")
        console.log({ values })

        return
      }
      return Promise.reject(res)
    } catch (error) {
      console.error(error.status)

      toast.error(
        `An Unexpected error occured.Failed to save new card.Error:${error.message}`
      )
    } finally {
      setSubmitting(false)
    }
  }

  if (cardId && fetching) {
    return <Loader />
  }

  return (
    <Formik
      initialValues={cardDetails}
      enableReinitialize
      onSubmit={handleSaveCard}
    >
      {({ values, errors, setFieldValue, touched, isSubmitting }) => (
        <Form>
          <Grid md={12} container className={classes.container} rowSpacing={2}>
            <Grid item md={12}>
              <Typography variant="h4" color="primary">
                Create New Card
              </Typography>
            </Grid>
            <Grid item container md={12}>
              <Grid item container md={9} className={classes.infoForm}>
                <Grid item container md={3.5}>
                  <Grid item md={12} className={classes.imgContainer}>
                    <div className={classes.imgHolder}>
                      <div
                        className={classes.imgOverlay}
                        style={{ zIndex: values.imgSrc == null && 1 }}
                      >
                        <input
                          id="img-upload"
                          className={classes.input}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            console.log(e.target.files[0])
                            const objectUrl = URL.createObjectURL(
                              e.target.files[0]
                            )

                            setFieldValue("imgSrc", objectUrl)
                          }}
                        />
                        <label htmlFor="img-upload" className={classes.label}>
                          <ImageOutlined color="primary" />
                        </label>
                        <div
                          style={{
                            color: "rgba(157, 157, 157, 1)",
                            fontSize: "0.8rem",
                          }}
                        >
                          Drag and drop
                        </div>

                        <div>Add Background Image</div>
                      </div>
                      <div className={classes.displayImg}>
                        <img
                          src={
                            cardId
                              ? `${baseurl}/getimage/${values.imgSrc}`
                              : values.imgSrc
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  {values.imgSrc != null && (
                    <Grid
                      item
                      container
                      md={12}
                      paddingTop={1}
                      textAlign={"center"}
                    >
                      <Grid item md={12}>
                        <Button
                          size="small"
                          style={{ background: "red" }}
                          onClick={() => {
                            setFieldValue("imgSrc", null)
                            setPreviewCard(false)
                          }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item container md={8.5} spacing={1}>
                  <Grid item md={12}>
                    <Typography variant="h5">Add Details</Typography>
                  </Grid>
                  <Grid item container md={12} spacing={6}>
                    <Grid item md={6}>
                      <Field
                        as={TextField}
                        name="name"
                        variant="outlined"
                        label="Package Name"
                        placeholder="Enter Package Name"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Field
                        as={TextField}
                        name="price"
                        variant="outlined"
                        label="Price"
                        placeholder="Enter Package Price"
                        inputMode="numeric"
                        onScroll={(e) => e.preventDefault()}
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#f46d25" }}
                            >
                              ₹
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        disablePortal
                        options={[{ label: "4N | 5D", value: "4N | 5D" }]}
                        getOptionLabel={(option) => option.label}
                        onChange={(evt, option) => {
                          console.log(option)
                          // setCardDetails((c) => ({ ...c, duration: option }))
                          setFieldValue("duration", option)
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Package Duration"
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Select Package Duration"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Field
                        as={TextField}
                        name="itineraryTimeLine"
                        variant="outlined"
                        label="Itinerary Timeline"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Field
                        as={TextField}
                        name="rating"
                        variant="outlined"
                        label="Rating"
                        placeholder="Enter Rating"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Field
                        as={TextField}
                        name="totalReviews"
                        variant="outlined"
                        label="No. of Reviews"
                        placeholder="Enter Reviews"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item container md={3}>
                    <Grid item md={6}>
                      <Button
                        size="small"
                        disabled={values.imgSrc == null}
                        onClick={() => {
                          setPreviewCard(true)
                        }}
                      >
                        Preview
                      </Button>
                    </Grid>
                    <Grid item md={6}>
                      <Button
                        size="small"
                        // onClick={handleSaveCard}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={3}
                padding={"0rem 1.2rem"}
                style={{ height: "100%" }}
              >
                <Grid
                  item
                  container
                  md={12}
                  className={classes.previewContainer}
                >
                  <Grid
                    item
                    md={12}
                    fontSize={"1.3rem"}
                    fontWeight={600}
                    textAlign={"center"}
                  >
                    Preview Screen
                  </Grid>
                  <Grid item md={12} className={classes.previewHolder}>
                    {previewCard && (
                      <>
                        <img
                          src={
                            cardId
                              ? `${baseurl}/getimage/${values.imgSrc}`
                              : values.imgSrc
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <div
                          className={classes.previewCardDetails}
                          style={{
                            background: values.imgSrc == null && "none",
                          }}
                        ></div>
                        <div className={classes.previewCardContent}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "0.85rem",
                            }}
                          >
                            <div
                              style={{
                                background: "rgba(255, 239, 231, 1)",
                                padding: "0.2rem 0.5rem",
                                borderRadius: "0.2rem",
                                color: "#000",
                              }}
                            >
                              {values.duration.value}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Star
                                color="primary"
                                style={{ width: "0.9rem" }}
                              />{" "}
                              <span style={{ color: "#fff" }}>
                                {values.rating}
                              </span>{" "}
                              <span style={{ color: "#ffffff80" }}>
                                ({values.totalReviews})
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: "1.2rem",
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>
                              {values.name}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                              }}
                            >
                              <div style={{ fontSize: "0.8rem" }}>
                                Starting From
                              </div>
                              <div style={{ fontWeight: 600 }}>
                                {currencyFormatter(values.price)}
                              </div>
                            </span>
                          </div>

                          <div
                            style={{
                              background: "rgba(0, 0, 0, 0.3)",
                              borderRadius: "0.3rem",
                              fontSize: "0.9rem",
                              padding: "0.5rem",
                            }}
                          >
                            {values.itineraryTimeLine}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              background:
                                "linear-gradient(to right,rgba(255, 243, 0, 0),rgba(244, 109, 37, 1),rgba(244, 109, 37, 1),rgba(255, 243, 0, 0))",
                              height: "0.1rem",
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "0.5rem",
                            }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                border: "2px solid #fff",
                                padding: "0.5rem",
                              }}
                            >
                              <PhoneOutlined style={{ fill: "#fff" }} />
                            </IconButton>
                            <Button fullWidth size="small">
                              Enquire Now
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CardUploadForm

// const postBody = {
//   name: "",
//   price: 100,
//   duration: "",
//   itineraryTimeLine:"",
//   imgSrc: imageObject,
//   rating: 3,
//   totalReviews:56
// }
