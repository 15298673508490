import {
  CallTwoTone,
  DateRangeOutlined,
  EmailRounded,
  LocationOnOutlined,
  Star,
} from "@material-ui/icons"
import { useEffect, useRef, useState } from "react"
import { BsCalendar2Week } from "react-icons/bs"
import { GiMountainClimbing, GiPathDistance } from "react-icons/gi"
import { LiaMountainSolid } from "react-icons/lia"
import ReactPlayer from "react-player"
import TrekkingLayout from "../../layout/TrekkingLayout/Trekking.layout"
import styles from "./trekking.page.module.css"
import { ReactComponent as ByAir } from "../../assets/icons/travelMode_icons/airplane.svg"
import { ReactComponent as ByCar } from "../../assets/icons/travelMode_icons/car.svg"
import { ReactComponent as ByTrain } from "../../assets/icons/travelMode_icons/train.svg"
import ValleyOfFlowers from "../../assets/illustrations/Trekking_assets/valley of flowers.jpg"
import ValleyOfFlowersTwo from "../../assets/illustrations/Trekking_assets/lfirbav0z2cb1.jpg"
import SphereHead from "../../components/Shapes/sphereHead.shape"
import { itineraryData } from "./data/itinerary.data"
import htmlParser from "html-react-parser"
import { FaRegClock } from "react-icons/fa"
import { GiMountaintop } from "react-icons/gi"
import { Button, IconButton } from "@material-ui/core"
import { useLocation } from "react-router-dom"

const travelModes = [
  {
    header: "By Air",
    icon: ByAir,
    content:
      "Dehradun's Jolly Grant Airport is the closest airport to Haridwar. You may take a bus or cab to get to Haridwar, which is around 37 miles distant. Using the Ambala-Haridwar-Dehradun route, which passes over NH 34 and NH 37, takes one to two hours.",
  },
  {
    header: "By Train",
    icon: ByTrain,
    content:
      "The Haridwar Junction Railway Station is the railway station located in Haridwar. Haridwar is a handy and affordable alternative because regular trains run between it and the main Indian towns. Some of the trains that go between Haridwar and New Delhi, Kolkata, Ahmedabad, Jaipur, etc. include the Shatabdi Express, Mussoorie Express, Upasana Express, Doon Express, and Ahmedabad-Haridwar Express.",
  },
  {
    header: "By Road",
    icon: ByCar,
    content:
      "Haridwar has excellent road connections to Delhi, Haryana, Punjab, Chandigarh, Jaipur, Uttar Pradesh, and other Uttarakhand cities. You can take a bus or drive your own vehicle if you reserve your seats in advance.",
  },
]

const bulletPoints = [
  {
    title: "Clothing",
    list: [
      {
        listTitle: "Trekking Pants",
        listDescription: "Move-friendly, quick-drying pants that are comfy.",
      },
      {
        listTitle: "T-Shirts",
        listDescription:
          "Moisture-wicking t-shirts (full and half sleeves) to keep you dry and comfortable.",
      },
      {
        listTitle: "Warm Layers",
        listDescription:
          "Fleece jacket, down jacket, and thermal innerwear to keep you warm in the cold temperatures.",
      },
      {
        listTitle: "Rain Gear",
        listDescription:
          " A waterproof jacket and pants to protect against rain and wind.",
      },
      {
        listTitle: "Gloves",
        listDescription:
          "Insulated gloves for warmth and waterproof gloves to keep your hands dry.",
      },
      {
        listTitle: "Hat and Cap",
        listDescription:
          "A wide-brimmed hat for sun protection and a warm beanie for cold weather.",
      },
    ],
  },
  {
    title: "Footwear",
    list: [
      {
        listTitle: "Trekking Shoes",
        listDescription:
          "Sturdy, waterproof trekking shoes with good grip and ankle support.",
      },
      {
        listTitle: "Socks",
        listDescription:
          "Moisture-wicking and woolen socks to keep your feet dry and warm.",
      },
    ],
  },
  {
    title: "Accessories",
    list: [
      {
        listTitle: "Backpack",
        listDescription:
          "A durable, comfortable backpack with a rain cover (30 -50 liters).",
      },
      {
        listTitle: "Sunglasses",
        listDescription:
          "UV-protected sunglasses to shield your eyes from the sun and snow glare.",
      },
      {
        listTitle: "Water Bottle",
        listDescription:
          "Reusable water bottles or a hydration system to stay hydrated.",
      },
    ],
  },
]

const TrekkingPage = () => {
  const [trekInfo, setTrekInfo] = useState([
    {
      iconComponent: <LocationOnOutlined />,
      title: "Start Point/End Point",
      subTitle: "Haridwar",
    },
    {
      iconComponent: <DateRangeOutlined />,
      title: "Duration",
      subTitle: "6D/5N",
    },
    {
      iconComponent: <LiaMountainSolid size={30} fill="#fff" />,
      title: "Maximum Altitude",
      subTitle: "14,400 ft",
    },
    {
      iconComponent: <GiPathDistance size={30} fill="#fff" />,
      title: "Trek Distance",
      subTitle: "47 km",
    },
    {
      iconComponent: <BsCalendar2Week size={30} fill="#fff" />,
      title: "Best Time",
      subTitle: "June to September",
    },
    {
      iconComponent: <GiMountainClimbing size={30} fill="#fff" />,
      title: "Trek Difficulty",
      subTitle: "04/10",
    },
  ])

  const navRef = useRef()
  const navItemContainerRef = useRef(null)

  useEffect(() => {
    const navElement = navRef.current
    const navItemContainer = navItemContainerRef.current
    // const observer = new ResizeObserver((entries) => {
    //   console.log(entries[0].contentRect.width <= 786 ? "Hello" : "")
    //   console.log(navItemContainer.lastChild.offsetLeft)
    // })

    // observer.observe(navElement)
    const handleScroll = (e) => {
      const rect = navElement.getBoundingClientRect()
      if (rect.top <= 80) {
        navElement.classList.add(styles["toggleBackground"])
        // navElement.parentElement.classList.add(styles["changeLayout"])
      } else {
        navElement.classList.remove(styles["toggleBackground"])
        // navElement.parentElement.classList.remove(styles["changeLayout"])
      }
    }

    document.addEventListener("scroll", handleScroll)
    return () => document.removeEventListener("scroll", handleScroll)
    // navElement.style.background = "red"
  }, [])

  const tabOptions = [
    { label: "About", value: "about" },
    { label: "Highlights", value: "highlights" },
    { label: "How to Reach", value: "howToReach" },
    { label: "Best Time to Visit", value: "bestTimeToVisit" },
    { label: "Things to Carry", value: "thingsToCarry" },
    { label: "Trek Itinerary", value: "trekItinerary" },
    { label: "Trek Policies", value: "trekPolicies" },
  ]

  const [currentTab, setCurrentTab] = useState("about")

  const trekInfoIcons = {
    Distance: <GiPathDistance size={25} className={styles.iconGradient} />,
    Duration: <FaRegClock size={25} />,
    Elevation: <GiMountaintop size={25} />,
  }

  return (
    <TrekkingLayout>
      <div className={styles.trekInfoContainer}>
        <div className={styles.cardList}>
          {trekInfo.map((t, index) => (
            <div className={styles.cardContainer} key={index}>
              {t.iconComponent}
              <div>{t.title}</div>
              <div>{t.subTitle}</div>
            </div>
          ))}
        </div>

        <div className={styles.nav} ref={navRef}>
          <ul ref={navItemContainerRef}>
            {tabOptions.map((t, index) => (
              <li
                key={`${t.label}-${index}`}
                className={`${styles.tab} ${
                  t.value === currentTab ? styles.active : ""
                }`}
                data-isActive={t.value === currentTab}
                // onClick={(e) => {
                //   e.preventDefault()
                // }}
              >
                <a className={styles.anchorTag} href={`#${t.value}`}>
                  <label htmlFor={`1-${t.value}`} className={styles.tabLabel}>
                    {t.label}

                    <input
                      type="radio"
                      id={`1-${t.value}`}
                      key={t.value}
                      value={t.value}
                      checked={t.value === currentTab}
                      onChange={(e) => {
                        setCurrentTab(e.target.value)
                        const section = document.querySelector(
                          `#${e.target.value}`
                        )
                        section.scrollIntoView({
                          inline: "nearest",
                          behavior: "smooth",
                        })
                      }}
                      className={styles.tabOption}
                    />
                  </label>
                </a>
              </li>
            ))}
            {/* <span className={styles.active}></span> */}
          </ul>
        </div>

        <main className={styles.main}>
          <section id="about" className={`${styles.sectionContainer}`}>
            The Valley of Flowers trek is a mesmerising trip that will take you
            through one of India's most stunning natural settings. It will take
            you on an amazing journey. The ideal balance of scenic beauty,
            spiritual comfort, and adventure may be found on this walk. The
            Valley of Flowers trip guarantees an experience of a lifetime,
            regardless of expertise level: experienced hikers or nature lovers.
            <header className={styles.sectionHeader}>
              <span className={styles.coloredHeader}>About</span> Valley of
              Flowers
            </header>
            <div className={styles.aboutContent}>
              <div className={styles.abContent1}>
                The Flower Valley "Foolon ki Ghati" is a stunning hiking
                adventure perfect for the monsoon season. It is perfectly tucked
                away at 3600 metres above sea level in Uttarakhand's West
                Himalayan area. One of the most popular hikes in the world is
                the Valley of Flowers trip, which offers the chance to see the
                extremely rare blue primula.
              </div>

              <div className={`${styles.abVideo} ${styles.whiteBorder}`}>
                <ReactPlayer
                  url={"https://youtu.be/AjuCYGKt5vY?si=jblXtFKpBL6tAESK"}
                  width="100% !important"
                  height="100%"
                  light={false}
                  playing={false}
                  style={{ width: "100%", borderRadius: "0.5rem !important" }}
                />
              </div>

              <div className={styles.abContent2}>
                In the Hindu epic Ramayan, Hanuman is said to have carried the
                miraculous plant needed to revive Lakshman from the Valley of
                Flowers. The delightful blend of scents released by the flowers
                instantly transports you to a paradisiacal setting as soon as
                you step foot in the valley. At 14,400 feet, a hidden lake forms
                a secret coop for a variety of wildflower species, including
                saxifrage, sedum, lilies, poppy, calendula, daisies, geranium,
                zinnia, and petunia.
              </div>
            </div>
          </section>

          <section id="highlights" className={`${styles.sectionContainer}`}>
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              <span className={styles.coloredHeader}>Highlights</span> of the
              Valley of Flowers
            </header>
            <div className={styles.highlightsContent}>
              <div className={styles.hlContent1}>
                The Flower Valley "Foolon ki Ghati" is a stunning hiking
                adventure perfect for the monsoon season. It is perfectly tucked
                away at 3600 metres above sea level in Uttarakhand's West
                Himalayan area. One of the most popular hikes in the world is
                the Valley of Flowers trip, which offers the chance to see the
                extremely rare blue primula.
              </div>
            </div>
          </section>

          <section id="howToReach" className={`${styles.sectionContainer}`}>
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              <span className={styles.coloredHeader}>How to Reach</span> of
              Valley of Flowers
            </header>
            <div className={styles.highlightsContent}>
              <div className={styles.hlContent1}>
                Reaching the Valley of Flowers involves a combination of various
                modes of transportation, including air, rail, and road travel.
                The trek to this UNESCO World Heritage site is an adventure that
                begins from the bustling town of Haridwar or Rishikesh in
                Uttarakhand, India. Here’s a detailed guide on how to reach
                Valley of Flowers.
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.7em",
                  paddingTop: "1.5em",
                }}
              >
                {travelModes.map((t, i) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <header
                      style={{
                        fontSize: "1.3em",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <span>
                        <t.icon
                          style={{ width: "2em", padding: 0, margin: 0 }}
                        />
                      </span>{" "}
                      {t.header}
                    </header>
                    <div>{t.content}</div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section
            className={`${styles.sectionContainer} ${styles.expandContainer}`}
            id="bestTimeToVisit"
          >
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              <span className={styles.coloredHeader}>Best Time To Visit</span>{" "}
              Valley of Flowers Trek
            </header>
            <div className={styles.bestTimeToVisitContent}>
              <div>
                The best time to visit Valley of Flowers is from July to
                September. This period coincides with the monsoon season, which
                is crucial for the valley's flora. During these months, the
                valley comes alive with a riot of colors as thousands of flowers
                bloom, creating a stunning carpet of vibrant hues.
              </div>

              <div className={styles.contentAndImageContainer}>
                <header>Valley of Flowers in July:</header>
                <div className={styles.timeToVisitContent}>
                  <div className={styles.textContent}>
                    One of the best times to visit the Valley of Flowers trek is
                    in July when much of India experiences the start of the
                    monsoon season. Trekking is made enjoyable and cool by the
                    temperature range of 13°C to 20°C. Rainfall occurs often in
                    July, bringing vibrant vegetation and flowering flowers to
                    life in the valley.
                    <p>
                      If you are considering a walk to the Valley of Flowers in
                      Uttarakhand, you must have sturdy hiking shoes because the
                      terrain might become slick from rain. The valley is most
                      beautiful when the landscape is covered in vibrant
                      flowers, even if it occasionally experiences rain. In
                      addition to the beautiful and vivid sights that make the
                      journey so worthwhile, you should be ready for rainy
                      circumstances.
                    </p>
                  </div>
                  <div className={styles.mediaContainer}>
                    <img src={ValleyOfFlowers} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.contentAndImageContainer}>
              <header>Valley of Flowers in August</header>
              <div className={styles.textContent}>
                This is considered the peak blooming period. The valley is in
                full bloom, and you can witness a spectacular variety of flowers
                in different colors. The weather is cool, and the entire
                landscape is at its most picturesque.
              </div>
            </div>

            <div className={styles.contentAndImageContainer}>
              <header>Valley of Flowers in September</header>
              <div className={styles.textContent}>
                If you wish to witness the Himalayas in their natural glory, you
                have to schedule the Valley of Flowers trek around September.
                All of the blooms are fully grown by this time of year,
                displaying a vibrant array of hues. Late September is a pleasant
                time to hike because there is less rainfall overall. Along the
                route, a large variety of berries are seen.
                <p>
                  All things considered, September promises to be a worthwhile
                  visit to this breathtaking Himalayan Valley. If you're
                  preparing for this lovely journey, gather your belongings and
                  get ready to go right now!
                </p>
              </div>
            </div>
            <div className={styles.contentAndImageContainer}>
              <header>Off-Season: October to June</header>
              <div className={styles.textContent}>
                While the best way to reach Valley of Flowers is during the peak
                season, visiting during the off-season is not recommended. From
                October to June, the valley is either covered in snow or devoid
                of the floral beauty that it is known for. Here’s why these
                months are less favorable:
              </div>
            </div>

            <div className={styles.contentAndImageContainer}>
              <header>Winter Season (October to April):</header>
              <div className={styles.textContent}>
                The valley remains snow-covered, making it inaccessible and
                unsafe for trekking. The harsh weather conditions and freezing
                temperatures are not conducive for a pleasant trek.
              </div>
            </div>

            <div className={styles.contentAndImageContainer}>
              <header>Pre-Monsoon Season (May to June):</header>
              <div className={styles.textContent}>
                The valley is still in a transitional state even though the snow
                is starting to melt. The flowers have not yet bloomed, and the
                landscape lacks the vibrant beauty seen during the monsoon.
              </div>
            </div>
          </section>

          <section
            className={`${styles.sectionContainer} ${styles.expandContainer}`}
            id="thingsToCarry"
          >
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              <span className={styles.coloredHeader}>Things To Carry </span> For
              Valley of Flowers Trek
            </header>

            <div className={styles.textContent}>
              Preparing for the Valley of Flowers trek is crucial to ensure a
              safe, comfortable, and enjoyable experience. Here is a
              comprehensive list of items you should pack for your Valley of
              Flowers tour:
            </div>

            <div className={styles.imgContainer}>
              <img src={ValleyOfFlowers} />
            </div>
            {bulletPoints.map((point, index) => (
              <div className={styles.timeToVisitPoints}>
                <b>{point.title}</b>
                <ul style={{ margin: 0 }}>
                  {point.list.map((l, i) => (
                    <li>
                      <b>{l.listTitle}:</b> {l.listDescription}
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div style={{ marginTop: "1em" }}>
              <b>Fitness and Difficulty Level</b>
              <div className={styles.fitness}>
                <div>
                  A Himalayan high-altitude trip demands serious physical
                  preparation. Your body must acclimate to doing greater work at
                  lower oxygen levels. Thus, cardiovascular exercise is crucial
                  for trekking. By the time your trek begins, we need to know
                  that you can run at least 5 km in 40 minutes as a gauge of
                  your fitness. Try to complete 5 km in 50 minutes if you are 45
                  years of age or older. This is the bare minimum needed. Try to
                  complete 20 kilometers in 60 minutes if you like to ride a
                  bike.
                </div>
                <div className={styles.fitnessMedia}>
                  <img src={ValleyOfFlowersTwo} />
                </div>
              </div>
              <p>
                If you are unable to do the same, your voyage may become
                challenging. Trekkers may at any time be asked to leave the trek
                if they have not prepared themselves sufficiently. Trekkers who
                don't fit our eligibility conditions at the base camp may be
                turned away by the Towno team.
              </p>
            </div>
          </section>

          <section className={styles.sectionContainer} id="trekItinerary">
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              Valley of Flowers
              <span className={styles.coloredHeader}> Trek Itinerary</span>
            </header>

            {itineraryData.map((i) => (
              <div className={styles.trekItinerary}>
                <header>
                  <SphereHead>{i.bulletTitle} : </SphereHead>{" "}
                </header>
                <div className={styles.itineraryContentContainer}>
                  <div className={styles.itineraryHeader}>{i.title}</div>
                  <div className={styles.trekItineraryContainer}>
                    <div className={styles.durationInfoContainer}>
                      {i.info.map((info, idx) => (
                        <>
                          <div className={styles.itineraryInfoCard}>
                            <div> {trekInfoIcons[info.type]}</div>
                            <div className={styles.itineraryInfoTitle}>
                              {info.type}
                            </div>
                            <div className={styles.itineraryInfoContent}>
                              {info.content}
                            </div>
                          </div>
                          {idx < i.info.length - 1 && (
                            <hr className={styles.hr} />
                          )}
                        </>
                      ))}
                    </div>

                    {i.additionalInfo ? (
                      <div className={styles.durationInfoContainer}>
                        {i.additionalInfo.map((info, idx) => (
                          <>
                            <div className={styles.itineraryInfoCard}>
                              <div> {trekInfoIcons[info.type]}</div>
                              <div className={styles.itineraryInfoTitle}>
                                {info.type}
                              </div>
                              <div className={styles.itineraryInfoContent}>
                                {info.content}
                              </div>
                            </div>
                            {idx < i.info.length - 1 && (
                              <hr className={styles.hr} />
                            )}
                          </>
                        ))}
                      </div>
                    ) : null}
                  </div>

                  <div className={styles.trekItineraryImgContainer}>
                    {i.content.media.map((m) => (
                      <div className={styles.itineraryImgWrapper}>
                        <img src={m} />
                      </div>
                    ))}
                  </div>
                  <div>{htmlParser(i.content.body)}</div>
                </div>
              </div>
            ))}
          </section>

          <section className={styles.sectionContainer} id="trekPolicies">
            <header
              className={styles.sectionHeader}
              style={{ paddingTop: "0em" }}
            >
              Valley of Flowers
              <span className={styles.coloredHeader}> Trek Policies</span>
            </header>

            <div className={styles.policyContainer}>
              <div className={styles.policy}>
                <div className={styles.policyHeader}>Term & Condition</div>
                <div className={styles.policyContent}></div>
              </div>

              <div className={styles.policy}>
                <div className={styles.policyHeader}>Cancellation Policy</div>
                <div className={styles.policyContent}>
                  Life is unpredictable and we understand that sometimes you
                  have to cancel or change your trip dates and it is our
                  endeavor to make it as easy as possible for you. However,
                  please understand that we plan everything including guide
                  fees, permits, accommodation and ration in advance. Therefore,
                  any cancellation means inconvenience and certain losses to the
                  people involved in various stages of the programme. Keeping
                  that in mind, our cancellation charges are as below
                  <p>
                    Cancellations prior to 30 days from the start of the event:
                    Get a monetary refund with 15% of cancellation charges.
                  </p>
                  Cancellations between 30 days and 15 days to the start of the
                  event: 50% refund as cash.
                  <p>
                    Cancellations less than 15 days to the start of the event:
                    No refund.
                  </p>
                  Cancellations will be accepted only by email.
                </div>
              </div>
              <div className={styles.policy}>
                <div className={styles.policyHeader}>Payment Terms Policy</div>
                <div className={styles.policyContent}>
                  100.0% of the total tour cost will have to be paid 0 days
                  before the date of booking
                </div>
              </div>
            </div>
          </section>
        </main>

        <aside className={`${styles.aside}`}>
          <div className={`${styles.priceCard} ${styles.sectionContainer}`}>
            <div className={styles.stayInfo}>
              <div
                className={styles.stayInfoHeader}
                style={{ fontWeight: "bold", fontSize: "1.6em" }}
              >
                6 days-5 night
              </div>
              <div className={styles.stayInfoSubHeader}>
                <span>Haridwar to Joshimath</span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4em",
                  }}
                >
                  <Star color="primary" style={{ height: "0.7em" }} />
                  <span style={{ color: "#fff" }}>4.5 </span>
                  <span style={{ color: "#f46d25" }}>|</span> <>56 reviews</>
                </span>
              </div>
            </div>
            <div>
              <div>
                <div style={{ color: "#BCBCBC", fontSize: "0.9rem" }}>
                  Starting From
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ fontSize: "1.5rem", fontWeight: 600 }}>
                    INR 7,500
                  </span>
                  <span>
                    INR{" "}
                    <span
                      style={{
                        textDecoration: "line-through",
                        textDecorationColor: "#f46d25",
                      }}
                    >
                      10,999
                    </span>{" "}
                  </span>
                </div>

                <Button
                  style={{
                    background: "linear-gradient(to right,#FFF300,#F46D25)",
                    marginTop: "1em",
                    fontSize: "1.7em",
                  }}
                  fullWidth
                >
                  Book Now
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.sectionContainer}>
            <div style={{ fontWeight: 600, fontSize: "clamp(1rem,8vw,1.2em)" }}>
              Get assistance for easy booking.
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <IconButton
                  style={{ background: "#FFEFE7", border: "1px solid #F46D25" }}
                  size="small"
                >
                  <CallTwoTone color="primary" />
                </IconButton>
                <a
                  href="tel:+918006800703"
                  style={{
                    textDecoration: "none",
                    color: "#ffff",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Phone</span>
                  <> +91 8006 8007 03</>
                </a>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <IconButton
                  style={{ background: "#FFEFE7", border: "1px solid #F46D25" }}
                  size="small"
                >
                  <EmailRounded color="primary" />
                </IconButton>
                <a
                  href="mailto:hello@towno.in"
                  style={{
                    textDecoration: "none",
                    color: "#ffff",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Email</span>
                  <>hello@towno.in</>
                </a>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </TrekkingLayout>
  )
}
export default TrekkingPage
