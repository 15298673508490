import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material"
import { createContext, useContext, useEffect, useRef, useState } from "react"

const DropDownContext = createContext({
  open: null,
  handleClose: () => null,
})

const useDropdownContext = () => {
  const values = useContext(DropDownContext)
  if (values == null) {
    throw new Error(
      "Ensure that the child components is wrapped by the context provider"
    )
  }
  return values
}

const DropdownMenu = ({ children, label, props, hasDropdown }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current == true && open == false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <DropDownContext.Provider value={{ open, handleClose }}>
      <div onMouseLeave={() => setOpen(false)}>
        <Button
          ref={anchorRef}
          sx={{
            color: "#fff !important",
            textTransform: "none",
          }}
          style={{ fontSize: "clamp(0.75rem, 0.185vw + 0.75rem, 0.875rem)" }}
          onMouseEnter={handleOpen}
        >
          {label}
        </Button>
        {hasDropdown ? (
          <Popper
            open={open}
            anchorEl={anchorRef?.current}
            role="undefined"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement == "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  sx={{ background: "rgba(255, 239, 231, 0.5)" }}
                  onMouseLeave={handleClose}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    {children}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null}
      </div>
    </DropDownContext.Provider>
  )
}

const DropdownMenuList = ({ children, props }) => {
  const { open } = useDropdownContext()

  return (
    <MenuList
      autoFocus={open}
      {...props}
      style={{
        padding: "0.3rem",
      }}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      {children}
    </MenuList>
  )
}

const DropdownMenuItem = ({ children, props }) => {
  const { handleClose } = useDropdownContext()

  return (
    <MenuItem
      onClick={handleClose}
      {...props}
      style={{ padding: "0.3rem", color: "#fff", borderRadius: "0.5rem" }}
      sx={{ "&.MuiMenuItem-root:hover": { background: "#f46d25" } }}
    >
      {children}
    </MenuItem>
  )
}

DropdownMenu.MenuList = DropdownMenuList
DropdownMenu.MenuItem = DropdownMenuItem

export default DropdownMenu
